import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid} from "@material-ui/core";
import ClinicalGraphInterface from "../Clinic/ClinicalGraphInterface";
import {EncounterMockBloc} from "./encounter.mock.bloc";
import DecodedComponent from "../../shared/DecodedComponent";
import {KBarAnimator, KBarPortal, KBarPositioner, KBarProvider, KBarSearch} from "kbar";
import {actions} from "../Clinic/ClinicalGraphInterface/util";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
});

const portalStyle = {
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: "2000",
};


const searchStyle = {
    padding: "12px 16px",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
    outline: "none",
    border: "none",
    color: "var(--foreground)",
};

const animatorStyle = {
    maxWidth: "600px",
    width: "100%",
    background: "var(--background)",
    color: "var(--foreground)",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "var(--shadow)",
};


class CGIMock extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = new EncounterMockBloc();

        this.state = {
            initialised: false,
        };
    }


    componentDidMount() {
        super.componentDidMount();
        this.bloc.initialise();
    }

    render() {

        const { classes } = this.props;
        const { initialised, viewBloc } = this.state;


        return (<>
            { !initialised ? <CircularProgress /> :
            <KBarProvider actions={actions(viewBloc)}>
                <KBarPortal style={portalStyle}>
                    <KBarPositioner style={portalStyle}>
                        <KBarAnimator style={animatorStyle}>
                            <KBarSearch style={searchStyle} />
                        </KBarAnimator>
                    </KBarPositioner>
                </KBarPortal>
                 <ClinicalGraphInterface bloc={viewBloc} />
            </KBarProvider> }
        </>);
    }
}

CGIMock.propTypes = {
};

export default withStyles(styles)(withRouter(CGIMock));

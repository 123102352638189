import { Relationship } from "./GraphWrapper";
import {AllStatusForObservation} from "../component/ClinicalGraph/Observations/Observations.utils";

export class SalientFeatureUtil {

  static filterUnique = (a, key) => {
    let seen = {};
    return a.filter(function(item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  static getFeatures = (bloc, selectedDx) => {
    const { workspaceGraph } = bloc.subject.value;

    const encounter = workspaceGraph?.encounter();
    const patient = workspaceGraph?.encounterPatient();

    if (!selectedDx?.bid) {
      return [];
    }

    const filter = workspaceGraph?.inNodes(selectedDx, Relationship.EVIDENCE_OF)
      .map((vertex) => vertex.bid);

    // const patientObservations = workspaceGraph?.inNodes(patient, "observed_on", filter);

    const result = workspaceGraph?.allNodes("observation", filter)
        .filter((item) => item.status && item.status !== AllStatusForObservation.unknown)
    ;

    return SalientFeatureUtil.filterUnique(result, (item) => item.description);
  };
}

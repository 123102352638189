export const addPhysicalExam = async (context) => {
  const encounterId = context?.encounter?.id;

  const patientEdge = context?.workspace?.graph?.edges?.filter(
    (edge) => edge.to === encounterId && edge?.roles?.includes("patient")
  )[0]?.from;
  const patientIdentifier = context?.workspace?.graph?.vertices.filter(
    (vertex) => vertex?.bid === patientEdge
  )[0]?.reference?.value;

  const payload = {
    command: "add_physical_exam",
    payload: {
      participants: [
        {
          identifier: {
            code: {
              code: "id",
              system: "decoded",
            },
            value: patientIdentifier,
          },
          role: "SUBJECT",
        },
      ],
    },
  };
  await context.bloc.__addPhysicalExam(payload);
};
export const physicalExamStatus = {
  abnormal: "abnormal",
  brief: "brief",
  detailed: "detailed",
  cancelled: "cancelled",
};
export const physicalExamStatusCode = {
  abnormal: 2,
  brief: 1,
  detailed: 0,
  discarded: -1,
};
export const getSelectedStatus = (item) => {
  switch (item.status) {
    case physicalExamStatusCode.detailed:
      return physicalExamStatus.detailed;
    case physicalExamStatusCode.brief:
      return physicalExamStatus.brief;
    case physicalExamStatusCode.discarded:
      return physicalExamStatus.cancelled;
    case physicalExamStatusCode.abnormal:
      return physicalExamStatus.abnormal;
    default:
      return physicalExamStatus.abnormal;
  }
};

export const physicalExamValueStatus = {
  discard: -1,
  valid: 0,
  modified: 1,
};

export const physicalExamValueOrigin = {
  system: 0,
  user: 1,
};

export const handleSave = async (physicalExamination, context) => {
  const payload = {
    data: {
      sections: physicalExamination.sections,
    },
    id: physicalExamination.bid,
  };
  await context.bloc.__updatePhysicalExam(payload);
};

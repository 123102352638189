import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, TextField,} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Typography from "@material-ui/core/Typography";
import AppointmentContext from "../context";

import {FormattedMessage} from "react-intl";
import {appointmentEventService} from "../service/appointment.event.service";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {gendersMandatory} from "../util";

const styles = theme => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    formContainer: {
        width: "inherit",
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    formInputTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.primary.main,
    },
    button: {
        paddingTop: "26px",
        display: "flex",
    }
});


class InsuranceDisplay extends Component {


    constructor(props, context) {
        super(props, context);
    }

    __continue = () => {

        appointmentEventService.update("REGISTRATION_SUBMIT_INSURANCE_DETAIL", { updated: false });
    }

    __edit_form = () => {
        this.props.editForm();
    };

    __isLoadingData = () => {

        return false;
    };

    __renderExistingInsurance = (classes, existingInsurance) => {

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatorForm onSubmit={this.__continue} className={classes.formContainer}>
                        <div className={classes.form}>
                            <div className={classes.formInput}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <Typography variant={"h5"}>
                                            <FormattedMessage id={"patient.payment.type"} defaultMessage={"Payment type"} />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="paymentType"
                                            label="Payment type"
                                            name="paymentType"
                                            value={existingInsurance.type}
                                            disabled={true}
                                        />
                                    </Grid>

                                    { existingInsurance.type === "insurance" && <>

                                        <Grid item xs={12}>
                                            <Typography variant={"h5"}>
                                                <FormattedMessage id={"patient.is.policy.holder.title"} defaultMessage={"Patient is the policy holder"} />
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={existingInsurance.isPolicyHolder}
                                                        id="isPolicyHolder"
                                                        name="isPolicyHolder"
                                                        color="primary"
                                                        disabled={true}
                                                    />
                                                }
                                                label={
                                                    <>
                                                        <FormattedMessage id={"patient.is.policy.holder"} defaultMessage={"Patient is the policy holder"} />
                                                    </>
                                                }
                                            />
                                        </Grid>

                                        { !existingInsurance.isPolicyHolder && (<>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" className={classes.title}>
                                                    <FormattedMessage id="policyholder" defaultMessage={`Policy Holder`} />
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    required
                                                    fullWidth
                                                    id="policyHolderGivenName"
                                                    label="First Name"
                                                    name="policyHolderGivenName"
                                                    autoComplete="given-name"
                                                    value={existingInsurance.policyHolderGivenName}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    required
                                                    fullWidth
                                                    id="policyHolderFamilyName"
                                                    label="Last Name"
                                                    name="policyHolderFamilyName"
                                                    autoComplete="family-name"
                                                    value={existingInsurance.policyHolderFamilyName}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    required
                                                    margin="normal"
                                                    id="policyHolderDob"
                                                    name="policyHolderDob"
                                                    label="Date picker dialog"
                                                    format="MM/dd/yyyy"
                                                    value={existingInsurance.policyHolderDob}
                                                    disabled={true}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>

                                                <TextField
                                                    id="policyHolderGender"
                                                    name="policyHolderGender"
                                                    fullWidth
                                                    select
                                                    required
                                                    label="Gender"
                                                    value={existingInsurance.policyHolderGender}
                                                    disabled={true}
                                                    helperText="Please select your gender"
                                                >
                                                    {gendersMandatory.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>

                                        </>) }

                                        <Grid item xs={12}>
                                            <Typography variant="h5" className={classes.title}>
                                                <FormattedMessage id="insurance" defaultMessage={`Insurance`} />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                id="ememberId"
                                                label="Member ID"
                                                name="ememberId"
                                                value={ existingInsurance.memberId }
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                id="provider"
                                                label="Insurance Provider"
                                                name="provider"
                                                value={existingInsurance.provider}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </> }
                                </Grid>
                            </div>
                            <div className={classes.fill} />
                            <div className={classes.button}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.__edit_form}
                                >
                                    Edit
                                </Button>
                                <div className={classes.fill}></div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.__isLoadingData()}
                                >
                                    {this.__isLoadingData() && <CircularProgress size="1.5em" />}
                                    {!this.__isLoadingData() && "Continue"}
                                </Button>
                            </div>
                        </div>
                    </ValidatorForm>
                </MuiPickersUtilsProvider>
            </div>
        );
    }

    render() {
        let { classes } = this.props;
        let { existingInsurance } = this.props.context;

        return this.__renderExistingInsurance(classes, existingInsurance);
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<InsuranceDisplay context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

import axios from 'axios';

class AdministrationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getSwitches() {

        return axios.get(`${this.baseUrl}/api/administration/switches`);
    }

    toggleSwitch(_data) {

        return axios.post(`${this.baseUrl}/api/administration/_command`, _data);
    }
}

export const administrationApi = new AdministrationApi();

import { Relationship } from "./GraphWrapper";


export function isHPIObservation(observation) {

  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata?.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(['hpi','symptom'].includes(evaluationMethod.code?.code)) {
        return true;
      }
    }
  }

  if(isContextObservation(observation) || isResultObservation(observation) || isPhysicalExamObservation(observation) || isDiagnosisObservation(observation) || isJudgementObservation(observation)) {
    return false;
  }

  return true;
}

export function isPhysicalExamObservation(observation) {

  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(evaluationMethod.physicalExam.section?.length > 0) {
        return true;
      }
    }
  }
  return false;
}


export function isContextObservation(observation) {

  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(['historical_finding','context'].includes(evaluationMethod.code?.code)) {
        return true;
      }
    }
  }

  return observation.source?.code?.code === "history";
}


export function isResultObservation(observation) {

  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(['test_result', 'assessment', 'result'].includes(evaluationMethod.code?.code)) {
        return true;
      }
    }
  }
  return false;
}


export function isDiagnosisObservation(observation) {
  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(['diagnosis'].includes(evaluationMethod.code?.code)) {
        return true;
      }
    }
  }
  return false;
}


export function isJudgementObservation(observation) {
  if(observation.metadata?.evaluationMethods.length > 0) {
    const evaluationMethods = observation.metadata.evaluationMethods;
    for(let i = 0; i < evaluationMethods.length; i++) {
      const evaluationMethod = evaluationMethods[i];
      if(['md_judgement'].includes(evaluationMethod.code?.code)) {
        return true;
      }
    }
  }
  return false;
}


export class ObservationUtil {


  constructor(bloc) {
    this.bloc = bloc;
  }

  getFilteredObservations = (filter) => {

    const { differential, workspaceGraph } = this.bloc.subject.value;

    if (!differential.highlightedDx?.bid) {
      return workspaceGraph?.allNodes("observation").filter(observation => filter(observation)).sort(this.__sortObservation);
    }

    return workspaceGraph?.inNodesSorted(differential.highlightedDx, Relationship.EVIDENCE_OF).filter(observation => filter(observation)).sort(this.__sortObservation);
  };

  __sortObservation = (a, b) => {

    if(a.note && !b.note) return -1;
    if(!a.note && b.note) return 1;

    const aProp = a?.code?.value ?? a?.code?.code?.display;
    const bProp = b?.code?.value ?? b?.code?.code?.display;

    return aProp.toLowerCase().localeCompare(bProp.toLowerCase())
  }

  getObservations = () => {

    const { differential, workspaceGraph } = this.bloc.subject.value;

    if (!differential.highlightedDx?.bid) {
      return workspaceGraph?.allNodes("observation");
    }

    return workspaceGraph?.inNodesSorted(differential.highlightedDx, Relationship.EVIDENCE_OF);
  };

  getAllObservations = () => {

    const { workspaceGraph, } = this.bloc.subject.value;

    return workspaceGraph?.allNodes("observation");
  };

  conditions = (observation) => {

    const { workspaceGraph, } = this.bloc.subject.value;

    return workspaceGraph?.allConditions(observation);
  };
}

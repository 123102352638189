import {phoneUtil} from "../../../../../utils/phone";
import {appointmentEventService} from "../service/appointment.event.service";


export const genders = [
    {
        value: 'M',
        label: 'Male',
    },
    {
        value: 'F',
        label: 'Female',
    },
    {
        value: 'U',
        label: 'Unspecified',
    }
];

export const gendersMandatory = [
    {
        value: 'M',
        label: 'Male',
    },
    {
        value: 'F',
        label: 'Female',
    }
];



class AppointmentDataChange {

    __handleTextChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };

    __handleGenderChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };

    __handleCheckboxChange = (event) => {
        appointmentEventService.update("REGISTRATION_FLAG_CHANGE", {
            target: event.target.name,
            value: event.target.checked,
        });
    };

    __handleProviderChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };
    __handlePlanTypeChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };
    __handleUserPlanChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };


    __handleDateChange = (name) => (date) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: name,
            value: date,
        });
    };
}

export const appointmentDataChange = new AppointmentDataChange();

export function createAccountRequest(form) {

    let { firstName, lastName, gender, dateOfBirth, acceptTerms, number, email, addressPostcode } = form;

    return {
        name: {
            given: firstName,
                family: lastName
        },
        dob: dateOfBirth,
            gender: gender,
        contactDetails: {
        address: {
            line1: "",
                city: "",
                locality: "",
                postcode: addressPostcode,
                country: "US"
        },
        number: phoneUtil.formatPhoneNumberForRegistration(number),
            email: email,
    },
        acceptTerms: acceptTerms,
        communicationConsent: acceptTerms,
        paymentInformation: createEligibilityRequest(form),
        appointmentInformation: {},
    };
}

export function createEligibilityRequest(form) {
    let { memberId, policyHolderGivenName, policyHolderFamilyName, policyHolderGender, policyHolderDob, provider, planType, userPlan, isPolicyHolder } = form;

    let members = [];
    if(!isPolicyHolder) {
        members.push({
            name: {
                given: policyHolderGivenName,
                family: policyHolderFamilyName,
            },
            role : "POLICY_HOLDER",
            sex: policyHolderGender,
            dob: policyHolderDob,
        });
    }

    return {
        memberId: memberId,
        type: "INSURANCE",
        provider: {
            name: provider
        },
        plan: {
            id: userPlan.planId,
            name: userPlan.planName,
            type: planType,
        },
        members: members,
    }
}

import axios from 'axios';

class AppointmentApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    schedule(request) {

        return axios.post(`${this.baseUrl}/api/appointments/_schedule`, request);
    }

    cancel(appointmentId, request) {

        return axios.post(`${this.baseUrl}/api/appointments/${appointmentId}/_cancel`, request);
    }

    getCancelReasons() {

        return axios.get(`${this.baseUrl}/api/appointments/cancel_reasons`);
    }

    checkin(appointmentId) {

        return axios.put(`${this.baseUrl}/api/appointments/${appointmentId}/_check_in`, {});
    }

    virtualWaiting(location, status, types) {

        //IN_PERSON_WALK_IN,VIRTUAL_WALK_IN,IN_PERSON
        return axios.get(`${this.baseUrl}/api/organisations/${location}/waiting_room/_queue?type=${types.join(",")}&status=${status.join(",")}`);
    }

    listEncountersInVirtualWaiting(location, page, size, data) {

        //IN_PERSON_WALK_IN,VIRTUAL_WALK_IN,IN_PERSON
        return axios.post(`${this.baseUrl}/api/organisations/${location}/waiting_room/_filter?p=${page}&s=${size}`, data);
    }

    startEncounter(appointmentId) {

        return axios.post(`${this.baseUrl}/api/organisations/virtual/waiting_room/_start_encounter`, { id: appointmentId });
    }

    currentEncounters() {

        return axios.get(`${this.baseUrl}/api/organisations/virtual/appointments/_current`);
    }

    updateAppointmentState(appointmentId, status, action) {

        return axios.patch(`${this.baseUrl}/api/organisations/virtual/appointments/${appointmentId}/_update_status`, { id: appointmentId, state: status, action: action });
    }

    registerDelay(organisationId, appointmentId, request) {

        return axios.post(`${this.baseUrl}/api/organisations/${organisationId}/appointments/${appointmentId}/_delay`, request);
    }

    sendCommunication(organisationId, appointmentId, request) {

        return axios.post(`${this.baseUrl}/api/organisations/${organisationId}/appointments/${appointmentId}/_send_communication`, request);
    }

    getAppointmentForDate = (customerId, date) => {

        return axios.post(`${this.baseUrl}/api/customers/${customerId}/appointments/_search`, {
            date: date,
            status: ["WAITING","RESERVED"]
        })
    }
}

export const appointmentApi = new AppointmentApi();

import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const styles = {
  drawerChip: {
    minHeight: 32,
    backgroundColor: "#F5F5F5",
    borderRadius: 22,
    marginTop: 6,
    marginBottom: 6,
    justifyContent: "flex-start",
    maxWidth: 322,
    minWidth: 322,
    color: "#333333",
    paddingTop: 7,
    paddingBottom: 7,
  },
  label: {
    paddingLeft: 24,
    width: "100%",
    lineBreak: "auto",
    whiteSpace: "break-spaces",
  },
  root: {
    minHeight: 32,
    maxHeight: 100,
    height: "auto",
  },
};
function DHDrawerChip({ drawerItem, ...props }) {
  return (
    <Chip
      {...props}
      style={{ ...styles.drawerChip, ...props.style }}
      classes={{
        label: props.classes.label,
        root: props.classes.root,
        icon: props.classes.icon,
      }}
      label={props.label}
    />
  );
}
export default withStyles(styles)(DHDrawerChip);

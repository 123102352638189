import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import ScheduleWidgetContext from "../context";

import {v4 as uuidv4} from 'uuid';
import DecodedComponent from "../../../shared/DecodedComponent";
import {Bloc as SchedulingBloc} from "./bloc";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Button, Checkbox, CircularProgress, FormControlLabel, Grid, Link, MenuItem, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {appointmentDataChange, genders} from "../Appointment/util";
import {DateMask, PhoneTextMask, SsnTextMask, ZipTextMask} from "../../../shared/InputTextMask";
import {STATES} from "./bloc";
import PatientPreview from "./PatientPreview";
import {createScheduleRequest} from "../Appointment/util/form";
import {tenantUtil} from "../../../../utils/tenant";

const styles = theme => ({
    root: {
        flex: "1 1 auto",
        width: "100%",
        padding: "60px 100px",
    },

    fill: {
        flex: "1 1 auto",
    },

    containerHeader: {
    },


    containerBody: {
        paddingTop: "30px",
        flex: "1 1 auto",
        maxHeight: "100%",
    },

    actions: {
        display: "flex",
        flexDirection: "row-reverse",
    },
});

const CHARACTERS = "abcdefghijklmnopqrstuvwxyz";
const NUMBERS = "0987654321";

class ScheduleWidget extends DecodedComponent {

    constructor(props) {
        super(props);

        let overrides = {}

        if(tenantUtil.tenant() === 'demo') {

            let random = "";
            for ( let i = 0; i < 6; i++ ) {
                random += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS.length));
            }

            let number = "";
            for ( let i = 0; i < 4; i++ ) {
                number += NUMBERS.charAt(Math.floor(Math.random() * NUMBERS.length));
            }

            overrides.givenName = random.charAt(0).toUpperCase() + random.slice(1)
            overrides.familyName = "DecodedTest"
            overrides.email = random + "doe.test@decodedhealth.com"
            overrides.number = "(202) 555-" + number
            overrides.addressLine1 = "na"
            overrides.addressLine1 = "na"
            overrides.addressLine2 = "na"
            overrides.addressCity = "na"
            overrides.addressAdministrativeArea = "CA"
            overrides.addressPostcode = "91111"
            overrides.reasonForVisit = "System testing"
            overrides.appointmentType = "fc844e6a-7d9f-11ec-bbd1-42010a924016"
            overrides.acceptTerms = true
        }

        this.bloc = new SchedulingBloc(overrides);

        this.state = {
            track: uuidv4(),
            loading: false,
            providerList: [],
            steps: this.__getSteps(),
            step: 0,
            completed: [],
            success: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.bloc.initialise();
    }


    __getSteps = () => {
        return 0;
    }

    __handleTextChange = (event) => this.bloc.handleFormTextChange(event.target.name, event.target.value);

    __handleAgeChange = (event) => {
        this.bloc.handleFormTextChange(event.target.name, event.target.value);

        if(event.target.value && event.target.value !== "") {
            const date = new Date();
            date.setFullYear(date.getFullYear() - event.target.value);
            let month = date.getMonth() + 1
            const string = `${month < 10 ? "0" + month : month }/${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${date.getFullYear()}`;
            this.bloc.handleFormTextChange('dob', string)
        }
    }

    __handleCheckboxChange = (event) => this.bloc.handleFormTextChange(event.target.name, event.target.checked);

    __search = () => this.bloc.tryCustomerMatch();

    __submit = () => {

        if(this.state.loading) return;

        this.bloc.scheduleAppointment(this.props.onComplete);
    }

    __isSearchValid = () => {

        let { form, } = this.state;

        return form.givenName && form.givenName !== "" &&
            form.familyName && form.familyName !== "" &&
            form.dob && form.dob !== "" &&
            ((form.email && form.email !== "") ||(form.number && form.number !== ""));
    }

    __isFormValid = () => {

        let { form, } = this.state;

        return form.givenName && form.givenName !== "" &&
            form.familyName && form.familyName !== "" &&
            form.gender && form.gender !== "" &&
            form.dob && form.dob !== "" &&
            form.addressLine1 && form.addressLine1 !== "" &&
            form.addressCity && form.addressCity !== "" &&
            form.addressAdministrativeArea && form.addressAdministrativeArea !== "" &&
            form.addressPostcode && form.addressPostcode !== "" &&
            form.number && form.number !== "" &&
            (!form.ssn || form.ssn === "" || form.ssn.length > 8) &&
            form.acceptTerms && form.acceptTerms !== "";
    }

    __genderKey = (e) => {

        if(e.key === 'M' || e.key === 'm') {
            this.bloc.handleFormTextChange('gender', 'M')
            e.preventDefault()
        } else if(e.key === 'F' || e.key === 'f') {
            this.bloc.handleFormTextChange('gender', 'F')
            e.preventDefault()
        } else if(e.key === 'U' || e.key === 'u') {
            this.bloc.handleFormTextChange('gender', 'U')
            e.preventDefault()
        }
    }

    render() {

        let { classes } = this.props;
        let { serviceProviders, form, match, loading, initialised } = this.state;

        const context = {
            bloc: this.bloc,
        };

        if(!initialised) {
            return <CircularProgress />
        }

        return (
            <ScheduleWidgetContext.Provider value={context}>
                <div className={classes.root}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ValidatorForm
                            autoComplete='off'
                            onSubmit={this.__submit}
                            className={classes.validation}
                        >
                            <div className={classes.form}>
                                <div className={classes.formInput}>
                                    <Grid container spacing={4}>

                                        <Grid item xs={12}>
                                            <Typography variant={"h3"} component="h3">
                                                Schedule Appointment
                                            </Typography>
                                        </Grid>


                                        {/*<Grid item xs={12}>*/}
                                        {/*    <Typography variant={"h4"} component="h4">*/}
                                        {/*        Patient Details*/}
                                        {/*    </Typography>*/}
                                        {/*</Grid>*/}

                                        <Grid item xs={12} sm={8} md={5}>
                                            <TextValidator
                                                autofocus
                                                required
                                                fullWidth
                                                autoComplete='off'
                                                inputProps={{
                                                    autoComplete: 'off'
                                                }}
                                                id="givenName"
                                                label="Patient First Name"
                                                name="givenName"
                                                value={form.givenName}
                                                onChange={this.__handleTextChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={5}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="familyName"
                                                label="Patient Last Name"
                                                type="text"
                                                name="familyName"
                                                value={form.familyName}
                                                onChange={this.__handleTextChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={5}>
                                            <TextField
                                                id="gender"
                                                name="gender"
                                                fullWidth
                                                select
                                                required
                                                label="Gender"
                                                value={form.gender}
                                                onChange={this.__handleTextChange}
                                                onKeyDown={this.__genderKey}
                                            >
                                                {genders.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {/*<Grid item xs={12} sm={8} md={5}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        required*/}
                                        {/*        fullWidth*/}
                                        {/*        id="dob"*/}
                                        {/*        label="Date of birth"*/}
                                        {/*        name="dob"*/}
                                        {/*        value={form.dob}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*        placeholder="MM/dd/yyyy e.g. 02/01/2000"*/}
                                        {/*        validators={['matchRegexp:^(0[1-9]|1[012])/(0[0-9]|[12][0-9]|3[01])/(18|19|20)[0-9]{2}$']}*/}
                                        {/*        errorMessages={['Invalid date of birth. Please use the format MM/dd/yyyy e.g. 02/01/2000']}*/}
                                        {/*        InputProps={{*/}
                                        {/*            inputComponent: DateMask,*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}

                                        <Grid item xs={12} sm={8} md={5}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="age"
                                                label="Age"
                                                name="age"
                                                value={form.age}
                                                onChange={this.__handleAgeChange}
                                                placeholder=""
                                                validators={['matchRegexp:^[0-9]{1,3}$']}
                                                errorMessages={['Age < 120 year']}
                                            />
                                        </Grid>

                                        {/*<Grid item xs={12} sm={8} md={5}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        fullWidth*/}
                                        {/*        id="email"*/}
                                        {/*        label="Email Address"*/}
                                        {/*        name="email"*/}
                                        {/*        type="email"*/}
                                        {/*        value={form.email}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*        validators={['isEmail']}*/}
                                        {/*        errorMessages={['email is not valid']}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12} sm={8} md={5}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        required*/}
                                        {/*        fullWidth*/}
                                        {/*        id="number"*/}
                                        {/*        label="Mobile Number"*/}
                                        {/*        name="number"*/}
                                        {/*        value={form.number}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*        inputMode="tel"*/}
                                        {/*        autoComplete='off'*/}
                                        {/*        inputProps={{*/}
                                        {/*            autoComplete: 'off'*/}
                                        {/*        }}*/}
                                        {/*        placeholder="e.g. (202) 555-1234"*/}
                                        {/*        validators={['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']}*/}
                                        {/*        errorMessages={['Invalid phone number']}*/}
                                        {/*        InputProps={{*/}
                                        {/*            inputComponent: PhoneTextMask,*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12} sm={8} md={5}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        fullWidth*/}
                                        {/*        id="ssn"*/}
                                        {/*        label="Social security number (optional)"*/}
                                        {/*        name="ssn"*/}
                                        {/*        value={form.ssn}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*        placeholder="e.g. 888-88-8888"*/}
                                        {/*        validators={['matchRegexp:^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$']}*/}
                                        {/*        errorMessages={['Invalid social security number.']}*/}
                                        {/*        InputProps={{*/}
                                        {/*            inputComponent: SsnTextMask,*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12}>*/}
                                        {/*    <Button*/}
                                        {/*        type="button"*/}
                                        {/*        variant="contained"*/}
                                        {/*        color="primary"*/}
                                        {/*        disabled={!this.__isSearchValid()}*/}
                                        {/*        onClick={this.__search}*/}
                                        {/*    >*/}
                                        {/*        {match?.loading ? <CircularProgress size="1.5em"/> : "Match patient"}*/}
                                        {/*    </Button>*/}
                                        {/*    <br />*/}
                                        {/*</Grid>*/}

                                        {/*{ match?.candidates && match.candidates.length > 0 &&*/}
                                        {/*    <>*/}
                                        {/*        <Grid item xs={12}>*/}
                                        {/*            <Typography variant={"h5"} component="h5">*/}
                                        {/*                Candidates*/}
                                        {/*            </Typography>*/}
                                        {/*        </Grid>*/}

                                        {/*        <Grid item xs={12}>*/}
                                        {/*            <div style={{ display: "flex" }}>*/}
                                        {/*                { match?.candidates.map(candidate => <PatientPreview onCandidateHighlight={(id) => this.bloc.matchHighlight(id) } onCandidateConfirm={(_candidate) => this.bloc.matchConfirm(_candidate) } highlighted={match.highlighted} candidate={candidate} />) }*/}
                                        {/*            </div>*/}
                                        {/*        </Grid>*/}
                                        {/*        <Grid item xs={12}>*/}
                                        {/*            <Button*/}
                                        {/*                type="button"*/}
                                        {/*                variant="contained"*/}
                                        {/*                color="primary"*/}
                                        {/*                onClick={() => this.bloc.matchReset()}*/}
                                        {/*            >*/}
                                        {/*                Close*/}
                                        {/*            </Button>*/}
                                        {/*        </Grid>*/}
                                        {/*    </>*/}
                                        {/*}*/}

                                        {/*<Grid item xs={12}>*/}
                                        {/*    <Typography variant={"h5"} component="h5">*/}
                                        {/*        Address*/}
                                        {/*    </Typography>*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        required*/}
                                        {/*        fullWidth*/}
                                        {/*        id="addressLine1"*/}
                                        {/*        label="Line 1"*/}
                                        {/*        type="text"*/}
                                        {/*        name="addressLine1"*/}
                                        {/*        value={form.addressLine1}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        fullWidth*/}
                                        {/*        id="addressLine2"*/}
                                        {/*        label="Line 2"*/}
                                        {/*        type="text"*/}
                                        {/*        name="addressLine2"*/}
                                        {/*        value={form.addressLine2}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        required*/}
                                        {/*        fullWidth*/}
                                        {/*        id="addressCity"*/}
                                        {/*        label="City"*/}
                                        {/*        type="text"*/}
                                        {/*        name="addressCity"*/}
                                        {/*        value={form.addressCity}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item xs={12} sm={8}>*/}

                                        {/*    <TextField*/}
                                        {/*        id="addressAdministrativeArea"*/}
                                        {/*        name="addressAdministrativeArea"*/}
                                        {/*        fullWidth*/}
                                        {/*        select*/}
                                        {/*        required*/}
                                        {/*        label="State"*/}
                                        {/*        value={form.addressAdministrativeArea}*/}
                                        {/*        onChange={appointmentDataChange.__handleTextChange}*/}
                                        {/*    >*/}
                                        {/*        {STATES.map((option) => (*/}
                                        {/*            <MenuItem key={option.code} value={option.code}>*/}
                                        {/*                {option.display}*/}
                                        {/*            </MenuItem>*/}
                                        {/*        ))}*/}
                                        {/*    </TextField>*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <TextValidator*/}
                                        {/*        required*/}
                                        {/*        id="addressPostcode"*/}
                                        {/*        name="addressPostcode"*/}
                                        {/*        label="Zip Code"*/}
                                        {/*        fullWidth*/}
                                        {/*        value={form.addressPostcode}*/}
                                        {/*        onChange={this.__handleTextChange}*/}
                                        {/*        validators={['matchRegexp:(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)']}*/}
                                        {/*        errorMessages={['Invalid zip code']}*/}
                                        {/*        InputProps={{*/}
                                        {/*            inputComponent: ZipTextMask,*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}

                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <Typography variant={"h4"} component="h4">*/}
                                        {/*        Appointment Details*/}
                                        {/*    </Typography>*/}
                                        {/*</Grid>*/}

                                        <Grid item xs={12} sm={8}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="reasonForVisit"
                                                label="Reason for visit"
                                                name="reasonForVisit"
                                                value={form.reasonForVisit}
                                                onChange={this.__handleTextChange}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={8}>
                                            <TextField
                                                id="appointmentType"
                                                name="appointmentType"
                                                fullWidth
                                                select
                                                required
                                                label="Appointment Type"
                                                value={form.appointmentType}
                                                onChange={this.__handleTextChange}
                                                helperText="Please select the desired appointment type">
                                                { serviceProviders && serviceProviders
                                                    .flatMap(_item => _item.serviceCatalogs)
                                                    .flatMap(_item => _item.services)
                                                    .map(_item => <MenuItem key={`service-select-${_item.id}`} value={_item.id}>{_item.description}</MenuItem>)}
                                            </TextField>
                                        </Grid>



                                        {/*<Grid item xs={12} sm={8}>*/}
                                        {/*    <FormControlLabel*/}
                                        {/*        control={*/}
                                        {/*            <Checkbox*/}
                                        {/*                required*/}
                                        {/*                checked={form.acceptTerms}*/}
                                        {/*                onChange={this.__handleCheckboxChange}*/}
                                        {/*                name="acceptTerms"*/}
                                        {/*                color="primary"*/}
                                        {/*            />*/}
                                        {/*        }*/}
                                        {/*        label={*/}
                                        {/*            <>*/}
                                        {/*                Patient acknowledges and consents to <Link*/}
                                        {/*                rel="noopener noreferrer"*/}
                                        {/*                target="_blank"*/}
                                        {/*                href={""}*/}
                                        {/*            >*/}
                                        {/*                The Terms of Use, Consent to Treatment, and Notice of Privacy Practices*/}
                                        {/*            </Link>*/}
                                        {/*            </>*/}
                                        {/*        }*/}
                                        {/*    />*/}
                                        {/*</Grid>*/}


                                    </Grid>
                                </div>

                                <div className={classes.fill}/>

                                <div className={classes.actions}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!this.__isFormValid() || loading}
                                        onClick={this.__submit}
                                    >
                                        {loading && <CircularProgress size="1.5em"/>}
                                        {!loading && "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </ValidatorForm>
                    </MuiPickersUtilsProvider>
                </div>
            </ScheduleWidgetContext.Provider>
        );
    }
}


export default withStyles(styles)(ScheduleWidget);

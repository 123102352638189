class PhoneUtil {

    getNumberParts(number) {
        let phone = number.trim().replace(/[()]/g, '');
        const separators = [' ', '-'];
        return phone.split(new RegExp(separators.join('|'), 'g'));
    }

    formatPhoneNumber(number) {
        let components = this.getNumberParts(number)
        return "+1"+components[0]+components[1]+components[2]
    }

    formatPhoneNumberShort(contactNumber) {
        return `${contactNumber.area}${contactNumber.prefix}${contactNumber.line}`;
    }

    formatPhoneNumberFull(contactNumber) {
        return `${contactNumber.country}${contactNumber.area}${contactNumber.prefix}${contactNumber.line}`;
    }

    formatPhoneNumberFullSpaced(contactNumber) {
        return `${contactNumber.country} ${contactNumber.area} ${contactNumber.prefix} ${contactNumber.line}`;
    }

    formatPhoneNumberShortForInput(contactNumber) {
        return `(${contactNumber.area}) ${contactNumber.prefix}-${contactNumber.line}`;
    }

    formatPhoneNumberForRegistration(number) {
        let components = this.getNumberParts(number)
        return {
            type: "CELL",
            country: "+1",
            area: components[0],
            prefix: components[1],
            line: components[2]
        }
    }

    formatPhoneNumberForDialout(number) {
        return {
            type: "CELL",
            country: "+1",
            area: number.substring(0,3),
            prefix: number.substring(3,6),
            line: number.substring(6,10)
        }
    }
}

export const phoneUtil = new PhoneUtil();

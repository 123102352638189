import React, { Component } from 'react';
import {DHCalendar, DHDivider, DHFormInput, DHInput} from "../../../../Common";
import DHFormRow from "../../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {Button, ButtonBase, IconButton, Typography} from "@material-ui/core";
import {dateUtil} from "../../../../../util/date";
import {patientChartUtils} from "../../../../../util/PatientChartUtils";
import DHFormTitle from "../../../../Common/DHFormTitle";
import {DeleteOutline} from "@material-ui/icons";
import FormComponent from "../Common/FormComponent";
import QuickSelection from "../QuickSelection";


const styles = theme => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    },
    suggestionBtn: {
        '& span': {
            fontSize: "10px"
        }
    }
})

const CRITICALITY_OPTIONS = [
    {label: "low", value: "low"},
    {label: "high", value: "high"},
    {label: "unable-to-assess", value: "unable to assess"},
]

const SEVERITY_OPTIONS = [
    {label: "mild", value: "mild"},
    {label: "moderate", value: "moderate"},
    {label: "severe", value: "severe"},
]

const initState = {
    loading: false
}

class ProcedureForm extends FormComponent {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    render() {

        let { classes, value } = this.props

        return (
            <React.Fragment>
                <DHFormTitle label={value.code.display}>
                    {(value.recorder || value.recordedDate) && patientChartUtils.renderTitleRecordText(value)}
                    <IconButton onClick={this.__delete}>
                        <DeleteOutline />
                    </IconButton>
                </DHFormTitle>

                <DHFormRow label={"Performed"}>
                    <DHFormInput name={"performed"} size={"large"} value={value.performed?.onset} onChange={this.__updateOnsetText}/>
                </DHFormRow>
                <DHFormRow label={""}>
                    <QuickSelection target={"performed"} onClick={this.__populateSuggestion}></QuickSelection>
                </DHFormRow>
                <DHDivider/>

            </React.Fragment>
        )

    }
}

export default withStyles(styles)(ProcedureForm);

import React from "react";
import { DraggableItem, DropZone } from "../../CGICommon";
import { isEmpty } from "loadsh";
import { DHChip } from "../../Common";
import { withStyles } from "@material-ui/core";
import { processDrop } from "./DifferentialDX.utils";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import {Skeleton} from "@material-ui/lab";
import {isDropAllowed} from "../../CGICommon/DragAndDrop/dndUtils";
import {OntologyConstants} from "../../../bloc";

const styles = {
  emptyCDX: {
    color: "#00D1FF",
    fontSize: 12,
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 6,
    paddingBottom: 6,
    textAlign: "center",
    margin: "1em",
    borderWidth: 1,
    borderColor: "#00d1ff",
    borderStyle: "dashed",
    borderRadius: 32,
  },
  occupiedCdx: {
    color: "#00D1FF",
    fontSize: 12,
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 6,
    paddingBottom: 6,
    textAlign: "center",
    margin: "1em",
    borderWidth: 1,
    borderColor: "#00d1ff",
    borderStyle: "dashed",
    borderRadius: 32,
  },
  currentCDX: {
    backgroundColor: "#777777",
  },
};

const semanticTypes = OntologyConstants.CONDITION.semantic;

class CurrentWorkingDX extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;
        this.state = { initialised: false };
    }


    render() {

        const { classes, context } = this.props;
        const { initialised, currentComplaint, differential, workspaceGraph } = this.state;

        if(!initialised) {
            return <div><Skeleton /></div>;
        }

        const candidates = workspaceGraph?.encounterReasonForVisitConditions(currentComplaint, ["confirmed"]) || [];
        const workingDx = candidates.length > 0 ? candidates[0] : {};

        return (
            <div style={{ clear: "both", marginTop: 18 }}>
                <DropZone
                    accept={["dxList"]}
                    allowedSemanticTypes={semanticTypes}
                    processDrop={(item) => {
                        if (!isDropAllowed({ item, allowedSemanticTypes: semanticTypes })) {
                            return;
                        }

                        this.bloc.diagnosis().setWorkingDx(item);
                    }}
                >
                    <div
                        className={isEmpty(workingDx) ? classes.emptyCDX : undefined}
                    >
                        {isEmpty(workingDx) && "Drag working diagnosis here"}
                        {!isEmpty(workingDx) && (
                            <DraggableItem
                                type={"currentWorkingDX"}
                                item={{
                                    ...workingDx,
                                    source: "currentWorkingDX",
                                }}
                                key={workingDx.bid}
                                accept={["currentWorkingDX"]}
                            >
                                <DHChip
                                    label={
                                        workingDx.code.value ??
                                        workingDx.code.code.display
                                    }
                                    onClick={(e) => {
                                        if (workingDx.bid === differential.highlightedDx?.bid) {
                                            this.bloc.clearSelectedDX();
                                        } else {
                                            this.bloc.setSelectedDX(e.target, workingDx);
                                        }
                                    }}
                                    style={{
                                        backgroundColor:
                                            workingDx.bid === differential.highlightedDx?.bid
                                                ? "#00D1FF"
                                                : "#777777",
                                        color: "#fff",
                                    }}
                                    colortiles={[
                                        this.bloc.differentialColour().getColour(currentComplaint.bid, workingDx.bid),
                                    ]}
                                />
                            </DraggableItem>
                        )}
                    </div>
                </DropZone>
            </div>
        );
    }
}



export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<CurrentWorkingDX context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import {FormattedMessage} from "react-intl";
import {customersApi} from "../../../../../../utils/services/customers.api";
import {notificationService} from "../../../../../../utils/notification";
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";
import {clinicWaitingRoomEventService} from "../../../service/clinic.waitingroom.event.service";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    fill: {
        flex: "1 1 auto",
    },
    actionButton: {
      paddingLeft: "24px",
    },
    notificationText: {
      paddingRight: "64px",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
});

const DEFAULT_REASONS = [
    {
        id: 0,
        description: "OTHER"
    }
]

class BaseCard extends Component {

    state = {
        open: false,
        resolved: [],
        localLoading: false,

        cancelReasonList: [],
        selectedReasonText: "",
        selectedReasonId: "",
    }

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
    }

    __openNotifications = () => {

        const { person } = this.props;

        if(person && person.notifications && person.notifications.length > 0) {
            this.setState({
                open: true,
            });
        }
    }

    __handleClose = () => {

        this.setState({
            open: false,
        });
    }

    __resolveInsuranceNotification = (notification) => () => {

        const { person } = this.props;

        const { resolved } = this.state;

        this.setState({
            resolved: resolved,
            localLoading: true,
        });

        customersApi.resolveNotifications(person.id, notification.identifier)
            .then((value) => {

                    resolved.push(notification.identifier.value);

                    this.setState({
                        resolved: resolved,
                        localLoading: false,
                    });

                    notificationService.success(`Success`);

            }, reason => {

                    notificationService.error(`Error resolving notification - ${reason}`);

                    this.setState({
                        localLoading: false,
                    });
            });
    }

    __cancelAppointment = () => {
        let { id } = this.props.item;
        let { __handleCancelDialogClose } = this.props;
        let { selectedReasonId, selectedReasonText } = this.state;

        const requestData = {
            reason: {
                id: selectedReasonId,
                description: selectedReasonId === 0 ? selectedReasonText : ""
            }
        }

        this.setState({localLoading: true});
        appointmentApi.cancel(id, requestData)
            .then(value => {

                clinicWaitingRoomEventService.restartAppointmentPoll(null, 'WAITING_LIST_EXTRA_LOADING');

                analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_CANCEL, { id: id, type: "CANCEL" });
            })
            .catch(reason => {

                notificationService.update({
                    error: `Unable to cancel the appointment - ${reason}. Please try canceling the appointment in Athena.`,
                });
            })
            .finally(() => {

                this.setState({
                    localLoading: false,
                })
                __handleCancelDialogClose();
            });

    }

    __handleTextChange = (event) => {
        let change = {};
        change[event.target.name] = event.target.value;

        this.setState(change);
    };

    __handleReasonTextChange = (event) => {
        let {selectedReason} = this.state;

        selectedReason.description = event.target.value
        this.setState(selectedReason);
    }

    __loadCancelReasons = () => {

        this.setState({localLoading: true});
        appointmentApi.getCancelReasons()
            .then(value => {

                this.setState({
                    cancelReasonList: value.data.items,
                })

            })
            .catch(reason => {

                this.setState({
                    cancelReasonList: DEFAULT_REASONS,
                })
                notificationService.update({
                    error: "Unable to load the reasons for appointment cancelling. " + reason,
                });

            })
            .finally(() => {

                this.setState({
                    localLoading: false
                })
            });
    }

    __formatReasonDescription = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    __onCancelDialogClose = () => {
        this.props.__handleCancelDialogClose();
        this.setState({
            cancelReasonList: [],
            selectedReasonText: "",
            selectedReasonId: "",
            localLoading: false,
        });
    }

    __renderInsuranceNotification = (notification) => {

        const { classes } = this.props;
        const { resolved, localLoading } = this.state;

        return (<>
            <ListItem>
                <ListItemText className={classes.notificationText} primary={notification.content} />
                <ListItemSecondaryAction className={classes.actionButton}>
                    { resolved.includes(notification.identifier.value) ?
                        <Button
                            disabled={true}
                            color="primary"
                            className={classes.button}
                        >
                            <FormattedMessage id={"resolve"} defaultMessage={"Resolved"} />
                        </Button>
                        :
                        <Button
                            disabled={localLoading}
                            color="primary"
                            onClick={this.__resolveInsuranceNotification(notification)}
                            className={classes.button}
                        >
                            <FormattedMessage id={"resolve"} defaultMessage={"Resolve"} />
                        </Button>
                    }

                </ListItemSecondaryAction>
            </ListItem>
        </>);
    }

    render() {

        const paperStyle = {};

        const { classes, person, notification, __handleCancelDialogClose, cancelDialogOpen } = this.props;

        const { open, resolved, localLoading, cancelReasonList, selectedReasonId, selectedReasonText } = this.state;

        const hasNotifications = (person && person.notifications && (person.notifications.length - resolved.length) > 0 ) || notification;

        return (
            <>
                <div className={`${classes.root} ${this.props.borderColor}`}>
                    <div className={this.props.headerStyle}>
                        <div className={classes.cardAvatar} onClick={this.__openNotifications}>
                            { (hasNotifications) ? <Badge color="secondary" variant={"dot"} overlap="circle" badgeContent=" " anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                                <Avatar
                                    className={classes.avatar}>{person ? <>{person.name.given.charAt(0)}</> : <>U</>}</Avatar>
                            </Badge> : <Avatar
                                className={classes.avatar}>{person ? <>{person.name.given.charAt(0)}</> : <>U</>}</Avatar>}
                        </div>
                        <div className={classes.cardTitle}>
                            <div className={classes.cardTitleText}>{person ? <>{person.name.given}<br/>{person.name.family}</> : <>Unknown<br/>Unknown</>}</div>
                        </div>
                        { this.props.menu }
                    </div>
                    { this.props.children }
                </div>
                <Dialog
                    maxWidth={"md"}
                    open={open}
                    onClose={this.__handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Notifications"}</DialogTitle>
                    <DialogContent>
                        <List>
                            {
                                person && person.notifications && person.notifications.map(_notification =>
                                    this.__renderInsuranceNotification(_notification)
                                )
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.__handleClose} variant={"contained"} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


            </>
        );
    }
}

export default withStyles(styles)(BaseCard);

import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import RpaContext from "./context";
import {authService} from "../../../utils/auth";
import ManualJobs from "./ManualJobs";
import ManualJobPanel from "./ManualJobPanel";
import {RpaBloc, RpaBlocEvent} from "./rpa.bloc";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
        minHeight: "calc(100% - 56px)",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
});

const DEFAULT_QUEUE_SIZE = 30;

class Rpa extends React.Component {

    bloc;
    subscription;
    eventSubscription;

    constructor(props) {
        super(props);

        this.bloc = new RpaBloc();

        this.state = {
            busy: false
        };

        this.__handleRpaEvent = this.__handleRpaEvent.bind(this);
    }

    componentDidMount() {
        this.eventSubscription = this.bloc.subscribeToEvents(this.__handleRpaEvent)
        this.bloc.start();
    }


    componentWillUnmount() {
        this.bloc.close();
        this.eventSubscription.unsubscribe();
    }


    __handleRpaEvent = (event) => {

        const { organisationId } = this.props.match.params;

        const type = event.event;
        const data = event.data;

        switch (type) {
            case RpaBlocEvent.JOB_SELECTED:
                this.props.history.push(`/rpa/${organisationId}/manual_steps/${data.job.id}`);
                break;
        }
    }


    render() {

        const { classes } = this.props;

        let context = {
            roles: authService.getUserRoles(),
            bloc: this.bloc,
        };

        return (
            <RpaContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Switch>
                            <Route path="/rpa/:organisationId/manual_steps" exact component={ ManualJobs } />
                            <Route path="/rpa/:organisationId/manual_steps/:stepId" exact component={ ManualJobPanel } />
                        </Switch>
                    </Grid>
                </div>
            </RpaContext.Provider>
        );
    }
}

Rpa.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Rpa));

import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from '../../context';

const __screen = "calc(100vh)";

const styles = (theme) => ({
    root: {
      position: "relative",
      backgroundColor: "#fff",
      top: "-56px",
      display: "flex",
      width: "100%",
      minHeight: __screen,
      maxHeight: __screen,
      height: __screen,
      overflow: "auto",
      overscrollBehavior: "contain",
      touchAction: "none",
    },
    fill: {
      flex: "1 1 auto",
    }
});

class LongitudinalGraph extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div>
            <h1> Longitudinal Graph tab</h1>
          </div>
        );
    }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<LongitudinalGraph context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

import React from "react";
import {Box, Button, Divider, InputBase, Typography, withStyles} from "@material-ui/core";


import DecodedComponent from "../../../../../../shared/DecodedComponent";


const styles = theme => ({
    description: {
        padding: "12px 0 12px 0",
        color: "#858585",
    },
    title: {
        color: theme?.palette?.CGIPrimary?.main,
    },
    chip: {
        width: "100%",
        backgroundColor: "#f8f8f8",
    },
    actions: {
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        marginBottom: "8px",
        marginTop: "8px",
    },
    textfield: {
        marginTop: "18px",
        marginBottom: "18px",
        borderColor: theme?.palette?.CGIInputBorderColor?.main,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 7,
        flex: 1,
        width: "100%",
        paddingLeft: 12,
        paddingRight: 12,
        fontFamily: "inter !important",
        fontSize: 15,
        color: theme?.palette?.CGITextPrimary?.main,
    },
    progressNotes: {
        maxHeight: "55vh",
        overflow: "auto",
    },
    progressNote: {
        fontSize: "15px",
        lineHeight: "28px",
    },
    progressNoteTimestamp: {
        color: "#858585",
        textAlign: "right",
        marginBottom: "8px",
    },
});

class GeneralOrderDetail extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = { note: props.item.note };
        this.ref = React.createRef();
    }

    __handleTextChange = (event) => {
        this.setState({
            note: event.target.value,
        });
    }

    __close = () => {
        this.props.onClose();
    }

    __save = ( ) => {

        const { note } = this.state;

        // this.bloc.evaluationManagement().patch(this.props.item, { note: note }, this.__close);
        this.bloc.evaluationManagement().updateProgressNotes(this.props.item, { note: note }, this.__close);
    }

    render() {

        const { item, classes } = this.props;
        const { note } = this.state;

        return (
            <Box>
                <Box className={classes.description}>
                    <Typography>{item.description || item.code.value}</Typography>
                </Box>
                { item.progressNotes?.length > 0 &&
                    <Box className={classes.progressNotes}>
                        { item.progressNotes.map(note => <Box>
                            <Box className={classes.progressNote}>
                                {note.note}
                            </Box>
                            <Box className={classes.progressNoteTimestamp}>{ new Date(note.modified).toLocaleString() }</Box>
                        </Box>) }
                    </Box>
                }
                <Box>
                    <InputBase
                        id={`note-rx-${item.bid}`}
                        className={classes.textfield}
                        multiline
                        rows={4}
                        defaultValue=""
                        variant="standard"
                        value={note}
                        onChange={this.__handleTextChange}
                    />
                </Box>
                <Box className={classes.actions}>
                    <Button className={classes.button} color={"primary"} variant={'contained'} onClick={this.__save}>Save</Button>
                </Box>
            </Box>);
    }
};

export default withStyles(styles)(GeneralOrderDetail);

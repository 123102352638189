import React, {Component, useRef, useState} from "react";
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    InputBase,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";


import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {DHChip, DHChipWithIcon} from "../../Common";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";


const styles = theme => ({
    root: {},
    chip: {
        width: "100%",
        backgroundColor: "#f8f8f8",
    },
    actions: {
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        marginBottom: "8px",
        marginTop: "8px",
    },
    content: {
        marginTop: "16px",
        maxHeight: "50vh",
        minHeight: "80px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    textfield: {
        borderColor: theme?.palette?.CGIPrimary?.main,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 32,
        flex: 1,
        width: "100%",
        paddingLeft: 24,
        paddingRight: 24,
        fontFamily: "inter !important",
        fontSize: 15,
    },
});

class DDxRecommendations extends Component {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {selected: []};
    }

    __select_item = (item) => {
        let {selected} = this.state;
        selected.push(item.code.code);
        this.setState({selected,});
    }

    __deselect_item = (item) => {
        let {selected} = this.state;
        selected = selected.filter((_item) => _item !== item.code.code);
        this.setState({selected,});
    }

    __save_complete = (error) => {
        if(!error) {
            this.props.onCloseClick();
        }
        this.setState({ busy: false });
    }

    __save = () => {

        const {ddxRecommendation, } = this.props;
        const { selected, } = this.state;

        let toSave = ddxRecommendation.filter((_item) => selected.includes(_item.code.code));

        this.setState({ busy: true });

        this.bloc.diagnosis().saveAll(toSave, this.__save_complete);
    }

    render() {

        const {message, ddxRecommendation, classes, } = this.props;
        const { selected, busy, } = this.state;

        return (
            <Box className={classes.root}>
                <Box className={classes.content}>
                    {message && <>{message}</>}
                    {!message && ddxRecommendation.length === 0 && <Box><CircularProgress/></Box>}
                    {!message && ddxRecommendation.map(_item => {
                        const selectedItem = selected.includes(_item.code.code);
                        return <>
                            <DHChipWithIcon
                                style={{
                                    width: "100%",
                                    backgroundColor: "#f8f8f8",
                                }}
                                drawerItem={false}
                                icon={selectedItem ? <CheckCircle style={{color: "#00D1FF"}}/> : <CheckCircleOutline/>}
                                label={_item.code.display}
                                clickable
                                onClick={() => {
                                    if (selectedItem) {
                                        this.__deselect_item(_item);
                                    } else {
                                        this.__select_item(_item)
                                    }
                                }}
                            />
                        </>
                    })}
                </Box>
                <Box className={classes.actions}>
                    {!message && ddxRecommendation.length > 0 &&
                        <Button disabled={busy || selected.length === 0} className={classes.button} color={"primary"} variant={'contained'} onClick={this.__save}>{ busy ? <><CircularProgress size={12} /></> : "Add" }</Button>}
                </Box>
            </Box>);
    }
}

export default withStyles(styles)(DDxRecommendations);

import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {Route, Switch, withRouter} from 'react-router-dom';
import EncounterLink from "./EncounterLink";
import RpaLink from "./RpaLink";
import {
    ENCOUNTER_LINK_ROUTE,
    ENCOUNTER_LINK_SHORT_ROUTE,
    RPA_LINK_ROUTE,
    RPA_LINK_SHORT_ROUTE
} from "../../../utils/route.name";
import {globalBloc} from "../global.bloc";

const styles = theme => ({
    root: {
        overflow: "auto",
        overscrollBehavior: "contain",
        backgroundColor: "#ffffff",
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
        color: "#fff",
    },
});


class LinkHandler extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        globalBloc.setEntry('link');
    }

    render() {

        let { classes } = this.props;

        return (
            <div className={classes.root}>
                <Switch>
                    <Route path={ ENCOUNTER_LINK_ROUTE } component={ EncounterLink } />
                    <Route path={ ENCOUNTER_LINK_SHORT_ROUTE } component={ EncounterLink } />
                    <Route path={ RPA_LINK_ROUTE } component={ RpaLink } />
                    <Route path={ RPA_LINK_SHORT_ROUTE } component={ RpaLink } />
                </Switch>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(LinkHandler));

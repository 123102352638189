import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Step, StepButton, Stepper, Typography} from "@material-ui/core";

import SchedulingContext from "../context";
import AppointmentContext from "./context";

import {notificationService} from "../../../../utils/notification";
import DecodedContainer from "../../../shared/Container";
import CustomerDetail from "./CustomerDetail";
import InsuranceDetail from "./InsuranceDetail";
import VisitReasonDetail from "./AppointmentDetail";
import {appointmentEventService} from "./service/appointment.event.service";
import {customersApi} from "../../../../utils/services/customers.api";
import CustomerMatch from "./CustomerMatch";
import {insurancesApi} from "../../../../utils/services/insurances.api";
import {createScheduleRequest} from "./util/form";
import Success from "./Success";
import {errorResolver} from "./util/error.resolver";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../utils/events";
import {dateUtil} from "../../../../utils/date";
import Confirmation from "./Confirmation";
import {phoneUtil} from "../../../../utils/phone";
import {v4 as uuidv4} from 'uuid';
import DecodedComponent from "../../../shared/DecodedComponent";
import {AppointmentBloc} from "./appointment.bloc";

const styles = theme => ({
    root: {
        flex: "1 1 auto",
        width: "100%",
        padding: "60px 100px",
    },

    fill: {
        flex: "1 1 auto",
    },

    containerHeader: {
    },


    containerBody: {
        paddingTop: "30px",
        flex: "1 1 auto",
        maxHeight: "100%",
    },
});


class Appointment extends DecodedComponent {

    registrationUpdateSubscription;

    constructor(props) {
        super(props);

        const { clinic_id } = this.props.match.params;

        this.bloc = new AppointmentBloc(clinic_id);

        this.state = {
            track: uuidv4(),
            loading: false,
            providerList: [],
            steps: this.__getSteps(),
            step: 0,
            completed: [],
            form: {
                organisationId: clinic_id,
                givenName: "",
                familyName: "",
                dob: "",
                ssn: "",
                email: "",
                number: "",
                addressPostcode: "",
                isPolicyHolder: true,
                isInValidLocation: false,
                memberId: "",
                isInClinic: false,
                timeslot: "",
            },
            success: false,
        }
        ;

        this.__registrationEventCallback.bind(this);
    }


    componentDidMount() {

        insurancesApi.getProviders().then((value => {
            this.setState({
                providerList: value.data.items
            })
        })).catch((reason) => {

            notificationService.error("Error loading insurance providers. Error - " + reason);
        }).finally(() => { });

        this.registrationUpdateSubscription = appointmentEventService.registerStateCallback(this.__registrationEventCallback)
    }


    componentWillUnmount() {

        this.registrationUpdateSubscription.unsubscribe();
    }

    __registrationEventCallback = (event) => {

        const { type, data } = event;

        let { form, track, existingInsurance } = this.state;

        let updates = {};

        switch (type) {
            case "REGISTRATION_APPOINTMENT_DETAIL":
                // this.__registerAppointmentDetail(form);
                // break;
            case "REGISTRATION_SUBMIT_USER_SEARCH":
                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_TRANSITION, { event: type, org: form.organisationId, track: track });
                this.__attemptCustomerMatch(form);
                form.id = undefined;
                form.addressLine1 = undefined;
                form.addressLine2 = undefined;
                form.addressCity = undefined;
                form.addressAdministrativeArea = undefined;
                form.addressPostcode = undefined;
                form.policyHolderGivenName = undefined;
                form.policyHolderFamilyName = undefined;
                form.policyHolderDob = undefined;
                form.memberId = "";
                form.provider = "";
                form.planType = undefined;
                form.userPlan = undefined;
                form.isPolicyHolder = true;
                updates.candidatePatients = undefined;
                updates.loading = true;
                updates.existingInsurance = undefined;
                break;
            case "REGISTRATION_SUBMIT_CUSTOMER_MATCH":
                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_TRANSITION, { event: type, org: form.organisationId, track: track });
                this.__processCustomerMatch(form, data);
                updates.existingInsurance = undefined;
                break;
            case "REGISTRATION_SUBMIT_USER_DETAIL":
                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_TRANSITION, { event: type, org: form.organisationId, track: track });
                this.__processCustomerInformation(form);
                break;
            case "REGISTRATION_SUBMIT_INSURANCE_DETAIL":
                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_TRANSITION, { event: type, org: form.organisationId, track: track });
                this.__processInsuranceDetail(form, data);
                break;
            case "REGISTRATION_SUBMIT":
                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_TRANSITION, { event: type, org: form.organisationId, track: track });
                this.__submitRegistration(form, existingInsurance);
                updates.loading = true;
                break;
            case "REGISTRATION_TEXT_CHANGE":
            case "REGISTRATION_FLAG_CHANGE":
                form[data.target] = data.value;
                if(data.other) {
                    for (const [key, value] of Object.entries(data.other)) {
                        form[key] = value;
                    }
                }
                break;
            case "REGISTRATION_RESET_INSURANCE":
                form.policyHolderGivenName = undefined;
                form.policyHolderFamilyName = undefined;
                form.policyHolderDob = undefined;
                form.memberId = "";
                form.provider = "";
                form.planType = undefined;
                form.userPlan = undefined;
                form.isPolicyHolder = true;
                break;
            default:
                console.error("Not sure how we got here. If you see this please report it.")
        }

        this.setState({
            form: form,
            ...updates,
        });
    }

    __registerAppointmentDetail = (form) => {

        const { step } = this.state;

        this.setState({
            step: step + 1,
        });
    }

    __attemptCustomerMatch = (form) => {

        const { track, step } = this.state;

        let request = {
            name: {
                given: form.givenName,
                family: form.familyName,
            },
            dob: dateUtil.formatDate(form.dob),
            contactDetails: {
                email: form.email,
                number: phoneUtil.formatPhoneNumberForRegistration(form.number),
                address: {
                    line1: form.addressLine1,
                    line2: form.addressLine2,
                    city: form.addressCity,
                    locality: form.addressAdministrativeArea,
                    postcode: form.addressPostcode,
                    country: "US",
                }
            },
            externalReferences: []
        };

        if(form.ssn.length > 0) {

            request.externalReferences.push(
                {
                    system: "http://hl7.org/fhir/vs/identifier-type",
                    code: "SS",
                    reference: form.ssn,
                }
            );
        }

        customersApi
            .match(request)
            .then(value => {

                let newProps = {
                    step: step + 1,
                };

                if(value.data && value.data.page) {

                    let page = value.data.page;

                    analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_MATCH_SUCCESS, { count: page.size, org: form.organisationId, track: track });

                    if(page.size > 0) {

                        newProps.candidatePatients = value.data.items;
                    } else {

                        customersApi.reserveId()
                            .then(value => this.__attemptCustomerNoMatch(form, value, step),
                                reason => this.__attemptCustomerNoMatch(form, undefined, step)
                            );
                        return;
                    }
                }

                this.setState({
                   ...newProps,
                });

            })
            .finally(() => this.setState({
                loading: false,
            }));
    }

    __attemptCustomerNoMatch = ( form, data, step ) => {

        if(data.data) {
            form.id = data.data.id;
        }

        this.setState({
            form: form,
            step: step + 1,
        });
    }

    __processCustomerMatch = (form, data) => {

        const { step, track } = this.state;

        if(form.paymentUpdates) {

            form.paymentUpdates = undefined;
        }

        if(data.candidate) {

            analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_MATCH_SELECTED, { org: form.organisationId, track: track });
            this.__setCustomerMatchedDetails(form, data, step);
        } else {

            customersApi.reserveId()
                .then(value => this.__setReservedCustomerMatchedDetails(form, value, step),
                        reason => this.__setReservedCustomerMatchedDetails(form, undefined, step)
                );
        }
    }

    __processCustomerInformation = (form, data) => {

        const { step } = this.state;

        this.setState({
            form: form,
            step: step + 1,
        });
    }

    __setReservedCustomerMatchedDetails = ( form, data, step ) => {

        if(data.data) {
            form.id = data.data.id;
        }

        this.setState({
            form: form,
            step: step + 1,
        });
    }

    __setCustomerMatchedDetails = ( form, data, step ) => {

        let candidate = data.candidate;

        form.id = data.candidate.id;
        form.givenName = data.candidate.name.given;
        form.familyName = data.candidate.name.family;
        form.dob = dateUtil.formatDateToAmerica(data.candidate.dob);
        form.gender = data.candidate.gender;
        if(data.candidate.email) {
            form.email = data.candidate.email;
        }
        if(data.candidate.contactNumber) {
            form.number = phoneUtil.formatPhoneNumberShortForInput(data.candidate.contactNumber);
        }

        if(candidate.address) {
            form.addressLine1 = candidate.address.line1;
            form.addressLine2 = candidate.address.line2;
            form.addressCity = candidate.address.city;
            form.addressAdministrativeArea = candidate.address.administrativeArea;
            form.addressPostcode = candidate.address.postcode;
        }

        this.setState({
            form: form,
            loading: true,
        });

        customersApi.getPaymentMethods(candidate.id)
            .then(value => {

                let updates = {};

                if(value.data.page && value.data.page.size > 0) {

                    let initial = value.data.items[0];

                    if(initial.type.code === "insurance") {

                        const insuranceInformation = initial.insuranceInformation;

                        let existingInsurance = { type: initial.type.code };

                        existingInsurance.isPolicyHolder = insuranceInformation.members.length === 0;
                        if(!existingInsurance.isPolicyHolder) {

                            const policyHolder = insuranceInformation.members[0];

                            existingInsurance.policyHolderGivenName = policyHolder.name.given;
                            existingInsurance.policyHolderFamilyName = policyHolder.name.family;
                            existingInsurance.policyHolderDob = policyHolder.dob;
                            existingInsurance.policyHolderGender = policyHolder.sex;
                        }
                        existingInsurance.memberId = insuranceInformation.memberId;
                        existingInsurance.provider = insuranceInformation.provider.name;
                        existingInsurance.planType = insuranceInformation.plan.type;
                        existingInsurance.userPlan = {
                            planId: insuranceInformation.plan.id,
                            planName: insuranceInformation.plan.name
                        };

                        updates.existingInsurance = existingInsurance;
                    } else if (initial.type.code === "outofpocket"){

                        let existingInsurance = { type: initial.type.code};
                        updates.existingInsurance = existingInsurance;
                    }
                }

                this.setState({
                    ...updates,
                });

            }).finally(() => {

                this.setState({
                    step: step + 1,
                    loading: false,
                });
            });
    }

    __processInsuranceDetail = (form, data) => {

        const { step } = this.state;

        if(data.updated) {

            form.paymentUpdates = true;
        }

        analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_INSURANCE_LOADED_SET, { type: form.paymentType ? form.paymentType : "_" });

        this.setState({
            form: form,
            step: step + 1,
        });
    }

    __getSteps = () => {

        if(this.state == null || this.state.candidatePatients == null) {
            return [['Appointment information', 0], ['Patient information', 0], ['Insurance details', 0], ['Confirmation', 0]];
        } else {
            return [['Appointment information', 0], ["Patient match", 0], ['Patient information', 0], ['Insurance details', 0], ['Confirmation', 0]];
        }
    }

    __getStepContent = (step) =>  {

        const { candidatePatients } = this.state;

        if(candidatePatients == null) {
            return [<VisitReasonDetail />, <CustomerDetail />, <InsuranceDetail />, <Confirmation />][step]
        } else {
            return [<VisitReasonDetail />, <CustomerMatch />, <CustomerDetail />, <InsuranceDetail />, <Confirmation />][step]
        }
    }

    __handleStep = (step) => () => {
        this.setState({
            step: step,
        });
    }

    __submitRegistration = (form, existingInsurance) => {

        const request = createScheduleRequest(form, existingInsurance);

        customersApi.schedule(request)
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_SUCCESS, { });
                notificationService.success("Success.");

                this.setState({
                    scheduleResult: value.data,
                    success: true,
                    loading: false,
                });
            }).catch(reason => {

                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_ERROR, { });
                notificationService.success(`An error occurred - ${errorResolver.resolveDisplay(reason)}.`);
                this.setState({
                    loading: false,
                });
            });
    }

    render() {

        let { classes } = this.props;
        let { user, serviceProviders, staff } = this.props.context;
        let { track, success, scheduleResult, step, completed, form, existingInsurance, candidatePatients, providerList, loading } = this.state;

        const steps = this.__getSteps();

        const context = {
            bloc: this.bloc,
            track: track,
            loading: loading,
            candidatePatients: candidatePatients,
            providerList: providerList,
            form: form,
            existingInsurance: existingInsurance,
            serviceProviders: serviceProviders,
            staff: staff,
        };

        return (
            <div className={classes.root}>

                <DecodedContainer loading={loading}>

                    { success ? <Success scheduleResult={scheduleResult} /> : <>

                    <div className={classes.containerHeader}>
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label, index) => {

                                let props = {};

                                if(label[1] === 1) {
                                    props.optional = <Typography variant="caption">Optional</Typography>
                                }

                                return (
                                    <Step key={label[0]}>
                                        <StepButton {...props} onClick={this.__handleStep(index)} completed={completed[index]}>
                                            {label[0]}
                                        </StepButton>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>

                    <div className={classes.containerBody}>
                        <AppointmentContext.Provider value={context}>
                            { this.__getStepContent(step) }
                        </AppointmentContext.Provider>
                    </div>
                    </>
                    }

                </DecodedContainer>
            </div>
        );
    }
}


export default withStyles(styles)(props => (
    <SchedulingContext.Consumer>
        {value => {
            return (<Appointment context={value} {...props} />);
        }
        }
    </SchedulingContext.Consumer>
));

import {Divider} from "@material-ui/core";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  divider: {
    paddingTop: "2em",
  }
})

export class DHDivider extends Component {

  render() {

    let { classes } = this.props;

    return (
        <div className={classes.divider}>
          <Divider/>
        </div>
    );
  }
}

export default withStyles(styles)(DHDivider);

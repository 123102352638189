import React, {Component} from "react";
import {Close} from "@material-ui/icons";
import {Button, Modal, Paper, withStyles} from "@material-ui/core";

const styles = (theme) => ({
  header: {
    marginBottom: 16,
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  body: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    minWidth: 540,
    minHeight: 636,
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 13,
  },
  fullScreenBody: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "95%",
    height: "100%",
    marginBottom: 36,
  },
  heading: {
    ...theme.typography.CGIHeading,
  },
  closeIcon: {
    color: theme.palette.CGIIconColor,
    cursor: "pointer",
  },
  cancelBtn: {
    marginRight: 12,
    color: theme.palette.CGIPrimary.main,
  },
  saveBtn: {
    backgroundColor: "#00D1FF",
    color: "#fff",
  },
});
class DHModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      isOpen,
      handleClose,
      showActionBtn = true,
      children,
      heading,
      classes,
      cancelBtnText = "Cancel",
      saveBtnText = "save",
      onSave,
      onCancel,
      fullScreen,
      closeIcon = true,
    } = this.props;
    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          className={fullScreen ? classes.fullScreenBody : classes.body}
          elevation={1}
        >
          <>
            <div className={classes.header}>
              <div className={classes.heading}>{heading}</div>
              {closeIcon && (
                <Close onClick={handleClose} className={classes.closeIcon} />
              )}
            </div>
            {children}
            {showActionBtn && (
              <div
                style={{
                  alignSelf: "flex-end",
                }}
              >
                <Button
                  className={classes.cancelBtn}
                  variant="outlined"
                  onClick={onCancel}
                >
                  {cancelBtnText}
                </Button>
                <Button
                  className={classes.saveBtn}
                  variant="contained"
                  color="CGISecondary"
                  onClick={onSave}
                >
                  {saveBtnText}
                </Button>
              </div>
            )}
          </>
        </Paper>
      </Modal>
    );
  }
}
export default withStyles(styles)(DHModal);

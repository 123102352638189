import {Box, Grid, IconButton, Typography, withStyles} from "@material-ui/core";
import React from "react";
import { AccountCircleTwoTone, KeyboardArrowLeft } from "@material-ui/icons";
import { DHAccordion, DHDivider } from "../../Common";
import {
  calculateAgeFromDOB,
  getValueForSection,
  getValueUnitForSection,
  UserVitalInfoYType,
} from "./Drawer.utils";
import { useHistory } from "react-router-dom";
import { useCGIContext } from "../../ClinicalGraph/CGIContext/CGIContext";

import DescriptionIcon from '@material-ui/icons/Description';

const styles = (theme) => ({
  container: {
    marginLeft: 8,
    marginTop: 24,
  },
  typoColor: {
    ...theme.typography.CGIbody2,
    color: "#777777",
  },
  subtitle: {
    ...theme.typography.CGISubTitle1,
    color: "#777777",
    fontSize: 16,
  },
  subtitle1: {
    ...theme.typography.CGISubTitle1,
    color: "#777777",
    fontSize: 12,
  },
  iconColorAndSize: {
    color: "#C4C4C4",
    fontSize: 32,
    marginLeft: 8,
    marginRight: 8,
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  divider: {
    marginTop: 16,
    marginRight: 16,
  },
  vitalItems: {
    justifyContent: "space-between",
    marginTop: 20,
  },
  vitalsSection: {
    marginRight: 16,
    marginLeft: 8,
  },
  linksSection: {
    marginRight: 16,
    marginLeft: 8,
  },
});

function UserInfo({ classes, toggleClinicalSummary, }) {
  const {
    clinicalSummary,
    userInfo: { name, gender, dob },
    vitals: { items },
    accntPreferenceUnitMap,
    bloc,
  } = useCGIContext();
  const history = useHistory();
  const goBack = () => {
    const { clinicId } = bloc.subject.value;
    history.replace(`/clinic/${clinicId}/waiting-room`);
  };
  return (
    <Box className={classes.container}>
      <div className={classes.inline}>
        <KeyboardArrowLeft
          className={classes.iconColorAndSize}
          onClick={goBack}
        />
        <AccountCircleTwoTone className={classes.iconColorAndSize} />
        <Typography>
          {`${name?.given}`}
          <br />
          {`${name?.family}`}
        </Typography>
      </div>
      <DHDivider className={classes.divider} />
      { clinicalSummary && <div className={classes.linksSection}>
        <IconButton onClick={toggleClinicalSummary} aria-label="clinicalsummary">
          <DescriptionIcon />
        </IconButton>
      </div> }
      <div className={classes.vitalsSection}>
        <DHAccordion header="VITALS" style={{ marginTop: 24 }}>
          <Grid container className={classes.vitalItems}>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>AGE</Typography>
                <Typography>{calculateAgeFromDOB(dob)}</Typography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>SEX</Typography>
                <Typography>{gender}</Typography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>HEIGHT</Typography>
                <Typography>
                  {getValueForSection(
                    items,
                    UserVitalInfoYType.HEIGHT,
                    accntPreferenceUnitMap?.height
                  ) ?? "-"}
                  <span className={classes.subtitle1}>
                    {getValueUnitForSection(
                      items,
                      UserVitalInfoYType.HEIGHT,
                      accntPreferenceUnitMap?.height
                    )}
                  </span>
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>WEIGHT</Typography>
                <Typography>
                  {getValueForSection(
                    items,
                    UserVitalInfoYType.WEIGHT,
                    accntPreferenceUnitMap?.weight
                  ) ?? "-"}
                  <span className={classes.subtitle1}>
                    {getValueUnitForSection(
                      items,
                      UserVitalInfoYType.WEIGHT,
                      accntPreferenceUnitMap?.weight
                    )}
                  </span>
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.vitalItems}>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>
                  BLOOD PRESSURE
                </Typography>
                <Typography>
                  {getValueForSection(
                    items,
                    UserVitalInfoYType.BLOOD_PRESSURE
                  ) ?? "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>PULSE</Typography>
                <Typography>
                  {getValueForSection(items, UserVitalInfoYType.PULSE) ?? "-"}
                  <span className={classes.subtitle1}>BPM</span>
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography className={classes.subtitle1}>SAO2</Typography>
                <Typography>
                  {getValueForSection(items, UserVitalInfoYType.SAO2) ?? "-"}
                  <span className={classes.subtitle1}>
                    {getValueUnitForSection(items, UserVitalInfoYType.SAO2)}
                  </span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DHAccordion>
      </div>
    </Box>
  );
}
export default withStyles(styles)(UserInfo);

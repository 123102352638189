import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, SvgIcon, Typography} from "@material-ui/core";
import {customersApi} from "../../../../../../utils/services/customers.api";
import BaseCard from "./BaseCard";
import {dateUtil} from "../../../../../../utils/date";
import {ReactComponent as CancelSVG} from "../../../../../../assets/cards/cancel.svg";
import ActionButton from "./ActionButton";
import {Bloc as CancelBloc, BlocEvent as CancelBlocEvent} from "../../../../component/CancelAppointmentDialog/bloc";
import CancelAppointmentDialog from "../../../../component/CancelAppointmentDialog";


const styles = theme => ({
    root: {
    },
    body: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
    },
    fill: {
        flex: "1 1 auto",
    },
    subtitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        display: "flex",
        textTransform: "uppercase",
        color: "#7F8993",
    },
    timestamp: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#3D5369",
        opacity: "0.8",
    },
    actions: {
        margin: "auto",
    },
});

class PatientWaitingCard extends Component {

    cancelEventSubscription;

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            processing: false,
            cancelBloc: new CancelBloc(props.item.id),
        };
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);
        this.cancelEventSubscription = this.state.cancelBloc.subscribeToEvents(this.__cancelEventHandler);
    }

    componentWillUnmount() {
        this.cancelEventSubscription.unsubscribe()
    }

    __cancelEventHandler = (event) => {
        const { type, data } = event;
        if(CancelBlocEvent.APPOINTMENT_CANCELLED === type) {
            this.setState({
                processing: true,
            });
        }
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    __cancel = () => {

        const { cancelBloc } = this.state;

        cancelBloc.open();
    }

    render() {

        let { person, processing, cancelBloc } = this.state;
        let { classes, item } = this.props;

        const body = this.__renderBody(item, processing, cancelBloc, classes);

        return (
            <div className={classes.root}>
                <BaseCard
                    person={person}
                    item={item}>
                    { body }
                </BaseCard>
            </div>
        );
    }

    __renderBody = (item, processing, cancelBloc, classes) => {
        return <>
            <div className={classes.body}>
                <div className={classes.content}>
                    <Typography variant="h4" component="h4" className={classes.subtitle}>{ item.status }</Typography>
                    <Typography variant="body1" component="p" className={classes.timestamp}>{ dateUtil.parseDate(item.slot.start).toLocaleString() }</Typography>
                </div>
                <div className={classes.fill} />
                <div className={classes.actions}>
                    { processing ? <><CircularProgress /></> :
                        <>
                            <ActionButton alt={true} onClick={this.__cancel} icon={ <SvgIcon component={ CancelSVG } viewBox="0 0 32 32" /> } text={"Cancel"} />
                        </> }
                </div>
            </div>
            <CancelAppointmentDialog bloc={cancelBloc} />
        </>;
    }
}

export default withStyles(styles)(PatientWaitingCard);

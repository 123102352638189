import {Box, ButtonBase, CircularProgress, ClickAwayListener, IconButton, Popper, withStyles} from "@material-ui/core";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import React, {useState} from "react";
import {DropZone, SwipeEnabledListItem} from "../../CGICommon";
import {DHChipWithIcon} from "../../Common";
import {OntologyConstants} from "../../../bloc";
import ArrivalDetail from "./ArrivalDetail";
import DispositionDetail from "./DispositionDetail";
import ItemDetail from "../../Common/ItemDetail";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

const styles = {
    intentionChips: {
        marginTop: 8,
        marginBottom: 8,
        flex: 1,
        justifyContent: "flex-start",
        width: "100%",
    },

    SectionHeader2Text: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
    },
    sectionHeader: {
        color: "#777777",
        textTransform: "uppercase",
    },
    phyEx: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: 12,
    },
    noteNone: {
        color: "#efefef",
    },
    note: {
    },
    chips: {
        backgroundColor: "transparent",
        border: "1px solid #777777",
        borderRadius: 22,
        height: 25,
    },
    outlineChip: {
        color: "#CDE0E4",
    },
    label: {
        textDecoration: "line-through",
        display: "flex",
    },
    fill: {
        flex: "1 1 auto",
    },
};

const Template = ({popperAnchor, bloc, item, classes}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [count, setCount] = React.useState(0);

    const handleClick = (event) => {
        if(anchorEl) {
            setAnchorEl( null )
            setCount(0)
        }  else {
            setAnchorEl(anchorEl ? null : popperAnchor.current);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function __clickAwayHandler() {
        if(anchorEl) {
            let _count = count + 1;
            if (_count % 2 === 0) {

                setAnchorEl(false)
                setCount(0)
            }
            setCount(_count)
        }
    }

    function __renderPopover() {

        let iconClass = classes.noteNone;

        if(item.note) {
            iconClass = classes.note;
        }

        return  <>
            <span  onClick={handleClick} style={{
                textAlign: "left",
                justifyContent: "flex-start",
                minWidth: "235px",
                lineHeight: "24px",
                cursor: "pointer",
            }}>{item.description || item.code.value}</span>
            <span className={classes.fill}></span>
            <span style={{
                paddingLeft: 12,
            }}><IconButton style={{ padding: 0 }}  onClick={handleClick}><AssignmentOutlinedIcon className={iconClass} /></IconButton></span>
            <ItemDetail   id={`detail-${item.bid}`} title={item.class} open={open} onClickAway={__clickAwayHandler} onCloseClick={handleClick} anchorEl={anchorEl}>
                { __renderDetails() }
            </ItemDetail>
        </>
    }

    function __renderDetails(target) {

        if(item.class !== "disposition") {
            return <><ArrivalDetail onClose={__clickAwayHandler} bloc={bloc} item={item}/></>;
        } else {
            return <><DispositionDetail onClose={__clickAwayHandler} bloc={bloc} item={item}/></>;
        }
    }

    return (
        <SwipeEnabledListItem
            onRemove={() => {
                bloc.arrivalDisposition().delete(item);
            }}
            onLeftSwipe={() => {
                bloc.arrivalDisposition().delete(item)
            }}
            onRightSwipe={() => {  }}
            disableLeftSwipe={item.status === "error"}
            disableRightSwipe={item.status !== "error"}
            style={{display: "inline-block"}}
        >
            <DHChipWithIcon
                className={classes.intentionChips}
                drawerItem={false}
                icon={<CheckCircle style={{color: "#00D1FF"}}/>}
                classes={{
                    label: item.status === "error" ? classes.label : undefined,
                }}
                label={<>
                    {__renderPopover()}
                </>}
                colortiles={bloc.differentialColour().getColoursForAction(item.bid)}
            />
        </SwipeEnabledListItem>
    );
};

export default withStyles(styles)(Template);

import React, {Component, } from "react";
import {
    Box,
    ClickAwayListener,
    IconButton,
    Popper,
    Typography,
    withStyles
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';



const styles = theme => ({
    content: {
        backgroundColor: "rgb(255, 255, 255)",
        borderRadius: "32px",
        padding: "18px",
        width: 358,
    },
    header: {
        backgroundColor: "rgb(255, 255, 255)",
        padding: "0",
        display: "flex",
    },
    title: {
        color: "#858585",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "29px",
        textTransform: "uppercase",
    },
    fill: {
        flex: "1 1 auto",
    },
});

class ItemDetail extends Component {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {};
    }

    render() {

        const { id, onCloseClick, onClickAway, open, anchorEl, placement, classes, title } = this.props;

        return (
            <ClickAwayListener onClickAway={onClickAway}>
                <Popper id={id} open={open} anchorEl={anchorEl} placement={placement || 'right-end'} style={{ marginLeft: "16px", zIndex: "9999", }}>
                    <Box className={classes.content}>
                        <Box className={classes.header}>{ title && <><Typography className={classes.title}>{ title }</Typography><span className={classes.fill} /></>}<IconButton size={"small"} onClick={onCloseClick}><CloseIcon fontSize={"small"} /></IconButton></Box>
                        {this.props.children}
                    </Box>
                </Popper>
            </ClickAwayListener>
        );
    }
};

export default withStyles(styles)(ItemDetail);

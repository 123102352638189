import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {Skeleton} from '@material-ui/lab';
import {navigationTitleService} from "../../../../utils/title";
import WorkflowContext from "../context";
import PatientReservedCard from "./components/cards/PatientReservedCard";
import PatientRegisteringCard from "./components/cards/PatientRegisteringCard";
import PatientStatusCard from "./components/cards/PatientStatusCard";
import {WorkflowPatientTrackerBloc} from "./workflow.patient.tracker.bloc";
import PatientWaitingCard from "./components/cards/PatientWaitingCard";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 68px)",
        height: "calc(100vh - 68px)",
        backgroundColor: "#fff",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },

    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },

    cardWrapper: {
        display: "flex",
        padding: "14px 35px 50px 50px",
        flexWrap: "wrap",
    },
    card: {
        margin: "16px",
        padding: "17px 21px 20px 12px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },

    cardEncounterPlaceholder: {
        height: "100%",
        display: "flex",
        '& span': {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#98A3AE",
            margin: "auto",
        }
    },

    cardHeader: {
        display: "flex",
        paddingBottom: "20px",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        display: "flex",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "3px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        flex: "1",
    },
    cardFooter: {
        paddingTop: "13px",
        display: "flex",
    },
    footerIndicator: {
        paddingTop: "4px",
        paddingRight: "7px",
        color: "#ff516A",
        height: "20px",
        width: "20px",
    },
    footerText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    footerTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    complaint: {
        minHeight: "115px",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
    },
    datePicker: {
        maxWidth: "14em",
        padding: "1em 2em",
    },
    filterGroup: {
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "2em",
        maxWidth: "100%",
        width: "100%",
    },
    filterButton: {
        paddingRight: "2em"
    },
    filterResult: {
        paddingTop: "1em",
        paddingLeft: "2em",
        color: "#6b6a6a",
    },
    loadButton: {
        paddingTop: "1.5em",
    }
});

class PatientTracker extends Component {

    constructor(props) {
        super(props);

        let { clinicId } = props.context;

        this.state = {
            cases: [],
            subjects: [],
        };

        this.bloc = new WorkflowPatientTrackerBloc(clinicId);
        this.subscription = this.bloc.subscribeToState(this.__subjectUpdate);
    }

    componentDidMount() {

        navigationTitleService.update("Patient Tracker");
        this.bloc.start();
    }

    componentWillUnmount() {
        this.bloc.close();
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    __subjectUpdate = (subject) => {

        this.setState({
            items: subject.items,
        });
    }

    render() {

        let { classes } = this.props;
        let { clinicId } = this.props.context;
        let { busy, items } = this.state;

        return (
            <div className={classes.root}>

                <div className={classes.cardWrapper}>

                { busy || !items ? (<>
                            { [1,2,3,4].map(_item => <div key={`skeleton-${_item}`} className={classes.card}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardAvatar}>
                                        <Skeleton variant="circle" width={49} height={49} />
                                    </div>
                                    <div className={classes.cardTitle}>
                                        <div className={classes.cardTitleText}><Skeleton variant="rect" /></div>
                                        <div className={classes.cardTitleSubText}><Skeleton variant="rect" /></div>
                                    </div>
                                </div>
                            </div>) }
                        </>) :

                    <>{items.map(_item => this.__renderCard(_item))}</>
                }
                </div>
            </div>
        );
    }

    __renderCard = (_item) => {

        const status = _item.status;

        const key = `patient-tracker-${_item.id}`;

        switch (status) {
            case "RESERVED":
                return <PatientReservedCard key={key} id={key} item={_item} />;
            case "REQUESTED":
                return <PatientRegisteringCard key={key} id={key} item={_item} />;
            case "WAITING":
                return <PatientWaitingCard key={key} id={key} item={_item} />;
            default:
                return <PatientStatusCard key={key} id={key} item={_item} />;
        }
    }
}

export default withStyles(styles)(props => (
    <WorkflowContext.Consumer>
        {value => {
            return (<PatientTracker context={value} {...props} />);
        }
        }
    </WorkflowContext.Consumer>
));

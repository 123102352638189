import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {AnalyticsEvent, analyticsEventLogger} from "../../../utils/events";
import {notificationService} from "../../../utils/notification";
import {appointmentApi} from "../../../utils/services/appointments.api";
import {GlobalEvent, globalEventService} from "../service/global.event.service";
import {globalBloc, IndexBlocEvent} from "../global.bloc";
import Loading from "../../shared/Loading";


const styles = theme => ({
    root: {
        overflow: "auto",
        overscrollBehavior: "contain",
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
    },
});


class EncounterLink extends Component {

    eventsSubscription;

    constructor(props) {
        super(props);

        const { match: { params } } = this.props;

        this.state = {
            loading: true,
            message: "Processing link.",
            encounterId: params.encounterId,
        };

        analyticsEventLogger.log(AnalyticsEvent.LINK_OPEN, { encounterId: params.encounterId });

        this.__handleBlocEvent = this.__handleBlocEvent.bind(this);
        this.__openAppointment = this.__openAppointment.bind(this);

        this.eventsSubscription = globalBloc.subscribeToEvents(this.__handleBlocEvent);
    }

    componentDidMount() {

        globalBloc.reloadPermissions();
    }

    componentWillUnmount() {

        this.eventsSubscription.unsubscribe();
    }

    __handleBlocEvent = (o) => {


        const {event, data} = o;

        let updates = {};

        switch (event) {
            case IndexBlocEvent.PERMISSIONS_LOADED:
                this.__loadAppointment();
                break;
            case IndexBlocEvent.LOCATION_UPDATED:
                this.__openAppointment(data);
                break;
        }
    }

    __loadAppointment = () => {

        let { encounterId } = this.state;

        analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_START, { id: encounterId });

        appointmentApi.currentEncounters()
            .then(value => {

                const eligibile = value.data.items.filter(_item => _item.id === encounterId);
                if(eligibile.length > 0) {
                    setTimeout(() => globalEventService.update(GlobalEvent.LOCATION_UPDATED, { newLocation: eligibile[0].organisationId }), 1000);
                } else {
                    this.__startAppointment();
                }
            })
    }


    __startAppointment = () => {

        let { encounterId } = this.state;

        analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_START, { id: encounterId });

        appointmentApi.startEncounter(encounterId)
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_STARTED, { id: encounterId });

                setTimeout(() => globalEventService.update(GlobalEvent.LOCATION_UPDATED, { newLocation: value.data.organisationId }), 1000);

            }).catch(reason => {

            notificationService.httpError(reason);

            analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_ERROR, { id: encounterId, type: "error_starting" });
        });
    }

    __openAppointment = (data) => {

        this.props.history.push(`/clinic/${data.location}/encounter/${this.state.encounterId}/_open`);
    }


    render() {

        let { classes } = this.props;

        return (
            <div className={classes.root}>
                <Loading />
            </div>
        );
    }
}

export default withStyles(styles)(EncounterLink);

import {Subject} from 'rxjs';
import {appointmentApi} from "../../../../utils/services/appointments.api";
import {authService} from "../../../../utils/auth";
import Roles from "../../../../utils/auth/roles";
import {format} from "date-fns";

export class ClinicWaitingRoomEventService {

    subject = new Subject();
    currentPoller;
    poller;
    selectedDate;
    currentDate;
    filterDate = undefined;
    filterPageSize;
    searchTerm;
    location;

    constructor() { }

    getDate() {
        return this.selectedDate;
    }

    setDate(date) {

        if(date) {
            this.selectedDate = new Date(date.setHours(23, 59, 59));
        } else {
            this.selectedDate = date;
        }
    }

    setSearchTerm(searchTerm) {
        this.searchTerm = searchTerm;
    }

    clearSearchTerm() {
        this.searchTerm = undefined;
    }

    setFilterDate(date) {
        this.filterDate = date ;
    }

    clearFilterDate() {
        this.filterDate = undefined;
    }

    setFilterPageSize(size) {
        this.filterPageSize = size;
    }

    setLocation(location) {
        this.location = location;
    }

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(event) {
        this.subject.next(event);
    }

    startCurrentAppointmentPoll = () => {
        if (!this.currentPoller) {
            this._pollCurrent();
            this.currentPoller = setInterval(this._pollCurrent, 15000);
        }
    }

    start() {
        if(!this.poller) {
            this._pollQueue();
            this.poller = setInterval(this._pollQueue, 3000);
        }
    }

    restartAppointmentPoll(size, event) {

        if (size != null && size > 0)
            this.setFilterPageSize(size);

        if(this.poller)
            clearInterval(this.poller)

        this.update({type: event})
        this._pollQueue();
        this.poller = setInterval(this._pollQueue, 3000);
    }

    _pollCurrent = () => {

        appointmentApi.currentEncounters()
            .then(value => {

                this.update({
                    type: 'CURRENT_LIST_UPDATE',
                    data: value.data,
                });
            });
    }

    _poll = () => {

        const { status, types } = this._resolveLookup();

        if(!status || status.length === 0) return;

        this.currentDate = new Date(new Date().setHours(23, 59, 59, 0));

        appointmentApi.virtualWaiting(this.location, status, types)
            .then(value => {

                if(value.config.url.indexOf(this.location) === -1) return;

                value.data.items = value.data.items.filter((_item) => {

                    let comparisonDate;

                    if(_item.slot && _item.slot.start) {
                        comparisonDate = new Date(new Date(_item.slot.start));
                    } else {
                        comparisonDate = new Date(new Date(_item.createdOn));
                    }

                    if(this.selectedDate === undefined)  {

                        return comparisonDate < this.currentDate;
                    } else {

                        return comparisonDate < this.selectedDate;
                    }
                });

                this.update({
                    type: 'WAITING_LIST_UPDATE',
                    data: value.data,
                });
            }).catch(reason => {

            console.log("Clinical waiting room polling error.", reason);
        });
    }

    _pollQueue = () => {

        const { status } = this._resolveLookup();

        if(!status || status.length === 0) return;

        let data = this._resolveLookup();

        if (this.filterDate)
            data.date = format(this.filterDate, 'yyyy-MM-dd');
        else
            data.timeWindow = 12;

        if (this.searchTerm)
            data.search = this.searchTerm

        appointmentApi.listEncountersInVirtualWaiting(this.location, 0, this.filterPageSize, data)
            .then(value => {

                if (value.config.url.indexOf(this.location) === -1) return;

                this.update({
                    type: 'WAITING_LIST_UPDATE',
                    data: value.data,
                });
            }).catch(reason => {

                console.log("Clinical waiting room polling error.", reason);
            });
    }

    _resolveLookup() {

        let status = [];
        let types = [];

        const roles = authService.getUserRoles();
        if(roles.includes(Roles.PROVIDER)) {

            status.push("TRANSITIONING", "REQUESTED", "CHECKING_IN", "RESERVED", "WAITING", "A_REVIEWING", "A_STARTED", "A_REVIEWED");
            types.push("VIRTUAL_WALK_IN", "IN_PERSON_WALK_IN", "IN_PERSON", "VIRTUAL");
        } else if(roles.includes(Roles.TNT_ASSIST)) {

            status.push("TRANSITIONING", "REQUESTED", "CHECKING_IN", "RESERVED", "WAITING", "A_REVIEWING", "A_STARTED", "A_REVIEWED", "REVIEWING", "STARTED");
            types.push("IN_PERSON_WALK_IN", "IN_PERSON", "VIRTUAL_WALK_IN", "VIRTUAL");
        } else if(roles.includes(Roles.TNT_CLERK)) {

            status.push("TRANSITIONING", "REQUESTED", "CHECKING_IN", "RESERVED", "WAITING", "A_REVIEWING", "A_STARTED", "A_REVIEWED", "REVIEWING", "STARTED");
            types.push("IN_PERSON_WALK_IN", "IN_PERSON", "VIRTUAL_WALK_IN", "VIRTUAL");
        }

        return {
            status: status,
            types: types,
        };
    }

    stop() {
        clearInterval(this.poller);
        clearInterval(this.currentPoller);
        this.poller = null;
        this.currentPoller = null;
    }
}

export const clinicWaitingRoomEventService = new ClinicWaitingRoomEventService();

import {ButtonBase, Typography} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React from "react";

function AddItemContextMenu({ label, onPress, isSelected, classes }) {
  return (
    <ButtonBase
      onClick={onPress}
      style={{
        display: "inline-flex",
        width: "100%",
        color: "#333333",
        borderRadius: 32,
        background: "#fff",
        height: 32,
        marginTop: 4,
        marginBottom: 4,
        alignItems: "center",
          justifyContent: "start",
      }}
    >
      <>
        {isSelected ? (
          <Clear style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }} />
        ) : (
          <Add style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }} />
        )}
      </>
      <Typography component={"span"} classes={{ body1: classes.body1 }}>
        {label}
      </Typography>
    </ButtonBase>
  );
}
const styles = {
  body1: {
    fontFamily: "inter !important",
    fontSize: 15,
  },
};
export default withStyles(styles)(AddItemContextMenu);

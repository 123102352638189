import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Tab, Tabs, Typography} from "@material-ui/core";
import EncounterContext from "../../context";
import {notificationService} from "../../../../../../utils/notification";
import {FormattedMessage} from "react-intl";
import Dialpad from "./Dialpad";
import Contacts from "./Contacts";
import {encounterApi} from "../../../../../../utils/services/encounter.api";
import Disconnect from "./Disconnect";

const styles = theme => ({
    root: {
        height: "100%",
        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        padding: "0 24px 32px 24px"
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "32px",
    },
    tabWrapper: {
        padding: "24px 64px",
        maxHeight: "inherit",
        height: "inherit",
    },
});


class TelehealthDialout extends Component {

    blocSubscription;

    constructor(props) {
        super(props);

        this.state = {
            tab: "0",
            callStatus: undefined,
        }

        this.__callNumber = this.__callNumber.bind(this);
    }

    componentDidMount() {

        const { encounterBloc } = this.props.context;

        this.blocSubscription = encounterBloc.subscribeToState(this.__encounterStateHandler);
    }

    componentWillUnmount() {

        this.blocSubscription.unsubscribe();
    }

    __encounterStateHandler = (state) => {

        this.setState({
            encounter: state.encounter,
            busy: state.busy,
            telehealthCall: state.telehealthCall,
            telehealthStatus: state.telehealthStatus,
        });
    }

    __callNumber = (data) => {
        const { encounterBloc } = this.props.context;
        const { encounter } = this.state;

        encounterApi.dialOut(encounter.id, data)
            .then(value => {
                encounterBloc.setTelehealthCallStatus('ACTIVE', value.data, data);
            }).catch(reason => {
            this.__disconnect();
            notificationService.httpError(reason);
        });
    }

    __disconnect = () => {

        const { encounterBloc } = this.props.context;

        encounterBloc.telehealthHangup();
    }

    _tabChange = (event, newValue) => {

        this.setState({
            tab: newValue,
        });
    }

    render() {

        let { classes, size } = this.props;
        let { tab, telehealthCall, telehealthStatus } = this.state;

        return (
            <div className={classes.root}>
                <Typography hidden={ size && size === 'small' } className={classes.header} variant="h6" component="h6">
                    <FormattedMessage id={"dial.out"} defaultMessage={"Dial"} />
                </Typography>
                <div hidden={ size && size === 'small' } className={classes.tabWrapper}>
                    { telehealthCall ? <Disconnect dialOutDisconnect={this.__disconnect} /> :
                    <><Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this._tabChange}
                        aria-label="dialout-tabs"
                        centered
                    >
                        <Tab value={"0"} label={<Typography>Numpad</Typography>} />
                        <Tab value={"1"} label={<Typography>Contacts</Typography>} />
                    </Tabs>
                    { tab !== "0" ? <></> : <Dialpad telehealthStatus={telehealthStatus} dialOut={this.__callNumber} /> }
                    { tab !== "1" ? <></> : <Contacts telehealthStatus={telehealthStatus} dialOut={this.__callNumber} /> }
                    </>
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<TelehealthDialout context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

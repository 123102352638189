import React from 'react';
import {Route, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {navigationTitleService} from "../../../utils/title";
import Appointment from "./Appointment";
import SchedulingContext from "./context";
// import {enrollmentEventService} from "./service/enrollment.event.service";
import {authService} from "../../../utils/auth";
import {secureEventService} from "../service/secure.event.service";
import Dashboard from "./Dashboard";
import {SchedulingBloc} from "./scheduling.bloc";
import {SchedulingEvent} from "./service/scheduling.event.service";
import {globalBloc, IndexBlocEvent} from "../global.bloc";
import {clinicWaitingRoomEventService} from "../Clinic/service/clinic.waitingroom.event.service";
import DashboardRedirect from "./DashboardRedirect";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
        overflow: "auto",
    },
    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },
});

class Scheduling extends React.Component {

    subscriptionState;
    subscriptionEvents;

    state = {
        complete: false,
        serviceProviders: [],
        staff: []
    };

    componentDidMount() {

        navigationTitleService.update("Schedule Appointment");

        let user = authService.getUser();
        this.setState({
           user: user,
        });

        this.schedulingBloc = new SchedulingBloc();
        this.subscriptionState = this.schedulingBloc.subscribeToState(this.__handleState);
        this.subscriptionEvents = this.schedulingBloc.subscribeToEvents(this.__handleEvents);

        this.globalSubscription = globalBloc.events.subscribe(this.__globalListener);
    }

    componentWillUnmount() {

        this.schedulingBloc.close();
        this.subscriptionState.unsubscribe();
        this.subscriptionEvents.unsubscribe();
        this.globalSubscription.unsubscribe();
    }

    __globalListener = (e) => {

        const { event, data } = e;

        let newProps = {};

        switch (event) {
            case IndexBlocEvent.LOCATION_UPDATED:
                clinicWaitingRoomEventService.setLocation(data.location);
                this.props.history.push(`/scheduling`);
                newProps.queue = {
                    complete: false,
                    serviceProviders: [],
                    staff: []
                };

                break;
        }

        this.setState({
            ...newProps,
        });
    }

    __handleEvents = (e) => {

        const { type, data } = e;

        let newProps = {};

        switch (type) {
            case SchedulingEvent.SERVICE_PROVIDERS_LOADED:
                newProps.serviceProviders = data.serviceProviders;
                break;
            case SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADED:
                newProps.staff = data.staff;
                break;
        }

        this.setState({
            ...newProps,
        });
    }

    __handleState = (e) => {

        this.setState({
            serviceProviders: e.serviceProviders,
            staff: e.staff,
        });
    }


    handleEvent = (event) => {

        const { data, type } = event;

        switch (type) {
            case 'ENROLLMENT_COMPLETE': {

                secureEventService.update(event);
                this.setState({
                    complete: true,
                });
            }
            break;
        }
    };


    render() {

        const { classes } = this.props;
        const { user, serviceProviders, staff } = this.state;

        let context = {
            user: user,
            schedulingBloc: this.schedulingBloc,
            serviceProviders: serviceProviders,
            staff: staff,
        };

        return (
            <SchedulingContext.Provider value={context}>
                <div className={classes.root}>

                    <Route path="/scheduling" exact component={ DashboardRedirect } />
                    <Route path="/scheduling/:clinic_id" exact component={ Dashboard } />
                    <Route path="/scheduling/:clinic_id/appointment" exact component={ Appointment } />
                </div>
            </SchedulingContext.Provider>
        );
    }
}

Scheduling.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Scheduling));

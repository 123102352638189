import { ButtonBase, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  AddCircleSharp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/styles";
import { DropZone } from "../CGICommon";

const styles = {
  drawerItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 322,
    minWidth: 322,
  },
  sectionHeader: {
    color: "#777777",
    textTransform: "uppercase",
  },
  iconColor: {
    color: "#C4C4C4",
    fontSize: 16,
    pointer: "hand",
  },
  iconStyles: {
    marginRight: 24,
    fontSize: 24,
  },
};
function DHAccordion({
  onHeaderTap,
  onHeaderDrop,
  onHeaderDropAccept,
  onHeaderDropType,
  onHeaderDropKey,
  header,
  alwaysOpen,
  expandedInitial,
  classes,
  actions,
  children,
  style,
  headerStyle = {},
  addContext = false,
  ...props
}) {
  const [expanded, setExpanded] = useState(alwaysOpen || expandedInitial);

  let root = (
    <div className={classes.drawerItems} style={style} {...props}>
      <Typography
        className={classes.sectionHeader}
        style={headerStyle}
        onClick={onHeaderTap}
        component={"span"}
      >
        {header}
      </Typography>
      <div className={classes.sectionHeaderActions}>
        { actions || [] }
        { !alwaysOpen && <ButtonBase>
          {expanded ? (
            <KeyboardArrowUp
              className={classes.iconColor}
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
          ) : (
            <KeyboardArrowDown
              className={classes.iconColor}
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
          )}
        </ButtonBase> }
      </div>
    </div>
  );

  if (onHeaderDropKey) {
    root = (
      <DropZone
        accept={onHeaderDropAccept || []}
        allowedSemanticTypes={onHeaderDropType || []}
        processDrop={(dItem) => {
          onHeaderDrop(dItem);
        }}
        key={`${onHeaderDropKey}`}
      >
        {root}
      </DropZone>
    );
  }

  return (
    <div>
      {root}
      {expanded && children}
    </div>
  );
}
export default withStyles(styles)(DHAccordion);

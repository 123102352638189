import React, { Component } from 'react';
import {withStyles} from "@material-ui/core/styles";
import {DHAccordion} from "../../../../Common";
import {Skeleton} from "@material-ui/lab";
import {Bloc, ProcedureBlocEvent} from "./bloc";
import ProcedureForm from "./ProcedureForm";
import DecodedComponent from "../../../../../../../../shared/DecodedComponent";
import SingleOptionContextMenu from "../../../ContextMenu/SingleOptionContextMenu";
import {ButtonBase, Typography} from "@material-ui/core";
import {ContextMenu} from "../../../ContextMenu";
import {OntologyConstants} from "../../../../../bloc";
import {AddCircleSharp} from "@material-ui/icons";
import RecommendationsPanel from "../Common/RecommendationsPanel";


const styles = theme => ({
    skeleton: {
        paddingTop: "1em"
    },
    iconStyles: {
        marginRight: 24,
        fontSize: 24,
    },
    inline: {
        display: "inline-flex",
    },
})

const initState = {
    initialised: false,
    context: false,
    resources: [],
}

class Procedures extends DecodedComponent {

    constructor(props) {
        super(props);
        this.state = initState;

        this.bloc = new Bloc(props.cgiBloc);
        props.formBloc.registerBloc(this.bloc);
    }

    componentDidMount() {
        super.componentDidMount()
        this.bloc.initialise();
    }

    __add = (selection) => {
        this.bloc.add(selection, this.__decorateNew)
    }

    __toggleContext = () => {
        this.setState({ context: !this.state.context, });
    }

    __decorateNew = (value) => {
        value.performed = { onset: "" }
        return value;
    }


    render() {

        let { initialised, loading, context, resources, updated, recommendations } = this.state;
        let { classes, items } = this.props;

        const added = updated ? [...updated.filter(resource => resource.dirty !== -1)] : [];

        return (
            <React.Fragment>
                <div style={{ marginBottom: 70 }}>
                    <DHAccordion
                        style={{ maxWidth: "98%" }}
                        header={"Surgical history"}
                        id={"Surgical history"}
                        headerStyle={{
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: 24,
                            textTransform: "none",
                        }}
                        expandedInitial={true}
                        actions={<div className={classes.inline}>
                            <ContextMenu
                                isOpen={context}
                                modal
                                onClose={this.__toggleContext}
                                heading={
                                    <ButtonBase onClick={this.__toggleContext}>
                                        <AddCircleSharp
                                            style={{
                                                color: "#00D1FF",
                                            }}
                                            className={classes.iconStyles}
                                        />
                                    </ButtonBase>
                                }
                            >
                                <SingleOptionContextMenu
                                    bloc={this.bloc.parent}
                                    label="Procedures"
                                    ontologyConstants={OntologyConstants.PROCEDURE_ORDERS_REQUEST.semantic}
                                    vocabulary={OntologyConstants.PROCEDURE_ORDERS_REQUEST.vocabulary}
                                    disableFreeText={true}
                                    onItemPress={(item) => {
                                        this.__toggleContext();
                                        this.__add(item);
                                    }}
                                />
                            </ContextMenu>
                        </div>}
                    >
                        <RecommendationsPanel onClick={(recommendation) => {
                            this.__add(recommendation);
                        }} recommendations={recommendations} items={added} />

                        { added.map(resource => <ProcedureForm bloc={this.bloc} value={resource}/>) }

                        {!initialised ?
                            <React.Fragment>
                                {items
                                    .filter(item => item.code.code === "past-procedures")
                                    .flatMap(item => item.items)
                                    .map(item => <div className={classes.skeleton}><Skeleton variant="rectangular" height={100}/></div>)}
                            </React.Fragment> :
                            resources && resources.filter(resource => resource.dirty !== -1).map(resource => <ProcedureForm bloc={this.bloc} value={resource}/>)}

                    </DHAccordion>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Procedures);


import { filter, isEmpty, sortBy } from "loadsh";
import { getFilteredWorkspace } from "../../CGICommon/Drawer/Drawer.utils";
import { Relationship } from "../../../util";

export const getFilteredSections = ({
  sections,
  sectionCode,
  exceptSectionCode = false,
}) => {
  if (exceptSectionCode) {
    return filter(sections, (sec) => sec?.code?.code !== sectionCode);
  }
  return filter(sections, (sec) => sec?.code?.code === sectionCode);
};

export const sortDxList = ({ workspaceGraph, selectedComplaint }) => {
  return workspaceGraph?.encounterReasonForVisitConditions(selectedComplaint) || [];
};

export const getFilteredObservations = (workspaceGraph, selectedDX) => {
  if (isEmpty(selectedDX)) {
    return workspaceGraph?.allNodes("observation");
  } else {
    return workspaceGraph?.inNodes(selectedDX, Relationship.EVIDENCE_OF);
  }
};

export const filterConditions = (conditions, statusCode) => {
  return (
    conditions?.filter((c) => c?.verificationStatus?.code === statusCode) ?? []
  );
};

export const allPossibleColors = [
  "#00FFE0",
  "#0912F0",
  "#A200FF",
  "#0085FF",
  "#FF0097",
  "#9EFF00",
  "#1a9f74de",
  "#9f1a1ade",
  "#9f951ade",
  "#9f1a1ade",
];

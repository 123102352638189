import React, {Component, useRef, useState} from "react";
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    InputBase,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";


import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {DHChip, DHChipWithIcon} from "../../Common";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import {OntologyConstants} from "../../../bloc";


const styles = theme => ({
    root: {
    },
    chip: {
        width: "100%",
        backgroundColor: "#f8f8f8",
    },
    actions: {
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        marginBottom: "8px",
        marginTop: "8px",
    },
    content: {
        marginTop: "16px",
        maxHeight: "50vh",
        minHeight: "80px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    textfield: {
        borderColor: theme?.palette?.CGIPrimary?.main,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 32,
        flex: 1,
        width: "100%",
        paddingLeft: 24,
        paddingRight: 24,
        fontFamily: "inter !important",
        fontSize: 15,
    },
});

class OrderRecommendations extends Component {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;

        this.state = {selected: [], busy: false,};
    }

    __categorise(_recommendations) {

        let used = [];
        let recommendations = _recommendations || [];

        let medications = recommendations.filter((recommendation) => {
            if(OntologyConstants.MEDICATION_ORDERS_REQUEST.semantic.includes(recommendation.semanticType)) {
                used.push(recommendation.code.code);
                return true;
            }
            return false;
        });

        let procedures = recommendations.filter((recommendation) => {
            if(!used.includes(recommendation.code.code) && OntologyConstants.PROCEDURE_ORDERS_REQUEST.semantic.includes(recommendation.semanticType)) {
                used.push(recommendation.code.code);
                return true;
            }
            return false;
        });

        let labs = recommendations.filter((recommendation) => {
            if(!used.includes(recommendation.code.code) && OntologyConstants.LAB_ORDERS_REQUEST.semantic.includes(recommendation.semanticType)) {
                used.push(recommendation.code.code);
                return true;
            }
            return false;
        });

        let other = recommendations.filter((recommendation) => {
            if(!used.includes(recommendation.code.code)) {
                used.push(recommendation.code.code);
                return true;
            }
            return false;
        });

        return {medications: medications, labs: labs, procedures: procedures, other: other};
    }

    __select_item = (item) => {
        let {selected} = this.state;
        selected.push(item.code.code);
        this.setState({selected,});
    }

    __deselect_item = (item) => {
        let {selected} = this.state;
        selected = selected.filter((_item) => _item !== item.code.code);
        this.setState({selected,});
    }

    __save_complete = (error) => {
        if(!error) {
            this.props.onCloseClick();
        }
        this.setState({ busy: false });
    }

    __save = () => {

        const {bid, recommendations, } = this.props;
        const { selected, } = this.state;

        let toSave = recommendations.filter((_item) => selected.includes(_item.code.code));

        this.setState({ busy: true });

        this.bloc.evaluationManagement().saveAll(bid, toSave, this.__save_complete);
    }

    __renderRecommendation = (title, classes, selected, recommendations) => {

        if(recommendations.length === 0) {
            return <></>;
        }

        return <>
            <Typography variant={"subtitle1"}>{ title }</Typography>
            { recommendations.map(_item => {
                const selectedItem = selected.includes(_item.code.code);
                return <>
                    <DHChipWithIcon
                        style={{
                            width: "100%",
                            backgroundColor: "#f8f8f8",
                        }}
                        clickable
                        drawerItem={false}
                        icon={<>{selectedItem ? <CheckCircle style={{color: "#00D1FF"}}/> : <CheckCircleOutline/>}</>}
                        label={_item.code.display}
                        onClick={() => {
                            if (selectedItem) {
                                this.__deselect_item(_item);
                            } else {
                                this.__select_item(_item)
                            }
                        }}
                    />
                </>
            })}
        </>
    }


    __renderRecommendations = (classes, selected, medications, labs, procedures, other) => {

        return <>
            { this.__renderRecommendation("Medications", classes, selected, medications) }
            { this.__renderRecommendation("Labs", classes, selected, labs) }
            { this.__renderRecommendation("Procedures", classes, selected, procedures) }
            { this.__renderRecommendation("Other", classes, selected, other) }
        </>
    }



    render() {

        const { recommendations, message, classes, bid } = this.props;
        const { selected, busy, } = this.state;
        const { medications, labs, procedures, other, } = this.__categorise(recommendations);

        return (
            <Box className={classes.root}>

                <Box className={classes.content}>
                    { message && <>{message}</>}
                    { !message && recommendations.length === 0 && <Box><CircularProgress /></Box> }
                    { !message && recommendations.length > 0 && this.__renderRecommendations(classes, selected, medications, labs, procedures, other) }
                </Box>
                <Box className={classes.actions}>
                    { !message && recommendations.length > 0 && <Button disabled={busy || selected.length === 0} className={classes.button} color={"primary"} variant={'contained'} onClick={this.__save}>{ busy ? <><CircularProgress size={12} /></> : "Add" }</Button> }
                </Box>
            </Box>);
    }
};

export default withStyles(styles)(OrderRecommendations);

import { DirectedGraph } from "graphology";

export class Relationship {
  static EVIDENCE_OF = "evidence_of";
  static OBSERVED_ON = "observed_on";
  static BASED_ON = "based_on";
}

export class DecodedGraph {
  startNodeAlias = {};
  startNodes = {};

  initialised;
  internal = new DirectedGraph();

  loadWorkspace(encounterId, workspace) {
    const graph = workspace?.graph;

    let added = [];

    graph.vertices.forEach((vertex) => {
      if (added.includes(vertex.bid)) {
        return;
      }
      added.push(vertex.bid);
      this.internal.addNode(vertex.bid, vertex);
      if (vertex.bid === encounterId) {
        this.startNodes[`${encounterId}`] = vertex;
        this.startNodeAlias[`encounter`] = `${encounterId}`;
      }
    });

    graph.edges.forEach((edge) => {
      if (
        added.includes(edge.bid) ||
        added.includes(edge.from + "-" + edge.to)
      ) {
        return;
      }
      added.push(edge.bid);
      added.push(edge.from + "-" + edge.to);
      this.internal.addEdgeWithKey(edge.bid, edge.from, edge.to, edge);
    });

    this.initialised = true;
    console.info("Workspace loaded..");
  }

  merge = (graph) => {
    if (graph) {
      const now = new Date();

      if (graph.vertices) {
        graph.vertices.forEach((vertex) => {
          if (!this.internal.hasNode(vertex.bid)) {
            this.internal.addNode(vertex.bid, vertex);
          } else {
            const existing = this.getNode(vertex.bid);
            console.log("existing", existing);
            if (existing?.version < vertex.version) {
              const compareDate = new Date(vertex.expired);
              if (compareDate < now) {
                this.internal.dropNode(vertex.bid);
              } else if (!existing) {
                this.internal.addNode(vertex.bid, vertex);
              } else {
                this.internal.replaceNodeAttributes(vertex.bid, vertex);
              }
            }
          }
        });
      }
      if (graph.edges) {
        graph.edges.forEach((edge) => {
          if (
            !this.internal.hasEdge(edge.from, edge.to) &&
            this.internal.hasNode(edge.from) &&
            this.internal.hasNode(edge.to)
          ) {
            this.internal.addEdgeWithKey(edge.bid, edge.from, edge.to, edge);
          }
        });
      }

      this.cleanupObservations();
    }
  };

  cleanupObservations = () => {
    this.internal.filterNodes((key, node) => node.class === "observation" && this.internal.degree(key) === 0)
        .forEach((observationId) => this.internal.dropNode(observationId))
  }

  encounter = () => {
    return this.startNodes[this.startNodeAlias[`encounter`]];
  };

  encounterPatient = () => {
    let encounterVertex = this.encounter();
    let patientVertex = undefined;

    this.internal.forEachInEdge(encounterVertex.bid, (key, edge) => {
      if (edge.rel === "participated_in" && edge.roles?.includes("patient")) {
        patientVertex = this.internal.getNodeAttributes(edge.from);
      }
    });
    return patientVertex;
  };

  encounterReasonForVisit = () => {

    let reasonForVisits = [];
    const encounterVertex = this.encounter();

    this.internal.forEachInEdge(encounterVertex.bid, (key, edge) => {
      if (edge.rel === "reason_for") {
        const from = this.internal.getNodeAttributes(edge.from);
        if(from.class === "reason_for_visit") {
          reasonForVisits.push(from);
        }
      }
    });

    return reasonForVisits;
  };

  encounterReasonForVisitConditionsOrderUpdate = (currentComplaint, item, newOrder, status = ["differential"]) => {

    if(newOrder === undefined || item.index === newOrder) {
      return [];
    }

    let conditionEdges = [];

    if(currentComplaint?.bid) {
      this.internal.forEachInEdge(currentComplaint.bid, (key, edge) => {
        if (edge.rel === "predicted_condition_for") {
          const from = this.internal.getNodeAttributes(edge.from);
          if(from.class === "condition") {
            conditionEdges.push(edge);
          }
        }
      });
    }

    conditionEdges = this.filterConditionsEdges(conditionEdges, status)
        .sort((a, b) => a.order - b.order)
        .map((edge, index) => {
          this.internal.setEdgeAttribute(edge.bid, 'order', index);
          return edge;
        });

    let conditions = [];

    conditionEdges.forEach((edge, index) => {
          const from = this.internal.getNodeAttributes(edge.from);
          let updateIndex = index;
          if(from.bid !== item.bid) {
            if(item.index < newOrder) {
              if(index === newOrder) {
                updateIndex = index - 1;
                this.internal.setEdgeAttribute(edge.bid, 'order', index - 1);
              } else if(index > item.index && index < newOrder) {
                updateIndex = index - 1;
                this.internal.setEdgeAttribute(edge.bid, 'order', index - 1);
              }
            } else if(item.index > newOrder) {
              if(index === newOrder) {
                updateIndex = index + 1;
                this.internal.setEdgeAttribute(edge.bid, 'order', index + 1);
              } else if(index < item.index && index > newOrder) {
                updateIndex = index + 1;
                this.internal.setEdgeAttribute(edge.bid, 'order', index + 1);
              }
            }
          } else if(from.bid === item.bid) {
            updateIndex = newOrder;
            this.internal.setEdgeAttribute(edge.bid, 'order', newOrder);
          }

      conditions.push({ bid: from.bid, order: updateIndex })
        });

    return conditions;
  };

  filterConditionsEdges = (edges, statusCodes) => {
    return edges?.filter((edge) => {
      const from = this.internal.getNodeAttributes(edge.from);
      return statusCodes.length === 0 || statusCodes.includes(from.verificationStatus?.code);
    });
  };


  encounterReasonForVisitConditions = (currentComplaint, status = []) => {

    let conditions = [];

    if(currentComplaint?.bid) {
      this.internal.forEachInEdge(currentComplaint.bid, (key, edge) => {
        if (edge.rel === "predicted_condition_for") {
          const from = this.internal.getNodeAttributes(edge.from);
          if(from.class === "condition") {
            conditions.push({order: edge.order, condition: from});
          }
        }
      });
    }
    return this.filterConditions(conditions, status)
        .sort((a, b) => a.order - b.order)
        .map(value => value.condition);
  };

  encounterConditions = (status = []) => {

    let conditions = [];

    const reasons = this.encounterReasonForVisit()

    reasons.forEach((reason) => {
      this.internal.forEachInEdge(reason.bid, (key, edge) => {
        if (edge.rel === "predicted_condition_for") {
          const from = this.internal.getNodeAttributes(edge.from);
          if(from.class === "condition") {
            conditions.push({order: edge.order, condition: from});
          }
        }
      });
    });

    return this.filterConditions(conditions, status)
        .sort((a, b) => a.order - b.order)
        .map(value => value.condition);
  };

  filterConditions = (conditions, statusCodes) => {
    return conditions?.filter((c) => statusCodes.length === 0 || statusCodes.includes(c?.condition.verificationStatus?.code));
  };


  encounterPatientPhysicalExam = () => {
    let patientVertex = this.encounterPatient();
    let physicalExamVertex = undefined;

    this.internal.forEachInEdge(patientVertex.bid, (key, edge) => {
      if (edge.rel === "performed_on") {
        const temp = this.internal.getNodeAttributes(edge.from);
        if(['physical_exam'].includes(temp.class)) {
          physicalExamVertex = temp;
        }
      }
    });
    return physicalExamVertex;
  };

  encounterEvaluations = (currentComplaint) => {

    let evaluations = [];

    if(currentComplaint?.bid) {
      this.internal.forEachInEdge(currentComplaint.bid, (key, edge) => {
        if (edge.rel === "based_on") {
          const from = this.internal.getNodeAttributes(edge.from);
          evaluations.push(from);
        }
      });
    }
    return evaluations;
  };

  encounterManagement = (currentComplaint) => {

    let management = [];

    if(currentComplaint?.bid) {
      this.internal.forEachInEdge(currentComplaint.bid, (key, edge) => {
        if (edge.rel === "based_on") {
          const from = this.internal.getNodeAttributes(edge.from);
          management.push(from);
        }
      });
    }
    return management;
  };

  encounterOrders = (currentComplaint) => {

    let orders = [];

    if(currentComplaint?.bid) {
      this.internal.forEachInEdge(currentComplaint.bid, (key, edge) => {
        if (edge.rel === "based_on") {
          const from = this.internal.getNodeAttributes(edge.from);
          orders.push(from);
        }
      });
    }
    return orders;
  };

  encounterArrivalAndDisposition = () => {

    let result = [];

    const encounterVertex = this.encounter();

    this.internal.forEachInEdge(encounterVertex.bid, (key, edge) => {
      if (["part_of"].includes(edge.rel)) {
        const from = this.internal.getNodeAttributes(edge.from);
        if(['arrival','disposition'].includes(from.class)) {
          result.push(from);
        }
      }
    });

    return result;
  };

  encounterROS = () => {

    let result = [];

    const encounterVertex = this.encounter();

    this.internal.forEachInEdge(encounterVertex.bid, (key, edge) => {
      if (["part_of"].includes(edge.rel)) {
        const from = this.internal.getNodeAttributes(edge.from);
        if(['ros'].includes(from.class)) {
          result.push(from);
        }
      }
    });

    return result;
  };

  orderIdConditions = (orderId) => {

    let conditions = [];

    if(orderId) {
      this.internal.forEachOutEdge(orderId, (key, edge) => {
        if (edge.rel === "based_on") {
          const to = this.internal.getNodeAttributes(edge.to);
          if(to.class === "condition") {
            conditions.push(to);
          }
        }
      });
    }
    return conditions;
  };

  allNodes = (clazz, exclude = ["_"]) => {
    return this.internal
      .filterNodes(
        (key, object) =>
          (clazz === undefined || object.class === clazz) &&
          !exclude.includes(key)
      )
      .map((key) => this.internal.getNodeAttributes(key));
  };

  allConditions = (observation) => {
    return this.outNodes(observation, Relationship.EVIDENCE_OF);
  };

  inNodes = (start, rel, exclude = ["_"]) => {
    return this.internal
      .filterInboundEdges(
        start.bid,
        (key, object) => object.rel === rel && !exclude.includes(key)
      )
      .map((key) => this.internal.source(key))
      .map((key) => this.internal.getNodeAttributes(key));
  };

  outNodes = (start, rel, exclude = ["_"]) => {
    return this.internal
      .filterOutboundEdges(
        start.bid,
        (key, object) => object.rel === rel && !exclude.includes(key)
      )
      .map((key) => this.internal.target(key))
      .map((key) => this.internal.getNodeAttributes(key));
  };

  inNodesSorted = (start, rel, exclude = ["_"]) => {
    return this.internal
      .filterInboundEdges(
        start.bid,
        (key, object) => object.rel === rel && !exclude.includes(key)
      )
      .sort((a, b) => {
        const edgeA = this.internal.getEdgeAttribute(a, 'order');
        const edgeB = this.internal.getEdgeAttribute(b, 'order');
        return edgeB - edgeA
      })
      .map((key) => this.internal.source(key))
      .map((key) => this.internal.getNodeAttributes(key));
  };

  getNode = (bid) => {
    const valid = this.internal
      .filterNodes((key, object) => key === bid)
      .map((key) => this.internal.getNodeAttributes(bid));

    if (valid?.length === 1) {
      return valid[0];
    }

    return null;
  };

  getEdge = (edge) => {
    return null;
  };

  updateNode = (node) => {
    if (node?.bid) {
      this.internal.replaceNodeAttributes(node.bid, node);
    }
  };

  removeNode = (node) => {
    if (node?.bid) {
      this.internal.dropNode(node.bid);
    }
  };

  static instanceOfWorkspace(encounterId, workspace) {
    let graph = new DecodedGraph();
    graph.loadWorkspace(encounterId, workspace);
    return graph;
  }
}

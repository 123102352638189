import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Input, Typography} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { createEditor, Transforms } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import RichEditor from "./RichEditor";

const styles = theme => ({
    root: {
        margin: "13px 0",
        display: "flex",
    },
    summarySectionLeft: {
        width: "11px",
        marginTop: "8px",
        borderTop: "1px solid #C5C5C560",
        borderLeft: "1px solid #C5C5C560",
        borderBottom: "1px solid #C5C5C560",
    },

    summarySectionRight: {
        width: "calc(100% - 15px)",
        paddingLeft: "8px",
    },

    summarySectionTitle: {
        fontSize: "16px",
        color: "#666",
        fontWeight: "300",
    },

    summarySectionContent: {
        paddingTop: "5px",
        paddingBottom: "10px",
    },

    summarySectionContentEntity: {
        padding: "2px 5px",
        background: "#00FFFF 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 12px #587BBC29",
        borderRadius: "8px",
        opacity: "1",
    },
});


class EditableSection extends Component {

    constructor(props) {
        super(props);

        let { section, } = this.props;

        this.state = {
            title: section.code.display,
            content: props.bloc.deserializeHtmlString(section.content),
        };
    }

    componentDidMount() {

    }

    onChange = (_section, _content) => {

        let { content } = this.state;
        let { section } = this.props;

        this.props.updateSection(section.id, content);

        this.setState({
            edit: content,
        });
    };

    render() {

        let { index, classes, section, bloc } = this.props;
        let { title, content } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.summarySectionLeft}>
                </div>
                <div id={`section-${index}`} className={classes.summarySectionRight}>
                    <div className={classes.summarySectionTitle}>
                        <Typography>{ title }</Typography>
                    </div>
                    <RichEditor onChange={this.onChange} bloc={bloc} section={section} content={content} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(EditableSection);

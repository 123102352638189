import * as rxjs from 'rxjs';


import {Bloc as ClinicalGraphBloc} from "../Clinic/ClinicalGraphInterface/bloc";

const _person = {
    "id": "e6f5f9ea-797c-44ff-afb0-2dfb84a8e4fb",
    "name": {"given": "Michelle", "family": "Chansuyco"},
    "gender": "F",
    "dob": "1994-10-17",
    "language": "en",
    "email": "michelle.chansuyco@healthful.care",
    "paymentMethodAvailable": false,
    "contactNumber": {"type": "CELL", "country": "+1", "area": "619", "prefix": "559", "line": "4929"},
    "externalReferences": [{"system": "athena", "code": "id", "reference": "26409"}]
}

const _encounter = {
    "id": "f8725054-c815-4021-806e-d7aa2bbf27a7",
    "status": "REVIEWING",
    "note": "head pain and tenderness, jaw pain, loss of vision",
    "conversationId": undefined,
    "organisationId": "26290eba-ea46-11ec-a16d-0242ac120002",
    "type": "VIRTUAL",
    "customer": {"code": "id", "value": "63d826c5-6218-4747-b40b-7def4f9ca4c5", "system": "decoded"},
    "encounter": {"code": "slotId", "value": "1174789", "system": "athena/appointment"},
    "createdOn": "2022-05-01T15:58:17Z",
    "modifiedOn": "2022-05-02T05:49:00Z",
    "links": [{
        "type": "telehealth",
        "details": {
            "detail": {
                "token": "T1==cGFydG5lcl9pZD00NzE0MzQ1NCZzaWc9MmVjNjUxODRhNjY3M2JkMDZmZjY1ODZhZTc0OTgzZWJmOTY4MTYxNTpzZXNzaW9uX2lkPTFfTVg0ME56RTBNelExTkg1LU1UWTFNVFEzTURVek9UYzBNSDVwTVc5dlJuaDJWRUpLZURsNmFTdDRTQ3RpWldkUFJtOS1mZyZjcmVhdGVfdGltZT0xNjUxNDcwNTM5Jm5vbmNlPS0yMjkxMDA5Njcmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY1MTUxMzczOSZjb25uZWN0aW9uX2RhdGE9bmFtZSUzRHByb3ZpZGVy",
                "id": "47143454",
                "sessionId": "1_MX40NzE0MzQ1NH5-MTY1MTQ3MDUzOTc0MH5pMW9vRnh2VEJKeDl6aSt4SCtiZWdPRm9-fg"
            }, "platform": "vonage"
        }
    }, {
        "type": "chart",
        "details": {"deeplink": "https://preview.athenahealth.com/20515/1/globalframeset.esp?&MAIN=clinicals%2Fcharttabs.esp%3FPATIENTID%3D26416"}
    }]
}

export class EncounterMockBloc {

    constructor() {

        const cgiBloc = new ClinicalGraphBloc({
            clinicId: _encounter.organisationId,
            encounter: _encounter,
            person: _person,
        });

        this.subject = new rxjs.BehaviorSubject({
            clinicId: _encounter.organisationId,
            encounter: _encounter,
            initialised: false,
            viewBloc: cgiBloc,
        });

        this.events = new rxjs.Subject();
    }

    __updateSubject = (value) =>
        this.subject.next({
            ...this.subject.value,
            ...value,
        });

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    initialise = () => {

        this.__updateSubject({
            initialised: true,
        });
    }
}

import React, {useEffect} from "react";
import {IconButton, InputAdornment} from "@material-ui/core";
import {DHInput} from "../../Common";
import {Mic} from "@material-ui/icons";

export function SearchForContextMenu({
  value,
  onChange,
  placeHolder = "Type",
}) {


  return (
    <DHInput
      autofocus={true}
      placeholder={placeHolder}
      // endAdornment={
      //   <InputAdornment position="end">
      //     <IconButton aria-label="toggle password visibility" edge="end">
      //       <Mic
      //         style={{
      //           color: "#8BA3B0",
      //           fontSize: 18,
      //         }}
      //       />
      //     </IconButton>
      //   </InputAdornment>
      // }
      onChange={onChange}
      value={value}
      style={{
        width: "100%",
        backgroundColor: "#fff",
        marginTop: 40,
        marginBottom: 40,
        borderColor: "#E3E3E3",
        borderStyle: "solid",
      }}
    />
  );
}

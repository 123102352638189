import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Avatar, Button, IconButton, Typography} from "@material-ui/core";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import RpaContext from "../context";

import {FormattedMessage} from "react-intl";
import {navigationTitleService} from "../../../../utils/title";
import InteractionAppointmentCheckinPanel from "./components/InteractionAppointmentCheckinPanel";
import InteractionAppointmentVirtualToInPersonPanel from "./components/InteractionAppointmentVirtualToInPersonPanel";
import InteractionAppointmentSchedulePanel from "./components/InteractionAppointmentSchedulePanel";
import {RpaJobBloc, RpaJobBlocEvent} from "./rpa.job.bloc";
import InteractionAppointmentFullRegistrationPanel from "./components/InteractionAppointmentFullRegistrationPanel";
import GeneralInstructionPanel from "./components/GeneralInstructionPanel";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 68px)",
        height: "calc(100vh - 68px)",
        backgroundColor: "#fff",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    content: {
        display: "flex",
        height: "inherit",
    },
    left: {
        padding: "18px 24px 18px 24px",
        flex: "1 1 auto",
        backgroundColor: "#EEEFF0",
    },
    right: {
        padding: "18px 24px 18px 24px",
        width: "450px",
    },

    titleWrapper: {
        display: "flex",
        marginBottom: "18px",
    },

    title: {
        color: '#505860',
        lineHeight: "48px",
        textTransform: "capitalize",
    },

    inputItem: {

    },

    links: {
        marginBottom: "18px",
    },

    cardWrapper: {
        display: "flex",
        padding: "14px 35px 50px 50px",
        flexWrap: "wrap",
    },
    inputCard: {
        display: "flex",
        width: "100%",
        maxHeight: "80%",
        overflow: "auto",
        background: "#f8f9f9",
        borderRadius: "22px",
    },
    subject: {
        padding: "17px 21px 20px 12px",
        width: "50%",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    input: {
        padding: "17px 21px 20px 24px",
    },

    cardEncounterPlaceholder: {
        height: "100%",
        display: "flex",
        '& span': {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#98A3AE",
            margin: "auto",
        }
    },

    subjectSummary: {
        display: "flex",
        paddingBottom: "20px",
    },
    subjectAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    subjectName: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        display: "flex",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "3px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        flex: "1",
    },
    cardFooter: {
        paddingTop: "13px",
        display: "flex",
    },
    footerIndicator: {
        paddingTop: "4px",
        paddingRight: "7px",
        color: "#ff516A",
        height: "20px",
        width: "20px",
    },
    footerText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    footerTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    complaint: {
        minHeight: "115px",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
    },
    datePicker: {
        maxWidth: "14em",
        padding: "1em 2em",
    },
    filterGroup: {
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "2em",
        maxWidth: "100%",
        width: "100%",
    },
    filterButton: {
        paddingRight: "2em"
    },
    filterResult: {
        paddingTop: "1em",
        paddingLeft: "2em",
        color: "#6b6a6a",
    },
    loadButton: {
        paddingTop: "1.5em",
    },

    heading: {
        marginTop: "8px",
        marginBottom: "12px",
    },

    headingSpacer: {
        letterSpacing: "0",
        fontWeight: "400",
        fontSize: "16px",
        color: "#8BA3B0",
        lineHeight: "20px",
        marginTop: "8px",
        marginBottom: "8px",
    },
});


class ManualJobPanel extends Component {

    bloc;
    stateSubscripton;
    eventsSubscripton;

    constructor(props) {
        super(props);

        const { organisationId, stepId } = this.props.match.params;

        this.bloc = new RpaJobBloc(this.props.context.bloc, organisationId, stepId);

        this.state = {
            busy: true,
            job: {},
            detail: {},
            subject: {},
        };

        this.__rpaJobStateUpdate = this.__rpaJobStateUpdate.bind(this);
        this.__jobEvent = this.__jobEvent.bind(this);
    }

    componentDidMount() {

        navigationTitleService.update("RPA Manual Steps");

        const { bloc } = this.props.context;

        this.stateRpaSubscripton = bloc.subscribeToState(this.__rpaJobStateUpdate);
        this.stateSubscripton = this.bloc.subscribeToState(this.__jobStateUpdate);

        this.eventsSubscripton = this.bloc.subscribeToEvents(this.__jobEvent);

        this.bloc.init();
    }

    componentWillUnmount() {

        this.stateSubscripton.unsubscribe();
        this.eventsSubscripton.unsubscribe();
        this.bloc.close()
    }

    __rpaJobStateUpdate = (data) => {
        this.setState({
            job: data.activeJob.job,
            subject: data.activeJob.subject,
        });
    }

    __jobStateUpdate = (data) => {
        this.setState({
            busy: false,
            detail: data.job,
        });
    }

    __jobEvent = (event) => {
        const { organisationId } = this.props.match.params;

        const type = event.event;

        switch (type) {
            case RpaJobBlocEvent.MANUAL_STEP_COMPLETE:

                this.props.history.push(`/rpa/${organisationId}/manual_steps`);
                break;
        }
    }
    __back = () => {

        const { organisationId } = this.props.match.params;
        this.props.history.push(`/rpa/${organisationId}/manual_steps`)
    }

    __openEhr = () => {

        const { detail } = this.state;

        window.open(detail?.link, "_blank");
    }

    render() {

        let { classes } = this.props;
        let { job, detail, subject } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.left}>
                        <div className={classes.titleWrapper}>
                            <IconButton name={"backBtn"} onClick={this.__back} aria-label="back">
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography className={classes.title} color={"primary"} variant={"h2"}>{ this.__formatType(job.type) }</Typography>
                        </div>
                        { detail.link &&
                            <div className={classes.links}>
                                <Button onClick={this.__openEhr} variant={"contained"}
                                        color={"primary"}><FormattedMessage id={"open.ehr"}
                                                                            defaultMessage={"Open EHR"}/></Button> {detail?.link}
                            </div>
                        }
                        <div className={classes.inputCard}>
                            <div className={classes.subject}>
                                { this.__renderSubject(classes, subject) }
                            </div>
                            <div className={classes.input}>
                                <Typography variant="h6" component="h2" className={classes.heading}>
                                    <FormattedMessage id={'rpa.manual.job.parameters.title'} defaultMessage={"Job Parameters"} />
                                </Typography>
                                <div>
                                    { this.__renderInput(classes, detail) }
                                </div>
                                <Typography variant="h6" component="h2" className={classes.heading}>
                                    <FormattedMessage id={'rpa.manual.job.output.title'} defaultMessage={"Job Output"} />
                                </Typography>
                                <div>
                                    { this.__renderOutput(classes, detail) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.right}>
                        { this.__renderActionPanel(job.type, detail) }
                    </div>
                </div>
            </div>
        );
    }

    __formatType = (type) => {

        if(!type) return "Loading...";

        return type.replaceAll(".", " ");
    }

    __renderActionPanel = (type, detail) => {

        switch (type) {
            case "interaction.appointment.schedule":
                return <InteractionAppointmentSchedulePanel bloc={this.bloc} detail={detail}/>;
            case "interaction.appointment.transfer.virtual.to.inperson":
                return <InteractionAppointmentVirtualToInPersonPanel bloc={this.bloc} detail={detail}/>;
            case "interaction.appointment.move.expected.to.waiting":
                return <InteractionAppointmentCheckinPanel bloc={this.bloc} detail={detail}/>;
            case "party.patient.full.registration":
                return <InteractionAppointmentFullRegistrationPanel bloc={this.bloc} detail={detail}/>;
        }
        return <GeneralInstructionPanel bloc={this.bloc} detail={detail} />;
    }

    __renderSubject = (classes, subject) => {

        if(!subject || !subject.id) return <></>;


        return <>
            <div className={classes.subjectSummary}>
                <div className={classes.subjectAvatar}>
                    <Avatar className={classes.avatar}>{subject ? <>{subject.name.given.charAt(0)}</> : <>U</>}</Avatar>
                </div>
                <div className={classes.subjectName}>
                    <Typography variant="h6" component="h2"
                                className={classes.patientSummaryDetailTitle}>{subject ? <>{subject.name.given} {subject.name.family}</> : <>Unknown
                        Unknown</>}</Typography>
                </div>
            </div>
        </>
    }

    __renderInput = (classes, detail) => {

        if(!detail || !detail.id) return <></>;

        let items = [];

        items.push(<Typography variant="h7" component="h2"
                               className={classes.headingSpacer}><FormattedMessage id={"subject"} defaultMessage={"Subject"} /> </Typography>)

        for (const [key, value] of Object.entries(detail.request).sort()) {
            if(!key.startsWith("response.payload") && key.startsWith("subject.")) {
                items.push(
                    <div className={classes.inputItem}>
                        {this.__formatType(key)}: {value}
                    </div>
                )
            }
        }

        items.push(<Typography variant="h7" component="h2"
                               className={classes.headingSpacer}><FormattedMessage id={"appointment"} defaultMessage={"Appointment"} /> </Typography>)


        for (const [key, value] of Object.entries(detail.request)) {
            if(!key.startsWith("response.payload") && key.startsWith("appointment.")) {
                items.push(
                    <div className={classes.inputItem}>
                        {this.__formatType(key)}: {value}
                    </div>
                )
            }
        }

        items.push(<Typography variant="h7" component="h2"
                               className={classes.headingSpacer}><FormattedMessage id={"other"} defaultMessage={"Other"} /> </Typography>)


        for (const [key, value] of Object.entries(detail.request)) {
            if(!key.startsWith("response.payload") && !key.startsWith("appointment.") && !key.startsWith("subject.")) {
                items.push(
                    <div className={classes.inputItem}>
                        {this.__formatType(key)}: {value}
                    </div>
                )
            }
        }

        return <>
            {items}
        </>
    }

    __renderOutput = (classes, detail) => {

        if(!detail || !detail.id) return <></>;

        let items = [];

        for (const [key, value] of Object.entries(detail.request)) {
            if(key.includes("response.payload")) {
                items.push(
                    <div className={classes.inputItem}>
                        {this.__formatType(key)}: {value}
                    </div>
                )
            }
        }

        return <>
            {items}
        </>
    }
}

export default withStyles(styles)(props => (
    <RpaContext.Consumer>
        {value => {
            return (<ManualJobPanel context={value} {...props} />);
        }
        }
    </RpaContext.Consumer>
));

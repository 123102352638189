import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {LinearProgress} from "@material-ui/core";
import {navigationTitleService} from "../../../../utils/title";
import AnalyticsContext from "../context";
import {AnalyticsDashboardBloc} from "./analytics.dashboard.bloc";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100%",
        height: "inherit",
        backgroundColor: "#fff",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    iframe: {
        minHeight: "inherit",
        display: "flex",
    }
});

class AnalyticsDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboardUri: undefined,
        };

        this.bloc = new AnalyticsDashboardBloc();
    }

    componentDidMount() {

        navigationTitleService.update("Dashboard");
        this.bloc.dashboardUri()
            .then(value => {
                this.setState({
                    dashboardUri: value,
                });
            })
    }

    componentWillUnmount() {
        this.bloc.close();
    }

    render() {

        let {classes} = this.props;
        let {
            busy,
            dashboardUri,
        } = this.state;

        const iframe = `<iframe src='${dashboardUri}' style='height:inherit;width:100%;border:none;overflow:hidden;flex-grow:1;'/>`

        return (
            <div className={classes.root}>
                {busy && <LinearProgress />}
                {dashboardUri && <div className={classes.iframe} dangerouslySetInnerHTML={{__html: iframe}}/>}
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AnalyticsContext.Consumer>
        {value => {
            return (<AnalyticsDashboard context={value} {...props} />);
        }
        }
    </AnalyticsContext.Consumer>
));

import React, {Component} from "react";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DialerSipIcon from "@material-ui/icons/DialerSip";

import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";

import {withStyles} from "@material-ui/core/styles";
import {navigationTitleService} from "../../../../utils/title";

import EncounterContext from "./context";
import {encounterEventService} from "./service/encounter.event.service";
import {conversationApi} from "../../../../utils/services/conversation.api";
import {resolvePlaceholders} from "../../../../utils/conversation.util";
import {notificationService} from "../../../../utils/notification";
import ActionBar from "./component/ActionBar";
import TelehealthPanel from "./component/Telehealth";
import RightPanel from "./component/RightPanel";
import Chart from "./component/Chart";

import TelehealthDialout from "./component/TelehealthDialout";
import TelehealthChat from "./component/TelehealthChat";
import VirtualClinicContext from "../context";

import {appointmentApi} from "../../../../utils/services/appointments.api";
import callIcon from "../../../../assets/icons/start_call.svg";
import {FormattedMessage} from "react-intl";
import {EncounterBloc, EncounterBlocEvent} from "./encounter.bloc";
import {globalBloc} from "../../global.bloc";
import ClinicalGraphInterface from "../ClinicalGraphInterface";
import {Actions, actions} from "../ClinicalGraphInterface/util";
import {KBarAnimator, KBarPortal, KBarPositioner, KBarProvider, KBarSearch} from "kbar";

const __screen = "calc(100vh - 56px)";

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    overflow: "auto",
    overscrollBehavior: "contain",
    touchAction: "none",
  },
  fill: {
    flex: "1 1 auto",
  },
  left: {
    flex: "1 1 auto",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    overflow: "hidden",
    overscrollBehavior: "contain",
    touchAction: "none",
    backgroundColor: "#525A61",
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "650px",
    minWidth: "650px",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    backgroundColor: "white",
    overflow: "visible",
    overscrollBehavior: "contain",
    touchAction: "none",
  },

  rightSmall: {
    display: "flex",
    flexDirection: "column",
    width: "54px",
    minWidth: "54px",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    backgroundColor: "white",
    overflow: "visible",
    overscrollBehavior: "contain",
    touchAction: "none",
  },
  panelNav: {
    position: "relative",
  },
  panelNavFabs: {
    paddingTop: "32px",
    zIndex: "1",
    position: "absolute",
    left: "-72px",
    display: "flex",
    flexDirection: "column",
    "& :first-child": {
      marginTop: "0 !important",
    },
  },
  panelNavFab: {
    marginTop: "24px",
    color: "#ffffff",
  },
  action: {
    height: "70px",
    width: "70px",
    backgroundColor: "rgba(45,176,66,1)",
    border: "8px solid rgba(73,127,93,1)",
    "&:hover": {
      backgroundColor: "rgba(45,176,66,0.6)",
    },
  },
  actionProgress: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  actionIcon: {
    color: "rgb(255, 255, 255)",
    height: "30px",
    width: "30px",
  },
  telehealth: {},
  telehealthButtonWrapper: {
    width: "72px",
    height: "72px",
    paddingTop: "20px",
    margin: "auto",
    display: "flex",
  },
  telehealthButton: {
    "& svg": {
      fontSize: "38px",
    },
  },
  telehealthButtonText: {
    textTransform: "capitalize",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "33px",
    textAlign: "center",

    color: "#FFFFFF",
  },
  popupFab: {
    float: "right",
  },

  popupContent: {
    width: "90vw",
  },

  savedRoot: {
    height: __screen,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  endBtnWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
  },
  button: {
    marginBottom: "16px",
  },

  zoomLaunch: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  zoomControlWrapperLaunch: {
    margin: "auto",
  },

  zoomLaunchBtn: {
    cursor: "pointer",
  },

  zoomLaunchText: {
    textTransform: "capitalize",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "33px",
    textAlign: "center",

    color: "#FFFFFF",
  },
  dialogButton: {
    marginRight: "12px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class Encounter extends Component {
  subscription;
  bloc;
  blocStateSubscription;
  blocEventSubscription;

  constructor(props) {
    super(props);

    const { encounterId, organisationId } = this.props.match.params;

    const search = props.location.search;
    const target = new URLSearchParams(search).get("t");

    let { clinicBloc } = this.props.context;

    const encounter = clinicBloc.subject.value.encounters.encounters.filter(
      (_encounter) => _encounter.id === encounterId
    );

    if (encounter.length === 0) {
      notificationService.error("Error loading the encounter.");
    }

    this.state = {
      target: target,
      panel: "chart",
      encounter: encounter[0],
      actionButtonGroupOpen: false,
      initialised: false,
      busy: false,
      telehealth: { state: "", audio: true, video: true },
      confirmUpsertVideo: false,
    };

    navigationTitleService.update("Virtual Clinic - Encounter");

    this.bloc = new EncounterBloc(organisationId, encounter[0]);

    this.handleEvent = this.handleEvent.bind(this);

    this.__switchPanel = this.__switchPanel.bind(this);
    this.__setPanel = this.__setPanel.bind(this);
  }

  __encounterStateHandler = (state) => {
    this.setState({
      initialised: state.initialised,
      busy: state.busy,
      encounter: state.encounter,
      resolved: state.resolvedNotifications,
      details: state.details,
      view: state.view,
      viewBloc: state.viewBloc,
      person: state.person,
    });
  };

  componentDidMount() {
    const { target } = this.state;
    navigationTitleService.update("Clinic - Encounter");
    this._initialise(target);
  }

  _initialise = (target) => {
    this.subscription = encounterEventService.registerStateCallback(
      this.handleEvent
    );
    this.blocStateSubscription = this.bloc.subscribeToState(
      this.__encounterStateHandler
    );
    this.blocEventSubscription = this.bloc.subscribeToEvents(
      this.__encounterEventHandler
    );
    this.bloc.initialise(target);

    let { encounter } = this.state;
    let props = {};

    if (encounter.conversationId) {
      this._getConversationInternal(encounter.conversationId, [], 0, 10);
      this._getConversationSummary(encounter.conversationId);
    } else {
      props.summary = {
        status: 404,
      };
    }

    this.setState({
      ...props,
    });
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.blocStateSubscription.unsubscribe();
    this.blocEventSubscription.unsubscribe();
    this.bloc.stopPoll();
  }

  handleEvent = (event) => {
    let { roles } = this.props.context;

    const { type, data } = event;

    const { encounter, telehealth, skipStartUpdate } = this.state;

    let newState = {};

    switch (type) {
      case "ENCOUNTER_CALL_CUSTOMER_START":
        {
          newState.telehealth = telehealth;
          newState.telehealth.state = "CONNECTED";

          const nextState = roles.includes("TNT_ASSIST")
            ? "A_STARTED"
            : "STARTED";

          if (
            !skipStartUpdate &&
            encounter.status !== "STARTED" &&
            encounter.status !== "A_STARTED"
          ) {
            appointmentApi
              .updateAppointmentState(encounter.id, nextState)
              .then((value) => {})
              .catch((reason) => {
                notificationService.update({
                  error:
                    "Unable to update the status of the appointment. " + reason,
                });
              });

            newState.skipStartUpdate = true;
          }

          this.bloc.setTelehealth(newState.telehealth);
        }
        break;
      case "ENCOUNTER_CALL_CUSTOMER_END":
        {
          newState.telehealth = telehealth;
          newState.telehealth.state = "DISCONNECT";
          newState.panel = "chart";
        }
        break;
      case "ENCOUNTER_CALL_TOGGLE_VIDEO":
        {
          newState.telehealth = telehealth;
          newState.telehealth.video = !telehealth.video;
        }
        break;
      case "ENCOUNTER_CALL_TOGGLE_AUDIO":
        {
          newState.telehealth = telehealth;
          newState.telehealth.audio = !telehealth.audio;
        }
        break;
    }

    this.setState({
      ...newState,
    });
  };

  __encounterEventHandler = (event) => {
    const { type, data } = event;

    const { messageReceived, panel, rightSize } = this.state;

    let newState = {};

    switch (type) {
      case EncounterBlocEvent.CHAT_RECEIVED_MESSAGE:
        {
          if (rightSize === "small" || panel !== "telehealth_chat") {
            newState.messageReceived = " ";
          }
        }
        break;
    }

    this.setState({
      ...newState,
    });
  };

  _getConversationInternal = (_conversationId, _messages, page, size) => {
    conversationApi
      .conversationMessages(_conversationId, page, size)
      .then((value) => {
        const data = value.data;
        const items = data.items;
        const pagination = data.page;

        let newState = {};

        if (items && items.length > 0) {
          let processed = data.items.map((item) => {
            item.content = resolvePlaceholders(item);
            return item;
          });
          _messages = _messages.concat(processed);
        }

        if (pagination && pagination.total > (page + 1) * size) {
          this._getConversationInternal(
            _conversationId,
            _messages,
            page + 1,
            size
          );
        } else {
          this.setState({
            messages: _messages,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: `Conversation not found - ${error}`,
          busy: false,
          messages: _messages,
        });
      });
  };

  _getConversationSummary = (_conversationId) => {
    conversationApi
      .conversationClinicalSummary(_conversationId)
      .then((value) => {
        if (value.status === 404) {
          this.setState({
            summary: {
              status: 404,
            },
          });
        } else {
          this.setState({
            summary: value.data ? value.data : { status: 404 },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            summary: {
              status: 404,
            },
          });
        } else {
          this.setState({
            error: `Conversation clinical summary not found - ${error}`,
            busy: false,
          });
        }
      });
  };

  _toggleRightSize = () => {
    const { rightSize } = this.state;
    if (rightSize && rightSize === "small") {
      this.setState({
        rightSize: "normal",
      });
    } else {
      this.setState({
        messageReceived: "",
        rightSize: "small",
      });
    }
  };

  _openTelehealth = () => {
    let { encounter } = this.state;

    appointmentApi
      .updateAppointmentState(encounter.id, "STARTED")
      .then((value) => {})
      .catch((reason) => {
        notificationService.update({
          error: "Unable to update the status of the appointment. " + reason,
        });
      });

    encounter.links.forEach((_link) => {
      if (_link.type === "telehealth") {
        window.open(
          _link.details.detail.start_url,
          "Telehealth Launch",
          "toolbar=no,scrollbars=yes,resizable=yes,top=0,left=0,width=500,height=500"
        );
      }
    });
  };

  __handleToggleButtonGroup = () => {
    const { actionButtonGroupOpen } = this.state;

    this.setState({
      actionButtonGroupOpen: !actionButtonGroupOpen,
    });
  };

  __createVideoSession = () => {
    this.setState({
      confirmUpsertVideo: false,
    });
    this.bloc.upsertVideoSession();
  };

  __openUpsertVideoDialog = () => {
    this.setState({
      confirmUpsertVideo: true,
    });
  };
  __closeUpsertVideoDialog = () => {
    this.setState({
      confirmUpsertVideo: false,
    });
  };

  __switchPanel = (newPanel) => (event) => {
    this.setState({
      panel: newPanel,
    });
  };

  __setPanel = (panel) => (event) => {
    let newState = {};

    if (panel === "telehealth_chat") {
      newState.messageReceived = "";
    }

    this.setState({
      ...newState,
      rightSize: "large",
      panel: panel,
    });
  };

  render() {
    let { classes } = this.props;
    let { subject, roles } = this.props.context;
    let {
      encounter,
      panel,
      initialised,
      busy,
      confirmUpsertVideo,
      telehealth,
      summary,
      person,
      details,
      messages,
      messageReceived,
      rightSize,
      view,
      viewBloc,
    } = this.state;

    if(!person) {
      return <CircularProgress />
    }

    if (view) {
      return this.__renderView(view, viewBloc);
    }

    let context = {
      roles: roles,
      initialised: initialised,
      busy: busy,
      encounter: encounter,
      subject: subject,
      person: person,
      summary: summary,
      messages: messages,
      telehealth: telehealth,
      encounterBloc: this.bloc,
    };

    let rightSizeClass = classes.right;
    let rightSizeIcon = <ChevronRightIcon />;

    if (rightSize && rightSize === "small") {
      rightSizeClass = classes.rightSmall;
      rightSizeIcon = <ChevronLeftIcon />;
    }

    let telehealthComponent = <></>;

    if (details) {
      if (details.platform === "vonage") {
        telehealthComponent = (
          <TelehealthPanel
            className={classes.telehealth}
            details={details}
            onStartVideo={this._startVideo}
          />
        );
      } else {
        telehealthComponent = (
          <div className={classes.zoomLaunch}>
            <div className={classes.zoomControlWrapperLaunch}>
              <img
                className={classes.zoomLaunchBtn}
                onClick={this._openTelehealth}
                src={callIcon}
              />
              <br />
              <div className={classes.zoomLaunchText}>Start zoom call</div>
            </div>
          </div>
        );
      }
    } else {
      const enableVideo = `${process.env.REACT_APP_APPOINTMENT_CREATE_VIDEO_ENABLED}`;
      const allowedToEnableVideo = globalBloc.checkAllowed(
        "interaction:encounter:send-patient-link"
      );
      const showEnableVideo = enableVideo === "1" && allowedToEnableVideo;

      telehealthComponent = (
        <div className={classes.zoomLaunch}>
          <div className={classes.zoomControlWrapperLaunch}>
            <div className={classes.telehealthButtonText}>
              <FormattedMessage
                id={"telehealth.generate.session"}
                defaultMessage={`${
                  encounter.service
                    ? encounter.service.description
                    : "In person"
                } encounter`}
              />
            </div>
            {showEnableVideo && (
              <>
                <div className={classes.telehealthButtonWrapper}>
                  <div className={classes.fill}></div>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={"encounter.video.upsert.help"}
                        defaultMessage={"Create video session for encounter"}
                      />
                    }
                  >
                    <Fab
                      disabled={busy}
                      onClick={this.__openUpsertVideoDialog}
                      className={classes.action}
                      aria-label="add"
                    >
                      <VideoCallIcon className={classes.actionIcon} />
                    </Fab>
                  </Tooltip>
                  <div className={classes.fill}></div>
                </div>
                <Dialog
                  open={confirmUpsertVideo}
                  onClose={this.__closeUpsertVideoDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    onClose={this.__closeUpsertVideoDialog}
                  >
                    <FormattedMessage
                      id={"encounter.video.upsert.confirmation.dialog.title"}
                      defaultMessage={
                        "Are you sure you want to start a video session?"
                      }
                    />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage
                        id={"encounter.video.upsert.confirmation.dialog.text"}
                        defaultMessage={
                          "Once you have created a video session, please remember to notify the patient via the 'send link' button. Click 'create' to create a video session?"
                        }
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.__closeUpsertVideoDialog}
                      className={classes.dialogButton}
                    >
                      <FormattedMessage
                        id={"cancel"}
                        defaultMessage={"Cancel"}
                      />
                    </Button>
                    <Button
                      color={"secondary"}
                      variant={"contained"}
                      onClick={this.__createVideoSession}
                      color={"primary"}
                      autoFocus
                    >
                      <FormattedMessage
                        id={"create"}
                        defaultMessage={"Create"}
                      />
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </div>
        </div>
      );
    }

    const unreadMessage = messageReceived?.length > 0;
    let messageColour =
      panel === "telehealth_chat"
        ? "primary"
        : unreadMessage
        ? "secondary"
        : "default";
    if (rightSize === "small" && unreadMessage) {
      messageColour = "secondary";
    }

    return (
      <EncounterContext.Provider value={context}>
        <ActionBar />
        <div className={classes.root}>
          {!initialised ? (
            <LinearProgress />
          ) : (
            <>
              <div className={classes.left}>
                {busy && <LinearProgress />}
                {telehealthComponent}
              </div>
              <div className={rightSizeClass}>
                <div className={classes.panelNav}>
                  {this.bloc.videoLink() && (
                    <div className={classes.panelNavFabs}>
                      <Fab
                        disabled={!this.bloc.telehealthConnected()}
                        onClick={this.__setPanel("telehealth_chat")}
                        aria-label={"chat"}
                        className={classes.panelNavFab}
                        color={messageColour}
                      >
                        <QuestionAnswerIcon />
                      </Fab>
                      <Fab
                        onClick={this.__setPanel("chart")}
                        aria-label={"chart"}
                        className={classes.panelNavFab}
                        color={
                          !panel || panel === "chart" ? "primary" : "default"
                        }
                      >
                        <AssignmentIndIcon />
                      </Fab>
                      <Fab
                        disabled={!this.bloc.telehealthConnected()}
                        onClick={this.__setPanel("telehealth_dial")}
                        aria-label={"dial"}
                        className={classes.panelNavFab}
                        color={
                          panel === "telehealth_dial" ? "primary" : "default"
                        }
                      >
                        <DialerSipIcon />
                      </Fab>
                    </div>
                  )}
                  {!this.bloc.videoLink() && (
                    <div className={classes.panelNavFabs}>
                      <Fab
                        onClick={this.__setPanel("chart")}
                        aria-label={"chart"}
                        className={classes.panelNavFab}
                        color={
                          !panel || panel === "chart" ? "primary" : "default"
                        }
                      >
                        <AssignmentIndIcon />
                      </Fab>
                    </div>
                  )}
                  <IconButton onClick={this._toggleRightSize}>
                    {rightSizeIcon}
                  </IconButton>
                </div>
                <RightPanel size={rightSize}>
                  {(!panel || panel === "chart") && <Chart size={rightSize} />}
                  {panel === "telehealth_chat" && (
                    <TelehealthChat size={rightSize} />
                  )}
                  {panel === "telehealth_dial" && (
                    <TelehealthDialout size={rightSize} />
                  )}
                </RightPanel>
              </div>
            </>
          )}
        </div>
      </EncounterContext.Provider>
    );
  }

  __renderView = (view, viewBloc) => {

    return <KBarProvider actions={actions(viewBloc)}>
      <KBarPortal style={Actions.portalStyle}>
        <KBarPositioner style={Actions.portalStyle}>
          <KBarAnimator style={Actions.animatorStyle}>
            <KBarSearch style={Actions.searchStyle} />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      <ClinicalGraphInterface bloc={viewBloc} />
    </KBarProvider>
  };
}

export default withStyles(styles)((props) => (
  <VirtualClinicContext.Consumer>
    {(value) => {
      return <Encounter context={value} {...props} />;
    }}
  </VirtualClinicContext.Consumer>
));

import { InputBase, withStyles } from "@material-ui/core";
import React, { Component } from "react";

const styles = (theme) => ({
  input: {
    borderColor: theme?.palette?.CGIPrimary?.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 32,
    flex: 1,
    // width: "100%",
    // maxWidth: props => !props.size || props.size === "medium" ? "50%" : "100%",
    height: 32,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('lg')]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: props => !props.size || props.size === "medium" ? "50%" : "100%",
    },
  },
  inputValue: {
    fontFamily: "inter !important",
    fontSize: 15,
  },
});

export class DHFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const {classes, placeholder, ...inputProps} = this.props;
    return (
        <InputBase
            autoFocus={this.props.autofocus}
            placeholder={placeholder}
            inputProps={{"aria-label": placeholder}}
            className={classes.input}
            classes={{input: classes.inputValue}}
            {...inputProps}
        />
    );
  }
}

export default withStyles(styles)(DHFormInput);

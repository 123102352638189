import * as rxjs from 'rxjs';
import _ from 'loadsh';
import {globalEventService} from "../../service/global.event.service";
import {appointmentApi} from "../../../../utils/services/appointments.api";
import {notificationService} from "../../../../utils/notification";
import {encounterApi} from "../../../../utils/services/encounter.api";
import {navigationTitleService} from "../../../../utils/title";
import {eventApi} from "../../../../utils/services/event.api";
import {organisationsApi} from "../../../../utils/services/organisations.api";

import {Bloc as ClinicalGraphBloc} from "../ClinicalGraphInterface/bloc";
import {customersApi} from "../../../../utils/services/customers.api";


export class EncounterBloc {

    sessionPollTimer;

    constructor(clinicId, encounter) {

        this.subject = new rxjs.BehaviorSubject({
            clinicId: clinicId,
            addressBook: [],
            encounter: encounter,
            telehealth: undefined,
            details: undefined,
            initialised: false,
            resolvedNotifications: [],
            sessionInformation: {},
            chat: {
                messages: [],
            },
            view: undefined,
        });

        this.events = new rxjs.Subject();

        globalEventService.registerStateCallback(this.__listenToGlobal);
    }

    initialise = (target) => {

        const { encounter } = this.subject.value;

        let chartLink = undefined;
        let details = undefined;

        if(encounter.links) {
            encounter.links.forEach((_link) => {
                if (_link.type === "telehealth") {
                    navigationTitleService.update("Clinic - Virtual");
                    details = _link.details
                } else if(_link.type === "chart") {
                    chartLink = _link
                }
            });
        }

        if(encounter.type !== "VIRTUAL_WALK_IN" && details) {
            this.startPoll();
        }

        this.subject.next({
            ...this.subject.value,
            details: details,
            chartLink: chartLink,
        });

        this.__loadAddressBook();

        this._loadSubject(encounter.customer, (subject) => {
            setTimeout(() => {
                if(target !== "cgi") {
                    this.openChart();
                } else {
                    this.toggleClinicalGraphInterface();
                }
                this.__updateSubject({initialised: true,})
            });
        });
    }

    __listenToGlobal = (event) => {


    }

    __updateSubject = (props) => {
        this.subject.next({
            ...this.subject.value,
            ...props,
        });
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    startPoll = () => {
        this.stopPoll();
        this.__encounterSession();
        this.sessionPollTimer = setInterval(() => this.__encounterSession(), 5000);
    };

    stopPoll = () => {
        if(this.sessionPollTimer) {
            clearInterval(this.sessionPollTimer);
        }
    };

    setView = (view) => {
        this.__updateSubject({ view: view })
    }

    _loadSubject = (subjectIdentifier, callback) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.__updateSubject({person: value.data});
                callback(value.data);
            });
    };

    __loadAddressBook = () => {
        organisationsApi.addressBook(this.subject.value.clinicId)
            .then(value => {

                this.subject.next({
                    ...this.subject.value,
                    addressBook: value.data.items,
                });
            }, reason => { notificationService.error("Error loading the organisation address book.")});
    }

    hasChartLink = () => {

        let { chartLink } = this.subject.value;

        return chartLink !== undefined
    };

    openChart = () => {

        let { encounter } = this.subject.value;

        encounter.links && encounter.links.forEach((_link) => {
            if(_link.type === "chart") {
                window.open(_link.details.deeplink, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            }
        });
    };


    toggleClinicalGraphInterface = () => {

        let { view } = this.subject.value;
        if( view === "cgi" ) {
            this.subject.next({
                ...this.subject.value,
                view: undefined,
                viewBloc: undefined,
            });
        } else {

            const { encounter, person, clinicId } = this.subject.value;

            const cgiBloc = new ClinicalGraphBloc({
                clinicId: clinicId,
                encounter: encounter,
                person: person,
            });

            this.subject.next({
                ...this.subject.value,
                view: "cgi",
                viewBloc: cgiBloc,
            });
        }
    };

    setTelehealth = (telehealth) => {
        const value = this.subject.value;
        this.subject.next({
            ...value,
            telehealth: telehealth,
        });
    }

    setTelehealthStatus = (status) => {
        const value = this.subject.value;
        this.subject.next({
            ...value,
            telehealthStatus: status,
        });
    }

    setTelehealthCallStatus = (status, data, local) => {
        const value = this.subject.value;
        this.subject.next({
            ...value,
            telehealthCall: {
                status: status,
                data: data,
                local: local,
            },
        });
    }

    telehealthHangup = () => {

        const { telehealthCall } = this.subject.value;

        if(telehealthCall?.data) {

            this.telehealthConnectionHangup(telehealthCall.data.connectionId);
        }
    }

    telehealthConnectionHangup = (connectionId) => {

        const value = this.subject.value;
        const { encounter, telehealthCall } = this.subject.value;


        encounterApi.dialOutDisconnect(encounter.id, {
            "identifier": {
                "system": "vonage",
                "code": "connectionId",
                "value": connectionId
            }
        })
            .then(_value => {

                if(telehealthCall?.data?.connectionId === connectionId) {

                    this.subject.next({
                        ...value,
                        telehealthCall: undefined,
                    });
                }
            }).catch(reason => {
            notificationService.httpError(reason);
        });
    }

    upsertVideoSession = () => {
        const value = this.subject.value;
        const { clinicId, encounter } = this.subject.value;

        this.subject.next({
            ...value,
            busy: true,
        });

        encounterApi.upsertVideoSession(clinicId, encounter.id)
            .then(data => {

                let details = undefined;

                data.data.links.forEach((_link) => {
                    if(_link.type === "telehealth" ) {
                        details = _link.details
                    }
                });

                this.subject.next({
                    ...value,
                    encounter: data.data,
                    details: details,
                    busy: false,
                });

                this.startPoll();
            }, reason => {
                notificationService.httpError(reason);
                this.subject.next({
                    ...value,
                    busy: false,
                });
            });
    }

    getChatHistory = () => {

        const { encounter } = this.subject.value;

        this.events.next({
            type: EncounterBlocEvent.CHAT_HISTORY_LOADING,
        });

        encounterApi.getChatHistory(encounter.id).then(response => {

            this.events.next({
                type: EncounterBlocEvent.CHAT_HISTORY_LOAD_SUCCESS,
            });

            const value = this.subject.value;
            this.subject.next({
                ...value,
                chat: {
                    messages: response.data.items
                },
            });

        }).catch(error => {

            this.events.next({
                type: EncounterBlocEvent.CHAT_HISTORY_LOAD_ERROR,
                error: error
            });

            notificationService.error(`Unable to retrieve chat history. ${error}`);
        })
    }

    telehealthStatus = () => {

        const { telehealth } = this.subject.value;

        if(telehealth) {
            return telehealth.state;
        }

        return 'DISCONNECT';
    }

    telehealthConnected = () => {

        return this.telehealthStatus() === 'CONNECTED';
    }

    videoLink = () => {

        return this.subject.value.details;
    }

    resolveNotification(identifier) {

        const value = this.subject.value;
        const { resolvedNotifications } = value;

        let newResolved = resolvedNotifications;
        newResolved.push(identifier);

        this.subject.next({
            ...value,
            resolvedNotifications: newResolved,
        });
    }

    __encounterSession = () => {

        const { clinicId, encounter, sessionInformation } = this.subject.value;

        encounterApi.encounterSession(clinicId, encounter.id)
            .then(value => {

                if(sessionInformation && _.isEqual(sessionInformation, value.data)) {
                    return;
                }

                this.subject.next({
                    ...this.subject.value,
                    sessionInformation: value.data,
                });
                this.__checkIfDisconnected(value.data);
            }, reason => { notificationService.error("Error retrieving session update - " + reason) })
    }

    __checkIfDisconnected = (sessionInformation) => {

        const { telehealthCall } = this.subject.value;

        if(telehealthCall) {

            const eligible = sessionInformation.participants.filter(value =>
                value.identifier?.code === "connectionId" &&
                value.identifier?.value === telehealthCall?.data?.connectionId &&
                ["HANGUP", "ENDED"].includes(value.status)
            );

            if(eligible.length > 0) {
                this.subject.next({
                    ...this.subject.value,
                    telehealthCall: undefined,
                });
            }
        }
    }

    sendPatientLink(organisationId, appointmentId, channel) {
        appointmentApi.sendCommunication( organisationId, appointmentId, { channel: { value: channel } })
            .then(value => { notificationService.success("Notification sent") }, reason => { notificationService.error("Error sending notification - " + reason) });
    }

    setSession = (newOtSession) => {

        const { otSession } = this.subject.value;
        if(otSession?.current?.sessionHelper?.session) otSession.current.sessionHelper.session.off();

        this.subject.next({
            ...this.subject.value,
            otSession: newOtSession,
        });

        const session = newOtSession?.current?.sessionHelper?.session;
        if(session) {
            session.on("signal:msg", this.__receivedChatMessage(session));
            if (this.subject.value.chat.messages.length === 0) this.getChatHistory();
        }
    }

    sendChatMessage = (msg) => {

        const { otSession } = this.subject.value;

        if(otSession && msg.data.message && msg.data.message.length > 0) {

            const session = otSession.current.sessionHelper.session;

            session.signal({
                type: 'msg',
                data: msg
            }, this.__sendChatMessageCompletionHandler);
            this.events.next({type: EncounterBlocEvent.CHAT_SEND_MESSAGE, content: msg});
            eventApi.publish(msg);
            this.__updateChatMessages(msg);
        } else {

            notificationService.error(`Messaging session not available.`);
        }
    }

    __sendChatMessageCompletionHandler = (error) => {

        if (error) {

            notificationService.error(`Error sending signal: ${error.message} ${error.message}.`);
        } else {

            this.events.next({ type: EncounterBlocEvent.CHAT_SENT_MESSAGE });
        }
    }

    __receivedChatMessage = (session) => (event) => {

        if (event.from.connectionId !== session.connection.id) {

            this.events.next({type: EncounterBlocEvent.CHAT_RECEIVED_MESSAGE, content: event.data});
            this.__updateChatMessages(event.data);
        }
    }

    __updateChatMessages = (message) => {

        const { chat } = this.subject.value;
        let newChat = chat;
        newChat.messages = [ message ].concat(newChat.messages);

        this.subject.next({
            ...this.subject.value,
            chat: newChat,
        });
    }
}

export class EncounterBlocEvent {
    static PERMISSIONS_LOADED = "CUSTOMER_NOTIFICATION_RESOLVED";

    static CHAT_SEND_MESSAGE = "CHAT_MESSAGE_SEND";
    static CHAT_SENT_MESSAGE = "CHAT_MESSAGE_SENT";
    static CHAT_RECEIVED_MESSAGE = "CHAT_MESSAGE_RECEIVED";

    static CHAT_HISTORY_LOADING = "CHAT_HISTORY_LOADING";
    static CHAT_HISTORY_LOAD_SUCCESS = "CHAT_HISTORY_LOAD_SUCCESS";
    static CHAT_HISTORY_LOAD_ERROR = "CHAT_HISTORY_LOAD_ERROR";
}

import {Typography} from "@material-ui/core";
import {AddCircleSharp, CancelSharp, CheckCircleSharp, RemoveCircleSharp,} from "@material-ui/icons";
import React from "react";
import {SwipeEnabledListItem} from "../../CGICommon";
import {getSelectedStatus, physicalExamStatusCode} from "./physicalExamUtils";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";

const styles = {
  root: {
    backgroundColor: "#F9F9F9",
    width: 400,
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 32,
    height: 40,
    marginTop: 8,
  },
  inline: {
    display: "inline-flex",
  },
};

class PhysicalExamBodySystemItem extends DecodedComponent {

  constructor(props) {
    super(props);
    this.bloc = props.physicalExamBloc;
    this.state = { initialised: false, };
  }

  render() {

    const { item, classes } = this.props;

    return (
        <SwipeEnabledListItem
            disableRightSwipe={ item.status !== physicalExamStatusCode.discarded }
            disableLeftSwipe={ item.status === physicalExamStatusCode.discarded }
            onRightSwipe={() => this.bloc.enableSection(item)}
            onLeftSwipe={() => this.bloc.discardSection(item)}
        >
          <div className={classes.root}>
            <div className={classes.inline}>
              {getIconForItem(item.status, {
                onClick: () => this.bloc.toggleAbnormalNormal(item)})}
              <Typography>{item.display}</Typography>
            </div>
            <SwitchChip
                status={item.status}
                detailed={item.status === physicalExamStatusCode.abnormal}
                onClick={() => { this.bloc.toggleNormalBriefNormal(item) }}
            />
          </div>
        </SwipeEnabledListItem>
    );
  }
}

export const SwitchChip = ({ status, detailed, onClick }) => {
  const { leadingIcon, trailingIcon, label } = getChipContent(status, detailed);
  const isAbnormal = status === 2;

  const styles = {
    root: {
      width: 86,
      height: 24,
      borderRadius: 32,
    },
    container: {
      height: "100%",
      display: "flex",
      backgroundColor: isAbnormal ? "#8BA3B0" : "#FFFFFF",
      borderRadius: 32,
    },
    content: {
      height: 24,
      minWidth: 24,
      borderRadius: 32,
      background: "#8BA3B0",
    },
    typography: {
      textAlign: "center",
      alignItems: "center",
      alignSelf: "center",
      fontSize: 10,
      width: "100%",
      color: isAbnormal ? "#fff" : "#9E9E9E",
      textTransform: "uppercase",
    },
    emptyDiv: {
      height: 24,
      minWidth: 24,
      borderRadius: 32,
      background: "#8BA3B0",
    },
  };
  return (
    <div style={styles.root} onClick={onClick}>
      <div style={styles.container}>
        {leadingIcon && <div style={styles.content} />}
        <Typography style={styles.typography}>{label}</Typography>
        {trailingIcon && <div style={styles.emptyDiv} />}
      </div>
    </div>
  );
};
export const getChipContent = (status, isDetailed) => {
  if (status === -1) {
    return {
      label: "Not Performed",
    };
  }
  if (status === 2) {
    return {
      label: "abnormal",
    };
  }
  if (status === 0) {
    return {
      label: "Detailed",
      trailingIcon: true,
    };
  }
  if (status === 1) {
    return {
      label: "Brief",
      leadingIcon: true,
      status: 1,
    };
  }
};
export const PhysicalExamBodySystemItemStatus = {
  cancelled: "cancelled",
  abnormal: "abnormal",
  positive: "positive",
  checked: "checked",
};

export const getIconForItem = (status, props) => {
  switch (getSelectedStatus({ status })) {
    case PhysicalExamBodySystemItemStatus.abnormal:
      return (
        <RemoveCircleSharp
          style={{ color: "#CD2863", marginLeft: 8, marginRight: 8 }}
          {...props}
        />
      );
    case PhysicalExamBodySystemItemStatus.positive:
      return (
        <AddCircleSharp
          style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }}
          {...props}
        />
      );
    case PhysicalExamBodySystemItemStatus.cancelled:
      return (
        <CancelSharp
          style={{ color: "#E3E3E3", marginLeft: 8, marginRight: 8 }}
          {...props}
        />
      );
    case PhysicalExamBodySystemItemStatus.checked:
      return (
        <CheckCircleSharp
          style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }}
          {...props}
        />
      );
    default:
      return (
        <AddCircleSharp
          style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }}
          {...props}
        />
      );
  }
};

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
      {value => {
        return (<PhysicalExamBodySystemItem context={value} {...props} />);
      }
      }
    </ClinicalGraphInterfaceContext.Consumer>
));

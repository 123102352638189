import axios from 'axios';

class CustomersApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getPersonSummary(consumerId, origin) {

        if(origin) {

            return axios.get(`${this.baseUrl}/api/customers/${encodeURI(consumerId)}?o=${encodeURI(origin)}`);
        } else {

            return axios.get(`${this.baseUrl}/api/customers/${encodeURI(consumerId)}`);
        }
    }

    getPersonChartSummary(organisationId, personId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(personId)}/chart`)
    }

    match(customerDetail) {

        return axios.post(`${this.baseUrl}/api/customers/_match`, customerDetail)
            ;
    }

    validate(customerDetail) {

        return axios.post(`${this.baseUrl}/api/customers/${customerDetail.id}/_validate`, customerDetail)
            ;
    }

    reserveId() {

        return axios.get(`${this.baseUrl}/api/customers/_reserve_id`)
            ;
    }


    getPaymentMethods(consumerId) {

        return axios.get(`${this.baseUrl}/api/customers/${encodeURI(consumerId)}/payment_methods`);
    }

    schedule(create) {

        return axios.post(`${this.baseUrl}/api/customers/_schedule`, create)
            ;
    }

    resolveNotifications(consumerId, identifier) {

        return axios.post(`${this.baseUrl}/api/customers/${consumerId}/notifications/_resolve`, identifier)
            ;
    }
}

export const customersApi = new CustomersApi();

import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {AnalyticsEvent, analyticsEventLogger} from "../../../utils/events";
import {globalBloc} from "../global.bloc";
import Loading from "../../shared/Loading";


const styles = theme => ({
    root: {
        overflow: "auto",
        overscrollBehavior: "contain",
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
    },
});


class RpaLink extends Component {

    eventsSubscription;

    constructor(props) {
        super(props);

        analyticsEventLogger.log(AnalyticsEvent.LINK_OPEN, { location: "rpa" });
    }

    componentDidMount() {

        globalBloc.reloadPermissions();
        setTimeout(() => {
            this.props.history.push(`/rpa/_/manual_steps`);
        }, 1000);
    }

    render() {

        let { classes } = this.props;

        return (
            <div className={classes.root}>
                <Loading />
            </div>
        );
    }
}

export default withStyles(styles)(RpaLink);

import * as rxjs from 'rxjs';
import {globalEventService} from "../../service/global.event.service";
import {analyticsApi} from "../../../../utils/services/analytics.api";
import {notificationService} from "../../../../utils/notification";


export class AnalyticsDashboardBloc {

    globalEventServiceSubscription;

    constructor(organisationId) {

        this.subject = new rxjs.BehaviorSubject({
            organisationId: organisationId,
            items: [],
        });

        this.events = new rxjs.Subject();

        this.globalEventServiceSubscription = globalEventService.registerStateCallback(this.__listenToGlobal);
    }

    close = () => {
        this.globalEventServiceSubscription.unsubscribe();
    }

    __listenToGlobal = (event) => {


    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    dashboardUri = () => {

        return analyticsApi.dashboardSignedUrl()
            .then(value => value.data.url,reason => notificationService.update({"error": "Error loading dashboard - " + reason}));
    }

}

export class AnalyticsBlocEvent {
    static DASHBOARD_LOADED = "DASHBOARD_LOADED";
    static DASHBOARD_FAILED = "DASHBOARD_FAILED";
}

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Allowed from "../../../utils/auth/Allowed";


import {ReactComponent as CustomersNavIconSvg} from '../../../assets/icons/nav/customers_nav.svg';
import {ReactComponent as EncounterNavIconSvg} from '../../../assets/icons/nav/encounter_nav.svg';
import {ReactComponent as AccountNavIconSvg} from '../../../assets/icons/nav/account_nav.svg';
import {ReactComponent as WorkIconSvg} from '../../../assets/icons/nav/work.svg';
import {ReactComponent as QueueControlSvg} from '../../../assets/icons/nav/queue_control.svg';
import {ReactComponent as SettingsIconSvg} from '../../../assets/icons/nav/settings_nav.svg';
import {ReactComponent as AnalyticsIconSvg} from '../../../assets/icons/nav/analytics.svg';
import ExitIconSvg from '../../../assets/icons/exit.svg';
import {authService} from "../../../utils/auth";
import GlobalContext from "../../Secure/context";

const closedDrawerWidth = 65;

function colorEnvironment() {

    if(window.location.href.indexOf("staging") > 0) {

        return "#2121ff";
    } else if(window.location.href.indexOf("test") > 0) {

        return "#21ff21";
    } else if(window.location.href.indexOf("localhost") > 0) {

        return "#ff2121";
    }

    return "transparent";
}

const styles = theme => ({
    root: {
        paddingTop: "165px",
        height: "100vh",
        width: closedDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: 'linear-gradient(174.69deg, #5E666D -30.47%, #2C353D 99.71%)',
        overflowX: 'hidden',
        overscrollBehavior: "contain",
        touchAction: "none",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    navList: {

    },
    navListBottom: {
        backgroundColor: colorEnvironment(),
    },
    navListItem: {
        paddingLeft: "20px",
        marginBottom: "50px",
        '&:hover': {
            '& svg': {
                '& path': {
                    fill: "#07C6FF",
                }
            }
        },
        '&:active': {
            '& svg': {
                '& path': {
                    fill: "#07C6FF",
                }
            }
        }
    },
    navListItemActive: {
        paddingLeft: "20px",
        marginBottom: "50px",
        '& svg': {
            '& path': {
                fill: "#07C6FF",
            }
        },
    },
    navListItemBottom: {
        paddingLeft: "20px",
        marginBottom: "10px",
    },
    navListItemIcon: {
    },

    icon: {
        color: "#fff",
    }
});

class DecodedDrawer extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            rpa: `${process.env.REACT_APP_RPA_ENABLED}`,
            workflow: `${process.env.REACT_APP_WORKFLOW_ENABLED}`,
            admin: `${process.env.REACT_APP_ADMIN_ENABLED}`,
        };
    }

    navigateTo = (url) => {

        return () => {
            this.props.history.push(`${url}`);
        }
    };

    doLogout = () => {

        authService
            .logout()
            .then(() => window.location = "/");
    };

    __determineActiveRoute = () => {

        const location = window.location.href;

        if(location.indexOf('/cases') > -1) {

            return '/cases';
        }

        if(location.indexOf('/clinic') > -1) {

            return '/clinic';
        }

        if(location.indexOf('/scheduling') > -1) {

            return '/scheduling';
        }

        if(location.indexOf('/account') > -1) {

            return '/account';
        }

        if(location.indexOf('/workflow') > -1) {

            return '/workflow';
        }

        if(location.indexOf('/rpa') > -1) {

            return '/rpa';
        }

        if(location.indexOf('/administration') > -1) {

            return '/administration';
        }

        if(location.indexOf('/analytics') > -1) {

            return '/analytics';
        }


        return "__undefined__";
    }

    render() {

        const { rpa, workflow, admin } = this.state;

        const { roles, classes } = this.props;

        const { location } = this.props.context;

        let menus = (
            <><div className={classes.fill}></div></>
        );

        if(location && location.length > 0 && roles && roles.length > 0) {

            const current = this.__determineActiveRoute();

            menus = (<>
                <Allowed roles={roles} priviledge="analytics:dashboard:view"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-analytics-btn"} className={current === "/analytics" ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/analytics/dashboard`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ AnalyticsIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                />
                <Allowed roles={roles} priviledge="interaction:encounter:*"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-waiting-room-btn"} className={current === "/clinic" ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/clinic/${location}/waiting-room`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ EncounterNavIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                />
                <Allowed roles={roles} priviledge="interaction:appointment:*"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-scheduling-btn"} className={current === "/scheduling" ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/scheduling`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ CustomersNavIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                />
                <Allowed roles={roles} priviledge="account:update:me"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-account-btn"} className={current === "/account" ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo('/account')} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ AccountNavIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                />
                { workflow === "1" && <Allowed roles={roles} priviledge="workflow:manual:*"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-workflow-btn"} className={current === `/workflow` ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/workflow/${location}/patient-tracker`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ QueueControlSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                /> }
                { rpa === "1" && <Allowed roles={roles} priviledge="rpa:manual:*"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-rpa-btn"} className={current === `/rpa` ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/rpa/${location}/manual_steps`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ WorkIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                /> }
                <div className={classes.fill}></div>
                { admin === "1" && <Allowed roles={roles} priviledge="admin:settings:*"
                    yes={() => (
                        <>
                            <List className={classes.navList}>
                                <ListItem id={"sidenav-administration-btn"} className={current === `/administration` ? classes.navListItemActive : classes.navListItem} onClick={this.navigateTo(`/administration`)} button>
                                    <ListItemIcon className={classes.navListItemIcon} >
                                        <SvgIcon component={ SettingsIconSvg } viewBox="0 0 25 25" />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </>)}
                    no={() => (<></>)}
                /> }
                  </>);
        }


        return (
            <div className={classes.root}>
                { menus }
                <List className={classes.navListBottom}>
                    <ListItem id={"sidenav-logout-btn"} className={classes.navListItemBottom} onClick={this.doLogout} button>
                        <ListItemIcon className={classes.navListItemIcon} >
                            <img src={ExitIconSvg} alt="" width="25" height="25" />
                        </ListItemIcon>
                    </ListItem>
                </List>
            </div>
        );
    }
}

DecodedDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(props => (
    <GlobalContext.Consumer>
        {value => {
                return (<DecodedDrawer context={value} {...props} />);
            }
        }
    </GlobalContext.Consumer>
)));

import axios from 'axios';
import {VERSION} from "../version";
import {tenantUtil} from "../tenant";

class AuthApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";

        this.instance = axios.create()
        this.instance.interceptors.request.use(async (config) => {

            config.headers['X-DH-source'] =  `Provider PWA`;
            config.headers['X-DH-version'] =  VERSION;
            config.headers['Content-Type'] = 'application/json'
            config.headers['X-API-KEY'] =  process.env.REACT_APP_DH_API_KEY;
            config.headers['X-TENANT-ID'] =  tenantUtil.tenant();

            return config;
        }, (error) => {

            return Promise.reject(error);
        });
    }

    resetPassword(email, url) {

        return this.instance.post(`${this.baseUrl}/api/auth/_reset`, { email: email, url: url });
    }
}

export const authApi = new AuthApi();

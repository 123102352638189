import * as rxjs from "rxjs";
import {globalBloc} from "../global.bloc";
import {organisationsApi} from "../../../utils/services/organisations.api";
import {SchedulingEvent} from "./service/scheduling.event.service";
import {notificationService} from "../../../utils/notification";

export class SchedulingBloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            serviceProviders: [],
            staff: []
        });

        this.events = new rxjs.Subject();

        this.globalBlocEventSubscription = globalBloc.subscribeToEvents(this.__listenToGlobalEvents);
        this.globalBlocStateSubscription = globalBloc.subscribeToState(this.__listenToGlobalState);
    }

    __listenToGlobalEvents = (e) => {

    }

    __listenToGlobalState = (s) => {

        this.location = s.location;
        this.loadServiceProviders();
        this.resetServiceProvidersStaff();
    }

    __updateSubject = (newProps) => {

        const _current = this.subject.value;

        this.subject.next({
            ..._current,
            ...newProps,
        })
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    loadServiceProviders = () => {

        organisationsApi.listServiceProviders(this.location)
            .then(value => {

                this.__updateSubject({ serviceProviders: value.data.items })

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_LOADED,
                    data: {
                        serviceProviders: value.data.items,
                    },
                });
            }, reason => {

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_LOADING_ERROR,
                    data: {
                        reason: reason,
                    },
                });

                notificationService.error(`Error reading the service providers to retrieve service catalog due to ${reason}.`);
            });
    }

    resetServiceProvidersStaff = () => {

        this.__updateSubject({ staff: [] })

        this.events.next({
            type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADED,
            data: { staff: [], },
        });
    }

    close = () => {

        this.globalBlocEventSubscription.unsubscribe();
        this.globalBlocStateSubscription.unsubscribe();
    }
}

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import AppointmentContext from "../context";


const styles = theme => ({
    root: {
        margin: "16px",
        width: "280px",
        height: "262px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        cursor: "hand",
    },
    fill: {
        flex: "1 1 auto",
    },
    selectedBorder: {
        border: "1px solid #2F80ED",
    },
    cardHeader: {
        padding: "17px 21px 12px 12px",
        display: "flex",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: {},
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        padding: "0px 21px 0px 12px",
        height: "162px",
    },
    cardBodyContent: {
        minHeight: "162px",
        maxHeight: "162px",
        overflow: "hidden",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.6",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: "#000000",
    },
    cardBodyContentLabel: {
        color: "#98A3AE",
    },
    cardSubHeader: {
        padding: "10px 21px 6px 12px",
        display: "flex",
    },
    cardFooter: {
        padding: "13px 21px 15px 12px",
        display: "flex",
    },
    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },

    cardAlert: {
        minHeight: "33px",
        maxHeight: "33px",
    },

    alert: {
        textAlign: "center",
        margin: "0",
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },

    cardBodyClaimed: {
        display: "flex",
        minHeight: "180px",
        maxHeight: "180px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyContentClaimed: {
        padding: "17px 21px 0px 12px",
        display: "flex",
        minHeight: "180px",
        maxHeight: "180px",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "10px",
        }
    },
    button: {
        height: "32px",
        minHeight: "32px",
        width: "100%",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    confirmationButton: {
        height: "32px",
        minHeight: "32px",
        paddingLeft: "24px",
        paddingRight: "24px",
        background: "#00C3FF",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial',
    },
    dialogButton: {
        marginRight: '12px',
    },
});

class PatientPreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
        };
    }

    _toggleHighlightCustomer = () => {

        let {candidate, highlighted,} = this.props;

        const selected = (highlighted === candidate.id);

        if (!selected) {
            this.setState({
                confirm: true,
            });
        } else {

            this.props.onCandidateHighlight(candidate.id);
        }
    }

    _selectCandidateConfirm = () => {

        this.props.onCandidateConfirm(this.props.candidate);

        this.setState({
            confirm: false,
        });
    }

    _closeDialog = () => {

        this.setState({
            confirm: false,
        });
    };

    render() {

        let {confirm} = this.state;
        let {candidate, highlighted, classes} = this.props;

        let borderColor = classes.okBorder;

        const selected = (highlighted === candidate.id);

        if (selected) {

            borderColor = classes.selectedBorder;
        }

        let ssn = "";
        const ssns = candidate.externalReferences.filter(value => value.system === "http://hl7.org/fhir/vs/identifier-type" && value.code === "SS");
        if (ssns.length > 0) {
            ssn = ssns[0];
        }

        let alert = <></>;

        return (
            <>
                <ButtonBase id={`customer-card-${candidate.id}`} className={classes.cardAction}
                            onClick={this._toggleHighlightCustomer}>
                    <div className={`${classes.root} ${borderColor}`}>
                        <div className={classes.cardHeader}>
                            <div className={classes.cardAvatar}>
                                <Avatar
                                    className={classes.avatar}>{candidate ? <>{candidate.name.given.charAt(0)}</> : <>U</>}</Avatar>
                            </div>
                            <div className={classes.cardTitle}>
                                <div
                                    className={classes.cardTitleText}>{candidate ? <>{candidate.name.given}<br/>{candidate.name.family}</> : <>Unknown<br/>Unknown</>}</div>
                            </div>
                        </div>
                        <div className={classes.cardBody}>
                            <div className={classes.cardBodyContent}>
                                <span className={classes.cardBodyContentLabel}>Email:</span> {candidate.email} <br/>
                                <span className={classes.cardBodyContentLabel}>Gender:</span> {candidate.gender} <br/>
                                <span className={classes.cardBodyContentLabel}>DOB:</span> {candidate.dob} <br/>
                                {candidate.contactNumber && <><span
                                    className={classes.cardBodyContentLabel}>Mob:</span> {candidate.contactNumber.country}{candidate.contactNumber.area}{candidate.contactNumber.prefix}{candidate.contactNumber.line}
                                    <br/></>}
                                <span className={classes.cardBodyContentLabel}>SSN:</span> {ssn.reference} <br/>
                            </div>
                        </div>
                    </div>
                </ButtonBase>
                <Dialog
                    open={confirm}
                    onClose={this._closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Select Customer"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure this is the correct customer?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._closeDialog} className={classes.dialogButton}>
                            Cancel
                        </Button>
                        <Button id={`customer-card-${candidate.id}-confirm`} color={"secondary"} variant={"contained"}
                                onClick={this._selectCandidateConfirm} color={"primary"} disableElevation autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(PatientPreview);

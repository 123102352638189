import MaskedInput from "react-text-mask";
import React from "react";

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

export function PhoneTextMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

export function ZipTextMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[ /\d/, /\d/,/\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

export function SsnTextMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-8*]/, /[0-9*]/, /[0-9*]/,
                '-', /[0-9*]/, /[0-9*]/, '-', /[0-9*]/, /[0-9*]/, /[0-9*]/, /[0-9*]/]}
            placeholderChar={'\u2000'}
        />
    );
}

export function DateMask(props) {
    const { inputRef, ...other } = props;

    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('MM/dd/yyyy', {minYear: 1880})

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[ /[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/,/\d/, /\d/, ]}
            placeholderChar={'\u2000'}
            keepCharPositions={true}
            pipe={autoCorrectedDatePipe}
        />
    );
}

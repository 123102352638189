import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core";
import CalendarIcon from "../../../../../../assets/calendar.svg";

const styles = (theme) => ({
  outlined: {
    // height: 32,
    // width: "100%",
    // minWidth: "100%",
    display: "flex",
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #858585",
    marginBottom: 4,
  },
  underline: {
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
  },
});

const DHCalendar = (props) => {
  const { defaultValue = "", onChange, classes, disabled } = props;

  const [dateValue, setDateValue] = useState(defaultValue);

  const handleDateChange = (date) => {
    setDateValue(date);
    onChange && onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <KeyboardDatePicker
          variant="inline"
          margin="normal"
          format="MM-dd-yyyy"
          value={dateValue}
          onChange={handleDateChange}
          className={classes.outlined}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputProps={{
            disableUnderline: true,
            style: { marginLeft: 16 },
          }}
          keyboardIcon={<img src={CalendarIcon} alt="calendar" />}
          inputVariant="standard"
          invalidDateMessage=""
          disabled={disabled}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(DHCalendar);

import * as rxjs from 'rxjs';
import {rpaApi} from "../../../utils/services/rpa.api";
import {notificationService} from "../../../utils/notification";


export class RpaBloc {

    manualStepPoll;

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            jobs: [],
        });

        this.events = new rxjs.Subject();
    }

    start = () => {

        this.__pollRpaManualSteps();
    }

    __pollRpaManualSteps = () => {

        this.__callRpaManualSteps();
        this.manualStepPoll = setInterval(this.__callRpaManualSteps, 2000);
    }

    __callRpaManualSteps = () => {

        rpaApi.list()
            .then(value => {

                this.subject.next({
                    ...this.subject.value,
                    jobs: value.data.items,
                });
            }, reason => {
                notificationService.httpError(reason);
            });
    }

    close = () => {

        clearInterval(this.manualStepPoll);
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    selectJob = (job) => {
        this.subject.next({
            ...this.subject.value,
            activeJob: job,
        });

        this.events.next({ event: RpaBlocEvent.JOB_SELECTED, data: job});
    }
}

export class RpaBlocEvent {
    static JOB_SELECTED = "JOB_SELECTED";
}

import React, {Component} from 'react';
import {IntlProvider} from "react-intl";

import {Route, Switch, withRouter} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import {DndProvider} from 'react-dnd';
import {TouchBackend} from 'react-dnd-touch-backend';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';

import './App.css';

import Login from '../Login';
import Secure from '../Secure';

import {PrivateRoute} from '../../utils/protected.route';
import {messages} from "../../utils/intl/messages";
import LocalNotifications from "../shared/Notifications";
import LinkHandler from "../Secure/Link";
import {LINK_ROUTE, LINK_ROUTE_SHORT} from "../../utils/route.name";

//TODO: Add font sizes here and remove from components.
const theme = createMuiTheme({
  root: {
    fontFamily: 'Roboto',
    minHeight: "100vh",
    maxHeight: "100vh",
    overflow: "hidden",
    // overscrollBehavior: "contain",
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
    ].join(', '),
    h1: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "32px",
      lineHeight: "24px",
      color: "#20516A",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "36px",
      color: "#20516A",
    },
    h3: {
      color: "#20516A",
    },
    h4: {
      color: "#20516A",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      color: '#8BA3B0',
    },
    h6: {
      color: "#20516A",
    },

    CGIbody1: {
      fontWeight: 500,
      fontSize: 14,
    },
    CGIbody2: {
      fontWeight: 500,
      fontSize: 16,
    },
    CGISubTitle1: {
      fontWeight: 400,
      fontSize: 14,
    },
    CGISubHead: {
      fontWeight: 400,
      fontSize: 12,
    },
    CGIHeading: {
      fontWeight: 400,
      fontSize: 20,
    },

  },
  palette: {
    type: 'light',
    primary: {
      main: '#07C6FF',
      alternate: '#C350CC',
    },
    CGIPrimary: {
      main: "#777777",
    },
    CGITextPrimary: {
      main: "#333333",
    },
    CGIInputBorderColor: {
      main: "#C4C4C4",
    },
    chipBackgroundColor: {
      main: "#E5E5E5",
    },
    chipSelectedBackgroundColor: {
      main: "#00EBFF",
    },
    CGISecondary: {
      main: "#323232",
    },
    CGIIconColor: {
      main: "#C4C4C4"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {

        background: '#FFFFFF',
        borderRadius: '32px',
        height: '39px',

        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',

        color: '#313941',
        '& > input': {
          height: '39px',
          padding: '18.5px 24px',
        }
      },
    },
    MuiDialogTitle: {
      root: {
        color: "#20516A",
      },
    },
    MuiDialogContent: {

      root: {
        padding: "24px 24px 24px 24px",
      },
    },
    MuiDialogActions: {

      root: {
        padding: "24px 24px 24px 12px",
      },
    },

    MuiChip: {
      colorSecondary: "#FD1F54",
    },

    MuiCircularProgress: {
      colorSecondary: "#ffffff",
    },

    MuiSwitch: {
      colorPrimary: {
        color: "#C92D5C",
      }
    },

    MuiButton: {

      root: {
        height: "36px",
        minHeight: "36px",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
      },

      contained: {
        minWidth: "120px",
        boxShadow: "none !important",
      },

      containedPrimary: {
        color: "#ffffff",
        backgroundColor: "#00C3FF",
      },

      containedSecondary: {
        color: "#ffffff",
        backgroundColor: "#FD1F54",
      },

      text: {
        '&:hover': {
          color: "#00C3FF",
        },
      },

      textPrimary: {

      },
      textSecondary: {
        '&:hover': {
          color: "#FD1F54",
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  }
});

const styles = {
  root: {
    display: 'flex',
    minHeight: "100%",
    width: "100%",
    maxHeight: "100%",
  },
};


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTouchable: false
    };
  }
  componentDidMount() {
    if (window.ontouchstart !== undefined) {
      this.setState(state => { return { ...state, isTouchable: true }; });
    }
    if (process.env.REACT_APP_HTTPS !== "http") {
      // eslint-disable-next-line no-restricted-globals
      window.location.protocol === 'http:' && (location.href = location.href.replace(/^http:/, 'https:'));
    }
  }

  render() {


    const { classes } = this.props;
    const { isTouchable } = this.state;

    return (
      <DndProvider backend={isTouchable ? TouchBackend : HTML5Backend} options={{touchSlop :20, delayTouchStart : 10000, ignoreContextMenu: true }}>
        <MuiThemeProvider theme={theme} >
          <IntlProvider locale='en' messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path={LINK_ROUTE_SHORT} component={LinkHandler} />
                <PrivateRoute path={LINK_ROUTE} component={LinkHandler} />
                <PrivateRoute path="/" component={Secure} />
              </Switch>
              <LocalNotifications />
            </div>
          </IntlProvider>
        </MuiThemeProvider>
      </DndProvider>
    );
  }
}


export default withStyles(styles)(withRouter(App));

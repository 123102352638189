import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {CircularProgress, IconButton, Input, InputAdornment} from "@material-ui/core";


import SendIcon from '@material-ui/icons/Send';
import EncounterContext from "../../context";
import {EncounterBlocEvent} from "../../encounter.bloc";
import {globalBloc} from "../../../../global.bloc";
import {CloudEvent} from "cloudevents";

const styles = theme => ({
    root: {
        height: "100%",
        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        padding: "0 12px 20px 24px"
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "32px",
    },

    chatInputFormWrapper: {
        position: "relative",
        paddingTop: "21px",
        paddingRight: "12px",
        paddingBottom: "8px",
    },

    chatInput: {
        cursor: "pointer",
    },

    click: {
        cursor: "pointer",
    },

    chat: {
        height: "inherit",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
    },

    subjectMessageRow: {
        paddingTop: "24px",
        display: "flex",
    },
    subjectMessageContent: {
        paddingTop: "32px",
        paddingRight: "12px",
    },
    subjectMessage: {
        minWidth: "100px",
        borderRadius: "6px",
        padding: "10px 12px",
        backgroundColor: "rgba(194, 206, 212, 0.2)",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#002F59",
    },
    subjectMessageTimestamp: {
        paddingTop: "8px",
        float: "right",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#828282",
        textAlign: "right",
    },

    providerMessageRow: {
        paddingTop: "24px",
        display: "flex",
    },

    providerMessageContent: {
        paddingTop: "32px",
    },
    providerMessage: {
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '6px',
        marginTop: "8px",
        padding: "10px 12px",
        backgroundColor: "#00C3FF",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#ffffff",
    },
    providerMessageTimestamp: {
        paddingTop: "8px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "22px",
        color: "#828282",
    },
});


class TelehealthChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            messages: [],
        };

        this.__handleEncounterEvents = this.__handleEncounterEvents.bind(this);
    }

    componentDidMount() {

        const { encounterBloc } = this.props.context;

        encounterBloc.subscribeToState(this.__handleEncounterState);
        encounterBloc.subscribeToEvents(this.__handleEncounterEvents);
    }

    __change = (event) => {

        this.setState({
            message: event.target.value,
        });
    }

    __handleEncounterState = (state) => {

        const { chat, encounter } = state;

        this.setState({
            encounter: encounter,
            messages: chat.messages,
        });
    }

    __handleEncounterEvents = (event) => {

        switch (event.type) {
            case EncounterBlocEvent.CHAT_SENT_MESSAGE:

                this.setState({
                    message: "",
                });
                break;
            case EncounterBlocEvent.CHAT_HISTORY_LOADING:

                this.setState({
                    loading: true,
                });
                break;
            case EncounterBlocEvent.CHAT_HISTORY_LOAD_SUCCESS:
            case EncounterBlocEvent.CHAT_HISTORY_LOAD_ERROR:

                this.setState({
                    loading: false,
                })
                break;
        }
    }

    __keyUp = (event) => {

        const enterKey = 13;
        if (event.which === enterKey){
            this.__send();
        }
    }

    __send = () => {

        const { message, encounter } = this.state;
        const { encounterBloc } = this.props.context;

        const data = {
            "author": {
                "role": "PROVIDER",
                "identifier": {
                    "system": "decoded",
                    "code": "id",
                    "value": globalBloc.subject.value.profile.id,
                }
            },
            "message": message
        };

        let ce = new CloudEvent({
            type: "telehealth.chat.message",
            datacontenttype: "application/json",
            source: "/provider-pwa",
            data
        });
        ce = ce.cloneWith({xdhvendor: "dh"});
        ce = ce.cloneWith({xdhsystem: "vonage"});
        ce = ce.cloneWith({xdhencounter: encounter.id});

        let newMessage = ce.toJSON();

        encounterBloc.sendChatMessage(newMessage);
    }

    render() {

        let { classes, size } = this.props;
        let { messages, message, loading } = this.state;

        return (
            <div  hidden={ size && size === 'small' }  className={classes.root}>
                <div className={classes.chat}>
                    { messages.map(_message => this.__renderChat(classes, _message)) }
                </div>
                <div className={classes.chatInputFormWrapper}>
                    <Input
                        id="chat-input"
                        className={classes.chatInput}
                        type={'text'}
                        placeholder={"Type a message..."}
                        fullWidth
                        value={message}
                        onChange={this.__change}
                        onKeyUp={this.__keyUp}
                        autoComplete='off'
                        disabled={loading}
                        endAdornment={
                            <InputAdornment position="end">
                                {loading ? <CircularProgress size={"1.1em"}/> :
                                    <IconButton
                                        aria-label="send message"
                                        onClick={this.__send}
                                    >
                                        <SendIcon/>
                                    </IconButton>
                                }
                            </InputAdornment>
                        }
                    />
                </div>
            </div>
        );
    }

    __renderChat(classes, message) {

        if(message.data.author.role === "PROVIDER") {
            return <>
                <div className={classes.subjectMessageRow}>
                    <div className={classes.fill}> </div>
                    <div className={classes.subjectMessageContent}>
                        <div className={classes.subjectMessage}>{message.data.message}</div>
                        <span className={classes.subjectMessageTimestamp}>{this.__renderMessageTime(message.time)}</span>
                    </div>
                </div>
            </>;
        }

        return <>
            <div className={classes.providerMessageRow}>
                <div className={classes.providerMessageContent}>
                    <div className={classes.providerMessageTimestamp}>{this.__renderMessageTime(message.time)}</div>
                    <div className={classes.providerMessage}>{message.data.message}</div>
                </div>
                <div className={classes.fill}> </div>
            </div>
        </>;
    }

    __renderMessageTime = (time) => {

        const displayTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return new Date(Date.parse(time)).toLocaleString('en-US', { timeZone: displayTimezone });
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<TelehealthChat context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const styles = {
  chip: {
    minHeight: 32,
    backgroundColor: "#FFFFFF",
    borderRadius: 22,
    marginTop: 6,
    marginBottom: 6,
    justifyContent: "flex-start",
    maxWidth: 322,
    minWidth: 322,
    color: "#333333",
    paddingTop: 7,
    paddingBottom: 7,
  },
  label: {
    width: "100%",
  },
  DHlabel: {
    lineBreak: "auto",
    whiteSpace: "break-spaces",
    width: "100%",
    display: "flex",
  },
  DHlabelIcon: {
  },
  root: {
    minHeight: 32,
    maxHeight: 100,
    height: "auto",
  },
};
function DHChip({ drawerItem, ...props }) {
  return (
    <Chip
      {...props}
      style={{ ...styles.chip, ...props.style }}
      classes={{
        label: props.classes.label,
        root: props.classes.root,
        icon: props.classes.icon,
      }}
      label={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={props.classes.DHlabel}>{props.label}</div>
          {props?.colortiles?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: 4,
                position: "absolute",
                right: 21,
                bottom: 0,
              }}
            >
              {props?.colortiles?.map((c) => (
                <div
                  key={`${c}`}
                  style={{
                    backgroundColor: c,
                    height: 12,
                    width: 12,
                    borderRadius: 16,
                    marginRight: 5,
                  }}
                />
              ))}
            </div>
          )}
        </div>
      }
    />
  );
}
export default withStyles(styles)(DHChip);

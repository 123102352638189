import React from "react";
import { withStyles } from "@material-ui/core";
import { DraggableItem } from "../DragAndDrop/DraggableItem";
import { DHDrawerChip } from "../../Common";

const styles = {
  chipContent: {
    backgroundColor: "#F5F5F5",
    marginTop: 16,
  },
  label: {
    flex: 1,
    justifyContent: "flex-start",
  },
};

function DrawerChipItems({ items, classes, source }) {
  return (
    <>
      {items.map((it, index) => (
        <DraggableItem
          index={index}
          item={{ ...it, source: "drawer", category: source }}
          type={"drawerChips"}
          accept={["dxList"]}
        >
          <DHDrawerChip
            drawerItem={true}
            label={it.code.display}
            className={classes.chipContent}
            classes={{ label: classes.label }}
          />
        </DraggableItem>
      ))}
    </>
  );
}

export default withStyles(styles)(DrawerChipItems);

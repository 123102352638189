import {Subject} from 'rxjs';

export class GlobalEventService {

    subject = new Subject();

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(event, data) {
        this.subject.next({ type: event, data: data });
    }
}

export class GlobalEvent {

    static LOCATION_UPDATED = "LOCATION_UPDATED";
}

export const globalEventService = new GlobalEventService();

import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { authService } from './auth'
import {LINK_ROUTE, LINK_ROUTE_SHORT} from "./route.name";

const LINKS = [LINK_ROUTE, LINK_ROUTE_SHORT]

function isLink(path) {

    return LINKS.filter(value => path.startsWith(value)).length > 0;
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => (
        authService.getUser() != null
            ? <Component { ...props } />
            : <Redirect push to={{
                pathname: '/login',
                state: {
                    next: isLink(props.location.pathname) ? props.location.pathname : "/",
                },
            }} />
    )} />
);

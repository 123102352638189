import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    SvgIcon,
    TextField,
    Typography
} from "@material-ui/core";
import {customersApi} from "../../../../../../utils/services/customers.api";
import BaseCard from "./BaseCard";
import {dateUtil} from "../../../../../../utils/date";
import {ReactComponent as InformationSVG} from "../../../../../../assets/cards/information.svg";
import ActionButton from "./ActionButton";
import {FileCopy} from "@material-ui/icons";
import {notificationService} from "../../../../../../utils/notification";
import {rpaApi} from "../../../../../../utils/services/rpa.api";
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {Alert} from "@material-ui/lab";


const styles = theme => ({
    root: {
    },
    body: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
    },
    fill: {
        flex: "1 1 auto",
    },
    subtitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        display: "flex",
        textTransform: "uppercase",
        color: "#7F8993",
    },
    info: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#C92D5C",
        opacity: "0.8",
    },
    timestamp: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#3D5369",
        opacity: "0.8",
    },
    actions: {
        margin: "auto",
    },

    form: {
        display: "flex",
        flexDirection: "row-reverse",
        '& div:first-child': {
            marginLeft: "12px",
        },
    },
    informationPanel: {
        paddingBottom: "12px",
    },
    informationPanelItem: {
        width: "100%",
        paddingTop: "8px",
    },

    informationPanelItemTitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        color: "#8BA3B0",
    },

    informationPanelItemText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#333333",
    },
    dialogButton: {
        marginRight: '12px',
    },
    progress: {
        color: "#fff",
    },
});

function InformationPanelItem(props) {

    function updateClipboard() {
        navigator.clipboard.writeText(props.value).then(value =>
            notificationService.success("Copied"),
                reason => notificationService.error("Unable to copy to clipboard."));
    }

    return (
        <div className={props.classes.informationPanelItem}>
            <div className={props.classes.informationPanelItemTitle}>{ props.title }</div>
            <div className={props.classes.informationPanelItemText}>
                { props.value }
                <IconButton size={"small"} aria-label="copy" onClick={updateClipboard}>
                    <FileCopy fontSize={"small"} />
                </IconButton>
            </div>
        </div>
    );
}

class PatientRegisteringCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            hasError: false,
            manualStep: undefined,
        };
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);

        this.__startPoll();
    }

    componentWillUnmount() {

        this.__stopPoll();
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    __startPoll = () => {

        const { item } = this.props;

        this.__callRpa(item);
        this.poll = setInterval(() => this.__callRpa(item), 10000);
    }

    __callRpa = (item) => {
        rpaApi.match(item.id, "interaction.appointment.schedule")
            .then(value => {
                this.setState({
                    manualStep: value.data,
                });
            }, reason => {

                if(reason.response?.status === 404) {
                    this.setState({
                        manualStep: undefined,
                    });
                }
            });
    }

    __stopPoll = () => {

        clearInterval(this.poll);
    }

    __openInformation = () => {
        this.setState({
            openInformation: true,
        });
    }

    __submit = () => {

        const { item } = this.props;
        const { busy } = this.state;

        if(busy) return;

        const nbrLength = document.getElementById('rpaNbr').value.length;
        const mnrLength = document.getElementById('rpaMnr').value.length;

        this.setState({ busy: true });

        if(nbrLength > 4 && mnrLength > 4) {

            this.__submitRpaJob("complete", { "NBR": document.getElementById('rpaNbr').value, "MRN": document.getElementById('rpaMnr').value });
        } else if(nbrLength === 0 && mnrLength === 0) {

            this.__cancelAppointment(item);
        } else {

            this.setState({ busy: false });
            notificationService.error("You need to enter a NBR and MNR to continue.");
        }
    }

    __cancelAppointment = (item) => {

        appointmentApi.updateAppointmentState(item.id, "CANCELLED")
            .then(value => this.__submitRpaJob("resolve", { }))
            .catch(reason => {
                this.setState({ busy: false });
                notificationService.update({
                    error: "Unable to cancel the appointment. " + reason,
                });
            });
    }

    __submitRpaJob = (action, data) => {

        const { manualStep } = this.state;

        const request = {
            message: "complete",
            action: action,
            inputs: data,
        }

        rpaApi.patch(manualStep.id, request)
            .then(value => {
                notificationService.success("Manual job done. Thanks you.")
            }, reason => {
                this.setState({ busy: false });
                notificationService.httpError(reason);
            });
    }

    __closeInformation = () => {

        const { busy } = this.state;

        if(busy) return;

        this.setState({
            openInformation: false,
        });
    }

    render() {

        let { person, processing, manualStep } = this.state;
        let { classes, item } = this.props;

        const body = this.__renderBody(item, processing, manualStep, classes);
        const dialog = this.__renderDialog(classes);

        return (
            <div className={classes.root}>
                <BaseCard
                    person={person}
                    item={item}>
                    { body }
                </BaseCard>
                { dialog }
            </div>
        );
    }

    __renderBody = (item, processing, manualStep, classes) => {
        return <>
            <div className={classes.body}>
                <div className={classes.content}>
                    <Typography variant="h4" component="h4" className={classes.subtitle}>REGISTERING</Typography>
                    { manualStep && <Typography variant="body1" component="p" className={classes.info}>Manual step required</Typography> }
                    <Typography variant="body1" component="p" className={classes.timestamp}>{ dateUtil.parseDate(item.slot.start).toLocaleString() }</Typography>
                </div>
                <div className={classes.fill} />
                <div className={classes.actions}>
                    { !manualStep || processing ? <><CircularProgress /></> :
                        <>
                            <ActionButton alt={true} onClick={this.__openInformation} icon={ <SvgIcon component={ InformationSVG } viewBox="0 0 28 28" /> } text={"Details"} />
                        </> }
                </div>
            </div>
        </>;
    }

    __renderDialog = (classes) => {

        let { item } = this.props;
        const { person, processing, manualStep, hasError, openInformation, busy } = this.state;

        if(!person || !manualStep) return <></>;

        if(hasError) {

            return <>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openInformation}
                    onClose={this.__closeInformation}
                    aria-labelledby="max-width-dialog-title">
                    <DialogTitle id="max-width-dialog-title">Something went wrong</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            An error has occurred trying to retrieve this information. Please refresh and if the problem continues contact support.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.__closeInformation} color="default" className={classes.dialogButton}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </>;
        }

        const parameters = manualStep.request;

        return <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openInformation}
                onClose={this.__closeInformation}
                aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">Register patient { parameters["appointment.type"] } encounter</DialogTitle>
                <DialogContent style={{ paddingTop: "0"}} >
                    <DialogContentText>
                        An error occurred while trying to register this patient for a { parameters["appointment.type"] } visit.
                        Please complete quick registration and enter the MRN and FIN number below.
                    </DialogContentText>
                    <Alert severity="warning">If the MRN and FIN are not entered the appointment will be marked as CANCELLED in Decoded Health.</Alert>
                    <div className={classes.informationPanel}>
                        <Grid container>
                            <Grid xs={6}>
                                <InformationPanelItem classes={classes} title={"Given name"} value={person.name.given} />
                                <InformationPanelItem classes={classes} title={"Family name"} value={person.name.family} />
                                <InformationPanelItem classes={classes} title={"Date of birth"} value={ dateUtil.formatDateToAmerica(person.dob) } />
                                <InformationPanelItem classes={classes} title={"Sex"} value={ parameters["subject.sex"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"SSN"} value={ parameters["subject.ssn"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Encounter type"} value={ parameters["appointment.type"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Encounter status"} value={ parameters["appointment.schedule.state"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Attending provider"} value={ parameters["provider.attending.fullname"] || "unknown" } />
                            </Grid>
                            <Grid xs={6}>
                                <InformationPanelItem classes={classes} title={"Email"} value={ parameters["subject.email"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Mobile"} value={ parameters["subject.mobile.number"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Address line 1"} value={ parameters["subject.address.line1"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Address line 2"} value={ parameters["subject.address.line2"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"City"} value={ parameters["subject.address.city"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"State"} value={ parameters["subject.address.administrativeArea"] || "unknown" } />
                                <InformationPanelItem classes={classes} title={"Zipcode"} value={ parameters["subject.address.postcode"] || "unknown" } />
                                { parameters["appointment.type"] === "virtual" && <InformationPanelItem classes={classes} title={"Appointment url"} value={ parameters["appointment.uri"] || "unknown" } /> }
                            </Grid>
                            <Grid xs={12}>
                                <InformationPanelItem classes={classes} title={"Chief complaint"} value={ parameters["appointment.chief.complaint"] || "unknown" } />
                            </Grid>
                        </Grid>
                    </div>
                    <hr />
                    <form className={classes.form} noValidate autoComplete="off">
                        <TextField className={classes.formInput} id="rpaNbr" label="NBR number" />
                        <TextField className={classes.formInput} id="rpaMnr" label="MRN number" />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.__closeInformation} color="default" className={classes.dialogButton}>
                        Cancel
                    </Button>
                    <Button disabled={ processing } onClick={this.__submit} color="primary" variant={"contained"}>
                        { busy ? <><CircularProgress className={classes.progress} size={"24px"} /></> : <>Done</> }
                    </Button>
                </DialogActions>
            </Dialog>
        </>;
    }
}

export default withStyles(styles)(PatientRegisteringCard);

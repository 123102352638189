import * as rxjs from "rxjs";
import {chartApi} from "../../../../../../../../../utils/services/chart.api";
import {notificationService} from "../../../../../../../../../utils/notification";
import {CommonBloc} from "../Common/bloc";

export class Bloc extends CommonBloc {

    constructor(cgiBloc) {
        super(cgiBloc, 'allergies', 'allergy', 'B001');
    }


    initialise = () => {
        this.getAllergies();
    }

    getAllergies = () => {

        let {clinicId, encounterId} = this.subject.value;

        this.events.next({
            type: AllergyBlocEvent.ALLERGY_LOADING,
        })

        chartApi.getPersonChartAllergies(clinicId, encounterId).then(result => {

            this.__updateSubject({
                resources : result.data.items,
            });

            this.events.next({
                type: AllergyBlocEvent.ALLERGY_LOAD_SUCCESS,
                data: {
                    resources: result.data.items,
                }
            })

        }).catch(error => {

            notificationService.error("Unable to retrieve patient's allergies")

            this.__updateSubject({
                resources : [],
            });

            this.events.next({
                type: AllergyBlocEvent.ALLERGY_LOAD_ERROR,
                data: {
                    error: error,
                }
            })
        }).finally(() => {
            this.__updateSubject({
                initialised: true,
            });
        });
    }
}

export class AllergyBlocEvent {

    static ALLERGY_LOADING = "ALLERGY_LOADING";
    static ALLERGY_LOAD_SUCCESS = "ALLERGY_LOAD_SUCCESS";
    static ALLERGY_LOAD_ERROR = "ALLERGY_LOAD_ERROR";
}

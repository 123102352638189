import React from "react";
import { Drawer, withStyles } from "@material-ui/core";
import UserInfo from "./UserInfo";
import DrawerItems from "./DrawerItem";
import CurrentComplaints from "./CurrentComplaints/CurrentComplaints";
import { useCGIContext } from "../../ClinicalGraph/CGIContext/CGIContext";

const styles = {
  paperAnchorLeft: {
    left: "auto",
  },
  paperAnchorDockedLeft: {
    borderColor: "transparent",
    boxShadow: "10px 0px 10px rgba(0, 0, 0, 0.05)",
    borderRight: "0px",
  },
};

function CGIDrawer({ classes, toggleClinicalSummary }) {
  const { userInfo, drawerItems, isOpen, vitals, currentComplaints } =
    useCGIContext();
  return (
    <div>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paperAnchorLeft: classes.paperAnchorLeft,
          paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
        }}
      >
        <>
          <UserInfo toggleClinicalSummary={toggleClinicalSummary} userInfo={{ userInfo, vitals }} />
          <CurrentComplaints item={currentComplaints} />
          <DrawerItems item={drawerItems} />
        </>
      </Drawer>
    </div>
  );
}
export default withStyles(styles)(CGIDrawer);

import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {authService} from "../../../utils/auth";
import AnalyticsDashboard from "./Dashboard";
import AnalyticsContext from "./context";


const styles = theme => ({
    root: {
        height: "100%",
        maxHeight: "100%",
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
        width: "100%",
        minHeight: "100%",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
});

class Analytics extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        let context = {
            roles: authService.getUserRoles(),
        };

        return (
            <AnalyticsContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Switch>
                            <Route path="/analytics/dashboard" exact component={ AnalyticsDashboard }/>
                        </Switch>
                    </Grid>
                </div>
            </AnalyticsContext.Provider>
        );
    }
}

Analytics.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Analytics));

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Avatar, Button, Chip, CircularProgress, IconButton, Menu, MenuItem,} from "@material-ui/core";
import VirtualClinicContext from "../../../context";
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {notificationService} from "../../../../../../utils/notification";
import {customersApi} from "../../../../../../utils/services/customers.api";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from "react-intl";
import BaseCard from "./Card";
import {format} from "date-fns";
import {Bloc as CancelBloc, BlocEvent as CancelBlocEvent} from "../../../../component/CancelAppointmentDialog/bloc";
import CancelAppointmentDialog from "../../../../component/CancelAppointmentDialog";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    redBorder: {
        border: "1px solid #FD1F54",
    },
    cyanishBorder: {
        border: "1px solid #00EBFF",
    },
    cyanishIndicator: {
        color: "#00EBFF",
    },
    orangeBorder: {
        border: "1px solid #FDCC1F",
    },
    okBorder: {
        border: "1px solid #F5F5F5",
    },
    redIndicator: {
        color: "#FD1F54",
    },
    orangeIndicator: {
        color: "#FDCC1F",
    },
    okIndicator: {
        color: "#BFE81C",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeaderInPerson: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.alternate}`,
    },
    cardHeaderTelehealth: {
        padding: "12px 21px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.main}`,
    },
    cardBodyContentClaimed: {
        padding: "17px 21px 0px 12px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "5px",
        }
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardMenu: {
        height: "48px",
    },
    cardTitleSubText: {},
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        overflow: "hidden",
        padding: "0px 14px 0px 12px",
        display: "flex",
        flex: "1 1 auto",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "6px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1",
    },
    cardSubHeader: {
        padding: "10px 14px 10px 12px",
        display: "flex",
    },
    cardSubHeader2: {
        padding: "0px 14px 10px 12px",
        display: "flex",
    },
    cardFooter: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 12px 16px 12px",
        '& button': {
            marginTop: "8px",
        },
    },

    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "21px",
        lineHeight: "21px",
        paddingTop: "3px",
        paddingRight: "4px",
        color: "#8BA3B0",
    },
    sessionType: {
        textAlign: "left",
        paddingBottom: "10px",
        paddingLeft: "70px",
    },
    complaint: {
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },
    complaintIntakeComplete: {
        overflow: "auto",
        padding: "7px 18px",
        background: "rgba(0,235,255, 0.1)",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },

    button: {
        width: "100%",
    },

    cardBodyLoading: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyLoadingContent: {
        margin: "auto",
    },
    cardBodyBusy: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyBusyContent: {
        margin: "16px 10px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    cardBodyBusyFooter: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingLeft: "12px",
        paddingRight: "12px",
        textAlign: "center",
    },
    cardBodyLoadingProgress: {
        textAlign: "center",
        paddingTop: "20px",
        marginBottom: "10px",
    },
    cardBodyLoadingText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#98A3AE",
    },


    cardBodyContent: {
        maxHeight: "136px",
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.6",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#000000",
    },



    dialogHeader: {
        display: "flex",
        paddingBottom: "32px",
    },

    dialogHeaderAvatar: {
        marginRight: "24px",
    },

    dialogHeaderTitle: {
        paddingTop: "12px",
    },
    processingHolder: {
        margin: "auto",
    }
});

class ReservedCard extends Component {

    constructor(props) {
        super(props);

        const { clinicBloc, } = this.props.context;

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            start: Date.parse(props.item.createdOn),
            delayTimes: this.__populateDelayTimes(),
            delay: "",
            cancelBloc: new CancelBloc(props.item.id, clinicBloc),
        };


    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);
        this.cancelEventSubscription = this.state.cancelBloc.subscribeToEvents(this.__cancelEventHandler);
    }

    componentWillUnmount() {
        this.cancelEventSubscription.unsubscribe();
    }

    __cancelEventHandler = (event) => {
        const { type } = event;
        if(CancelBlocEvent.APPOINTMENT_CANCELLED === type) {
            this.setState({
                processing: true,
            });
        }
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    _checkin = () => {

        const { item } = this.props;

        this.setState({
            busy: true,
        });

        appointmentApi.checkin(item.id)
            .then(value => {

                this.setState({
                    start: new Date(),
                });
            }).catch(reason => {

            notificationService.error(reason.message);

            this.setState({
                busy: false,
            });
        })

    }

    __renderBody = (medium) => {

        return this.__renderInPersonReserved(medium);
    }

    __addMinutes = (date, minutes) => {
        if(!minutes) return date;
        return new Date(date.getTime() + minutes*60000);
    }

    __renderInPersonReserved = (medium) => {

        const { person, now, start, busy } = this.state;
        const { classes, item } = this.props;
        const { disableSee } = this.props.context;

        let ssn = "";
        if(person) {
            const ssns = person.externalReferences.filter(value => value.system === "http://hl7.org/fhir/vs/identifier-type" && value.code === "SS");
            if (ssns.length > 0) {
                ssn = ssns[0];
            }
        }

        let athenaId = "";
        if(person) {
            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            if (athenaIds.length > 0) {
                athenaId = athenaIds[0];
            }
        }

        const displayTime = format(this.__addMinutes(new Date(item.slot.start), item.delay), "MMM dd, HH:mm");

        return (<>
            <div className={classes.cardSubHeader}>
                <div className={classes.fill}/>
                <span className={classes.subHeaderText}>{ medium } at </span>
                <span
                    className={classes.subHeaderTime}>{ displayTime }</span>{ (item.delay && item.delay > 0) ?
                <Chip
                    size="small"
                    label={`delay ${item.delay}m`}
                /> : <></> }
            </div>

            <div className={classes.cardBody}>
                <div className={classes.cardBodyRight}>
                    <div className={classes.cardBodyContent}>
                        { item.service && <div className={classes.cardSubHeader2}><div className={classes.fill}></div><span
                            className={classes.subHeaderText}>{ item.service.description }</span><div className={classes.fill}></div></div>}
                        { person && (<>
                            { athenaId !== "" && <><span className={classes.cardBodyContentLabel}>Athena Id:</span> {athenaId.reference} <br /></> }
                            <span className={classes.cardBodyContentLabel}>Language:</span> {person?.language || "en"} <br />
                            <span className={classes.cardBodyContentLabel}>Gender:</span> {person.gender} <br />
                            <span className={classes.cardBodyContentLabel}>DOB:</span> {person.dob} <br />
                            <span className={classes.cardBodyContentLabel}>SSN:</span> {ssn.reference} <br />
                        </>) }
                        { item.note && (<><span className={classes.cardBodyContentLabel}>Reason:</span> {item.note} <br /></>)}
                    </div>
                </div>
            </div>
            <div className={classes.fill} />
            <div className={classes.cardFooter}>

                <Button disabled={ disableSee || busy }
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={this._checkin}>
                    { busy ? <CircularProgress size={24} /> : <>Check in</> }
                </Button>
            </div>
            { this.__renderDelayDialog() }
            { this.__renderPatientDetailDialog() }
        </>);
    }

    __handleMenuOpen = (event) => {

        this.setState({
            cardMenuAnchorEl: event.currentTarget,
        });
    }

    __handleMenuSelect = (target) => (event) => {

        if(target === 'delay') this.__handleDelayOpen();


        this.setState({
            cardMenuAnchorEl: undefined,
        });
    }

    __handleMenuClose = () => {

        this.setState({
            cardMenuAnchorEl: undefined,
        });
    }

    _openWindow = (url) => {

        return () => {
            window.open(url, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            this.__handleMenuClose();
        };
    };

    __renderMenu = () => {

        const { item, classes } = this.props;
        const { person, cancelBloc, processing, cardMenuAnchorEl } = this.state;

        if(processing) return <></>;

        let items = [];

        const chartUrl = `${process.env.REACT_APP_EHR_CHART_URL}`;
        if(chartUrl.length > 0 && person) {

            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            const department = item.links.filter(_value => _value.type === "deeplink_references").filter(_value => _value.details.department).map(_value => _value.details.department);
            if (athenaIds.length > 0 && department.length > 0) {
                items.push(<MenuItem onClick={this._openWindow(chartUrl.replace('__id__', athenaIds[0].reference).replace('__department__', department[0]))}>Open Chart</MenuItem>);
            }
        }

        items.push(
            <MenuItem onClick={this.__handleMenuSelect('delay')}>
                Delay
            </MenuItem>
        );

        const cancelEnableFlag = process.env.REACT_APP_APPOINTMENT_CANCEL_ENABLED;
        let cancelEnable = cancelEnableFlag.length > 0 ? parseInt(cancelEnableFlag) : 0;
        if (cancelEnable === 1) {
            items.push(<MenuItem onClick={() => {
                cancelBloc.open();
                this.__handleMenuClose();
            }}>
                Cancel
            </MenuItem>);
        }

        return (<>
            <IconButton className={classes.cardMenu} aria-label="settings"  aria-controls="simple-menu" aria-haspopup="true"onClick={this.__handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={cardMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(cardMenuAnchorEl)}
                onClose={this.__handleMenuClose}
            >
                { items }
            </Menu>
        </>);
    }

    __handleDelayChange = (event) => {

        this.setState({
            delay: event.target.value,
        });
    }

    __handleDelayNotificationsToggle = (event) => {

        this.setState({
            delaySendNotifications: event.target.checked,
        });
    }

    __handleDelayOpen = () => {

        this.setState({
            delay: "",
            delaySendNotifications: false,
            delayDialogOpen: true,
        });
    }

    __handleDelayClose = () => {

        this.setState({
            delay: "",
            delaySendNotifications: false,
            delayDialogOpen: false,
        });
    }

    __handleDelaySubmit = () => {

        const { item } = this.props;
        const { delay, delaySendNotifications } = this.state;

        this.setState({
            busy: true,
        });

        const request = {
            reason: {
                system: "",
                code: "",
                value: "",
            },
            message: "",
            duration: delay,
            sendNotifications: delaySendNotifications,
        };

        appointmentApi.registerDelay("_", item.id, request)
            .then(value => {
                this.setState({
                    delay: undefined,
                    delayDialogOpen: false,
                });
                notificationService.success("Delay has been registered. Notifications will be sent.");
            })
            .catch(reason => notificationService.error(`An error occurred trying to register a delay. Please try again or contact support. Reason: ${reason}`))
            .finally(() => this.setState({ busy: false }));
    }

    __populateDelayTimes = () => {

        const times = [];

        for (let i = 1; i < 12; i++) {

            const minutes = i * 10;
            const minuteOfHour = minutes % 60;
            const hour = parseInt(minutes / 60);
            let display = "";

            if(hour > 0) {
                display = `${hour}`
                if(hour > 1) {
                    display += ` hours`
                } else {
                    display += ` hour`
                }
            }

            if(minuteOfHour > 0) {
                display += (display.length === 0 ? "" : " " ) + `${minuteOfHour}`
                if(minuteOfHour > 1) {
                    display += ` minutes`
                } else {
                    display += ` minute`
                }
            }

            times.push({
                minutes: minutes,
                display: display,
            });
        }

        return times;
    }

    __renderDelayDialog = () => {

        const { classes } = this.props;

        const { item } = this.props;
        const { delayDialogOpen, delay, delaySendNotifications, delayTimes, person } = this.state;

        const currentStart = new Date(item.slot.start);

        return (<Dialog open={delayDialogOpen} onClose={this.__handleDelayClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title"><FormattedMessage  id={"patient.is.late"} defaultMessage={"Patient Is Delayed"} /></DialogTitle>
            <DialogContent>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogHeaderAvatar}>
                        <Avatar className={classes.avatar}>{person ? <>{person.name.given.charAt(0)}</> : <>U</>}</Avatar>
                    </div>
                    <div className={classes.dialogHeaderTitle}>
                        <div className={classes.cardTitleText}>{person ? <>{person.name.given} {person.name.family}</> : <>Unknown Unknown</>}</div>
                    </div>
                </div>
                <DialogContentText>
                    <FormattedMessage  id={"appointment.current.scheduled.for"} defaultMessage={"Current appointment scheduled for"} /> { currentStart.toLocaleString() }
                </DialogContentText>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="delay-appointment">Delay</InputLabel>
                    <Select
                        onChange={this.__handleDelayChange}
                        labelId="delay-appointment"
                        id={`delay-${item.id}`}
                        name={`delay-${item.id}`}
                        value={delay}
                    >
                        { delayTimes.map((delay) => <MenuItem value={delay.minutes}>{delay.display}</MenuItem>)  }
                    </Select>
                </FormControl>
                <br />
                <br />
                <FormControlLabel control={<Switch
                    color="primary"
                    checked={delaySendNotifications}
                    onChange={this.__handleDelayNotificationsToggle}
                    name="delaySendNotifications"
                    id="delaySendNotifications"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />} label="Send notifications ?" />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={this.__handleDelaySubmit}
                        variant="contained" color="primary">
                    <FormattedMessage  id={"confirm"} defaultMessage={"Confirm"} />
                </Button>
            </DialogActions>
        </Dialog>);
    }

    __renderPatientDetailDialog = () => {

        const { classes } = this.props;

        const { item } = this.props;
        const { delayDialogOpen, delay, delaySendNotifications, delayTimes, person } = this.state;

        const currentStart = new Date(item.slot.start);

        return (<Dialog open={delayDialogOpen} onClose={this.__handleDelayClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title"><FormattedMessage  id={"patient.is.late"} defaultMessage={"Patient Is Delayed"} /></DialogTitle>
            <DialogContent>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogHeaderAvatar}>
                        <Avatar className={classes.avatar}>{person ? <>{person.name.given.charAt(0)}</> : <>U</>}</Avatar>
                    </div>
                    <div className={classes.dialogHeaderTitle}>
                        <div className={classes.cardTitleText}>{person ? <>{person.name.given} {person.name.family}</> : <>Unknown Unknown</>}</div>
                    </div>
                </div>
                <DialogContentText>
                    <FormattedMessage  id={"appointment.current.scheduled.for"} defaultMessage={"Current appointment scheduled for"} /> { currentStart.toLocaleString() }
                </DialogContentText>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="delay-appointment">Delay</InputLabel>
                    <Select
                        onChange={this.__handleDelayChange}
                        labelId="delay-appointment"
                        id={`delay-${item.id}`}
                        name={`delay-${item.id}`}
                        value={delay}
                    >
                        { delayTimes.map((delay) => <MenuItem value={delay.minutes}>{delay.display}</MenuItem>)  }
                    </Select>
                </FormControl>
                <br />
                <br />
                <FormControlLabel control={<Switch
                    color="primary"
                    checked={delaySendNotifications}
                    onChange={this.__handleDelayNotificationsToggle}
                    name="delaySendNotifications"
                    id="delaySendNotifications"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />} label="Send notifications ?" />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={this.__handleDelaySubmit}
                        variant="contained" color="primary">
                    <FormattedMessage  id={"confirm"} defaultMessage={"Confirm"} />
                </Button>
            </DialogActions>
        </Dialog>);
    }

    render() {

        let { person, processing, cancelBloc } = this.state;
        let { classes, item } = this.props;
        let { focusItem } = this.props.context;

        const medium = item && item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        const headerStyle = medium === "Telehealth" ? classes.cardHeaderTelehealth : classes.cardHeaderInPerson;

        const menu = this.__renderMenu();
        const body = this.__renderBody(medium);

        return (
            <>
                <BaseCard
                    menu={menu} headerStyle={headerStyle} person={person} item={item}>
                    { processing ? <div className={classes.processingHolder}><CircularProgress /></div> : body }
                </BaseCard>
                <CancelAppointmentDialog bloc={cancelBloc} />
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<ReservedCard context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import RpaContext from "../../context";
import {customersApi} from "../../../../../utils/services/customers.api";
import {FormattedMessage} from "react-intl";
import {notificationService} from "../../../../../utils/notification";
import {globalBloc} from "../../../global.bloc";


const styles = theme => ({
    root: {
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeader: {
        padding: "17px 21px 0px 12px",
        display: "flex",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        padding: "14px 29px 0px 19px",
        display: "flex",
        flexDirection: "column",
        height: "230px",
    },
    contentText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        /* or 171% */
        color: "#3D5369",
        textTransform: "capitalize",

        opacity: "0.8",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    button: {
        height: "32px",
        minHeight: "32px",
        width: "100%",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    dialogButton: {
        marginRight: '12px',
    },
});

class JobCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subject: undefined,
            confirm: false,
        };
    }

    componentDidMount() {
        const { identifiers } = this.props.item;

        if(identifiers) {
            const subject = identifiers && identifiers.filter(_item => _item.system === 'decoded' && _item.code === 'id')[0];
            this._loadSubject(subject);
        }
    }

    componentWillUnmount() {
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
               this.setState({
                   subject: value.data,
               });
            }, reason => notificationService.httpError(reason));
    };

    _openJob = () => {

        const { rpaBloc } = this.props;
        const { subject } = this.state;

        const data = {
            job: this.props.item,
            subject: subject,
        }

        rpaBloc.selectJob(data);
    };

    _openJobConfirm = () => {

        this.setState({
            confirm: true,
        });
    };


    _closeDialog = () => {

        this.setState({
            confirm: false,
        });
    };


    render() {

        let { subject, confirm } = this.state;
        let { classes } = this.props;
        const { status, type, user, timestamp } = this.props.item;

        let clickhandler = this._openJob;
        let lock = <></>;
        if(status !== 0) {
            if(user?.value !== globalBloc.getMe().id) {
                clickhandler = this._openJobConfirm;
                lock = <Chip
                    label="Opened by another"
                    color="secondary"
                />;
            } else {
                lock = <Chip
                    label="Opened by you"
                    color="primary"
                />;
            }
        }

        const realTimestamp = new Date(timestamp).toLocaleString();

        return (
            <>
            <div className={`${classes.root}`}>
                <div className={classes.cardHeader}>
                    <div className={classes.cardAvatar} onClick={this._seePatient}>
                        <Avatar className={classes.avatar}>{ subject ? <>{subject.name.given.charAt(0)}</> : <>U</> }</Avatar>
                    </div>
                    <div className={classes.cardTitle}>
                        <div className={classes.cardTitleText}>{ subject ? <>{subject.name.given}<br />{subject.name.family}</> : <>Unknown<br />Unknown</> }</div>
                    </div>
                </div>
                <div className={classes.cardBody}>
                    <div className={classes.contentText}>{ this.__formatType(type) }</div>
                    <div className={classes.contentText}>{ realTimestamp }</div>
                    <div className={classes.contentText}>{ lock }</div>

                    <div className={classes.fill} />
                    <Button variant="contained"
                            color="primary"
                            className={classes.button}
                            fullWidth={true} onClick={clickhandler}><FormattedMessage id={"open.job"} defaultMessage={"Open"} /></Button>
                </div>
            </div>
                <Dialog
                    open={confirm}
                    onClose={this._closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"><FormattedMessage id={'rpa.open.taken.job'} defaultMessage={"Open RPA Job?"} /></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={'rpa.open.taken.job.text'} defaultMessage={"The job has already been opened. Continue anyway?"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._closeDialog} className={classes.dialogButton}>
                            Cancel
                        </Button>
                        <Button variant={"contained"} onClick={this._openJob} color={"primary"} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    __formatType = (type) => {
        return type.replaceAll(".", " ");
    }
}

export default withStyles(styles)(props => (
    <RpaContext.Consumer>
        {value => {
            return (<JobCard context={value} {...props} />);
        }
        }
    </RpaContext.Consumer>
));

import { filter } from "loadsh";
import { unit } from "mathjs";

export const UserVitalInfoYType = {
  HEIGHT: "C0487985",
  WEIGHT: "C0944911",
  BLOOD_PRESSURE: "C4318513",
  PULSE: "C0488794",
  SAO2: "C0365295",
  BHI: "C1542867",
  TEMPERATURE: "C0487995",
};

export const calculateAgeFromDOB = (dob) => {
  return new Date(new Date() - new Date(dob ?? "")).getUTCFullYear() - 1970;
};

export const getValueForSection = (
  sections,
  selectedSection,
  preferredUnit
) => {
  const value = filter(sections, (sec) => {
    return sec.code.code === selectedSection;
  })[0]?.value?.value?.value;
  if (preferredUnit && Boolean(value?.toString())) {
    const defaultUnit = getValueUnitForSection(sections, selectedSection);
    let val = unit(value, defaultUnit);
    val = val?.to(preferredUnit)?.toNumber()?.toFixed(1) || "";
    return val || value;
  }
};
export const getValueUnitForSection = (
  sections,
  selectedSection,
  preferredUnit
) => {
  if (preferredUnit) return preferredUnit.toUpperCase();
  return filter(sections, (sec) => {
    return sec.code.code === selectedSection;
  })[0]?.value?.unit?.display;
};
export const getAllSemanticTypes = (item) => {
  const semanticTypes = [];
  if (item.semanticTypes) {
    item.semanticTypes.forEach((type) => {
      semanticTypes.push(type.code ?? type);
    });
  }
  return semanticTypes;
};

export const processDrop = ({ source, destination, selectedItem }) => {
  return {
    source: filter(source, (it) => it.bid !== selectedItem.bid),
    destination: [...destination, selectedItem],
  };
};
export const addCurrentComplaints = async (item, context) => {
  await context.bloc.__addCurrentComplaints(item);
};

export const createFirstItemOfSearchResult = (query, data) => {
  const firstArray = {
    canonicalName: query,
    index: [query],
  };

  const newArrayWithUpdatedFirstPosition = [firstArray, ...data.data.results];
  return newArrayWithUpdatedFirstPosition;
};
export const DrawerContextMenuTypes = {
  drawerItem: "drawerItem",
  currentComplaint: "currentComplaint",
};

import axios from "axios";

class UsersApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
  }
  listUsers() {
    return axios.get(
      `${this.baseUrl}/next/users?p=0&s=100`
    );
  }
}
export const usersApi = new UsersApi();

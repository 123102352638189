import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import WaitingRoom from "./WaitingRoom";
import VirtualClinicContext from "./context";
import {clinicWaitingRoomEventService} from "./service/clinic.waitingroom.event.service";
import {clinicEventService} from "./service/clinic.event.service";
import {notificationService} from "../../../utils/notification";
import Encounter from "./Encounter";
import {authService} from "../../../utils/auth";
import {globalBloc, IndexBlocEvent} from "../global.bloc";
import OpenEncounter from "./OpenEncounter";
import {ClinicBloc} from "./clinic.bloc";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
        minHeight: "calc(100% - 56px)",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
});

const DEFAULT_QUEUE_SIZE = 30;

class Clinic extends React.Component {

    clinicBloc;

    globalSubscription;

    subscription;
    waitingRoomSubscription;


    constructor(props) {
        super(props);

        this.clinicBloc = new ClinicBloc();

        this.state = {
            open: false,
            disableSee: false,
            queue: {
                total: 0,
                busy: true,
                items: [],
                backgroundLoading: true,
            },
            focusItem: {
                busy: false,
                data: undefined,
            },
            encounters: {
                busy: false,
                appointmentIds: [],
                appointments: [],
            },
            encounterFilter: {
                searchDate: new Date(),
                filterSwitch: "date",
                queueSize: DEFAULT_QUEUE_SIZE,
            }
        };

        this.__handleClinicBlocStateUpdate = this.__handleClinicBlocStateUpdate.bind(this);
    }

    componentDidMount() {

        this.clinicBloc.initialise();

        const { organisationId } = this.props.match.params;

        clinicWaitingRoomEventService.setLocation(organisationId);

        this.state.roles = authService.getUserRoles();

        this.subscription = clinicEventService.registerStateCallback(this.handleEvent);
        this.waitingRoomSubscription = clinicWaitingRoomEventService.registerStateCallback(this.handleWaitingRoomEvent);

        clinicWaitingRoomEventService.startCurrentAppointmentPoll();

        this.globalSubscription = globalBloc.events.subscribe(this.__globalListener);
        this.clinicBlocStateSubscription = this.clinicBloc.subscribeToState(this.__handleClinicBlocStateUpdate);


    }



    componentWillUnmount() {

        this.clinicBloc.close();

        clinicWaitingRoomEventService.stop();

        this.subscription.unsubscribe();
        this.waitingRoomSubscription.unsubscribe();
        this.globalSubscription.unsubscribe();
    }

    __globalListener = (e) => {

        const { event, data } = e;

        let newProps = {};

        switch (event) {
            case IndexBlocEvent.LOCATION_UPDATED:
                clinicWaitingRoomEventService.setLocation(data.location);

                this.props.history.push(`/clinic/${data.location}/waiting-room`);
                newProps.queue = {
                    total: 0,
                    busy: true,
                    items: [],
                };


                const filterEnableFlag = process.env.REACT_APP_APPOINTMENT_FILTER_ENABLED;
                let filterEnabled = filterEnableFlag.length > 0 ? parseInt(filterEnableFlag) : 0;
                if (filterEnabled === 1){

                    newProps.encounterFilter = {
                        searchDate: new Date(),
                        filterSwitch: "date",
                        queueSize: DEFAULT_QUEUE_SIZE,
                    };
                    clinicWaitingRoomEventService.setFilterDate(new Date());
                } else {

                    newProps.encounterFilter = {
                        filterSwitch: "current",
                        searchDate: null,
                        queueSize: DEFAULT_QUEUE_SIZE,
                    };
                    clinicWaitingRoomEventService.setFilterDate(null);
                }

                clinicWaitingRoomEventService.restartAppointmentPoll(DEFAULT_QUEUE_SIZE, 'WAITING_LIST_LOADING')
                break;
        }

        this.setState({
           ...newProps,
        });
    }

    __handleClinicBlocStateUpdate = (subject) => {

        const { encounters } = subject;

        this.setState({
            encounters: {
                busy: encounters.loading,
                appointmentIds: encounters.encounterIds,
                appointments: encounters.encounters,
            }
        });
    }

    handleEvent = (event) => {

        const {type, data} = event;

        const { organisationId } = this.props.match.params;

        let {encounters, focusItem } = this.state;

        let newState = {};

        // debug(type, data);

        switch (type) {
            case 'VIRTUAL_CLINIC_ENCOUNTER_OPEN': {

                this._openEncounter(data.id, 'cgi');
            }
                break;
            case 'VIRTUAL_CLINIC_ENCOUNTER_UPDATED':
            case 'VIRTUAL_CLINIC_ENCOUNTER_COMPLETED': {
                encounters.busy = false;
                encounters.appointmentIds = encounters.appointmentIds.filter((_id) => _id !== data.id);
                encounters.appointments = encounters.appointments.filter((_item) => _item.id !== data.id);
                if(!this.props.history.location.pathname.includes("waiting-room")) {
                    this.props.history.push(`/clinic/${organisationId}/waiting-room`);
                }
            }
                break;
            case 'VIRTUAL_CLINIC_LOADING_PATIENT': {
                newState.disableSee = true;
                focusItem.busy = true;
                focusItem.appointmentId = data.id;
                focusItem.data = data;
            }
                break;
            case 'VIRTUAL_CLINIC_LOADING_PATIENT_ERROR': {
                newState.disableSee = false;
                focusItem.busy = false;
                focusItem.appointmentId = false;
                focusItem.data = undefined;
            }
                break;
            case 'VIRTUAL_CLINIC_SEE_PATIENT': {

                if (!data || !data.id) {
                    notificationService.error("Unable to see a patient. Please end your last encounter.");
                    return;
                }

                this.clinicBloc.appendEncounter(data);

                encounters.busy = false;
                newState.disableSee = false;
                focusItem.busy = false;
                focusItem.appointmentId = false;
                focusItem.data = undefined;

                this._openEncounter(data.id, 'cgi');
            }
                break;
        }

        this.setState({
            encounters: encounters,
            focusItem: focusItem,
            ...newState,
        });
    };

    _openEncounter = (appointmentId, param) => {

        const { organisationId } = this.props.match.params;

        this.props.history.push(`/clinic/${organisationId}/encounter/${appointmentId}?t=${param || "standard"}`);
    }

    handleWaitingRoomEvent = (event) => {

        const {data, type} = event;

        switch (type) {
            case 'WAITING_LIST_FILTER_UPDATE': {

                var newFilter = {...this.state.encounterFilter, ...data}

                this.setState({
                    encounterFilter: newFilter
                });
                break;
            }
            case 'WAITING_LIST_LOADING': {

                this.setState({
                    queue: {
                        total: 0,
                        busy: true,
                        items: [],
                    },
                });
                break;
            }
            case 'WAITING_LIST_EXTRA_LOADING': {

                let {queue} = this.state;

                queue.backgroundLoading = true

                this.setState({
                    queue: queue,
                });
                break;
            }
            case 'WAITING_LIST_UPDATE': {

                let {queue} = this.state;

                queue.items = data.items;
                queue.total = data.page.total;
                queue.busy = false;
                queue.backgroundLoading = false;

                this.setState({
                    queue: queue,
                });
                break;
            }

        }
    };


    render() {

        const { classes } = this.props;
        const { organisationId } = this.props.match.params;
        const { queue, focusItem, encounters, disableSee, encounterFilter } = this.state;

        let context = {
            disableSee: disableSee,
            queue: queue,
            focusItem: focusItem,
            encounters: encounters,
            clinicId: organisationId,
            roles: authService.getUserRoles(),
            clinicBloc: this.clinicBloc,
            encounterFilter: encounterFilter,
        };

        return (
            <VirtualClinicContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Switch>
                            <Route path="/clinic/:organisationId/waiting-room" exact component={ WaitingRoom }/>
                            <Route path="/clinic/:organisationId/encounter/:encounterId/_open" exact component={ OpenEncounter }/>
                            <Route path="/clinic/:organisationId/encounter/:encounterId" exact component={ Encounter }/>
                        </Switch>
                    </Grid>
                </div>
            </VirtualClinicContext.Provider>
        );
    }
}

Clinic.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Clinic));

import axios from 'axios';

class EncounterApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    encounterSession(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/api/organisations/${organisationId}/encounters/${encounterId}/_session`);
    }

    workspace(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace`);
    }

    deleteEncounter(organisationId, encounterId) {

        return axios.delete(`${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}`);
    }

    dialOut(encounterId, data) {

        return axios.post(`${this.baseUrl}/api/encounters/${encounterId}/telehealth/_dial_out`, data);
    }

    dialOutDisconnect(encounterId, data) {

        return axios.post(`${this.baseUrl}/api/encounters/${encounterId}/telehealth/_dial_out_disconnect`, data);
    }

    getChatHistory(encounterId) {

        return axios.get(`${this.baseUrl}/api/encounters/${encounterId}/telehealth/chat/history`);
    }

    upsertVideoSession(organisationId, encounterId) {

        return axios.post(`${this.baseUrl}/api/organisations/${organisationId}/encounters/${encounterId}/_upsert_video`, {});
    }
}

export const encounterApi = new EncounterApi();

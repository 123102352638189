import React, { Component } from 'react';
import {DHCalendar, DHDivider, DHFormInput} from "../../../../Common";
import DHFormRow from "../../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {patientChartUtils} from "../../../../../util/PatientChartUtils";
import DHFormTitle from "../../../../Common/DHFormTitle";
import DHDropdown from "../../../../Common/DHDropdown";
import {IconButton} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import FormComponent from "../Common/FormComponent";
import QuickSelection from "../QuickSelection";


const styles = theme => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    }
})

const CRITICALITY_OPTIONS = [
    {label: "low", value: "low"},
    {label: "high", value: "high"},
    // {label: "unable-to-assess", value: "unable to assess"},
]

const SEVERITY_OPTIONS = [
    {label: "mild", value: "mild"},
    {label: "moderate", value: "moderate"},
    {label: "severe", value: "severe"},
]

const initState = {
    loading: false
}

class AllergyForm extends FormComponent {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    render() {

        let { classes, value } = this.props


        return (
            <React.Fragment>
                <DHFormTitle label={value.code.display}>
                    {(value.recorder || value.recordedDate) && patientChartUtils.renderTitleRecordText(value)}
                    <IconButton onClick={this.__delete}>
                        <DeleteOutline />
                    </IconButton>
                </DHFormTitle>
                <DHFormRow label={"Criticality"}>
                    <DHDropdown options={CRITICALITY_OPTIONS} defaultValue={value.criticality} onChange={this.__updateOption("criticality")}/>
                </DHFormRow>
                <DHFormRow label={"Onset"}>
                    <DHFormInput name={"onset"} size={"large"} value={value.onset?.onset} onChange={this.__updateOnsetText}/>
                </DHFormRow>
                <DHFormRow label={""}>
                    <QuickSelection target={"onset"} onClick={this.__populateSuggestion}></QuickSelection>
                </DHFormRow>
                {value.reactions?.map(reaction =>
                    <React.Fragment>
                        <DHFormRow label={"Reaction"}>
                            <DHFormInput value={reaction.code.display} disabled={true}/>
                        </DHFormRow>
                        <DHFormRow label={"Severity"}>
                            <DHDropdown
                                options={SEVERITY_OPTIONS}
                                defaultValue={reaction.severity}
                                disabled={true}
                            />
                        </DHFormRow>
                    </React.Fragment>
                )}
                <DHFormRow label={"Note"}>
                    <DHFormInput name={"note"} size={"large"} value={value.note} onChange={this.__updateText}/>
                </DHFormRow>

                <DHDivider/>

            </React.Fragment>
        )

    }
}

export default withStyles(styles)(AllergyForm);

import { intersection } from "loadsh";
import update from "immutability-helper";
import { getAllSemanticTypes } from "../Drawer/Drawer.utils";

export const isDropAllowed = (payload) => {
  const allSemanticTypes = getAllSemanticTypes(payload.item);
  const isAllowed = intersection(
    payload.allowedSemanticTypes,
    allSemanticTypes
  );
  return isAllowed.length > 0;
};
export const moveDxItem = (hoverIndex, dragIndex, context) => {
  context.bloc.setDxList((prevCards) =>
    update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]],
      ],
    })
  );
};

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Button, FormControl, Grid, IconButton, Input, InputAdornment} from "@material-ui/core";

import DialIcon from '@material-ui/icons/DialerSip';
import BackspaceIcon from '@material-ui/icons/Backspace';
import EncounterContext from "../../context";
import {phoneUtil} from "../../../../../../utils/phone";

const styles = theme => ({
    root: {

        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        padding: "32px 72px"
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "32px",
    },
    tabWrapper: {
        padding: "24px 64px",
        maxHeight: "50vh",
    },

    numberInput: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "36px",
        lineHeight: "43px",
    },

    dialPadBtnContainer: {
        marginTop: "12px",
        marginBottom: "24px",
    },

    dialPadBtn: {
        cursor: "pointer",
        '& p': {
            textAlign: "center",
            color: "#315271",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "36px",
            lineHeight: "43px",
        }
    },

    dialBtn: {
        height: "60px",
        borderRadius: "30px",
    }
});


class Dialpad extends Component {

    constructor(props) {
        super(props);

        this.state = {
            number: "",
        }

        this.__onButtonPushed = this.__onButtonPushed.bind(this);
    }

    __onButtonPushed = (character) => () => {

        const { number } = this.state;

        let newNumber = number;

        switch (character) {
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "0":
                newNumber += character;
                break;
            case "<":

                newNumber = number.substring(0, number.length - 1);
                break;
            case "*":
            case "#":
        }

        this.setState({
            number: newNumber,
        });
    }

    __onDial = () => {

        const { number } = this.state;

        let contactNumber = phoneUtil.formatPhoneNumberForDialout(number)
        contactNumber.country = "+1"

        this.props.dialOut({
            type: "TELEPHONE",
            display: "UNKNOWN",
            contactNumber: contactNumber,
        });
    }

    __onChange = (event) => {

        const value = event.target.value;
        if(/^[0-9]{0,10}$/.test(value)) {

            const { number } = this.state;

            this.setState({
                number: value,
            });
        }
    }

    render() {

        let { classes, telehealthStatus } = this.props;
        let { tab, number } = this.state;

        return (
            <div className={classes.root}>
                <div id={"dialpad-number-container"}>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <Input
                            autoComplete='off'
                            id={"dialpad-number-input"}
                            value={number}
                            className={classes.numberInput}
                            onChange={this.__onChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.numberInputBackspace}
                                        aria-label="backspace"
                                        onClick={this.__onButtonPushed(`<`)}
                                        edge="end"
                                    >
                                        <BackspaceIcon fontSize={"large"} />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <Grid id={"dialpad-container"} className={classes.dialPadBtnContainer} container spacing={1}>
                    { Array(9).fill().map((value, index) => (
                        <Grid id={`dialpad-button-${index + 1}`} className={classes.dialPadBtn} item xs={4} onClick={this.__onButtonPushed(`${index + 1}`)} button>
                            <p>{index + 1}</p>
                        </Grid>
                    )) }

                    <Grid id={"dialpad-button-asterisk"} className={classes.dialPadBtn} item xs={4} onClick={this.__onButtonPushed(`*`)} button>
                        <p>*</p>
                    </Grid>
                    <Grid id={"dialpad-button-0"} className={classes.dialPadBtn} item xs={4} onClick={this.__onButtonPushed(`0`)} button>
                        <p>0</p>
                    </Grid>
                    <Grid id={"dialpad-button-hash"} className={classes.dialPadBtn} item xs={4} onClick={this.__onButtonPushed(`#`)} button>
                        <p>#</p>
                    </Grid>
                </Grid>
                <div id={"dialpad-dial-container"}>
                    <Button disabled={number?.length < 10 || telehealthStatus !== 'connected'} className={classes.dialBtn} variant={"contained"} color={"primary"} onClick={this.__onDial} fullWidth><DialIcon fontSize={"large"} /></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Dialpad context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import React from "react";
import {DHAccordion} from "../../Common";
import PhysicalExamBodySystemItem from "./PhysicalExamBodySystemItem";
import PhysicalExaminationContent from "./PhysicalExaminationContent";

import {Bloc as PhysicalExamBloc, Event} from "./bloc";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import {Box, CircularProgress, Typography,} from "@material-ui/core";

const styles = theme => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColorAndSize: {
    color: "#C4C4C4",
    fontSize: 32,
    marginLeft: 8,
    marginRight: 8,
  },
  heading: {
    fontWeight: 400,
    fontSize: 20,
  },
  flexLayout: {
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  selectTemplateLayout: {
    maxWidth: "100%",
    overflow: "auto",
  },
  selectTemplateList: {
    paddingRight: "24px",
  },
  items: {
    margin: "16px 32px 0 32px",
  },
  bodySystem: {
    color: "#777777",
  },
  bodySystemHeader: {
    margin: "52px 32px 0 32px",
  },
  contentInfoSec: {
    backgroundColor: "#F9F9F9",
    marginBottom: 32,
    borderRadius: 16,
    width: "100%",
  },
});

class  PhysicalExam extends DecodedComponent {

  constructor(props) {
    super(props);
    this.parentBloc = props.parentBloc;
    this.bloc = new PhysicalExamBloc(this.parentBloc);
    this.state = { initialised: false, conditions: [] };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.bloc.saveAndClose();
  }

  __handleEvent = (event) => {
    if(event.type === Event.INITIALISED) {

    }
  }

  render() {

    const { initialised, physicalExam, templates, templateRecommendation } = this.state;
    const { classes } = this.props;

    if(!initialised) {
      return (
          <Box style={{
            height: "100%",
            padding: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <CircularProgress />
        </Box>);
    }

    return (
        <div className={classes.flexLayout}>
          <div className={classes.bodySystem}>
            <div className={classes.bodySystemHeader}>
              <Typography>BODY SYSTEM</Typography>
            </div>
            <div className={classes.items}>
              {physicalExam?.sections?.map((bodySystem, index) => (
                  <div key={`${bodySystem.code}-${index}`} style={{ width: 400 }}>
                    <PhysicalExamBodySystemItem
                        physicalExamBloc={this.bloc}
                        key={`${bodySystem.code}-${index}`}
                        item={bodySystem}
                    />
                  </div>
              ))}
            </div>
          </div>
          <div className={classes.contentInfoSec}>
            <PhysicalExaminationContent physicalExamBloc={this.bloc} handleClose={this.parentBloc.togglePhysicalExam} />
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(PhysicalExam);

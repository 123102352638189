import * as rxjs from "rxjs";
import {chartApi} from "../../../../../../../../../utils/services/chart.api";
import {notificationService} from "../../../../../../../../../utils/notification";
import {CommonBloc} from "../Common/bloc";

export class Bloc extends CommonBloc {

    constructor(cgiBloc) {
        super(cgiBloc, 'medications', 'medication', 'B004', ['medication_history']);
    }

    initialise = () => {
        this.getMedications();
    }

    getMedications = () => {

        let {clinicId, encounterId} = this.subject.value;

        this.events.next({
            type: BlocEvent.MEDICATION_LOADING
        })

        chartApi.getPersonChartMedications(clinicId, encounterId).then(result => {

            this.__updateSubject({
                resources : result.data.items
            })

            this.events.next({
                type: BlocEvent.MEDICATION_LOAD_SUCCESS,
                data: {
                    resources: result.data.items,
                }
            })

        }).catch(error => {

            notificationService.error("Unable to retrieve patient's medications")

            this.events.next({
                type: BlocEvent.MEDICATION_LOAD_ERROR,
                data: {
                    error: error,
                }
            })
        }).finally(() => this.__updateSubject({ initialised: true }))
    }
}

export class BlocEvent {

    static MEDICATION_LOADING = "MEDICATION_LOADING";
    static MEDICATION_LOAD_SUCCESS = "MEDICATION_LOAD_SUCCESS";
    static MEDICATION_LOAD_ERROR = "MEDICATION_LOAD_ERROR";
}

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Chip, Tab, Tabs, Typography} from "@material-ui/core";

import EncounterContext from "../../context";
import NotificationsPanel from "../../NotificationsPanel";
import ConversationPanel from "../../../../../shared/ConversationPanel";
import ClinicalSummary from "./ClinicalSummary";

const styles = theme => ({
    root: {
        height: "100%",
        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "32px",
    },

    tabWrapper: {
        padding: "24px 32px",
        maxHeight: "50vh",
    },

    buttons: {
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px",
    },

    button:{
        marginTop: "8px",
        marginBottom: "8px",
    },
});


class Chart extends Component {

    blocSubscription

    constructor(props) {
        super(props);

        this.state = {
            tab: "0",
            resolved: [],
        };

        this.__encounterStateHandler = this.__encounterStateHandler.bind(this);
    }

    componentDidMount() {

        const { encounterBloc } = this.props.context;

        this.blocSubscription = encounterBloc.subscribeToState(this.__encounterStateHandler);
    }

    componentWillUnmount() {

        this.blocSubscription.unsubscribe();
    }

    __encounterStateHandler = (state) => {

        this.setState({
            initialised: state.initialised,
            encounter: state.encounter,
            busy: state.busy,
            resolved: state.resolvedNotifications,
        });
    }

    _tabChange = (event, newValue) => {

        this.setState({
            tab: newValue,
        });
    }

    render() {

        let { classes, size } = this.props;

        let { person, messages } = this.props.context;

        let { tab, resolved } = this.state;

        return (
            <div className={classes.root}>
                    <div hidden={ size && size === 'small' } className={classes.tabWrapper}>
                        <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this._tabChange}
                            aria-label="disabled tabs example">
                            <Tab value={"0"} label={<Typography>Clinical Summary</Typography>} />
                            <Tab value={"1"} label={
                                <>
                                    {(person && person.notifications && person.notifications.length - resolved.length > 0) ?
                                        <Typography>Notifications <Chip  color="secondary" size="small" label={person.notifications.length} /></Typography>
                                        : <Typography>Notifications</Typography>
                                    }
                                </>
                            } />
                            <Tab value={"2"} label={<Typography>Full Conversation</Typography>} />
                        </Tabs>
                        { tab !== "0" ? <></> : <ClinicalSummary /> }
                        { tab !== "1" ? <></> : <NotificationsPanel /> }
                        { tab !== "2" ? <></> : <ConversationPanel messages={messages} /> }
                    </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Chart context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

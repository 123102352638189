import { withStyles } from "@material-ui/core";
import React from "react";
import { DragableCurrentComplaints } from "./DragableCurrentComplaints";
import { useCGIContext } from "../../../ClinicalGraph/CGIContext";
import { DHAccordion, DHDrawerChip } from "../../../Common";
import CurrentComplaintDropElement from "./CurrentComplaintDropElement";
import { combineCurrentComplaints } from "./CurrentComplaints.utils";
import { DrawerContextMenuTypes } from "../Drawer.utils";

//https://codesandbox.io/s/6b3m5?file=/src/components/App/index.jsx
const styles = {
  drawerItems: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
  },
  sectionHeader: {
    color: "#777777",
  },
  iconColor: {
    color: "#C4C4C4",
  },
  chipContent: {
    width: 250,
    backgroundColor: "#F5F5F5",
    marginTop: 12,
  },
  label: {
    flex: 1,
    justifyContent: "flex-start",
  },
};

function CurrentComplaints({ item, classes }) {
  const processState = useCGIContext();
  const {
    currentSelectedComplaint,
    setCurrentSelectedComplaint,
    drawerContextMenu,
    setDrawerContextMenu,
    setSelectedDrawerContextMenu,
  } = processState;
  return (
    <>
      <div style={{ marginLeft: 16, marginRight: 16 }}>
        <DHAccordion
          header="CURRENT COMPLAINTS"
          expandedInitial={true}
          style={{ marginTop: 40 }}
          onHeaderTap={() => {
            setDrawerContextMenu(!drawerContextMenu);
            setSelectedDrawerContextMenu(
              DrawerContextMenuTypes.currentComplaint
            );
          }}
        >
          <div style={{ overflow: "hidden", clear: "both" }}>
            {item?.map((sec, index) => {
              const isSelected = currentSelectedComplaint.bid === sec.bid;
              return (
                <React.Fragment key={`${sec.bid}-${index}`}>
                  <CurrentComplaintDropElement
                    accept={["currentComplaints"]}
                    processDrop={(item) =>
                      combineCurrentComplaints(sec, item, processState)
                    }
                    allowedSemanticTypes={["currentComplaints"]}
                  >
                    <DragableCurrentComplaints
                      index={index}
                      item={{ ...sec, source: "currentComplaints" }}
                      type={"currentComplaints"}
                      accept="currentComplaints"
                      key={sec.bid}
                    >
                      <DHDrawerChip
                        drawerItem={true}
                        label={sec.description}
                        className={classes.chipContent}
                        classes={{ label: classes.label }}
                        onClick={() => {
                          if (!isSelected) {
                            setCurrentSelectedComplaint(sec);
                          }
                        }}
                        style={{
                          backgroundColor: isSelected ? "#00D1FF" : "#F5F5F5",
                          color: isSelected ? "#fff" : "#000",
                        }}
                      />
                    </DragableCurrentComplaints>
                  </CurrentComplaintDropElement>
                </React.Fragment>
              );
            })}
          </div>
        </DHAccordion>
      </div>
    </>
  );
}

export default withStyles(styles)(CurrentComplaints);

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab} from "@material-ui/core";

import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import {encounterEventService} from "../../service/encounter.event.service";
import EncounterContext from "../../context";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    root: {
        position: "absolute",
        bottom: "16px",
        width: "100%",
        zIndex: "1000",
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapper: {
        display: "flex",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "12px",
    },
    wrapperRight: {
        marginLeft: "auto",
        paddingBottom: "0",
    },

    click: {
        cursor: "pointer",
    },
    action: {
        height: "70px",
        width: "70px",
        backgroundColor: "rgba(253, 31, 84,1)",
        border: "8px solid #be345b",
        "&:hover": {
            backgroundColor: "rgba(253, 31, 84,0.6)",
        }
    },
    actionIcon: {
        color: "rgb(255, 255, 255)",
        height: "30px",
        width: "30px"
    },
    marginRight: {
        marginRight: "32px",

    }
});


class End extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }


    _toggleAudio = () => {
        encounterEventService.update({
            type: 'ENCOUNTER_CALL_TOGGLE_AUDIO',
            data: {}
        });
    }

    _toggleVideo = () => {
        encounterEventService.update({
            type: 'ENCOUNTER_CALL_TOGGLE_VIDEO',
            data: {}
        });
    }

    _endCall = () => {
        encounterEventService.update({
            type: 'ENCOUNTER_CALL_CUSTOMER_END',
            data: {}
        });
    }

    _closeDialog = () => {

        this.setState({
            confirmEnd: false,
        });
    };

    _confirmEndCallDialog = () => {

        this.setState({
            confirmEnd: true,
        });
    };


    render() {

        let { classes } = this.props;
        let { telehealth } = this.props.context;
        let { confirmEnd } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <span className={classes.wrapperContent}>
                    </span>
                    <span className={classes.wrapperContent}>

                        <Fab onClick={ this._confirmEndCallDialog } className={classes.action} aria-label="hangup">
                            <CallEndIcon className={classes.actionIcon} />
                        </Fab>
                    </span>
                    <span className={classes.wrapperRight}>
                        <Fab size="medium" aria-label="vid" className={classes.marginRight} onClick={this._toggleVideo}>
                          { telehealth.video ? <VideocamIcon fontSize="medium"/> : <VideocamOffIcon fontSize={"medium"} /> }
                        </Fab>
                        <Fab size="medium" aria-label="aud" className={classes.marginRight} onClick={this._toggleAudio}>
                          { telehealth.audio ? <MicIcon fontSize="medium"/> : <MicOffIcon fontSize={"medium"} /> }
                        </Fab>
                    </span>
                </div>
                <Dialog
                    open={confirmEnd}
                    onClose={this._closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"End Call"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to end the call?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._closeDialog} className={classes.dialogButton}>
                            <FormattedMessage id={'cancel'} defaultMessage={"Cancel"} />
                        </Button>
                        <Button color={"secondary"} variant={"contained"} onClick={this._endCall} color={"primary"} disableElevation autoFocus>
                            <FormattedMessage id={'confirm'} defaultMessage={"Confirm"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<End context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import React from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {navigationTitleService} from "../../../utils/title";
import AccountContext from "./context";
import Profile from "./Profile";
import {notificationService} from "../../../utils/notification";
import {authService} from "../../../utils/auth";
import {accountApi} from "../../../utils/services/account.api";
import {accountEventService} from "./service/account.event.service";
import {phoneUtil} from "../../../utils/phone";
import {usersApi} from "../../../utils/services/users.api";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },
});

class Account extends React.Component {

    subscription;

    state = {
        complete: false,
        loading: true,
        profile: {
            firstName: "",
            lastName: "",
            email: "",
            number: "",
        },
    };

    componentDidMount() {

        navigationTitleService.update("Account");

        let user = authService.getUser();
        this.setState({
           user: user,
        });

        accountApi.me().then(value => {

            this.setState({
                profile: {
                    firstName: value.data.name.given,
                    lastName: value.data.name.family,
                    email: value.data.email,
                    number: phoneUtil.formatPhoneNumberShortForInput(value.data.contactNumber),
                },
            });
        }).catch(reason => {

            notificationService.error(`Error retrieving your account - ${reason}.`);
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });

        this.subscription = accountEventService.registerStateCallback(this.__accountEventCallback);
    }


    componentWillUnmount() {

        this.subscription.unsubscribe();
    }

    __accountEventCallback = (event) => {

        const { type, data } = event;

        let { profile } = this.state;

        let updates = {};

        switch (type) {
            case "PROFILE_TEXT_CHANGE":
                profile[data.target] = data.value;
                break;
            case "PROFILE_SUBMIT":
                this.__updateProfile(profile);
                updates.loading = true;
                break;
            default:
                console.error("Not sure how we got here. If you see this please report it.")
        }

        this.setState({
            profile: profile,
            ...updates,
        });
    }

    __updateProfile = (profile) => {

        const request = {
            name: {
                given: profile.firstName,
                family: profile.lastName,
            },
            email: profile.email,
            contactNumber: phoneUtil.formatPhoneNumberForRegistration(profile.number),
        };

        accountApi.updateMe(request)
            .then(value => {

                notificationService.success(`Your profile has been updated.`);
            }).catch(reason => {

                notificationService.error(`Error retrieving your account - ${reason}.`);
            }).finally(() => {

                this.setState({
                    loading: false,
                });
            });
    }


    render() {

        const { classes } = this.props;
        const { user, profile, loading } = this.state;

        let context = {
            loading: loading,
            user: user,
            profile: profile,
        };

        return (
            <AccountContext.Provider value={context}>
                <div className={classes.root}>
                    <Profile />
                </div>
            </AccountContext.Provider>
        );
    }
}

Account.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Account));

import React, { Component } from 'react';
import {DHCalendar, DHDivider, DHFormInput, DHInput} from "../../../../Common";
import DHFormRow from "../../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, IconButton, Typography} from "@material-ui/core";
import {dateUtil} from "../../../../../util/date";
import DHItemSelector from "../../../../Common/DHItemSelector";
import {patientChartUtils} from "../../../../../util/PatientChartUtils";
import DHFormTitle from "../../../../Common/DHFormTitle";
import {DeleteOutline} from "@material-ui/icons";
import FormComponent from "../Common/FormComponent";
import QuickSelection from "../QuickSelection";


const styles = theme => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    },
    suggestionBtn: {
        '& span': {
            fontSize: "10px"
        }
    }
});

const CONDITION_STATUS = ["active", "historical"];

const CONDITION_TYPE = ["chronic", "acute"];

const CONDITION_LATERALITY = ["left", "right", "bilateral"];

const initState = {
    loading: false
}

class ConditionForm extends FormComponent {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    __updateSelectOption = (target) => (_value) => {
        let v = _value?.toLowerCase();
        let { bloc, value } = this.props;
        bloc.updateText(value, target, v);
    }

    render() {

        let { classes, value, index } = this.props

        return (
            <Box id={value.id}>

                <DHFormTitle label={value.code.display}>
                    {(value.recorder || value.recordedDate) && patientChartUtils.renderTitleRecordText(value)}
                    <IconButton onClick={this.__delete}>
                        <DeleteOutline />
                    </IconButton>
                </DHFormTitle>

                <DHFormRow label={"Onset"}>
                    <DHFormInput name={"onset"} size={"large"} value={value.onset?.onset} onChange={this.__updateOnsetText}/>
                </DHFormRow>

                <DHFormRow label={""}>
                    <QuickSelection id={`onset-${value.id}`} target={"onset"} onClick={this.__populateSuggestion}></QuickSelection>
                </DHFormRow>

                <DHFormRow label={"Status"}>
                    <DHItemSelector id={`clinicalStatus-${value.id}`} defaultOption={value.clinicalStatus} value={value.clinicalStatus}  options={CONDITION_STATUS} onSelect={this.__updateSelectOption("clinicalStatus")}/>
                </DHFormRow>

                <DHFormRow label={"Type"}>
                    <DHItemSelector id={`type-${value.id}`} defaultOption={value.type} value={value.type} options={CONDITION_TYPE} onSelect={this.__updateSelectOption("type")}/>
                </DHFormRow>

                <DHFormRow label={"Laterality"}>
                    <DHItemSelector id={`type-${value.laterality}`} defaultOption={value.laterality} value={value.laterality} options={CONDITION_LATERALITY} onSelect={this.__updateSelectOption("laterality")}/>
                </DHFormRow>

                <DHDivider/>
            </Box>
        )

    }
}

export default withStyles(styles)(ConditionForm);

import axios from 'axios';

class ConversationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    conversationMessages(conversationId, page, size) {

        return axios.get(`${this.baseUrl}/api/conversations/${encodeURI(conversationId)}/messages?s=${size}&p=${page}`)
            .then(function (res) { return res; });
    }

    conversationClinicalSummary(conversationId) {
        return axios.get(`${this.baseUrl}/api/conversations/${conversationId}/_clinical_summary`);
    }
}

export const conversationApi = new ConversationApi();

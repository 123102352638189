import firebase from "firebase/app";
import "firebase/auth";
import {notificationService} from "./notification";
import {tenantUtil} from "./tenant";
import {AnalyticsEvent, analyticsEventLogger} from "./events";

export default class AuthService {

    constructor() {

        this.roles = []

        this.loginInProgress = true;
    }

    initialise = (auth) => {
        this.auth = auth;

        this.auth = firebase.auth();
        this.auth.tenantId = tenantUtil.tenantId();

        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        this.oidcProvider = new firebase.auth.OAuthProvider(`oidc.${tenantUtil.tenant()}.sso`);
        this.googleProvider.addScope('profile');
        this.googleProvider.addScope('email');

        const configuredPersistence = `${process.env.REACT_APP_AUTH_PERSISTENCE_TYPE}`;
        let persistenceType = firebase.auth.Auth.Persistence.LOCAL;
        if(configuredPersistence && configuredPersistence.length > 0) {
            persistenceType = firebase.auth.Auth.Persistence[configuredPersistence]
        }

        this.auth.setPersistence(persistenceType).then(value => {
            console.log("Session setup successfully.");
        }).catch(error => {
            notificationService.error("Error setting up your session. Please contact support@decodedhealth.com and reference '" + error.code + " - " + error.message + "'");
        })

        this.auth.getRedirectResult().then((result) => {

            if (result.credential) {
                this.token = result.credential.accessToken;
            }

            this.user = result.user;

            analyticsEventLogger.log(AnalyticsEvent.LOGIN_SUCCESS, {});
        }).catch((error) => {

            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.email;

            notificationService.error("Error trying to authenticate your user due to '" + (error.code ? error.code : "Unknown" ) + "'");

            analyticsEventLogger.log(AnalyticsEvent.LOGIN_ERROR, { code: error.code});
        }).finally(() => {

            this.loginInProgress = false;
        });
    }

    loginWithUsernamePassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password).then((result) => {

        if (result.credential) {
            this.token = result.credential.accessToken;
        }

        this.user = result.user;

        analyticsEventLogger.log(AnalyticsEvent.LOGIN_SUCCESS, {});
    }, (error) => {

        const errorCode = error.code;
        const errorMessage = error.message;

        if(['auth/user-not-found', 'auth/wrong-password'].includes(errorCode)) {
            notificationService.error(`Invalid credential combination.`);
        } else {
            notificationService.error(`${error.code} - ${errorMessage}`);
        }

        analyticsEventLogger.log(AnalyticsEvent.LOGIN_ERROR, { code: errorCode});

        throw error;

    }).finally(() => {

        this.loginInProgress = false;
    });

    sendResetPasswordEmail = (email) => this.auth.sendPasswordResetEmail(email);

    loginWithGoogle = () => this.auth.signInWithRedirect(this.googleProvider);

    loginWithOidc = () => this.auth.signInWithRedirect(this.oidcProvider);

    logout = () => this.auth.signOut();

    getUser = () => this.auth.currentUser;

    setUserRoles = (roles) => this.roles = roles;

    getUserRoles = () => this.roles;

    isLoginInProgress = () => this.loginInProgress;

    getToken = () => {

        return this.auth.currentUser
            .getIdToken()
            .then((token) => {

                if(!token) {
                    window.location = "/";
                }

                return token;
            }).catch(reason => window.location = "/");
    }
}

export const authService = new AuthService();

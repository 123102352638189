import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {authService} from "../../../utils/auth";
import PatientTracker from "./PatientTracker";
import WorkflowContext from "./context";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
        minHeight: "calc(100% - 56px)",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
});

class Workflow extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { classes } = this.props;
        const { organisationId } = this.props.match.params;

        let context = {
            clinicId: organisationId,
            roles: authService.getUserRoles(),
        };

        return (
            <WorkflowContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Switch>
                            <Route path="/workflow/:organisationId/patient-tracker" exact component={ PatientTracker }/>
                        </Switch>
                    </Grid>
                </div>
            </WorkflowContext.Provider>
        );
    }
}

Workflow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Workflow));

import {parse, format} from "date-fns";
import enUS from "date-fns/locale/en-US";
import {dateUtil} from "./date";

class PatientChartUtils {

    renderTitleRecordText = (value) => {
        let result = "";

        if (value.recorder) {
            result += "Entered by " + value.recorder;
        }

        if (value.recorder && value.recordedDate) {
            result += " | ";
        }

        if (value.recordedDate) {

            let date = dateUtil.parseDate(value.recordedDate);
            result += dateUtil.formatChartRecord(date)
        }

        return result;
    }
}

export const patientChartUtils = new PatientChartUtils();

import axios from "axios";

class UtilityApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
  }

  codeset(group) {
    return axios.get(
      `${this.baseUrl}/next/codesets/${group}`,
        {
          params: {
          }
        }
    );
  }
}
export const utilityApi = new UtilityApi();

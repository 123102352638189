import {Subject} from 'rxjs';
import {authService} from '../auth'

function publishEvent(request, data) {
    console.log(data);
}
function fromString(data) {
    console.log(data);
    return {};
}

export class AudioWebsocket {

    sockerUrl;
    socket;

    lastPing;

    connectionSubject = new Subject();
    stateSubject = new Subject();
    dataSubject = new Subject();
    errorSubject = new Subject();


    constructor() {
        this.baseUrl = process.env.REACT_APP_DH_API_BASE_WS || "ws://localhost:18085";
        this.sockerUrl = `${this.baseUrl}/ws/speech`;
    }


    connect() {

        this.close();

        let $this = this;

        authService.getToken()
            .then((token) => {

                this.socket = new WebSocket(this.sockerUrl, ["access_token", token]);
                this.socket.binaryType = "arraybuffer";

                this.socket.onmessage = (event) => {

                    if(event.data === "ping") {
                        this.send("pong");
                        return;
                    }

                    const data = fromString(event.data);
                    $this.dataSubject.next(event.data);
                };

                this.socket.onopen = function(event) {

                    $this.connectionSubject.next("on");
                    console.log("Websocket conversations is open!");
                };
                this.socket.onclose = function(event) {

                    if(event.code === 1008) {
                        $this.errorSubject.next({data: {text: "You already have a session open. Please close all sessions, or contact an administrator to force the termination of your sessions."}});
                        publishEvent({}, { "event": "websocket.close", "message": "Multiple sessions not allowed." });
                    }

                    if (!event.wasClean) {

                        if(event.code !== 1008) {
                            $this.errorSubject.next({ data: { text: "Websocket closed abnormally." }});


                            setTimeout(() => {
                                console.log("Reconnecting from close.", event);
                                $this.connect();
                                publishEvent({}, { "event": "websocket.reconnect" });
                            }, 500);

                            publishEvent({}, { "event": "websocket.close", "message": `Websocket closed due to ${event.code}.`, "detail": event });
                        }
                    }

                    $this.connectionSubject.next("closed");
                };

                this.socket.onerror = function(error) {

                    if (error.code === 'ECONNREFUSED') {
                        $this.connectionSubject.next("closed");

                        setTimeout(() => {
                            console.log("Reconnecting from error.", error);
                            $this.connect();
                            publishEvent({}, { "event": "websocket.reconnect" });
                        }, 500);
                        publishEvent({}, { "event": "websocket.error", "message": "Connection refused", "detail": error });
                    }
                }
            });
    }

    registerConnectionCallback(callback) {

        return this.connectionSubject.subscribe(callback);
    }

    registerStateCallback(callback) {

        return this.stateSubject.subscribe(callback);
    }

    registerDataCallback(callback) {

        return this.dataSubject.subscribe(callback);
    }

    registerExceptionCallback(callback) {

        return this.errorSubject.subscribe(callback);
    }


    send(message) {
        if (!window.WebSocket) {
            return;
        }
        if (this.socket.readyState === WebSocket.OPEN) {

            this.socket.send(message);
        } else {

            this.errorSubject.next({ data : { text : "Websocket is not open, please refresh." }})
        }
    }

    close() {

        if(!this.socket) return;

        try {

            this.socket.close();
        } catch (e) {

            console.error(e);
        }
    }
}

// export const audioWebsocket = new AudioWebsocket(process.env.REACT_APP_DH_AUDIO_WEBSOCKET_URL);

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Button, Typography} from "@material-ui/core";
import RpaContext from "../../context";
import {FormattedMessage} from "react-intl";


const styles = theme => ({
    root: {
        height: "inherit",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },

    form: {
        width: "100%",
    },
    formInput: {
        width: "100%",
    },

    formWrapper: {
        padding: "10px 12px 24px 12px",
    },
    formBtn: { },
    cardHeader: {
        padding: "17px 21px 0px 12px",
        display: "flex",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        padding: "14px 29px 0px 19px",
        display: "flex",
        flexDirection: "column",
        height: "230px",
    },
    contentText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        /* or 171% */
        color: "#3D5369",
        textTransform: "capitalize",

        opacity: "0.8",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    button: {
        height: "32px",
        minHeight: "32px",
        width: "100%",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    dialogButton: {
        marginRight: '12px',
    },
    notificationItem: {
        marginTop: "12px",
    },
});

class InteractionAppointmentCheckinPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: false,
        };


        this.__unlock = this.__unlock.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    _submit = () => {

        const { busy } = this.state;

        if(busy) return;

        this.setState({ busy: true });
        this.props.bloc.submitResponse({}, this.__unlock);
    };

    __unlock = () => {
        this.setState({ busy: false });
    }

    render() {

        let { classes } = this.props;

        return (
            <>
                <div className={`${classes.root}`}>
                    <Typography variant="h6" component="h2" className={classes.heading}>
                        <FormattedMessage id={'notification'} defaultMessage={"Notification"} />
                    </Typography>
                    <div className={classes.notificationWrapper}>
                        <div className={classes.notificationItem}>
                            Please transfer this patient from the expected waiting room (Expected - WR) into the waiting room (WR).
                        </div>
                        <div className={classes.notificationItem}>
                            If the patient is not in the expected waiting room do nothing and just click the submit button below.
                        </div>
                        <div className={classes.notificationItem}>
                            If the patient is not listed on the Cerner launch point waiting room board <b>please do not create a new patient</b>.
                        </div>
                    </div>
                    <div className={classes.fill}></div>
                    <Button className={classes.formBtn} onClick={this._submit} variant={"contained"} color={"primary"}><FormattedMessage id={"submit"} defaultMessage={"Submit"} /></Button>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <RpaContext.Consumer>
        {value => {
            return (<InteractionAppointmentCheckinPanel context={value} {...props} />);
        }
        }
    </RpaContext.Consumer>
));

import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Redirect} from 'react-router-dom';

import {authService} from '../../utils/auth';

import Loading from "../shared/Loading";
import {Grid, Hidden} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {tenantUtil} from "../../utils/tenant";
import {VERSION} from "../../utils/version";
import UsernamePasswordLogin from "./UsernamePassword";
import UsernamePasswordResetRequest from "./UsernamePasswordResetRequest";

function colorEnvironment() {

    if(window.location.href.indexOf("staging") > 0) {

        return "#2121ff";
    } else if(window.location.href.indexOf("test") > 0) {

        return "#21ff21";
    } else if(window.location.href.indexOf("localhost") > 0) {

        return "#ff2121";
    }

    return "#313941";
}

const styles = theme => ({
    root: {
        backgroundColor: "#313941",
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
        color: "#fff",
    },
    grid: {
        minHeight: "100%",
        height: "100%",
    },
    left: {
        minHeight: "100%",
        height: "100%",
        backgroundColor: colorEnvironment(),
    },
    right: {
        paddingTop: "12.8rem",
        paddingLeft: "4.8rem",
        paddingRight: "4.8rem",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('md')]: {
            paddingTop: "7.0rem !important",
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: "5.0rem !important",
        },
    },
    topRightContainer: {
      height: "15vh",
    },
    versionTitle: {
        marginBottom: "2.2rem",
        fontStyle: "normal",
    },
    loginTitle: {
        marginBottom: "0.1rem",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "54px",
        color: "#ffffff",
        '& span': {
            color: "#00C3FF",
        }
    },
    loginTermsWrapper: {
        paddingTop: "40px",
        margin: "0 auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    submit: {
    },
    footer: {
        textAlign: "center",
        paddingBottom: "10px",
    }
});


class Login extends Component {

    constructor(props) {
        super(props);

        this.loadingChecker  = setInterval(() => {

            if(!authService.isLoginInProgress()) {
                this.setState({
                    loading: false,
                });
            }
        }, 100);

        let and = {};

        const string_location = window.sessionStorage.getItem("redirect_to");

        if(string_location) {

            const redirect = JSON.parse(string_location);
            and.redirect = redirect;
        }

        this.state = {
            loading: true,
            reset: false,
            sso: `${process.env.REACT_APP_SSO_ENABLED}`,
            userpass: `${process.env.REACT_APP_USERNAME_PASSWORD_ENABLED}`,
            year: new Date().getFullYear(),
            ...and,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        clearInterval(this.loadingChecker);
        this.loadingChecker = undefined;
        window.sessionStorage.removeItem("redirect_to");
    }

    doLogin = () => {

        this.setState({
            error: undefined,
        });

        const next = {
            location: this.props.location.state.next,
            expire: new Date().getTime() + (60000 * 5)
        };

        window.localStorage.setItem("redirect_to", JSON.stringify(next))

        authService.loginWithGoogle();
    }

    doOktaLogin = () => {

        this.setState({
            error: undefined,
        });

        authService.loginWithOidc();
    }

    __toggleReset = () => {
        const { reset } = this.state;

        this.setState({
           reset: !reset,
        });
    }

    renderContent() {

        let { classes } = this.props;
        let { sso, userpass, year, reset } = this.state;

        if(this.state.loading) {

            return (<Loading />);
        } else {

            return (
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Hidden xsDown>
                            <Grid item xs={12} sm={3} md={5} lg={7} className={classes.left}>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={9} md={7} lg={5} className={classes.right}>
                            <div className={classes.topRightContainer}>
                            </div>
                            <div>
                                <Typography variant="h1" className={classes.loginTitle}>
                                    <FormattedMessage id="decoded" defaultMessage={`DECODED`} /> <span><FormattedMessage id="decoded" defaultMessage={`HEALTH`} /></span>
                                </Typography>
                                <p className={classes.versionTitle}>Version: { VERSION }</p>
                            </div>
                            <br />
                            {sso === '1' &&
                                <div className={classes.ssoContainer}>
                                    <Button
                                        id={"loginBtn"}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.doOktaLogin}>
                                        <FormattedMessage id="login" defaultMessage={`Login`}/>
                                    </Button>
                                </div>
                            }

                                {   userpass === '1' &&
                                    <div className={classes.usernamePasswordContainer}>
                                        { !reset ? <UsernamePasswordLogin toggleReset={this.__toggleReset} /> : <UsernamePasswordResetRequest toggleReset={this.__toggleReset} /> }
                                    </div>
                                }

                            <div className={classes.loginTermsWrapper}>
                                <Typography hidden={true} variant="caption" className={classes.loginTerms}><FormattedMessage id="terms" defaultMessage={`Terms and conditions`} /></Typography>
                            </div>
                            <div className={ classes.fill } />
                            <div className={ classes.footer }>Copyright © Decoded Health {year}</div>
                        </Grid>
                    </Grid>
                </div>
            );
        }

    }

    render() {

        let { location } = this.props;
        let { redirect } = this.state;

        if(authService.getUser()) {

            let next = "/";
            if(redirect) {
                next = redirect.location;
            } else if(location && location.state && location.state.next) {
                next = location.state.next;
            }

            if(!next) next = "/";

            return (<Redirect to={next} />);
        }

        let content = this.renderContent();

        return <>
            { content }
        </>;
    }
}

export default withStyles(styles)(Login);

import React from 'react';
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles';
import DecodedDrawer from '../shared/Drawer';

import {authService} from '../../utils/auth';
import {PrivateRoute} from "../../utils/protected.route";
import Clinic from "./Clinic";
import Enrollment from "./Enrollment";
import {secureEventService} from "./service/secure.event.service";

import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

import IdleTimer from 'react-idle-timer'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from "@material-ui/core";
import Scheduling from "./Scheduling";
import Account from "./Account";
import {checkAllowed} from "../../utils/auth/Allowed";
import rules from "../../utils/auth/rules";
import {globalBloc, IndexBlocEvent} from "./global.bloc";
import GlobalContext from "./context";
import LocationSelect from "../shared/LocationSelect";
import SelectLocation from "./SelectLocation";
import {navigationTitleService} from "../../utils/title";
import Rpa from "./Rpa";
import Workflow from "./Workflow";
import Administration from "./Administration";
import Analytics from "./Analytics";
import CGIMock from "./Mock";

const styles = theme => ({
    root: {
        display: 'flex',
        maxHeight: "100vh",
        height: "100vh",
        width: "100%",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    header: {
        maxHeight: "56px",
        width: "100%",
        padding: "12px 24px 12px 24px",
        display: "flex",
    },
    fill: {
        flex: "1 1 auto",
    },
    body: {
        maxHeight: "calc(100% - 56px)",
        height: "calc(100% - 56px)",
        width: "100%",
    },
    title: {
        fontSize: "24px",
        paddingRight: "42px",
        paddingTop: "4px",
    },
    drawerContent:{
        width: "100%",
        // boxShadow: '10px 0px 5px -5px rgba(0, 0, 0, 0.4) inset',
    },
    appContent: {
        maxHeight: "calc(100vh)",
        overflowY: "hidden",
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});

const TIMEOUT = 60 * 60;
const LEEWAY = 60;

class Secure extends React.Component {

    subscription;
    eventsSubscription;

    state = {
        countdownSeconds: LEEWAY,
        idle: false,
        open: false,
        entry: globalBloc.entry(),
        location: globalBloc.location() || "",
        user: {},
        title: "",
    };

    constructor(props) {
        super(props);

        this.idleTimer = null;
        this.countDownTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.onCountdown = this._countdown.bind(this);
        this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);

        this.eventsSubscription = globalBloc.subscribeToEvents(this.__handleBlocEvent);
        this.navigationTitleServiceSubscription = navigationTitleService.registerStateCallback(this.__handleNavigationUpdate);
    }

    __handleNavigationUpdate = (title) => {

        this.setState({
            title: title,
        });
    };

    __handleBlocEvent = (o) => {

        const { event, data } = o;

        let updates = {};

        switch (event) {
            case IndexBlocEvent.LOCATION_UPDATED:
                updates.location = data.location;
                break;
            case IndexBlocEvent.PERMISSIONS_LOADED:
                authService.setUserRoles(data.roles);
                updates.roles = data.roles;
                updates.audience = data.audience;
                updates.location = data.location;
                updates.loading = false;


                if(data.location && data.roles && data.entry !== 'link') {

                    let location = "/account";

                    if(data.roles.includes("PROVIDER") || data.roles.includes("TNT_ASSIST")) {
                        location = `/clinic/${data.location}/waiting-room`;
                    } else if(data.roles.includes("TNT_CLERK")) {
                        location = `/scheduling`;
                    } else if(data.roles.includes("TNT_DATA")) {
                        location = `/analytics`;
                    }

                    setTimeout(() => this.props.history.push(location), 500);
                } else if(!data.location) {

                    setTimeout(() => {

                        this.props.history.push('/select-location');
                    }, 500);
                }

                break;
            case "PERMISSIONS_NOT_FOUND":
                updates.loading = false;

                setTimeout(() => {

                    this.props.history.push('/enrollment');
                }, 500);
                break;
            case "PERMISSIONS_LOADING_ERROR":
                updates.loading = false;
                break;
        }

        this.setState({
            ...updates
        });
    }


    _onAction(e) { }

    _onActive(e) { }

    _onIdle(e) {

        if(!this.countDownTimer) {
            this.countDownTimer = setInterval(this.onCountdown, 1000);
        }

        this.setState({
            idle: true,
        });
    }

    _countdown = () => {

        const { countdownSeconds } = this.state;

        if(countdownSeconds < 0) {
            this.doLogout();
        } else {
            this.setState({
                countdownSeconds: (countdownSeconds - 1),
            });
        }
    }


    _cancelIdleTimeout = () => {
        this.idleTimer.reset();
        clearInterval(this.countDownTimer);
        this.countDownTimer = undefined;
        this.setState({
            idle: false,
            countdownSeconds: LEEWAY,
        });
    }

    componentDidMount() {

        let user = authService.getUser();

        if(user) {
            secureEventService.startRemotePublishing();
            globalBloc.reloadPermissions();
            globalBloc.setMe();
        }

        this.setState(
            {
                user: user,
            }
        );

        this.subscription = secureEventService.registerStateCallback(this._handleEvent)
    }


    componentWillUnmount() {
        if(this.countDownTimer) {
            clearInterval(this.countDownTimer);
            this.countDownTimer = undefined;
        }
        secureEventService.stopRemotePublishing();
        this.subscription.unsubscribe();
        this.subscription = null;
        this.eventsSubscription.unsubscribe();
        this.eventsSubscription = null;
    }

    _handleEvent = (event) => {

        const { data, type } = event;

        switch (type) {
            case 'ENROLLMENT_COMPLETE': {
                globalBloc.reloadPermissions();
            }
            break;
        }
    };

    doLogout = () => {

        authService
            .logout()
            .then(() => window.location = "/");
    };

    handleDrawerToggle = () => {

        this.setState(state => ({ open: !state.open }));
    };



    render() {

        const { classes } = this.props;

        const { location, title, user, roles, audience, open, idle, countdownSeconds } = this.state;

        let routes = (<></>);

        if(audience && audience.length > 0 && roles && roles.length > 0) {

            let routesAllowed = [];

            if(checkAllowed(rules, roles, "interaction:encounter:*")) {

                routesAllowed.push(<PrivateRoute key={"clinic"} path="/clinic/:organisationId" component={ Clinic } />);
            }
            if(checkAllowed(rules, roles, "interaction:appointment:*")) {

                routesAllowed.push(<PrivateRoute key={"scheduling"} path="/scheduling" component={ Scheduling } />);
            }
            if(checkAllowed(rules, roles, "account:update:me")) {

                routesAllowed.push(<PrivateRoute key={"account"} path="/account" component={ Account } />);
            }
            if(checkAllowed(rules, roles, "global:location:switch")) {

                routesAllowed.push(<PrivateRoute key={"select-location"} path="/select-location" component={ SelectLocation } />);
            }
            if(checkAllowed(rules, roles, "rpa:manual:*")) {

                routesAllowed.push(<PrivateRoute key={"rpa-route"} path="/rpa/:organisationId" component={ Rpa } />);
            }
            if(checkAllowed(rules, roles, "workflow:manual:*")) {

                routesAllowed.push(<PrivateRoute key={"workflow-route"} path="/workflow/:organisationId" component={ Workflow } />);
            }
            if(checkAllowed(rules, roles, "admin:settings:*")) {

                routesAllowed.push(<PrivateRoute key={"settings-route"} path="/administration" component={ Administration } />);
            }
            if(checkAllowed(rules, roles, "analytics:dashboard:view")) {

                routesAllowed.push(<PrivateRoute key={"analytics-route"} path="/analytics" component={ Analytics } />);
            }

            routes = (<>{ routesAllowed }</>);
        } else {
            routes = (<PrivateRoute  key={"enrollment"} path="/enrollment" exact component={ Enrollment } />);
        }

        const context = {
            audience: audience,
            location: location,
        };

        if(false) {

            return (
                <div className={classes.drawerContent}>
                    <div className={`${classes.body}`}>
                        <CGIMock></CGIMock>
                    </div>
                </div>
            );
        }

        return (
            <GlobalContext.Provider  value={context}>
                <div className={classes.root}>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        onAction={this.onAction}
                        debounce={250}
                        timeout={1000 * TIMEOUT} />
                    <DecodedDrawer
                        roles={roles}
                        open={this.state.open}
                        onClose={this.handleDrawerToggle}
                    />

                    <div className={`${classes.open} ${classes.drawerContent}`}>
                        <div className={`${classes.header}`}>
                            <Typography className={classes.title} variant="h1" component="div">
                                { title }
                            </Typography>
                            <LocationSelect audience={audience} />
                            <span className={classes.fill} />
                            <Tooltip title="Help Center" aria-label="add">
                                <IconButton onClick={() => window.open('https://decodedhealth.atlassian.net/servicedesk/customer/portals', '_blank') } aria-label="help"><ContactSupportOutlinedIcon /></IconButton>
                            </Tooltip>
                        </div>
                        <div className={`${classes.body}`}>
                        { routes }
                        </div>
                    </div>
                    <Dialog
                        open={idle}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Session Expiring"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You have {countdownSeconds > 0 ? countdownSeconds : 0} second(s) to extend your session.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={countdownSeconds <= 0} onClick={this.onCancelIdleTimeout} color="primary" autoFocus>
                                Extend
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </GlobalContext.Provider>
        );
    }
}

export default withStyles(styles)(withRouter(Secure));

import { Relationship } from "./GraphWrapper";
import {AllStatusForObservation} from "../component/ClinicalGraph/Observations/Observations.utils";
import {cgiApi} from "../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../utils/notification";

export class DiagnosisUtil {

  constructor(bloc) {
    this.bloc = bloc;
  }

  allConditions = (status = []) => this.bloc.subject.value.workspaceGraph?.encounterConditions(status)

  processReorder = (item, newOrder) => {

    const {clinicId, encounter, currentComplaint, workspaceGraph, } = this.bloc.subject.value;

    const conditions = workspaceGraph.encounterReasonForVisitConditionsOrderUpdate(currentComplaint, item, newOrder);

    this.bloc.__updateSubject({workspaceGraph: workspaceGraph});

    delete item.hoverIndex;

    const payload = {
      command: "update_condition_order",
      payload: {
        reasonForVisitBid: currentComplaint.bid,
        bids: conditions,
      }
    }

    this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "");
  }

  saveAll = (conditions, onComplete) => {

    const {clinicId, encounter, currentComplaint, } =
        this.bloc.subject.value;

    const identifiers = conditions.map(_item => { return {
        value: _item.code.display,
        code: _item.code
    }});

    const payload = {
      command: "add_condition",
      payload: {
        reasonForVisitBid: currentComplaint.bid,
        identifiers: identifiers,
      },
    };

    this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "", onComplete);
  }

  setWorkingDx = (selectedDx) => {

    const {clinicId, encounter, currentComplaint, } =
        this.bloc.subject.value;

    const payload = {
      command: "set_diagnosis",
      payload: {
        reasonForVisit: currentComplaint.bid,
        bid: selectedDx.bid,
      }
    }

    this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "");
  }

  delete = (condition) => {

    const {clinicId, encounter, workspaceGraph,differential,} =
        this.bloc.subject.value;
    differential.highlightedDx = undefined;

    if(differential?.highlightedDx?.bid === condition.bid) {
      this.bloc.clearSelectedDX();
    }

    cgiApi.deleteEntity(clinicId, encounter.id, "conditions", condition.bid)
        .then(value => {
          workspaceGraph.removeNode(condition);
          workspaceGraph.cleanupObservations();
          this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
        }, reason => notificationService.httpError(reason));
      }

  getFeatures = (selectedDx) => {
    const { workspaceGraph } = this.bloc.subject.value;

    const encounter = workspaceGraph?.encounter();
    const patient = workspaceGraph?.encounterPatient();

    if (!selectedDx?.bid) {
      return [];
    }

    const filter = workspaceGraph
      ?.inNodes(selectedDx, Relationship.EVIDENCE_OF)
      .map((vertex) => vertex.bid);

    // const patientObservations = workspaceGraph?.inNodes(patient, "observed_on", filter);

    const result = workspaceGraph?.allNodes("observation", filter)
        .filter((item) => item.status && item.status !== AllStatusForObservation.unknown);

    return result;
  };
}

import React from "react";
import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";
import EditableSection from "./EditableSection";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {Bloc} from "./bloc";
import {Skeleton} from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import {FileCopyOutlined} from "@material-ui/icons";
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';


const __screen = "calc(100vh - 78px)";

const styles = (theme) => ({
  root: {
    position: "relative",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    overflow: "auto",
    touchAction: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  summaryContainer: {
    marginTop: "32px",
    width: "85%",
  },
  controlContainer: {
    display: "flex",
  },
  fill: {
    flex: "1 1 auto",
  },
  dialogButton: {
    marginRight: '12px',
  },
});

class ClinicalNote extends DecodedComponent {

  constructor(props) {
    super(props);
    this.state = { initialised: false, confirm: false, };
    this.bloc = new Bloc(props.context.bloc, {});
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.bloc.close();
  }

  __updateSection = (sectionId, content) => {
    const { note, } = this.state;
    note.__dirty = true
  }

  __regenerate = () => {
    this.bloc.regenerate();
    this.__confirmClose();
  }

  __confirm = () => this.setState({confirm: true,});

  __confirmClose = () => this.setState({confirm: false,});

  __save = () => this.bloc.save();

  render() {

    const { classes } = this.props;
    const { note, initialised, confirm, } = this.state;

    if(!initialised) {
      return (<div className={classes.root}>

        <div className={classes.summaryContainer}>
          <Typography variant={"h2"}>Loading...</Typography>


          <Skeleton  />
          <Skeleton  />
          <Skeleton  />
        </div>
      </div>);
    }

    return (
      <div className={classes.root}>

        <div className={classes.summaryContainer}>
          <Box className={classes.controlContainer}>
            <Typography variant={"h4"}><span className={classes.fill}></span><IconButton onClick={() => this.bloc.copyNoteToClipboard()}><FileCopyOutlined /></IconButton><IconButton onClick={this.__confirm}><RefreshIcon /></IconButton><IconButton onClick={this.__save}><SaveIcon /></IconButton></Typography></Box>

          { note.document?.sections?.map((section, index) => {
            return (<EditableSection index={index} bloc={this.bloc} updateSection={this.__updateSection} section={section} />)
          })}
        </div>
        <Dialog
            open={confirm}
            onClose={this.__confirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-confirm-regenerate">{"Regenerate clinical note?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-confirm-regenerate-description">
              Regenerating the clinical note will undo any changes you have made to the clinical note. Do you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.__confirmClose} color="default" className={classes.dialogButton}>
              Cancel
            </Button>
            <Button onClick={this.__regenerate} variant={"contained"} color="primary" autoFocus>
              Regenerate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <ClinicalGraphInterfaceContext.Consumer>
    {(value) => {
      return <ClinicalNote context={value} {...props} />;
    }}
  </ClinicalGraphInterfaceContext.Consumer>
));

import * as rxjs from 'rxjs';
import {GlobalEvent, globalEventService} from "../service/global.event.service";
import {clinicWaitingRoomEventService} from "./service/clinic.waitingroom.event.service";
import {appointmentApi} from "../../../utils/services/appointments.api";


export class ClinicBloc {

    waitingRoomSubscription;
    globalEventServiceSubscription;

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            encounters: { loading: true, encounters: [], encounterIds: [] },
        });

        this.events = new rxjs.Subject();

        this.waitingRoomSubscription = clinicWaitingRoomEventService.registerStateCallback(this.__handleWaitingRoomEvent);
        this.globalEventServiceSubscription = globalEventService.registerStateCallback(this.__listenToGlobal);
    }

    initialise = () => {
        this.loadCancelReasons();
    }

    close = () => {
        this.globalEventServiceSubscription.unsubscribe();
        this.waitingRoomSubscription.unsubscribe();
    }

    __listenToGlobal = (event) => { }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);


    __handleWaitingRoomEvent = (event) => {

        const current = this.subject.value;

        const {data, type} = event;

        switch (type) {
            case 'CURRENT_LIST_UPDATE': {

                const appointments = data.items;
                const appointmentIds = appointments.map((_appointment) => _appointment.id);

                const newEncounters = {
                    loading: false,
                    encounters: appointments,
                    encounterIds: appointmentIds,
                }

                this.subject.next({
                    ...current,
                    encounters: newEncounters,
                });

                break;
            }
        }
    };

    appendEncounter = (encounter) => {

        const current = this.subject.value;

        const { encounters } = this.subject.value;

        encounters.encounters.push(encounter);
        encounters.encounterIds.push(encounter.id);

        this.subject.next({
            ...current,
            encounters: encounters,
        });
    }

    loadCancelReasons = () => {

        const current = this.subject.value;

        appointmentApi.getCancelReasons()
            .then(value => {

                this.subject.next({
                    ...current,
                    cancelReasons: value.data.items,
                });

            })
            .catch(reason => { });
    }

}

export class ClinicBlocEvent {
    static PERMISSIONS_LOADED = "PERMISSIONS_LOADED";
    static PERMISSIONS_NOT_FOUND = "PERMISSIONS_NOT_FOUND";
    static PERMISSIONS_LOADING_ERROR = "PERMISSIONS_LOADING_ERROR";

    static LOCATION_UPDATED = GlobalEvent.LOCATION_UPDATED;
}

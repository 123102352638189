import React, {Component} from "react";
import {Box, Divider, Tab, Tabs, Typography, withStyles,} from "@material-ui/core";

const styles = {
  indicator: {
    height: 8,
  },
};
export class CGITabs extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { selectedTab, onChange, classes } = this.props;
    return (
      <>
        <Box
          style={{
            borderBottom: 1,
            borderColor: "divider",
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChange}
            classes={{ indicator: classes.indicator }}
          >
            <Tab value="0" label={<Typography>CLINICAL GRAPH</Typography>} />
            <Tab value="1" label={<Typography>PHYSICAL EXAM</Typography>} />
            <Tab value="2" label={<Typography>CLINICAL NOTE</Typography>} />
          </Tabs>
        </Box>
        <Divider variant="middle" />
      </>
    );
  }
}

export default withStyles(styles)(CGITabs);

import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import {CheckCircle, RadioButtonUnchecked} from "@material-ui/icons";
import React, {Component} from "react";

const styles = (theme) => ({
  checkBoxStyle: {
    marginRight: 18,
  },
  checkBoxLabelStyle: {
    alignItems: "flex-start",
  },
});
export class DHCheckBox extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, children, ...checkBoxProps } = this.props;
    return (
      <FormControlLabel
        className={classes.checkBoxLabelStyle}
        label={children}
        control={
          <Checkbox
            {...checkBoxProps}
            className={classes.checkBoxStyle}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<CheckCircle />}
            color="#777777"
            label={children}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(DHCheckBox);

import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Delete from "../../../../../../assets/delete.svg";
import DownLoad from "../../../../../../assets/download.svg";

const styles = () => ({
  keyWrp: {
    fontSize: 15,
    lineHeight: "18px",
  },
  item: {
    display: "flex",
    justifyContent: "end",
  },
});

const DHKeyValue = ({
  keyComp,
  value,
  classes,
  isRemovable,
  isDownloadable,
}) => {
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ alignSelf: "center" }}>
          <span className={classes.keyWrp}>{keyComp}</span>
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 32,
              alignItems: "center",
            }}
          >
            <div>{value}</div>
            {isRemovable && (
              <img
                src={Delete}
                alt="delete"
                style={{ marginLeft: 24, height: 16, width: 12 }}
              />
            )}
            {isDownloadable && (
              <img
                src={DownLoad}
                alt="download"
                style={{ marginLeft: 24, height: 16, width: 12 }}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(DHKeyValue);

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {preloadScript} from 'opentok-react';

import Display from "./Display";
import EncounterContext from "../../context";
import TelehealthContext from "./context";
import {telehealthEventService} from "./service/telehealth.event.service";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";
import {Tooltip} from "@material-ui/core";


const styles = theme => ({
    root: {
        position: "relative",
        height: '100%',
        width: '100%',
        paddingRight: "0",
    },
    brandingWrapper: {
        position: "absolute",
        display: "flex",
        top: "24px",
        left: "31px",
        [theme.breakpoints.down('sm')]: {
            top: "24px",
            left: "31px",
        }
    },
    time: {
        minWidth: "100px",
        height: "32px",
        padding: "4px 24px 0 24px",
        marginRight: "16px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        background: "#FFFFFF",
        border: "1px solid #F7F7F7",
        boxSizing: "border-box",
        borderRadius: "22px",

        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#20516A",
        [theme.breakpoints.down('xs')]: {
            display: "none",
        }
    },
    controls: {
    },
    fill: {
        flex: "1 1 auto",
    }
});


class TelehealthPanel extends Component {

    clockTimer;
    subscription;
    encounterBlocSubscription;

    constructor(props, context) {
        super(props, context);

        this.state = {
            start: new Date().getTime(),
            now: new Date().getTime(),
            error: null,
            connected: false,
            sessionInformation: {},
        };

        this.sessionEvents = {
            sessionConnected: () => {
                this.setState({ connected: true });
            },
            sessionDisconnected: () => {
                this.setState({ connected: false });
            }
        };
    }

    componentDidMount() {

        const { encounterBloc } = this.props.context;

        this.clockTimer = setInterval(() => {
            this.setState({
                now: new Date().getTime(),
            });
        }, 1000);

        this.subscription = telehealthEventService.registerStateCallback(this._handleEvent);
        this.encounterBlocSubscription = encounterBloc.subscribeToState(this.__patientStatusUpdate);
    }


    componentWillUnmount() {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
        if(this.encounterBlocSubscription) {
            this.encounterBlocSubscription.unsubscribe();
        }
        clearInterval(this.clockTimer);
        this.clockTimer = undefined;
    }

    _handleEvent = (event) => {

        const { type } = event;

        const { telehealth } = this.state;

        let newState = {};

        switch (type) {
            case 'ENCOUNTER_CALL_JOIN':
                newState.telehealth = telehealth;
                newState.telehealth.state = 'CONNECTED';
                break;
            case 'ENCOUNTER_CALL_END':
                newState.telehealth = telehealth;
                newState.telehealth.state = 'DISCONNECT';
                analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_SESSION_END, {});
                break;
            case 'ENCOUNTER_CALL_TOGGLE_VIDEO':
                newState.telehealth = telehealth;
                newState.telehealth.video = !telehealth.video;
                break;
            case 'ENCOUNTER_CALL_TOGGLE_AUDIO':
                newState.telehealth = telehealth;
                newState.telehealth.audio = !telehealth.audio;
                break;
        }

        this.setState({
            ...newState,
        });
    };

    __patientStatusUpdate = (subject) => {
        this.setState({
            sessionInformation: subject.sessionInformation,
        });
    }

    __patientStatus = () => {

        const { sessionInformation } = this.state;

        if(sessionInformation && sessionInformation.participants && sessionInformation.participants.length > 0) {

            const subject = sessionInformation.participants[0];
            if(subject.status === "UNKNOWN" && subject.communicationAttempts && subject.communicationAttempts.length > 0) {

                const latestCommunicationAttempt = subject.communicationAttempts[0];

                return <Tooltip title={ latestCommunicationAttempt.message === "" ? latestCommunicationAttempt.description : latestCommunicationAttempt.message }><span>{latestCommunicationAttempt.channel.toLowerCase()} {latestCommunicationAttempt.status.toLowerCase()}</span></Tooltip>;
            } else {

                return <>{subject.status}</>;
            }
        }

        return <>Unknown</>;
    }

    render() {

        const { encounterBloc } = this.props.context;

        const { children, details, value, classes, index, onStartVideo } = this.props;

        const { start, now, busy } = this.state;

        let context = {
            encounterBloc: encounterBloc,
            busy: busy,
        };

        let seconds = (now - start) / 1000;
        let minutes = seconds / 60;

        // eslint-disable-next-line react/react-in-jsx-scope
        return (
            <TelehealthContext.Provider value={ context }>
            <div className={classes.root}>

                { details.detail != null ?
                    <Display
                        onStartVideo={onStartVideo}
                        apiKey={details.detail.id}
                        sessionId={details.detail.sessionId}
                        token={details.detail.token}
                        loadingDelegate={<div>Loading...</div> } /> :
                    <></>
                }
                <div className={classes.brandingWrapper}>
                    <p className={ classes.time }>{`${parseInt(minutes % 60)}`.padStart(2, '0')}:{`${parseInt(seconds % 60)}`.padStart(2, '0')}</p>
                    <p className={ classes.time }>Patient: {this.__patientStatus()}</p>
                </div>
            </div>
            </TelehealthContext.Provider>
        );
    }
}

export default preloadScript(withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<TelehealthPanel context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
)));

import axios from "axios";

class CgiApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
  }
  addObservation(organisationId, encounterId, data) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace/_command`,
      data
    );
  }

  updateObservation(
    organisationId,
    encounterId,
    observationId,
    status,
    polarity
  ) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/observations/${observationId}`,
      { status: status, polarity: polarity }
    );
  }

  addCondition(organisationId, encounterId, data) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace/_command`,
      data
    );
  }

  updateCondition(organisationId, encounterId, conditionId, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/conditions/${conditionId}`,
      data
    );
  }

  deleteEntity(organisationId, encounterId, entityType, entityId) {
    return axios.delete(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/${entityType}/${entityId}`);
  }

  updateOrder(organisationId, encounterId, orderId, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/orders/${orderId}`,
      data
    );
  }

  updateArrival(organisationId, encounterId, id, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/arrivals/${id}`,
      data
    );
  }

  updateROS(organisationId, encounterId, id, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/ros/${id}`,
      data
    );
  }

  updateDisposition(organisationId, encounterId, id, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/dispositions/${id}`,
      data
    );
  }

  loadPhysicalExamTemplates(organisationId, encounterId) {
    return axios.get(`${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/physicalexamtemplates`);
  }


  recommendPhysicalExamTemplates(organisationId, encounterId) {
    return this.getPhysicalExamTemplate(organisationId, encounterId, "_recommend")
  }

  getPhysicalExamTemplate(organisationId, encounterId, templateId) {
    return axios.get(`${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/physicalexamtemplates/${templateId}`);
  }

  addPhysicalExam(organisationId, encounterId, data) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace/_command`,
      data
    );
  }

  updatePhysicalExam(organisationId, encounterId, physicalExamId, data) {
    return axios.patch(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/physicalexams/${physicalExamId}`,
      data
    );
  }

  addReasonForVisit(organisationId, encounterId, data) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace/_command`,
      data
    );
  }

  submitCommand(organisationId, encounterId, data) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/workspace/_command`,
      data
    );
  }

  recommend(organisationId, encounterId, payload) {

    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/_recommend`,
        payload,
    );
  }

  recommendDDx(organisationId, encounterId, conditionId) {

    return axios.get(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/conditions/${conditionId}/ddx`
    );
  }

  recommendOrders(organisationId, encounterId, conditionId) {
    return axios.get(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/conditions/${conditionId}/orders`
    );
  }

  recommendHistory(organisationId, encounterId, payload) {
    return axios.post(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/history/_recommend`, payload
    );
  }

  clinicalNote(organisationId, encounterId, force) {
    return axios.get(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/clinical_notes`,
        { params: { regenerate: force || "false" } }
    );
  }

  clinicalNoteSave(organisationId, encounterId, note) {
    return axios.put(
      `${this.baseUrl}/next/organisations/${organisationId}/encounters/${encounterId}/clinical_notes/${note.bid}`,
        note
    );
  }
}
export const cgiApi = new CgiApi();

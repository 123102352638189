import {cgiApi} from "../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../utils/notification";

export class EvaluationManagementUtil {

    constructor(bloc) {
        this.bloc = bloc;
    }

    toggleMenu = () => {
        let { orders } = this.bloc.subject.value;
        orders.open = !orders.open;
        this.bloc.__updateSubject({ orders: orders });
    }

    recommend = (item) => {

        const {clinicId, encounter,} = this.bloc.subject.value;
        return cgiApi.recommendOrders(clinicId, encounter.id, item.bid);
    }

    saveAll = (bid, recommendations, onComplete) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const patient = workspaceGraph.encounterPatient();

        const payload = {
            command: "add_known_actions",
            payload: {
                bid: currentComplaint.bid,
                participants: [
                    {
                        role: "SUBJECT",
                        identifier: {
                            code: {
                                code: patient.reference.code.code,
                                system: patient.reference.code.system,
                            },
                            value: patient.reference.value,
                        }
                    }
                ],
                basedOn: {
                    type: "condition",
                    bid: bid,
                    items: recommendations,
                },
                type: ["orders"]
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "orders", onComplete);
    }

    addRelated = (item) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            command: "add_known_actions",
            payload: {
                bid: currentComplaint.bid,
                participants: [
                    {
                        role: "SUBJECT",
                        identifier: {
                            code: {
                                code: workspaceGraph.encounterPatient().reference.code.code,
                                system: workspaceGraph.encounterPatient().reference.code.system,
                            },
                            value: workspaceGraph.encounterPatient().reference.value,
                        }
                    }
                ],
                basedOn: {
                    type: item.class,
                    bid: item.bid,
                },
                type: ["orders"]
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "orders");
    }


    add = (item) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            command: "add_known_actions",
            payload: {
                bid: currentComplaint.bid,
                participants: [
                    {
                        role: "SUBJECT",
                        identifier: {
                            code: {
                                code: workspaceGraph.encounterPatient().reference.code.code,
                                system: workspaceGraph.encounterPatient().reference.code.system,
                            },
                            value: workspaceGraph.encounterPatient().reference.value,
                        }
                    }
                ],
                code: {
                    system: item.system,
                    code: item.conceptId,
                    display: item.display || item?.canonicalName,
                },
                type: ["orders"],
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "orders");
    }

    linkOrder = (item, target) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            command: "link_actions",
            payload: {
                reasonForVisitBid: currentComplaint.bid,
                bid: item.bid,
                targetBid: target.bid,
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "orders");
    }

    patch = (item, props, callback) => {

        const {clinicId, encounter, workspaceGraph} = this.bloc.subject.value;

        const payload = {
            ...props,
        }

        cgiApi.updateOrder(clinicId, encounter.id, item.bid, payload)
            .then(value => {
                if(value.data) {
                    workspaceGraph.updateNode(value.data);
                    this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
                }
                callback();
            }, reason => notificationService.httpError(reason));
    }

    updateProgressNotes = (item, props, callback) => {

        const {clinicId, encounter, currentComplaint,} =
            this.bloc.subject.value;

        let newOne = [{note: props.note}]
        if(item.progressNotes) {
            newOne = newOne.concat(item.progressNotes);
        }
        const payload = {
            command: "update_order",
            payload: {
                bid: item.bid,
                progressNotes: newOne,
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "orders", callback);
    }

    update = (item, status) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            status: status
        }

        cgiApi.updateOrder(clinicId, encounter.id, item.bid, payload)
            .then(value => {
                if(value.data) {
                    workspaceGraph.updateNode(value.data);
                    this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
                }
            }, reason => notificationService.httpError(reason));
    }

    delete = (order) => {

        const {clinicId, encounter, workspaceGraph,} =
            this.bloc.subject.value;

        cgiApi.deleteEntity(clinicId, encounter.id, "orders", order.bid)
            .then(value => {
                workspaceGraph.removeNode(order);
                this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
            }, reason => notificationService.httpError(reason));
    }
}

import React from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography";
import AppointmentContext from "./context";
import {appointmentEventService} from "./service/appointment.event.service";
import CustomerCard from "./components/CustomerCard";
import {FormattedMessage} from "react-intl";
import Alert from "@material-ui/lab/Alert";
import {compareAsc, format, parse} from "date-fns";
import {AppointmentBlocEvent} from "./appointment.bloc";
import {notificationService} from "../../../../utils/notification";
import {dateUtil} from "../../../../utils/date";
import DecodedComponent from "../../../shared/DecodedComponent";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",

    },
    cards: {
        display: "flex",
        flexWrap: "wrap",
    },
    fill: {
        flex: "1 1 auto",
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        textAlign: "end",
    },
    dialogButton: {
        marginRight: '12px',
    },
});


class CustomerMatch extends DecodedComponent {


    constructor(props, context) {
        super(props, context);

        this.bloc = props.context.bloc;

        this.state = {
            selectedCustomer: undefined,
            appointments: [],
            localLoading: false,
            dialogOpen: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleState = (e) => {

        this.setState({
            appointments: e.appointments,
            dialogOpen: e.appointments && e.appointments.length > 0,
        });
    }

    __handleEvent = (e) => {

        const { type, data } = e;
        let newProps = {};

        switch (type) {
            case AppointmentBlocEvent.USER_APPOINTMENT_CURRENT_NOT_EXIST:
                this.__continue();
                break;
            case AppointmentBlocEvent.USER_APPOINTMENT_LOADING:
                newProps.localLoading = true;
                break;
            case AppointmentBlocEvent.USER_APPOINTMENT_LOADED:
                newProps.localLoading = false;
                break;
            case AppointmentBlocEvent.USER_APPOINTMENT_LOADING_ERROR:
                newProps.localLoading = false;
                notificationService.error("Error checking for patient's existing appointments. Error - " + data.error);
                break;
        }

        this.setState({
            ...newProps,
        });
    }

    __continue = () => {

        let { candidatePatients } = this.props.context;
        const { selectedCustomer } = this.state;

        let props = {};

        if(selectedCustomer) {

            const selectedCandidate = candidatePatients.filter(candidatePatient => candidatePatient.id === selectedCustomer);
            if(selectedCandidate.length > 0) {
                props.candidate = selectedCandidate[0];
            }
        }

        appointmentEventService.update("REGISTRATION_SUBMIT_CUSTOMER_MATCH", {
            ...props,
        });
    }

    __selectCustomer = (person) => {

        const { selectedCustomer } = this.state;

        if(selectedCustomer === person) {

            this.setState({
                selectedCustomer: undefined,
            });
        } else {

            this.setState({
                selectedCustomer: person,
            });
        }
    };

    __handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
        });
    }

    __checkExistingAppointment = () => {
        let {selectedCustomer} = this.state;
        let { form } = this.props.context;

        const searchDate = format(form.appointmentDate, 'yyyy-MM-dd')

        if(selectedCustomer)
            this.bloc.checkExistingAppointments(selectedCustomer, searchDate);
        else
            this.__continue();

    }

    __renderDialogBox = () => {
        let { classes } = this.props;
        let { dialogOpen, appointments } = this.state;

        let appointment = appointments.sort((appointment1, appointment2) => {
            const date1 = dateUtil.parseDate(appointment1.slot.start);
            const date2 = dateUtil.parseDate(appointment2.slot.start);
            return compareAsc(date1, date2);
        })[0];
        let appointmentType = (appointment.type === "IN_PERSON_WALK_IN" || appointment.type === "IN_PERSON") ?
            "In person" : "Virtual";

        return (
            <Dialog
                maxWidth={"md"}
                open={dialogOpen}
                onClose={this.__handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4" component={"div"}>
                        <FormattedMessage
                            id={"customer.match.dialog.title"}
                            defaultMessage={"Existing Reservation"}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    <Typography variant="body2" component={"div"}>
                        <FormattedMessage
                            id={"customer.match.dialog.content1"}
                            defaultMessage={"This patient already has an appointment scheduled for the same day."}
                        />
                    </Typography>

                    <br/>
                    <Typography variant="body2" component={"div"}>
                        Time:  {format(parse(appointment.slot.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date()), "PPpp")}
                    </Typography>
                    <Typography variant="body2" component={"div"}>
                        Reason: {appointment.note}
                    </Typography>
                    <Typography variant="body2" component={"div"}>
                        Type: {appointmentType}
                    </Typography>
                    <br/>

                    <Typography variant="body2" component={"div"}>
                        <FormattedMessage
                            id={"customer.match.dialog.content2"}
                            defaultMessage={"If you continue, the patient will have multiple appointments scheduled on the same day."}
                        />
                    </Typography>
                    <Typography variant="body2" component={"div"}>
                        <FormattedMessage
                            id={"customer.match.dialog.content3"}
                            defaultMessage={"If you wish to change this booking, please go back and change the information."}
                        />
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.dialogButton}
                        onClick={this.__handleDialogClose}
                        disabled={this.__isLoading()}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.__continue}
                        variant={"contained"}
                        color="primary"
                        disabled={this.__isLoading()}
                    >
                        {this.__isLoading()? <CircularProgress size="1.5em" /> : "Continue"}
                    </Button>

                </DialogActions>
            </Dialog>
        )
    }

    __isLoading = () => {
        let { loading } = this.props.context;
        let { localLoading } = this.state;

        return loading || localLoading;
    }
    render() {
        let { classes } = this.props;
        let { form, candidatePatients, loading } = this.props.context;
        let { selectedCustomer, dialogOpen } = this.state;

        return (
            <div className={classes.root}>

                {dialogOpen && this.__renderDialogBox()}

                <Typography variant={"h5"}>
                    <FormattedMessage id={"customer.match.title"} defaultMessage={"Potential matches"} />
                </Typography>

                <Alert severity="info">
                    <FormattedMessage id={"customer.match.result"} defaultMessage={"Please validate if this is a returning patient."} />
                </Alert>

                <div className={classes.cards}>
                    { candidatePatients.map(person => <CustomerCard person={person} selectedPerson={selectedCustomer} selectCustomer={this.__selectCustomer} />) }
                </div>

                <div className={classes.fill}/>

                <div className={classes.button}>
                    <Button
                        id={"customerMatchSubmitBtn"}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={this.__isLoading()}
                        onClick={this.__checkExistingAppointment}
                    >
                        {this.__isLoading() ? <CircularProgress size="1.5em"/> : "Continue"}
                    </Button>
                </div>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<CustomerMatch context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

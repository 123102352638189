import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography";
import AppointmentContext from "./context";
import {FormattedMessage} from "react-intl";


const styles = theme => ({
    root: {
        flex: "1",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    },
    fill: {
        flex: "1 1 auto",
    },
    focus: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "142px",
        lineHeight: "166px",
        textAlign: "center",
        color: "#20516A",
    },
    textItem: {
        flex: "1",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    blue: {
        color: theme.palette.primary.main,
    },
    amount: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "5.6em",
        lineHeight: "1.214",
        color: "#0F0F0F",
        marginBottom: "0.44643em",
    },
    confirmation: {
        width: "80%",
        lineHeight: "1.75",
        color: "#575757",
        marginBottom: "1.875em",
        paddingTop: "0.5em",
        padding: "3em",
    },
    button: {
        width: "100%",
        padding: "1em",
        textAlign: "right",
    }
});


class Success extends Component {


    constructor(props, context) {
        super(props, context);

        const now = new Date();
        let seconds = -1;
        let minutes = -1;

        if(props.scheduleResult.start) {
            seconds = (new Date(props.scheduleResult.start) - now) / 1000;
            minutes = seconds / 60;
        }

        const displayTime = this.__renderTime(minutes);

        this.state = {
            displayTime: displayTime,
        };
    }

    __renderTime = (minutes) => {

        const displayMinutes = parseInt(minutes % 60);
        const displayHours = parseInt(minutes / 60);

        let display = "";

        if(displayHours > 0) {
            display = `in about ${displayHours}`
            if(displayHours > 1) {
                display += ` hours`
            } else {
                display += ` hour`
            }
        }

        if(displayMinutes > 0) {
            display += (display.length === 0 ? "in about " : " " ) + `${displayMinutes}`
            if(displayMinutes > 1) {
                display += ` minutes`
            } else {
                display += ` minute`
            }
        } else {
            display = "soon"
        }

        return display;
    }

    render() {
        let { displayTime } = this.state;
        let { classes, scheduleResult } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.textItem}>

                    <Typography variant="h2" component={"div"} className={classes.blue}>
                        <FormattedMessage id={"appointment.schedule.success"} defaultMessage={"Success."} />
                    </Typography>
                    <br />
                    { scheduleResult.placeInQueue > 0 ? <>
                    <Typography variant={"h1"} className={classes.focus}>
                        {scheduleResult.placeInQueue}
                    </Typography>

                        <Typography variant="h2" component={"div"}>
                        <FormattedMessage id={"general.in.the.queue"} defaultMessage={"in the queue"} />
                        </Typography>
                    </> :
                        <>
                            <Typography variant="h2" component={"div"}>
                            <FormattedMessage id={"appointment.schedule.success.alt"} defaultMessage={"Your appointment has been scheduled."} />
                        </Typography>
                        </>
                    }

                    { scheduleResult.start &&
                    <Typography variant="h2" component={"div"}>
                        <FormattedMessage id={"general.doctor.will.see.you.in"} defaultMessage={"A provider will see you "} /> <span className={classes.blue}>{ displayTime }</span>
                    </Typography>
                    }

                    <br />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<Success context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

import React, { useMemo, useCallback, useState } from "react";
import ReactDOM from "react-dom";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
    createEditor,
    Transforms,
    Descendant,
    Element as SlateElement,
} from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import {withStyles} from "@material-ui/core";

const styles = {
    intentionChips: {
        marginTop: 8,
        marginBottom: 8,
        flex: 1,
        justifyContent: "flex-start",
        width: "100%",
    },

    SectionHeader2Text: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
    },
    sectionHeader: {
        color: "#777777",
        textTransform: "uppercase",
    },
    phyEx: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: 12,
    },
    chips: {
        backgroundColor: "transparent",
        border: "1px solid #777777",
        borderRadius: 22,
        height: 25,
    },
    outlineChip: {
        color: "#CDE0E4",
    },
    label: {
        textDecoration: "line-through",
    },
};

const withHtml = (bloc, editor) => {
    const { insertData, isInline, isVoid } = editor

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const html = data.getData('text/html')

        if (html) {
            const fragment = bloc.deserializeHtmlString(html)
            Transforms.insertFragment(editor, fragment)
            return
        }

        insertData(data)
    }

    return editor
}


const RichEditor2 = ({onChange, bloc, section, content}) => {
    const editor = useMemo(
        () => withHtml(bloc, withReact(withHistory(createEditor()))),
        []
    )
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const [items, setItems] = useState(content);

    return (
        <Slate editor={editor} value={items} onChange={(content) => {
            setItems(content)
            onChange(section, content)
        }}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
            />
        </Slate>
    );
};

const RichEditor = ({onChange, bloc, section, content}) => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const renderElement = useCallback(props => <SimpleElement {...props} />, [])
    const [items, setItems] = useState(content);
    return (
        <Slate editor={editor} value={items} onChange={(content) => {
            setItems(content)
            onChange(section, content)
        }}>
            <Editable
                renderElement={renderElement}
            />
        </Slate>
    );
};

const Element = props => {
    const { attributes, children, element } = props

    switch (element.type) {
        case 'quote':
        case 'quote-block':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'code':
            return (
                <pre>
          <code {...attributes}>{children}</code>
        </pre>
            )
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>
        case 'heading-four':
            return <h4 {...attributes}>{children}</h4>
        case 'heading-five':
            return <h5 {...attributes}>{children}</h5>
        case 'heading-six':
            return <h6 {...attributes}>{children}</h6>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'link':
            return (
                <a href={element.url} {...attributes}>
                    {children}
                </a>
            )
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.strikethrough) {
        children = <del>{children}</del>
    }

    return <span {...attributes}>{children}</span>
}

const SimpleElement = props => {
    const { attributes, children } = props

    return <p {...attributes}>{children}</p>
}

const SimpleLeaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.strikethrough) {
        children = <del>{children}</del>
    }

    return <span {...attributes}>{children}</span>
}

export default withStyles(styles)(RichEditor);

import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import Delete from "../../../../../../assets/delete.svg";
import DownLoad from "../../../../../../assets/download.svg";

const styles = () => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    },
    keyWrp: {
        fontSize: 15,
        lineHeight: "18px",
    },
    item: {
        display: "flex",
        justifyContent: "end",
        marginTop: "2em",
    },
});

class DHFormTitle extends Component {

    render() {
        let {classes, label, children} = this.props;

        return (
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6} style={{alignSelf: "center"}}>
                    <Typography className={classes.title}>
                        {label}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.item}>
                    {children}
                </Grid>

            </Grid>
        )
    }
}

export default withStyles(styles)(DHFormTitle);
import React, { Component } from 'react';
import {DHCalendar, DHDivider, DHFormInput} from "../../../../Common";
import DHFormRow from "../../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {IconButton, Typography} from "@material-ui/core";
import {dateUtil} from "../../../../../util/date";
import DHDropdown from "../../../../Common/DHDropdown";
import {patientChartUtils} from "../../../../../util/PatientChartUtils";
import DHFormTitle from "../../../../Common/DHFormTitle";
import {DeleteOutline} from "@material-ui/icons";
import FormComponent from "../Common/FormComponent";
import QuickSelection from "../QuickSelection";


const styles = theme => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    }
})

const initState = {
    loading: false
}

class ImmunizationForm extends FormComponent {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    render() {

        let { classes, value } = this.props

        return (
            <React.Fragment>
                <DHFormTitle label={value.code.display}>
                    {(value.recorder || value.recordedDate) && patientChartUtils.renderTitleRecordText(value)}
                    <IconButton onClick={this.__delete}>
                        <DeleteOutline />
                    </IconButton>
                </DHFormTitle>

                <DHFormRow label={"Occurrence"}>
                    <DHFormInput name={"occurrence"} size={"large"} value={value.occurrence?.onset} onChange={this.__updateOnsetText}/>
                </DHFormRow>
                <DHFormRow label={""}>
                    <QuickSelection target={"occurrence"} onClick={this.__populateSuggestion}></QuickSelection>
                </DHFormRow>
                {/*<DHFormRow label={""}>*/}
                {/*    <div style={{display: "flex", alignItems: "center"}}>*/}
                {/*        <div>*/}
                {/*            <DHFormInput size={"large"} disabled={true}/>*/}
                {/*        </div>*/}
                {/*        <div style={{marginLeft: 24}}>*/}
                {/*            <DHDropdown size={"large"} options={[]} disabled={true}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Administered via"}>*/}
                {/*    <div style={{ display: "flex", alignItems: "center" }}>*/}
                {/*        <div style={{ marginRight: 24 }}>*/}
                {/*            <DHDropdown size={"large"} options={[]} disabled={true}/>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <Typography>route to</Typography>*/}
                {/*        </div>*/}
                {/*        <div style={{ marginLeft: 24 }}>*/}
                {/*            <DHFormInput size={"large"} disabled={true}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Administered by"}>*/}
                {/*    <DHFormInput value={value.performer} disabled={true}/>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Lot #"}>*/}
                {/*    <DHFormInput value={value.lotNumber} disabled={true}/>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Manufactured"}>*/}
                {/*    <DHFormInput value={value.manufacturer} disabled={true}/>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Exp."}>*/}
                {/*    <DHCalendar defaultValue={dateUtil.parseDate(value.expirationDate)} disabled={true}/>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"VIS Given"}>*/}
                {/*    <DHCalendar disabled={true}/>*/}
                {/*</DHFormRow>*/}
                {/*<DHFormRow label={"Date on VIS"}>*/}
                {/*    <DHCalendar disabled={true}/>*/}
                {/*</DHFormRow>*/}

                <DHDivider/>

            </React.Fragment>
        )

    }
}

export default withStyles(styles)(ImmunizationForm);

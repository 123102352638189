import { DirectedGraph } from "graphology";

export class Relationship {
  static EVIDENCE_OF = "evidence_of";
  static OBSERVED_ON = "observed_on";
  static BASED_ON = "based_on";
}

export class RecommendationGraph {

  internal = new DirectedGraph();

  handle = (update) => {
    if(update.action === "REPLACE") {
      this.replace(update.graph);
    }
  }

  replace(graph) {
    this.internal.clear();
    if (graph) {
      const now = new Date();

      if (graph.vertices) {
        graph.vertices.forEach((vertex) => {
          this.internal.addNode(vertex.bid, vertex);
        });
      }
      if (graph.edges) {
        graph.edges.forEach((edge) => {
          if (
              !this.internal.hasEdge(edge.from, edge.to) &&
              this.internal.hasNode(edge.from) &&
              this.internal.hasNode(edge.to)
          ) {
            this.internal.addEdgeWithKey(edge.bid, edge.from, edge.to, edge);
          }
        });
      }
    }
  }

  updateNode = (node) => {
    if (node?.bid) {
      this.internal.replaceNodeAttributes(node.bid, node);
    }
  };

  removeNode = (node) => {
    if (node?.bid) {
      this.internal.dropNode(node.bid);
    }
  };

  static instanceOfRecommendationGraph() {
    let graph = new RecommendationGraph();
    return graph;
  }
}

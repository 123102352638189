import {Box, Drawer, Typography} from "@material-ui/core";
import {KeyboardArrowLeft} from "@material-ui/icons";
import React from "react";

import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";
import PhysicalExam from "./PhysicalExam";
import DecodedComponent from "../../../../../../shared/DecodedComponent";

const styles = theme => ({
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  drawer: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "98%",
    marginLeft: "2%",
    padding: "20px 24px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "18px",
  },

  iconColorAndSize: {
    color: "#C4C4C4",
    fontSize: 32,
    marginLeft: 8,
    marginRight: 8,
  },
  heading: {
    fontWeight: 400,
    fontSize: 20,
  },
  flexLayout: {
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    overflow: "auto",
  },
  items: {
    marginTop: 16,
  },
  contentInfoSec: {
    backgroundColor: "#F9F9F9",
    marginBottom: 12,
    borderRadius: 16,
  },
});


class  PhysicalExamModal extends DecodedComponent {

  constructor(props) {
    super(props);
    this.bloc = props.context.bloc;
    this.state = { initialised: false, physicalExam: { open: false }};
  }

  render() {

    const { physicalExam } = this.state;
    const { classes } = this.props;

    return (
        <Drawer
            transitionDuration={0}
            anchor={"left"}
            variant={"temporary"}
            open={physicalExam?.open}
            onClose={(e) => { this.bloc.togglePhysicalExam() }}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              disableEnforceFocus: true,
              hideBackdrop: false,
              onClose: (e, reason) => {
                if(['escapeKeyDown','backdropClick'].includes(reason)) {
                  e.stopPropagation();
                }
              }
            }}
        >
          <Box className={classes.header}>
            <div className={classes.inline}>
              <KeyboardArrowLeft
                  className={classes.iconColorAndSize}
                  onClick={this.bloc.togglePhysicalExam}
              />
              <Typography className={classes.heading}>Physical Examination</Typography>
            </div>
          </Box>
          { physicalExam?.open && <PhysicalExam parentBloc={this.bloc} /> }
        </Drawer>
    );
  }
}

export default withStyles(styles)(props => (
      <ClinicalGraphInterfaceContext.Consumer>
        {value => {
          return (<PhysicalExamModal context={value} {...props} />);
        }
        }
      </ClinicalGraphInterfaceContext.Consumer>
  ));

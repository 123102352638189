import * as rxjs from "rxjs";
import {BlocEvent as GlobalBlocEvent} from "../../../../../bloc";
import {uuidUtil} from "../../../../../../../../../utils/uuidUtil";

export class CommonBloc {

    constructor(cgiBloc, name, _class, semanticType, recommendationFilter = []) {

        this.name = name;
        this._class = _class;
        this.semanticType = semanticType;
        this.recommendationFilter = recommendationFilter;

        this.subject = new rxjs.BehaviorSubject({
            initialised: false,
            loading: false,
            clinicId: null,
            encounterId: null,
            updated : [],
            resources : [],
            recommendations : [],
        });

        this.events = new rxjs.Subject();

        this.parent = cgiBloc;

        cgiBloc.subscribeToState(this.__listenToCgiBloc);
        cgiBloc.subscribeToEvents(this.__listenToCgiEvent);

    }

    refresh() {
        this.__updateSubject({
            initialised: false,
            loading: false,
            updated : [],
            resources : [],});
        this.initialise();
    }

    updateRecommendation = (recommendations) => {

        const applied = recommendations.filter(recommendation => this.recommendationFilter.includes(recommendation.category));
        this.__updateSubject({
            recommendations: applied,
        });
    }

    add = (item, decorator) => {
        let { updated } = this.subject.value;
        let semanticType = this.semanticType;
        if(item.semanticTypes?.length > 0) {
            semanticType = item.semanticTypes[0].tui
        } else if(item.semanticType) {
            semanticType = item.semanticType;
        }

        let value = { id: uuidUtil.next(), class: this._class, semanticType: semanticType, code: { display: item.display || item.code.display, code: item.conceptId || item.code.code, system: item.system || item.code.system}};

        if(decorator) {
            value = decorator(value);
        }

        updated.unshift(value);
        this.__updateSubject({ updated })
        this.events.next({
            type: CommonBlocEvent.NEW_ITEM_ADDED,
            data: { },
        });
    }

    addSilent = (item, decorator) => {
        let { updated } = this.subject.value;
        let semanticType = this.semanticType;
        if(item.semanticTypes?.length > 0) {
            semanticType = item.semanticTypes[0].tui
        } else if(item.semanticType) {
            semanticType = item.semanticType;
        }

        let value = { id: uuidUtil.next(), class: this._class, semanticType: semanticType, code: { display: item.display || item.code.display, code: item.conceptId || item.code.code, system: item.system || item.code.system}};

        if(decorator) {
            value = decorator(value);
        }

        updated.unshift(value);
        this.__updateSubject({ updated })
    }

    addRecommendation = (recommendation) => {
        let { updated } = this.subject.value;
        updated.unshift(recommendation);
        this.__updateSubject({ updated })
        this.events.next({
            type: CommonBlocEvent.NEW_ITEM_ADDED,
            data: {},
        });
    }

    updateText = (item, property, value) => {
        let { resources, updated } = this.subject.value;
        item.dirty = 1;
        item[property] = value;
        this.__updateSubject({ resources, updated, });
    }

    updateOnsetText = (item, property, value) => {
        let { resources, updated } = this.subject.value;
        item.dirty = 1;
        item[property] = { onset: value };
        this.__updateSubject({ resources, updated, });
    }

    delete = (item) => {
        let { resources, updated } = this.subject.value;
        item.dirty = -1;
        this.__updateSubject({ resources, updated, });
    }

    openChart = () => {

        let { encounter } = this.subject.value;

        encounter.links && encounter.links.forEach((_link) => {
            if(_link.type === "chart") {
                window.open(_link.details.deeplink, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            }
        });
    };

    __listenToCgiBloc = (props) => {

        let values = {
            clinicId: props.clinicId,
            encounterId: props.encounterId? props.encounterId : props.encounter.id,
            encounter: props.encounter
        }

        this.__updateSubject(values);
    }

    __listenToCgiEvent = (event) => {

        let {type} = event;

        switch (type) {
            case GlobalBlocEvent.RELOAD_TRIGGERED:
                this.getMedications();
        }
    }


    __updateSubject = (props) => {
        this.subject.next({
            ...this.subject.value,
            ...props,
        });
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);
}

export class CommonBlocEvent {

    static LOADING = "LOADING";
    static LOAD_SUCCESS = "LOAD_SUCCESS";
    static LOAD_ERROR = "LOAD_ERROR";

    static NEW_ITEM_ADDED = "NEW_ITEM_ADDED";
}

import {BehaviorSubject, Subject} from "rxjs";
import {appointmentApi} from "../../../../utils/services/appointments.api";
import {organisationsApi} from "../../../../utils/services/organisations.api";
import {SchedulingEvent} from "../service/scheduling.event.service";
import {notificationService} from "../../../../utils/notification";
import {scheduleApi} from "../../../../utils/services/schedule.api";
import {appointmentEventService} from "./service/appointment.event.service";
import {dateUtil} from "../../../../utils/date";

export class AppointmentBloc {

    constructor(location) {
        this.subject = new BehaviorSubject({
            location: location,
            appointments: [],
            staff: [],
        });

        this.events = new Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func)

    subscribeToState = (func) => this.subject.subscribe(func)

    checkExistingAppointments = (customerId, selectedDate) => {

        this.events.next({type: AppointmentBlocEvent.USER_APPOINTMENT_LOADING})

        appointmentApi.getAppointmentForDate(customerId, selectedDate)
            .then(result => {

                this.events.next({type: AppointmentBlocEvent.USER_APPOINTMENT_LOADED})

                if (result.data.items.length > 0) {
                    this.__updateSubject({appointments: result.data.items});
                } else {
                    this.events.next({type: AppointmentBlocEvent.USER_APPOINTMENT_CURRENT_NOT_EXIST})
                }

            })
            .catch(error => {

                this.events.next({
                    type: AppointmentBlocEvent.USER_APPOINTMENT_LOADING_ERROR,
                    data: {
                        error: error,
                    },
                })
            })
    }

    loadServiceProvidersStaff = (service) => {

        if(this.subject.value.loading) return;

        this.__updateSubject({ staff: [], loading: true, });

        this.events.next({
            type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADED,
            data: { staff: [], },
        });

        organisationsApi.listServiceProvidersPeople(this.subject.value.location, service)
            .then(value => {

                this.__updateSubject({ staff: value.data.items })

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADED,
                    data: {
                        staff: value.data.items,
                        loading: false,
                    },
                });
            }, reason => {

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADING_ERROR,
                    data: {
                        reason: reason,
                        loading: false,
                    },
                });

                notificationService.error(`Error reading the staff permissions to retrieve service catalog due to ${reason}.`);
            });
    }

    __updateSubject = (newProps) => {

        const _current = this.subject.value;

        this.subject.next({
            ..._current,
            ...newProps,
        })
    }



    changeAppointmentType = (serviceId, selectionTarget, service) => {

        if(this.subject.value.loading) return;

        this.__updateSubject({ summary: {}, staff: [], slots: [], targetProvider: undefined, loading: true, });

        const serviceCode = `organisation:service-code:${service.code}`;
        const channel = service.deliveryOptions[0].code;

        organisationsApi.listServiceProvidersPeople(this.subject.value.location, serviceCode)
            .then(value => {

                let props = {staff: value.data.items,};
                const staff = value.data.items;

                if(staff.length === 0) {
                    this.__lookupScheduleSummary(service.id, staff);
                    this.reloadSelectedSchedule(new Date(), service.id);
                } else {
                    props.loading = false;
                }

                this.__updateSubject({ ...props });

            }, reason => {

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADING_ERROR,
                    data: {
                        reason: reason,
                        loading: false,
                    },
                });

                notificationService.error(`Error reading the staff permissions to retrieve service catalog due to ${reason}.`);
            });


        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: selectionTarget,
            value: service.id,
            other: {
                appointmentDate: new Date(),
                appointmentStartTime: "",
                appointmentStartTimeSpecific: "",
                channel: channel,
                appointmentProvider: "",
            },
        });

    }

    changeProvider = (provider, form) => {

        this.__updateSubject({ targetProvider: provider, summary: {}, slots: [] });
        this.__lookupScheduleSummary(form.appointmentType, provider && [ provider ])
        this.reloadSelectedSchedule(new Date(), form.appointmentType);
    }

    __lookupScheduleSummary = (service, staff) => {

        scheduleApi.scheduleSummary(this.subject.value.location, service, staff)
            .then(value => {

                const summary = value.data;
                this.__updateSubject({ summary: summary });

            }).catch(reason => {
            notificationService.error("Error loading the schedule summary.");
        }).finally(() => this.__updateSubject({ loading: false, }));
    }

    reloadSelectedSchedule = (date, targetType) => {

        const clinicId = this.subject.value.location;
        const targetProvider = this.subject.value.targetProvider;

        this.__updateSubject({ loading: true, slots: []});

        scheduleApi.scheduleSearch(clinicId, targetType, date, targetProvider && [targetProvider])
            .then(value => {

                let slots = [];

                let now = new Date();
                let nowDate = new Date();
                nowDate.setTime(nowDate.getTime() - (60*60*1000));
                let selectedDate = dateUtil.formatIsoDate(date);

                if(value.data.results.length > 0) {
                    slots = value.data.results[0]
                        .intervals
                        .filter(_item => {

                            if(_item.capacity - _item.current > 0 && nowDate < dateUtil.toDate(_item.start) && now > dateUtil.toDate(_item.start)) {
                                _item.availableSlots = _item.availableSlots.filter(_slot => parseInt(_slot.start.split(':')[1]) > now.getMinutes() - 5)
                                _item.capacity = _item.availableSlots.length;
                            }

                            return nowDate < dateUtil.toDate(_item.start) &&
                                selectedDate === dateUtil.formatIsoDate(dateUtil.toDate(_item.start)) &&
                                (_item.capacity - _item.current) > 0;
                        })
                        .map(_item => {

                            const start = dateUtil.toDate(_item.start)

                            return {
                                date: start,
                                availableSlots: _item.availableSlots,
                                start: dateUtil.formatTime(start),
                                end: dateUtil.formatTimeEnd(start),
                            };
                        });
                }

                this.__updateSubject({ slots: slots })
                this.events.next({ type: AppointmentBlocEvent.SLOTS_LOADED, data: slots });
            }, reason => {

                this.__updateSubject({ slots: [] })
                this.events.next({ type: AppointmentBlocEvent.SLOTS_LOADED, data: [] });
                notificationService.error(`Error loading the appointment slots for ${date} due to - ${reason}`);
            }).finally(() => this.__updateSubject({ loading: false, }));
    }
}


export class AppointmentBlocEvent {

    static SLOTS_LOADED = "SLOTS_LOADED";

    static SERVICE_STAFF_LOADING = "SERVICE_STAFF_LOADING";

    static USER_APPOINTMENT_CURRENT_EXIST = "USER_APPOINTMENT_CURRENT_EXIST";
    static USER_APPOINTMENT_CURRENT_NOT_EXIST = "USER_APPOINTMENT_CURRENT_NOT_EXIST";

    static USER_APPOINTMENT_LOADING = "USER_APPOINTMENT_LOADING";
    static USER_APPOINTMENT_LOADED = "USER_APPOINTMENT_LOADED";
    static USER_APPOINTMENT_LOADING_ERROR = "USER_APPOINTMENT_LOADING_ERROR";
}

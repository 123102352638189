import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Typography,} from "@material-ui/core";
import {customersApi} from "../../../../../../utils/services/customers.api";
import BaseCard from "./BaseCard";
import {dateUtil} from "../../../../../../utils/date";


const styles = theme => ({
    root: {
    },
    body: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
    },
    fill: {
        flex: "1 1 auto",
    },
    subtitle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        display: "flex",
        textTransform: "uppercase",
        color: "#7F8993",
    },
    timestamp: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#3D5369",
        opacity: "0.8",
    },
    actions: {
        margin: "auto",
    },
});

class PatientStatusCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
        };
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);
    }

    componentWillUnmount() {
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    render() {

        let { person, processing } = this.state;
        let { classes, item } = this.props;

        const body = this.__renderBody(item, processing, classes);

        return (
            <div className={classes.root}>
                <BaseCard
                    person={person}
                    item={item}
                >
                    { body }
                </BaseCard>
            </div>
        );
    }

    __renderBody = (item, processing, classes) => {
        return <>
            <div className={classes.body}>
                <div className={classes.content}>
                    <Typography variant="h4" component="h4" className={classes.subtitle}>{ item.status }</Typography>
                    <Typography variant="body1" component="p" className={classes.timestamp}>{ dateUtil.parseDate(item.slot.start).toLocaleString() }</Typography>
                </div>
                <div className={classes.fill} />
                <div className={classes.actions}>
                    <CircularProgress />
                </div>
            </div>
        </>;
    }
}

export default withStyles(styles)(PatientStatusCard);

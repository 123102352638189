import React, { Component } from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Bloc, RecommendationsBlocEvent,} from "./bloc";
import {DHAccordion, DHChip} from "../../../../Common";
import {Skeleton} from "@material-ui/lab";
import DecodedComponent from "../../../../../../../../shared/DecodedComponent";
import SingleOptionContextMenu from "../../../ContextMenu/SingleOptionContextMenu";
import {Box, Button, ButtonBase, Chip, Grid, Typography} from "@material-ui/core";
import {ContextMenu} from "../../../ContextMenu";
import {OntologyConstants} from "../../../../../bloc";
import {AddCircleSharp} from "@material-ui/icons";


const styles = theme => ({
    skeleton: {
        paddingTop: "1em"
    },
    iconStyles: {
        marginRight: 24,
        fontSize: 24,
    },
    inline: {
        display: "inline-flex",
    },
    recommendationSuggestions: {
    },
    recommendationSuggestion: {
        margin: "6px",
    }
})

const initState = {
    selected: [],
}

class RecommendationsPanel extends Component {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    componentDidMount() {
    }



    render() {

        let { initialised, loading, context, resources, updated } = this.state;
        let { classes, recommendations, items } = this.props;

        const actual = recommendations ? recommendations
            .filter(recommendation => {
                for(let i = 0; i < items.length; i++) {
                    if(items[i].code.code === recommendation.code.code) {
                        return false;
                    }
                }
                return true;
            }) : [];

        if(actual.length > 0) {

            return (
                <Box className={classes.root}>
                    <Typography variant={"subtitle1"}>Recommendations</Typography>
                    <Box className={classes.recommendationSuggestions}>
                        { actual
                            .map((recommendation, index) => <>
                                <DHChip
                                    drawerItem={false}
                                    label={
                                        recommendation.code.display
                                    }
                                    index={index}
                                    colortiles={[
                                        //this.bloc.differentialColour().getColour(currentComplaint.bid, item.bid),
                                    ]}
                                    onClick={() => {this.props.onClick(recommendation)}}
                                    style={{
                                        minWidth: 100,
                                        marginRight: 12,
                                        backgroundColor: "#fff",
                                        color: "#333333",
                                    }}
                                />
                            </>) }
                    </Box>
                </Box>
            )
        } else {
            return <></>;
        }
    }
}

export default withStyles(styles)(RecommendationsPanel);


import * as rxjs from 'rxjs';
import {accountApi} from "../../utils/services/account.api";
import {notificationService} from "../../utils/notification";
import {GlobalEvent, globalEventService} from "./service/global.event.service";
import {organisationsApi} from "../../utils/services/organisations.api";
import {checkAllowed} from "../../utils/auth/Allowed";
import rules from "../../utils/auth/rules";
import {AccountUtils} from "./service/accountUtils";

export class GlobalBloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            organisations: undefined,
            permissions: undefined,
            entry: undefined,
            location: undefined,
        });

        this.events = new rxjs.Subject();

        this.__account = new AccountUtils(this);

        globalEventService.registerStateCallback(this.__listenToGlobal);
    }

    __listenToGlobal = (event) => {

        const {type, data} = event;

        switch (type) {
            case GlobalEvent.LOCATION_UPDATED:
                this.events.next({ event: IndexBlocEvent.LOCATION_UPDATED, data: { location: data.newLocation }});

                const current = this.subject.value;

                const organisation = this.subject.value.organisations.filter(_org => _org.id === data.newLocation)[0];

                this.subject.next({
                    ...current,
                    location: data.newLocation,
                    organisation: organisation,
                });
                break;
        }
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    setEntry = (point) => {

        const current = this.subject.value;

        this.subject.next({
            ...current,
            entry: point,
        });
    }

    reloadPermissions = () => {

        const { organisation } = this.subject.value;

        Promise.all([accountApi.getPermissions(), organisationsApi.list()])
            .then(value => {

                const permissions = value[0].data;
                const organisations = value[1].data.items;

                const current = this.subject.value;

                let targetOrganisation = organisation;

                const autoSetLocation = permissions.audience.length === 1 && !current.location;

                const location = autoSetLocation ? permissions.audience[0].partyIdentifier.value : current.location;

                if(autoSetLocation && !targetOrganisation) {
                    targetOrganisation = organisations.filter(_org => _org.id === location)[0];
                }


                this.events.next({ event: IndexBlocEvent.PERMISSIONS_LOADED, data: { entry: current.entry, roles: permissions.roles, audience: permissions.audience, location: location}});

                this.subject.next({
                    ...current,
                    permissions: permissions,
                    organisation: targetOrganisation,
                    organisations: organisations,
                    location: location,
                });
            }, reason => {
                if(reason.response && (reason.response.status === 404 || reason.response.status === 401)) {

                    this.events.next({ event: IndexBlocEvent.PERMISSIONS_NOT_FOUND, data: { } });
                } else if(reason.response) {

                    notificationService.update({ error: "Unable to load roles due to '" + reason.response.data.message + "'. Please refresh or if the problem persists contact support. " })
                    this.events.next({ event: IndexBlocEvent.PERMISSIONS_LOADING_ERROR });
                } else {

                    notificationService.update({ error: "Unable to load roles due to '" + reason.message + "'. Please refresh or if the problem persists contact support. " })
                    this.events.next({ event: IndexBlocEvent.PERMISSIONS_LOADING_ERROR });
                }
            });
    }

    checkAllowed(action) {

        const roles = this.subject.value.permissions ? this.subject.value.permissions.roles : [];

        return checkAllowed(rules, roles, action);
    }

    setMe = () => {

        accountApi.me().then(value => {

            const current = this.subject.value;

            this.events.next({ event: IndexBlocEvent.ACCOUNT_LOADED, data: value.data});

            this.loadAccountPreferences();

            this.subject.next({
                ...current,
                profile: value.data,
            });
        }).catch(reason => {

            notificationService.error(`Error retrieving your account - ${reason}.`);
        });
    }

    loadAccountPreferences = () => {

        accountApi.preferences().then(value => {

            const current = this.subject.value;

            this.events.next({ event: IndexBlocEvent.ACCOUNT_PREFERENCES_LOADED, data: value.data});

            this.subject.next({
                ...current,
                preferences: value.data,
            });
        }).catch(reason => {

            notificationService.error(`Error retrieving your account preferences - ${reason}.`);
        });
    }

    entry = () => {
        return this.subject.value.entry;
    }

    getMe = () => {
        return this.subject.value.profile;
    }

    location = () => {

        return this.subject.value.location;
    }

    organisation = () => {

        return this.subject.value.organisation;
    }

    account = () => {
        return this.__account;
    }
}

export class IndexBlocEvent {
    static ACCOUNT_LOADED = "ACCOUNT_LOADED";
    static ACCOUNT_PREFERENCES_LOADED = "ACCOUNT_PREFERENCES_LOADED";

    static PERMISSIONS_LOADED = "PERMISSIONS_LOADED";
    static PERMISSIONS_NOT_FOUND = "PERMISSIONS_NOT_FOUND";
    static PERMISSIONS_LOADING_ERROR = "PERMISSIONS_LOADING_ERROR";

    static LOCATION_UPDATED = GlobalEvent.LOCATION_UPDATED;
}


export const globalBloc = new GlobalBloc();

import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Chip,
  FormControlLabel,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import { DHDivider } from "../Common";
import SalientFeatures from "./SalientFeatures/SalientFeatures";
import PhysicalExamModal from "./PhysicalExam/PhysicalExamModal";
import {
  AddObservationsAndDXContextMenu,
  ContextMenu,
} from "../CGICommon/ContextMenu";
import { OntologyConstants } from "../../bloc";
import { onAddObservation } from "./Observations/";
import Observations from "./Observations/Observations";
import { onAddCondition } from "./DiffrentialDX/DifferentialDX.utils";
import DifferentialDX from "./DiffrentialDX/DifferentialDX";
import CurrentWorkingDX from "./DiffrentialDX/CurrentWorkingDX";
import StrickedDifferentialDX from "./DiffrentialDX/StrickedDiffrentialDX";
import { useCGIContext } from "./CGIContext/CGIContext";
import { isEmpty } from "loadsh";
import {FormattedMessage} from "react-intl";
import EvaluationManagement from "./EvaluationManagement";
import ArrivalAndDisposition from "./ArrivalAndDisposition";
import {ExpandMore} from "@material-ui/icons";
import ActionPanel from "./ActionPanel";

const styles = {
  DifferentialDxSection: {
    flex: 1,
    minWidth: "100%",
    minHeight: "23vh",
    maxHeight: "23vh",
    overflow: "auto",
  },
  SectionHeaderText: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
  },
  SectionHeader2Text: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 50,
  },
  sectionHeader: {
    color: "#777777",
    textTransform: "uppercase",
  },
  phyEx: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginLeft: 12,
  },
  chips: {
    backgroundColor: "#fff",
    borderRadius: 22,
    height: 30,
  },
  reviewChip: {
    backgroundColor: "#fff",
    borderRadius: 22,
    height: 24,
    paddingLeft: 12,
    paddingRight: 12,
  },
  differentialDX: {
    minHeight: 450,
  },
  divider: {
    marginTop: "32px",
  },
  divider1: {
    marginTop: "42px",
    maxWidth: 322,
  },
  workspace: {
    display: "flex",
  },
  workspaceLeft: {
    width: 370,
    minWidth: 370,
    paddingLeft: 18,
    paddingRight: 18,
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  workspaceCenter: {
    width: 32,
    paddingTop: 100,
  },
  workspaceRight: {
    flex: "1",
    paddingLeft: 18,
  },
};

function ClinicalGraph({ classes }) {
  const [selectedDx, setSelectedDx] = useState();
  const {
    context,
    currentSelectedComplaint,
    bloc,
  } = useCGIContext();
  const [isAddDxOpen, setIsAddDxOpen] = useState(false);
  const [isAddObsOpen, setIsAddObsOpen] = useState(false);

  useEffect(() => {
    const subscription = bloc.subscribeToState(_updateState);
    return () => {
      subscription.unsubscribe();
    };
  });

  const evaluationAndManagementEl = useRef(null);
  const arrivalAndDispositionEl = useRef(null);

  function _updateState(data) {
    setSelectedDx(data.selectedDx);
  }
  return (
    <Box
      className={classes.workspace}
      style={{ height: "inherit", maxHeight: "calc(100% - 25px)" }}
    >
      <Box className={classes.workspaceLeft}>
        <div className={classes.SectionHeaderText}>
          <ContextMenu
            isOpen={isAddDxOpen}
            modal
            heading={
            <ButtonBase onClick={() => setIsAddDxOpen(true)}>
              <Typography className={classes.sectionHeader}>
                <FormattedMessage id={"cgi.differential.dx"} defaultMessage={"DIFFERENTIAL DX"} />
              </Typography>
            </ButtonBase>
            }
          >
            <AddObservationsAndDXContextMenu
              bloc={bloc}
              label="Add Condition"
              ontologyConstants={OntologyConstants.CONDITION.semantic}
              vocabulary={OntologyConstants.CONDITION.vocabulary}
              disableFreeText={true}
              onItemPress={(item) => {
                setIsAddDxOpen(false);
                onAddCondition(item, currentSelectedComplaint, context);
              }}
            />
          </ContextMenu>
        </div>
        <CurrentWorkingDX />

        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes.divider}
        >
          <Grid item xs={12} className={classes.DifferentialDxSection}>
            <div>
              <DifferentialDX />
              <DHDivider className={classes.divider1} />
              <StrickedDifferentialDX />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "18px",
              flex: "1",
              overflow: "auto",
              minHeight: "46vh",
              height: "46vh",
              maxHeight: "46vh",
            }}
            ref={evaluationAndManagementEl}
          >
            <EvaluationManagement popperAnchor={evaluationAndManagementEl} bloc={ bloc } />
            <ArrivalAndDisposition popperAnchor={evaluationAndManagementEl} bloc={ bloc } />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.workspaceCenter}>
        <svg
          width="32"
          height="465"
          viewBox="0 0 32 465"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!isEmpty(selectedDx) && (
            <>
              <path
                d={`M16 ${16 + selectedDx.top}C16 ${
                  7.71573 + selectedDx.top
                } 8.28427 ${selectedDx.top} 0 ${selectedDx.top}`}
                stroke="#00D1FF"
              />
              <path
                d="M16 16V16C16 7.71573 23.71573 1 32 1V1"
                stroke="#00D1FF"
              ></path>
              <line x1="16" y1="16" x2="16" y2="445" stroke="#00D1FF"></line>
              <path
                d="M16 445V445C16 452.28427 23.71573 461 32 461V461"
                stroke="#00D1FF"
              ></path>
            </>
          )}
        </svg>
      </Box>
      <Box className={classes.workspaceRight}>
        <div className={classes.SectionHeader2Text}>
          <ContextMenu
            isOpen={isAddObsOpen}
            modal
            heading={
              <ButtonBase onClick={() => setIsAddObsOpen(true)}>
                <Typography className={classes.sectionHeader}>
                  <FormattedMessage id={"cgi.observations.and.findings.title"} defaultMessage={"Observations and Findings"} />
                </Typography>
              </ButtonBase>
            }
          >
            <AddObservationsAndDXContextMenu
                bloc={bloc}
                label="Add Finding"
                ontologyConstants={OntologyConstants.OBSERVATION.semantic}
                vocabulary={OntologyConstants.OBSERVATION.vocabulary}
              onItemPress={async (item) => {
                setIsAddObsOpen(false);
                onAddObservation(item, context);
              }}
            />
          </ContextMenu>
        </div>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ minHeight: "70vh", maxHeight: "70vh" }}
          spacing={2}
        >
          <Grid
            item
            xs={6}
            style={{
              flex: 1,
              minWidth: "100%",
              minHeight: "45vh",
              maxHeight: "45vh",
            }}
          >
            <Observations />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              flex: 1,
              minWidth: "100%",
              minHeight: "20vh",
              maxHeight: "20vh",
            }}
          >
            <div
              style={{
                marginTop: 24,
              }}
            >
              <ActionPanel />
            </div>
            <div
              style={{
                marginTop: 24,
              }}
            >
              <SalientFeatures />
            </div>
          </Grid>
        </Grid>
        <PhysicalExamModal  />
      </Box>
    </Box>
  );
}

export default withStyles(styles)(ClinicalGraph);

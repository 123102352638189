import React, {Component} from "react";

import EncounterContext from "../../context";

import {withStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "0",
    maxHeight: "50vh",
  },

  fill: {
    flex: "1 1 auto",
  },

  summaryContainer: {
    padding: "10px 0 10px 10px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#636366",
    opacity: "0.8",
    overflow: "auto",
  },

  title: {
    paddingBottom: "18px",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "30px",
    textTransform: "capitalize",
    color: "#010101",
    opacity: "0.5",
  },
  saveBtnWrapper: {
    paddingBottom: "24px",
  },
  saveBtn: {
    width: "100%",
    background: "#37B9FD 0% 0% no-repeat padding-box",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
    font: "28px",
    fontWeight: "300",
    letterSpacing: "0",
    padding: "0px",
    paddingLeft: "8px",
    color: "#fff",
    borderRadius: "22px",
    maxHeight: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#20516A",
    },
    "&:focus": {
      backgroundColor: "#20516A",
    },
  },
  saveBtnTxt: {
    paddingLeft: "9px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
  },
  saveBtnIcon: {
    paddingTop: "2px",
    height: "26px",
    width: "26px",
    borderRadius: "22px",
    border: "1px solid #fff",
    boxShadow: "-10px 0px 20px #47567917",
    "& svg": {
      height: "20px",
      width: "20px",
      color: "#fff",
    },
  },
});

class ClinicalSummary extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    let { classes } = this.props;
    let { person, summary, encounter } = this.props.context;

    if (!summary) return <LinearProgress />;

    let complaint = "";

    if (summary.document) {
      complaint = summary.document;
    }

    const encounterIdentifier = encounter.encounter;

    let alert = <></>;

    if (
      !encounterIdentifier ||
      (encounterIdentifier.code === "appointmentId" &&
        encounterIdentifier.value === "tbd")
    ) {
      alert = <p className={classes.alert}>*** No appointment created ***</p>;
    }

    return (
      <div className={classes.root}>
        {summary.status === 404 || summary.id === undefined ? (
          <>
            <div className={classes.summaryContainer}>
              {alert}
              {encounter.service && (
                <p>Encounter type: {encounter.service.description}</p>
              )}
              {person && <p>Language: {person.language || "en"}</p>}
              <p>Reason for visit: '{encounter.note}'</p>
              <p>
                Note: Clinical summary was not generated for this conversation.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={classes.summaryContainer}>
              {encounter.service && (
                <p>Encounter type: {encounter.service.description}</p>
              )}
              {person && <p>Language: {person.language || "en"}</p>}
              {complaint.sections &&
                complaint.sections.map((section, index) => {
                  return (
                    <React.Fragment key={`${JSON.stringify(section)}-${index}`}>
                      {section.content.split("\n").map((value, index) => (
                        <React.Fragment
                          key={`${JSON.stringify(value)}-${index}`}
                        >
                          {value}
                          <br />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <EncounterContext.Consumer>
    {(value) => {
      return <ClinicalSummary context={value} {...props} />;
    }}
  </EncounterContext.Consumer>
));

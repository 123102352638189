import {Box, ButtonBase, CircularProgress, ClickAwayListener, IconButton, Popper, withStyles} from "@material-ui/core";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import React, {useState} from "react";
import {DropZone, SwipeEnabledListItem} from "../../CGICommon";
import {DHChipWithIcon} from "../../Common";
import {OntologyConstants} from "../../../bloc";
import MedicationOrderDetail from "./MedicationOrderDetail";
import GeneralOrderDetail from "./GeneralOrderDetail";
import ItemDetail from "../../Common/ItemDetail";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

const styles = {
    intentionChips: {
        marginTop: 8,
        marginBottom: 8,
        flex: 1,
        justifyContent: "flex-start",
        width: "100%",
    },

    SectionHeader2Text: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
    },
    sectionHeader: {
        color: "#777777",
        textTransform: "uppercase",
    },
    phyEx: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: 12,
    },
    noteNone: {
        color: "#efefef",
    },
    note: {
    },
    chips: {
        backgroundColor: "transparent",
        border: "1px solid #777777",
        borderRadius: 22,
        height: 25,
    },
    outlineChip: {
        color: "#CDE0E4",
    },
    label: {
        textDecoration: "line-through",
        display: "flex",
    },
    fill: {
        flex: "1 1 auto",
    },
};

const Template = ({popperAnchor, bloc, item, classes}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [count, setCount] = React.useState(0);

    const handleClick = (event) => {
        if(anchorEl) {
            setAnchorEl( null )
            setCount(0)
        }  else {
            setAnchorEl(anchorEl ? null : popperAnchor.current);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    function __clickAwayHandler() {
        if(anchorEl) {
            let _count = count + 1;
            if (_count % 2 === 0) {

                setAnchorEl(false)
                setCount(0)
            }
            setCount(_count)
        }
    }
    /*
    function __renderPopover() {

        return  <>
            <span  onClick={handleClick} style={{
                textAlign: "left",
                justifyContent: "flex-start",
                minWidth: "235px",
                lineHeight: "24px",
                cursor: "pointer",
            }}>{item.description || item.code.value}</span>
            <span className={classes.fill}></span>
            <span style={{
                paddingLeft: 12,
            }}><IconButton style={{ padding: 0 }}  onClick={handleClick}><AssignmentOutlinedIcon /></IconButton></span>
            <ItemDetail   id={`detail-${item.bid}`} title={item.class} open={open} onClickAway={__clickAwayHandler} onCloseClick={handleClick} anchorEl={anchorEl}>
                { __renderDetails() }
            </ItemDetail>
        </>
    }
     */

    function __renderPopover() {

        let semanticType = "Order";
        if(item?.semanticTypes) {
            for(let idx = 0; idx < item?.semanticTypes.length; idx = idx + 1) {
                if(OntologyConstants.MEDICATION_ORDERS_REQUEST.semantic.includes(item.semanticTypes[idx])) {
                    semanticType = "Medication";
                    break;
                }
            }
        }

        let iconClass = classes.noteNone;
        if(item.progressNotes?.length > 0) {
            iconClass = classes.note;
        }

        return  <>
            <ButtonBase style={{
                textAlign: "left",
                justifyContent: "flex-start",
                minWidth: "235px",
                lineHeight: "24px",
                cursor: "pointer",
            }} onClick={handleClick}>{item.description || item.code.value}</ButtonBase>
            <span className={classes.fill}></span>
            <span style={{
                paddingLeft: 12,
            }}><IconButton style={{ padding: 0 }}  onClick={handleClick}><AssignmentOutlinedIcon className={iconClass} /></IconButton></span>
            <ItemDetail   id={`detail-${item.bid}`} title={"notes"/*semanticType*/} open={open} onClickAway={__clickAwayHandler} onCloseClick={handleClick} anchorEl={anchorEl}>
                { __renderDetails(semanticType) }
            </ItemDetail>
        </>
    }

    function __renderDetails(semanticType) {

        if(semanticType === "Medication") {
            return <><MedicationOrderDetail onClose={__clickAwayHandler} bloc={bloc} item={item}/></>;
        } else {
            return <><GeneralOrderDetail onClose={__clickAwayHandler} bloc={bloc} item={item}/></>;
        }
    }

    return (
        <DropZone
            accept={["dxList", "observations", "currentWorkingDX"]}
            allowedSemanticTypes={[...OntologyConstants.CONDITION.semantic, ...OntologyConstants.CONDITION.semantic]}
            processDrop={(dItem) => {
                console.log("droppable item", dItem, item)
                bloc.evaluationManagement().linkOrder(item, dItem);
            }}
            key={`${item.bid}`}
        >
            <SwipeEnabledListItem
                onRemove={() => {
                    bloc.evaluationManagement().delete(item);
                }}
                onLeftSwipe={() => {
                    bloc.evaluationManagement().update(item, "error")
                }}
                onRightSwipe={() => {
                    bloc.evaluationManagement().update(item, "unknown")
                }}
                disableLeftSwipe={item.status === "error"}
                disableRightSwipe={item.status !== "error"}
                style={{display: "inline-block"}}
            >
                <DHChipWithIcon
                    className={classes.intentionChips}
                    drawerItem={false}
                    icon={
                        item.status === "unknown" ? (
                            <ButtonBase onClick={() => {
                                bloc.evaluationManagement().update(item, "proposed")
                            }}>
                                <CheckCircleOutline/>
                                {/*<CircularProgress*/}
                                {/*    size={24}*/}
                                {/*    style={{*/}
                                {/*      position: 'absolute',*/}
                                {/*      top: 0,*/}
                                {/*      left: 0,*/}
                                {/*      zIndex: 1,*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </ButtonBase>
                        ) : (
                            <ButtonBase onClick={() => {
                                bloc.evaluationManagement().update(item, "unknown")
                            }}>
                                <CheckCircle style={{color: "#00D1FF"}}/>
                            </ButtonBase>
                        )
                    }
                    classes={{
                        icon: item.status !== "unknown" ? undefined : classes.outlineChip,
                        label: item.status === "error" ? classes.label : undefined,
                    }}
                    label={<>
                        {__renderPopover()}
                    </>}
                    colortiles={bloc.differentialColour().getColoursForAction(item.bid)}
                />
            </SwipeEnabledListItem>
        </DropZone>
    );
};

export default withStyles(styles)(Template);

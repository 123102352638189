import {Box, IconButton, Typography} from "@material-ui/core";
import {Clear} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useCGIContext} from "../../ClinicalGraph/CGIContext";
import {SearchForContextMenu} from "../ContextMenu";
import {createFirstItemOfSearchResult} from "./Drawer.utils";

const __screen = "calc(100vh)";

export function AddComplaintContextMenu({
  handleClose,
  ontologyConstants,
  label = "Add complaint",
  placeHolder = "Type complaint",
  onItemPress,
}) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const context = useCGIContext();
  useEffect(() => {
    (async () => {
      if (query.length > 2) {
        const data = await context.bloc.searchOntology(query,
            ontologyConstants?.semantic,
            ontologyConstants?.vocabulary
        );
        const _result = createFirstItemOfSearchResult(query,data);
        setResults(_result.splice(0, 7));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (query.length < 3) {
      setResults([]);
    }
  }, [query]);
  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        minHeight: __screen,
        maxHeight: __screen,
        height: __screen,
      }}
    >
      <Box style={{ paddingTop: 100, marginLeft: 64, marginRight: 64 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {label}
          </Typography>
          <IconButton aria-label="close"  onClick={() => {
              handleClose();
          }}>
              <Clear

                style={{ color: "#C4C4C4", }}
              />
          </IconButton>
        </Box>
        <Box style={{ marginTop: 20, maxWidth: 650 }}>
          <SearchForContextMenu
            value={query}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            placeHolder={placeHolder}
          />
        </Box>
        <Box>
          {results?.map((cc) => (
            <Typography
              style={{ marginBottom: 16 }}
              key={`${cc.conceptId}`}
              onClick={() => {
                onItemPress(cc, context);
                setResults([]);
                setQuery("");
              }}
            >
              {cc.canonicalName}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
}

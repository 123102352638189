import React from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
} from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import {FormattedMessage} from "react-intl";
import {globalBloc} from "../global.bloc";
import GlobalContext from "../context";
import {GlobalEvent, globalEventService} from "../service/global.event.service";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },
});

class SelectLocation extends React.Component {

    state = {
        loading: true,
        organisations: [],
        location: globalBloc.location(),
    };

    componentDidMount() {

        navigationTitleService.update("Select Location");
        this.globalSubscription = globalBloc.subject.subscribe(this.__globalUpdate);
    }

    componentWillUnmount() {
        this.globalSubscription.unsubscribe();
        this.globalSubscription = undefined;
    }

    __globalUpdate = (_value) => {

        this.setState({
            location: _value.location,
            organisations: _value.organisations,
        });
    }

    __changeLocation = (event) => {

        this.setState({
            location: event.target.value,
        });
    }

    __selectLocation = (event) => {

        const location = this.state.location;

        globalEventService.update(GlobalEvent.LOCATION_UPDATED, { newLocation: location });

        this.setState({
            close: true,
        });
    }

    __lookupOrganisation = (_item) => {

        const { organisations } = this.state;

        if(organisations) {

            const organisation = organisations.filter((_org) => _org.id === _item.partyIdentifier.value);

            if (organisation && organisation.length > 0) {

                return {
                    id: organisation[0].id,
                    name: organisation[0].name
                }
            }
        }

        return {};
    }

    render() {

        const { classes } = this.props;
        const { audience } = this.props.context;
        const { location, close, loading } = this.state;

        return (
                <div className={classes.root}>

                    { close && <Redirect to={`/clinic/${location}/waiting-room`} /> }

                    <Dialog open={!close}  aria-labelledby="form-dialog-title" fullWidth>
                        <DialogTitle id="form-dialog-title"><FormattedMessage id={"select.location.modal.title"} defaultMessage={"Select location"} /></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <FormattedMessage id={"select.location.modal.content"} defaultMessage={"Please select your current location."} />
                            </DialogContentText>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    id="location"
                                    name="location"
                                    displayEmpty
                                    value={location}
                                    onChange={this.__changeLocation}
                                >
                                    <MenuItem id={"location_select_none"} value="" disabled><FormattedMessage id={"organisation.location.select"} defaultMessage={"Select location"} /></MenuItem>
                                    { audience && audience
                                        .map(_item => this.__lookupOrganisation(_item))
                                        .filter(_item => _item.id)
                                        .map(_item => <MenuItem key={`location-select-${_item.id}`} value={_item.id}>{_item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button id={"button_select_location"} onClick={this.__selectLocation} color="primary" variant={"contained"} disabled={!location || location.length === 0 }>
                                <FormattedMessage id={"select.location.modal.button"} defaultMessage={"Submit"} />
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
        );
    }
}

SelectLocation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(props => (
    <GlobalContext.Consumer>
        {value => {
            return (<SelectLocation context={value} {...props} />);
        }
        }
    </GlobalContext.Consumer>
)));

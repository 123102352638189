import {dateUtil} from "../../../utils/date";
import {phoneUtil} from "../../../utils/phone";

export class PatientFormMapper {

    static mapPatient =(patient, form) => {

        form.id = patient.id;
        form.givenName = patient.name.given;
        form.familyName = patient.name.family;
        form.dob = dateUtil.formatDateToAmerica(patient.dob);
        form.gender = patient.gender;
        if(patient.email) {
            form.email = patient.email;
        }
        if(patient.contactNumber) {
            form.number = phoneUtil.formatPhoneNumberShortForInput(patient.contactNumber);
        }

        if(patient.address) {
            form.addressLine1 = patient.address.line1;
            form.addressLine2 = patient.address.line2;
            form.addressCity = patient.address.city;
            form.addressAdministrativeArea = patient.address.administrativeArea;
            form.addressPostcode = patient.address.postcode;
        }

        return form;
    }
}

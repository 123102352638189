import {phoneUtil} from "../../../../../utils/phone";
import {dateUtil} from "../../../../../utils/date";


export function validateForm(form) {

    return true;
}


export function createScheduleRequest(form, existingInsurance) {

    let paymentInformation = undefined;

    if(!existingInsurance || !(form.id && !form.paymentUpdates)) {
        paymentInformation = {
            type: "OUTOFPOCKET"
        };
    }

    if(form.memberId && form.memberId !== "") {

        let members = [];

        if(!form.isPolicyHolder) {

            members.push({
                name: {
                    given: form.policyHolderGivenName,
                    family: form.policyHolderFamilyName,
                },
                role : "POLICY_HOLDER",
                sex : form.policyHolderGender,
                dob: dateUtil.formatDate(form.policyHolderDob),
            });
        }

        paymentInformation = {
                memberId: form.memberId,
                type: "INSURANCE",
                provider: {
                    name: form.provider
                },
                "plan": {
                    id: form.userPlan.planId,
                    name: form.userPlan.planId  !== -1 || !form.customUserPlanName ? form.userPlan.planName : form.customUserPlanName,
                    type: form.planType
                },
                "members": members
        };
    }

    let appointmentInformation = {
        isInClinic: form.isEmergency || form.isInClinic,
        type: form.appointmentType,
        channel: form.channel,
        organisation: {
            id: form.organisationId,
        },
        reason: form.chiefComplaint,
        priority: form.isEmergency ? 1 : 10,
    };

    if(!form.isEmergency) {

        let specifiedStart = form.appointmentStartTime;
        if(form.appointmentStartTimeSpecific && form.appointmentStartTimeSpecific.length === 2) {
            specifiedStart.setMinutes(form.appointmentStartTimeSpecific);
        }

        appointmentInformation.slot = {
            intervalStart: specifiedStart,
        };
    }

    let participants = [];

    if(form.appointmentProvider && form.appointmentProvider.length > 0) {
        participants.push({
           "role": "PROVIDER",
           "identifier": {
               "system": "decoded",
               "code": "id",
               "value": form.appointmentProvider,
           }
        });
    }

    let request = {
        code: "register.and.schedule",
        payload: {
            id: form.id,
            name: {
                given: form.givenName,
                family: form.familyName,
            },
            dob: dateUtil.formatDate(form.dob),
            gender: form.gender,
            externalReferences: [],
            contactDetails: {
                address: {
                    line1: form.addressLine1,
                    line2: form.addressLine2,
                    city: form.addressCity,
                    locality: form.addressAdministrativeArea,
                    postcode: form.addressPostcode,
                    country: "US"
                },
                number: phoneUtil.formatPhoneNumberForRegistration(form.number),
                email: form.email,
            },
            acceptTerms: form.acceptTerms,
            acceptLocationTerm: form.isInValidLocation,
            communicationConsent: form.acceptTerms,
            paymentInformation: paymentInformation,
            appointmentInformation: appointmentInformation,
            participants: participants,
        }
    };

    if(form.ssn.length > 0) {

        request.payload.externalReferences.push(
            {
                system: "http://hl7.org/fhir/vs/identifier-type",
                code: "SS",
                reference: form.ssn,
            }
        );
    }

    return request;
}

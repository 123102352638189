import {Component} from 'react';

export default class FormComponent extends Component {

    constructor(props, context) {
        super(props, context);
    }

    __updateText = (e) => {
        let { bloc, value } = this.props;
        bloc.updateText(value, e.target.name, e.target.value);
    }

    __delete = (e) => {
        let { bloc, value } = this.props;
        bloc.delete(value);
    }

    __updateOption = (target) => (_value) => {
        let { bloc, value } = this.props;
        bloc.updateText(value, target, _value);
    }

    __updateOnsetText = (e) => {
        let { bloc, value } = this.props;
        bloc.updateOnsetText(value, e.target.name, e.target.value);
    }

    __populateSuggestion = (target, _value) => {
        let { bloc, value } = this.props;
        bloc.updateOnsetText(value, target, _value);
    }
}

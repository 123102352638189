import React, {useRef, useState} from "react";
import { useDrag, useDrop } from "react-dnd";
import { moveDxItem } from "./dndUtils";
import { CustomDragLayer } from "./CustomDragLayer";
import { useCGIContext } from "../../ClinicalGraph/CGIContext/CGIContext";

export function DraggableItem({
  children,
  type,
  item,
  index,
  isHoverAllowed = true,
  accept,
}) {
  const ref = useRef(null);
  const currentElement = useRef(null);
  const context = useCGIContext();

  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item, monitor) {
      if (!isHoverAllowed) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.hoverIndex = hoverIndex;

    },
  });
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: () => {
        return { ...item, index };
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [type, item]
  );
  drag(drop(ref));

  return (
    <div ref={currentElement}>
      <div
        key={item.bid}
        data-index={index}
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
      >
        {children}
      </div>
      <CustomDragLayer></CustomDragLayer>
    </div>
  );
}

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import VirtualClinicContext from "../../../context";
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {notificationService} from "../../../../../../utils/notification";
import {customersApi} from "../../../../../../utils/services/customers.api";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {accountApi} from "../../../../../../utils/services/account.api";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";
import BaseCard from "./Card";
import {addMinutes, differenceInMinutes, parse} from "date-fns";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    redBorder: {
        border: "1px solid #FD1F54",
    },
    cyanishBorder: {
        border: "1px solid #00EBFF",
    },
    cyanishIndicator: {
        color: "#00EBFF",
    },
    orangeBorder: {
        border: "1px solid #FDCC1F",
    },
    okBorder: {
        border: "1px solid #F5F5F5",
    },
    redIndicator: {
        color: "#FD1F54",
    },
    orangeIndicator: {
        color: "#FDCC1F",
    },
    okIndicator: {
        color: "#BFE81C",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeaderInPerson: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.alternate}`,
    },
    cardHeaderTelehealth: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.main}`,
    },
    cardBodyContentClaimed: {
        padding: "17px 0px 0px 12px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "5px",
        }
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardMenu: {

    },
    cardTitleSubText: {},
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        overflow: "hidden",
        padding: "0px 14px 0px 12px",
        display: "flex",
        flex: "1 1 auto",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "6px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1",
    },
    cardSubHeader: {
        padding: "10px 14px 10px 12px",
        display: "flex",
    },
    cardSubHeader2: {
        padding: "0px 14px 10px 12px",
        display: "flex",
    },
    cardFooter: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 12px 16px 12px",
        '& button': {
            marginTop: "8px",
        },
    },

    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "21px",
        lineHeight: "21px",
        paddingTop: "3px",
        paddingRight: "4px",
        color: "#8BA3B0",
    },
    sessionType: {
        textAlign: "left",
        paddingBottom: "10px",
        paddingLeft: "70px",
    },
    complaint: {
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },
    complaintIntakeComplete: {
        overflow: "auto",
        padding: "7px 18px",
        background: "rgba(0,235,255, 0.1)",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },

    button: {
        width: "100%",
    },

    cardBodyLoading: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyLoadingContent: {
        margin: "auto",
    },
    cardBodyBusy: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyBusyContent: {
        margin: "16px 10px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    cardBodyBusyFooter: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingLeft: "12px",
        paddingRight: "12px",
        textAlign: "center",
    },
    cardBodyLoadingProgress: {
        textAlign: "center",
        paddingTop: "20px",
        marginBottom: "10px",
    },
    cardBodyLoadingText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#98A3AE",
    },


    cardBodyContent: {
        maxHeight: "136px",
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.6",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#000000",
    },



    dialogHeader: {
        display: "flex",
        paddingBottom: "32px",
    },

    dialogHeaderAvatar: {
        marginRight: "24px",
    },
    dialogHeaderTitle: {
        paddingTop: "12px",
    },
    dialogButton: {
        marginRight: '12px',
    },
});

class BusyCard extends Component {

    interval;

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            start: Date.parse(props.item.createdOn),
            delay: "",
            dialogOpen: false,
            loading: false,
        };
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);

        this.__revealUser();

        this.interval = setInterval(this.__updateInformation, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = undefined;
    }

    __updateInformation = () => {

        const {participants} = this.props.item;
        const {user, loadingUser} = this.state;

        if(!loadingUser && this.props.item && this.props.item.participants && this.props.item.participants.length > 0) {

            const participant = this.props.item.participants[0];
            if(!user || user.id !== participant.identifier.value) {
                this.__revealUser();
            }
        }

        this.setState({
            now: new Date().getTime(),
        });
    }


    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };


    __revealUser = () => {

        if(this.props.item.participants && this.props.item.participants.length > 0) {

            this.setState({
                loadingUser: true,
            });

            const participant = this.props.item.participants[0];

            accountApi.user(participant.identifier.value)
                .then(value => {

                    this.setState({
                        user: value.data,
                    });
                })
                .catch(reason => {

                    notificationService.error(`Error loading user. Reason: ${reason}`)
                })
                .finally(() => {

                    this.setState({
                        loadingUser: false,
                    });
                })
        }
    }

    _openWindow = (url) => {

        return () => {
            window.open(url, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            this.__handleMenuClose();
        };
    };

    __handleMenuOpen = (event) => {

        this.setState({
            cardMenuAnchorEl: event.currentTarget,
        });
    }

    __handleMenuClose = () => {

        this.setState({
            cardMenuAnchorEl: undefined,
        });
    }

    __handleDialogClose = () => {
        this.setState({
            dialogOpen: false,
        });
    }

    __endAppointment = () => {
        let { id } = this.props.item;

        this.setState({loading: true})

        appointmentApi.updateAppointmentState(id, "COMPLETE")
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_END, { id: id, type: "COMPLETE" });

            })
            .catch(reason => {
                this.endCount += 1;
                notificationService.update({
                    error: "Unable to end the appointment. " + reason,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    dialogOpen: false
                })
            });
    }


    __renderMenu = () => {

        const { item, classes } = this.props;
        const { person, cardMenuAnchorEl } = this.state;

        let items = [];

        const chartUrl = `${process.env.REACT_APP_EHR_CHART_URL}`;
        if(chartUrl.length > 0 && person) {

            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            const department = item.links.filter(_value => _value.type === "deeplink_references").filter(_value => _value.details.department).map(_value => _value.details.department);
            if (athenaIds.length > 0 && department.length > 0) {
                items.push(<MenuItem onClick={this._openWindow(chartUrl.replace('__id__', athenaIds[0].reference).replace('__department__', department[0]))}>Open Chart</MenuItem>);
            }
        }

        const bufferFlag = process.env.REACT_APP_ENCOUNTER_END_BUFFER;
        let triggerTime = bufferFlag.length > 0 ? parseInt(bufferFlag) : 10;
        const appointmentStartTime = parse(item.slot.start, "yyyy-MM-dd'T'HH:mm:ssX", new Date());
        let appointmentEndTime = addMinutes(appointmentStartTime, item.slot.duration + item.delay)
        if (differenceInMinutes(new Date(), appointmentEndTime) > triggerTime) {
            items.push(
                <MenuItem onClick={() => {
                    this.setState({dialogOpen: true});
                    this.__handleMenuClose();
                }}
                >
                    Force End Encounter
                </MenuItem>
            )
        }

        return (
            <>
                {
                    items.length > 0 && (
                        <>
                            <IconButton className={classes.cardMenu} aria-label="settings"  aria-controls="simple-menu" aria-haspopup="true"onClick={this.__handleMenuOpen}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={cardMenuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                open={Boolean(cardMenuAnchorEl)}
                                onClose={this.__handleMenuClose}
                            >
                                { items }
                            </Menu>
                        </>
                    )
                }
            </>
        );
    }

    __renderBody = () => {

        return this.__renderInAppointment();
    }

    __renderInAppointment = () => {

        let { classes, item } = this.props;
        const { user, userLoading } = this.state;

        return (<div className={classes.cardBodyBusy}>
            <div className={classes.cardBodyBusyContent}>
                <Typography variant={"h6"} component={"div"} align={"center"}>Currently { item.status === "A_REVIEWING" ? "in intake" : "in exam" }</Typography>
                <div className={classes.fill}></div>
                { user ?
                    <div className={classes.cardBodyBusyFooter}>{user.name.given} {user.name.family}</div> :
                    <Button disabled={ userLoading }
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.__revealUser}>Reveal Provider</Button>
                }
                <div className={classes.fill}></div>
            </div>
        </div>);
    }

    render() {

        let { person, now, start, dialogOpen, loading } = this.state;
        let { classes, item } = this.props;
        let { focusItem } = this.props.context;

        let seconds = (now - start) / 1000;
        let minutes = seconds / 60;

        let borderColor = classes.okBorder;

        if( ["A_REVIEWED", "A_REVIEWING"].includes(item.status) ) {

            borderColor = classes.cyanishBorder;

        } else if(!(["REVIEWING", "STARTED", "RESERVED"].includes(item.status))) {

            if(item.type.startsWith('VIRTUAL')) {
                if (minutes > 15) {

                    borderColor = classes.redBorder;
                } else if (minutes > 8) {

                    borderColor = classes.orangeBorder;
                }
            } else {
                if (minutes > 30) {

                    borderColor = classes.redBorder;
                } else if (minutes > 15) {

                    borderColor = classes.orangeBorder;
                }
            }
        }

        const medium = item && item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        const headerStyle = medium === "Telehealth" ? classes.cardHeaderTelehealth : classes.cardHeaderInPerson;

        const focused = focusItem.data && focusItem.data.id === item.id;

        const menu = this.__renderMenu(focused, medium, item);
        const body = this.__renderBody(focused, medium, item);

        return (
            <>
                <BaseCard headerStyle={headerStyle} borderColor={borderColor} person={person} menu={menu}>
                    <Dialog
                        maxWidth={"md"}
                        open={dialogOpen}
                        onClose={this.__handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"End Encounter"}</DialogTitle>
                        <DialogContent>
                            Are you sure you want to end the encounter?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.dialogButton}
                                onClick={this.__handleDialogClose}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.__endAppointment}
                                variant={"contained"}
                                color="primary"
                                disabled={loading}
                            >
                                {loading? <CircularProgress size="1.5em" /> : "Confirm"}
                            </Button>

                        </DialogActions>
                    </Dialog>

                    { body }
                </BaseCard>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<BusyCard context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Button, Chip, CircularProgress, IconButton, Menu, MenuItem,} from "@material-ui/core";
import VirtualClinicContext from "../../../context";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {clinicEventService} from "../../../service/clinic.event.service";
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {notificationService} from "../../../../../../utils/notification";
import {customersApi} from "../../../../../../utils/services/customers.api";
import Allowed from "../../../../../../utils/auth/Allowed";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from "react-intl";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";
import BaseCard from "./Card";
import {format} from "date-fns";
import CancelAppointmentDialog from "../../../../component/CancelAppointmentDialog";
import {Bloc as CancelBloc, BlocEvent as CancelBlocEvent} from "../../../../component/CancelAppointmentDialog/bloc";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    redBorder: {
        border: "1px solid #FD1F54",
    },
    cyanishBorder: {
        border: "1px solid #00EBFF",
    },
    cyanishIndicator: {
        color: "#00EBFF",
    },
    orangeBorder: {
        border: "1px solid #FDCC1F",
    },
    okBorder: {
        border: "1px solid #F5F5F5",
    },
    redIndicator: {
        color: "#FD1F54",
    },
    orangeIndicator: {
        color: "#FDCC1F",
    },
    okIndicator: {
        color: "#BFE81C",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeaderInPerson: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.alternate}`,
    },
    cardHeaderTelehealth: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.main}`,
    },
    cardBodyContentClaimed: {
        padding: "17px 21px 0px 12px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "5px",
        }
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardMenu: {

    },
    cardTitleSubText: {},
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        overflow: "hidden",
        padding: "0px 14px 0px 12px",
        display: "flex",
        flex: "1 1 auto",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "6px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1",
    },
    cardSubHeader: {
        padding: "10px 14px 10px 12px",
        display: "flex",
    },
    cardSubHeader2: {
        padding: "0px 14px 10px 0px",
        display: "flex",
    },
    cardFooter: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 12px 16px 12px",
        '& button': {
            marginTop: "8px",
        },
    },

    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "21px",
        lineHeight: "21px",
        paddingTop: "3px",
        paddingRight: "4px",
        color: "#8BA3B0",
    },
    sessionType: {
        textAlign: "left",
        paddingBottom: "10px",
        paddingLeft: "70px",
    },
    complaint: {
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },
    complaintIntakeComplete: {
        overflow: "auto",
        padding: "7px 18px",
        background: "rgba(0,235,255, 0.1)",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },

    button: {
        width: "100%",
    },

    cardBodyLoading: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyLoadingContent: {
        margin: "auto",
    },
    cardBodyBusy: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyBusyContent: {
        margin: "16px 10px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    cardBodyBusyFooter: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingLeft: "12px",
        paddingRight: "12px",
        textAlign: "center",
    },
    cardBodyLoadingProgress: {
        textAlign: "center",
        paddingTop: "20px",
        marginBottom: "10px",
    },
    cardBodyLoadingText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#98A3AE",
    },


    cardBodyContent: {
        maxHeight: "136px",
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.6",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#000000",
    },



    dialogHeader: {
        display: "flex",
        paddingBottom: "32px",
    },

    dialogHeaderAvatar: {
        marginRight: "24px",
    },

    dialogHeaderTitle: {
        paddingTop: "12px",
    },

    processingHolder: {
        margin: "auto",
    },
});

class WaitingCard extends Component {

    interval;

    constructor(props) {
        super(props);

        const { clinicBloc, } = this.props.context;

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            start: Date.parse(props.item.createdOn),
            delay: "",
            cancelBloc: new CancelBloc(props.item.id, clinicBloc),
        };

        this.__forceEndClick = this.__forceEndClick.bind(this);
        this.__openConfirmation = this.__openConfirmation.bind(this);
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);

        this.interval = setInterval(this.__updateInformation, 1000);
        this.cancelEventSubscription = this.state.cancelBloc.subscribeToEvents(this.__cancelEventHandler);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = undefined;
        this.cancelEventSubscription.unsubscribe();
    }

    __updateInformation = () => {

        this.setState({
            now: new Date().getTime(),
        });
    }

    __cancelEventHandler = (event) => {
        const { type } = event;
        if(CancelBlocEvent.APPOINTMENT_CANCELLED === type) {
            this.setState({
                processing: true,
            });
        }
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    _seePatient = () => {

        let { item } = this.props;
        let { encounters } = this.props.context;

        this.setState({
            busy: true,
        });

        clinicEventService.update({
            type: 'VIRTUAL_CLINIC_LOADING_PATIENT',
            data: item,
        });

        analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_START, { id: item.id });

        appointmentApi.startEncounter(item.id)
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_STARTED, { id: item.id });

                clinicEventService.update({
                    type: 'VIRTUAL_CLINIC_SEE_PATIENT',
                    data: value.data,
                });

            }).catch(reason => {

            notificationService.update({
                error: "Unable to load patient for a session. Due to: " + reason,
            });

            analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_ERROR, { id: item.id, type: "error_starting" });

            clinicEventService.update({
                type: 'VIRTUAL_CLINIC_LOADING_PATIENT_ERROR',
                data: reason,
            });

            this.setState({
                busy: false,
            });
        });
    };


    __renderBody = (focused, medium, appointmentInformation) => {

        if(focused) return this.__renderStarting(medium, appointmentInformation);

        if(medium === "In Person") {

            return this.__renderInPerson(medium);
        } else {

            return this.__renderVirtual(medium);
        }
    }

    __renderInPerson = (medium) => {

        const { person, now, start, busy } = this.state;
        const { classes, item } = this.props;
        const { disableSee, roles } = this.props.context;

        const seconds = (now - start) / 1000;
        const minutes = seconds / 60;

        let indicatorColor = classes.okIndicator;

        let buttons = (<>
            <Allowed roles={roles} priviledge="interaction:encounter:start"
                     yes={() => (
                         <>
                             <Button disabled={ disableSee || item.status === "A_REVIEWING" }
                                     variant="contained"
                                     color="primary"
                                     className={classes.button}
                                     onClick={this._seePatient}>See Patient</Button>
                         </>)}
                     no={() => (<></>)} />
        </>);

        if( item.status !== "A_REVIEWED" ) {

            if (minutes > 30) {

                indicatorColor = classes.redIndicator;
            } else if (minutes > 15) {

                indicatorColor = classes.orangeIndicator;
            }
        } else {

            indicatorColor = classes.cyanishIndicator;
            if(roles.includes("TNT_ASSIST")) {
                buttons = (<></>);
            }
        }

        const displayTime = format(this.__addMinutes(new Date(item.slot.start), item.delay), "MMM dd, HH:mm");

        let information = <>
            <div className={classes.fill}></div>
            <span className={classes.subHeaderText}>reserved for approx </span>
            { (item.delay && item.delay > 0) ?
                <Chip
                    size="small"
                    color="secondary"
                    label={ displayTime }
                /> : <><span className={classes.subHeaderText}>{ displayTime }</span></> }
            <br />
        </>;


        let waitingInformation = <>
            <div className={classes.fill}></div>
            <FiberManualRecordIcon className={`${classes.indicator} ${indicatorColor}`}/>
            <span className={classes.subHeaderText}>{ medium } waiting for</span>
            <span className={classes.subHeaderTime}>
                {this.__renderWaitingTime(seconds)}
            </span>

            <br />
        </>;

        let complaintColor = classes.complaint;

        let athenaId;
        if(person) {
            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            if (athenaIds.length > 0) {
                athenaId = athenaIds[0];
            }
        }

        return (<>
            <div className={classes.cardSubHeader}>
                { waitingInformation }
            </div>
            <div className={classes.cardSubHeader2}>
                { information }
            </div>
            <div className={classes.cardBody}>
                <div className={classes.cardBodyRight}>
                    <div className={complaintColor}>

                        { item.service && <div className={classes.cardSubHeader2}><div className={classes.fill}></div><span
                            className={classes.subHeaderText}>{ item.service.description }</span><div className={classes.fill}></div></div>}
                        { item.status === "A_REVIEWED" && <div className={classes.cardSubHeader2}><div className={classes.fill}></div><span
                            className={classes.subHeaderText}>Intake completed</span><div className={classes.fill}></div></div>}
                        { athenaId &&  <>Athena Id: {athenaId.reference} <br/></> }
                        Language: {person?.language || "en"} <br />
                        Reason: {item.note}
                    </div>
                </div>
            </div>
            <div className={classes.cardFooter}>
                { buttons }
            </div>
        </>);
    }

    __addMinutes = (date, minutes) => {
        if(!minutes) return date;
        return new Date(date.getTime() + minutes*60000);
    }

    __renderWaitingTime = (seconds) => {

        let minutes = seconds / 60;
        let hours = parseInt(minutes / 60);
        let days = Math.floor(hours / 24);

        let displayedTime;
        if (hours <= 24) {

            displayedTime = hours > 0 ? `${hours}`.padStart(2, '0') + ":" : "";
            displayedTime += `${parseInt(minutes % 60)}`.padStart(2, '0') + ":" + `${parseInt(seconds % 60)}`.padStart(2, '0');
        } else if (days === 1) {

            displayedTime = `a day`
        } else {

            displayedTime = `${days} days`
        }

        return displayedTime;
    }

    __renderVirtual = (medium) => {

        const { person, now, start, busy } = this.state;
        const { classes, item } = this.props;
        const { disableSee, roles } = this.props.context;

        let seconds = (now - start) / 1000;
        let minutes = seconds / 60;

        let waitingInformation = <></>;
        let information = <></>;

        if(item.slot && item.slot.start) {

            const slotStart = this.__addMinutes(new Date(item.slot.start), item.delay);

            seconds = (now - slotStart) / 1000;
            minutes = seconds / 60;

            const displayTime = format(this.__addMinutes(new Date(item.slot.start), item.delay), "MMM dd, HH:mm");

            if(seconds < 0) {

                waitingInformation = <>
                    <div className={classes.fill}/>
                    <span className={classes.subHeaderText}>{ medium } at </span>
                    <span
                        className={classes.subHeaderTime}>{ displayTime }</span>{ (item.delay && item.delay > 0) ?
                    <Chip
                        size="small"
                        label={`delay ${item.delay}m`}
                    /> : <></> }
                    <br />
                </>;

            } else {

                let indicatorColor = classes.okIndicator;

                if( item.status === "A_REVIEWED" ) {

                    indicatorColor = classes.cyanishIndicator;
                } if (minutes > 15) {

                    indicatorColor = classes.redIndicator;
                } else if (minutes > 8) {

                    indicatorColor = classes.orangeIndicator;
                }

                information = <div className={classes.cardSubHeader2}>
                    <div className={classes.fill}></div>
                    <span className={classes.subHeaderText}>reserved for approx </span>
                    { (item.delay && item.delay > 0) ?
                        <Chip
                            size="small"
                            color="secondary"
                            label={ displayTime }
                        /> : <><span className={classes.subHeaderText}>{ displayTime }</span></> }


                    <br />
                </div>;

                waitingInformation = <>
                    <div className={classes.fill}/>
                    <FiberManualRecordIcon className={`${classes.indicator} ${indicatorColor}`}/>
                    <span className={classes.subHeaderText}>{ medium } waiting for</span>
                    <span className={classes.subHeaderTime}>
                        {this.__renderWaitingTime(seconds)}
                    </span>
                    <br />
                </>;
            }
        } else {

            let indicatorColor = classes.okIndicator;

            if (minutes > 15) {

                indicatorColor = classes.redIndicator;
            } else if (minutes > 8) {

                indicatorColor = classes.orangeIndicator;
            }

            waitingInformation = <>
                <div className={classes.fill}/>
                <FiberManualRecordIcon className={`${classes.indicator} ${indicatorColor}`}/>
                <span className={classes.subHeaderText}>{ medium } waiting for</span>
                <span className={classes.subHeaderTime}>
                    {this.__renderWaitingTime(seconds)}
                </span>
                <br />
                </>;
        }


        let buttons = (<>
            <Allowed roles={roles} priviledge="interaction:telehealth:start"
                     yes={() => (
                         <>
                             <Button disabled={ disableSee }
                                     variant="contained"
                                     color="primary"
                                     className={classes.button}
                                     onClick={this._seePatient}>See Patient</Button>
                         </>)}
                     no={() => (<></>)} />
        </>);

        if( item.status === "A_REVIEWED" || item.type === "VIRTUAL_WALK_IN") {

            if(roles.includes("TNT_ASSIST")) {
                buttons = (<></>);
            }
        }

        let athenaId = "";
        if(person) {
            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            if (athenaIds.length > 0) {
                athenaId = athenaIds[0];
            }
        }

        return (<>
            <div className={classes.cardSubHeader}>
                { waitingInformation }
            </div>
            { information }
            <div className={classes.cardBody}>
                <div className={classes.cardBodyRight}>
                    <div className={classes.complaint}>
                        { item.service && <div className={classes.cardSubHeader2}><div className={classes.fill}></div><span
                            className={classes.subHeaderText}>{ item.service.description }</span><div className={classes.fill}></div></div>}
                        { item.status === "A_REVIEWED" && <div className={classes.cardSubHeader2}><div className={classes.fill}></div><span
                            className={classes.subHeaderText}>Intake completed</span><div className={classes.fill}></div></div>}
                        { athenaId !== "" && <>Athena Id: {athenaId.reference} <br/></> }
                        Language: {person?.language || "en"} <br />
                        Reason: {item.note}
                    </div>
                </div>
            </div>
            <div className={classes.cardFooter}>
                { buttons }
            </div>
        </>);
    }

    __renderStarting = (medium) => {

        let { classes } = this.props;

        return (<div className={classes.cardBodyLoading}>
            <div className={classes.cardBodyLoadingContent}>
                <div className={classes.cardBodyLoadingProgress}><CircularProgress /></div>
                <div className={classes.cardBodyLoadingText}>Preparing session.</div>
            </div>
        </div>);
    }

    _openWindow = (url) => {

        return () => {
            window.open(url, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            this.__handleMenuClose();
        };
    };

    __handleMenuOpen = (event) => {

        this.setState({
            cardMenuAnchorEl: event.currentTarget,
        });
    }

    __handleMenuClose = () => {

        this.setState({
            cardMenuAnchorEl: undefined,
        });
    }


    __renderMenu = () => {

        const { item, classes } = this.props;
        const { person, cancelBloc, processing, cardMenuAnchorEl } = this.state;

        if(processing) return <></>;

        let items = [];

        const chartUrl = `${process.env.REACT_APP_EHR_CHART_URL}`;
        if(chartUrl.length > 0 && person) {

            const athenaIds = person.externalReferences.filter(value => value.system === "athena" && value.code === "id");
            const department = item.links.filter(_value => _value.type === "deeplink_references").filter(_value => _value.details.department).map(_value => _value.details.department);
            if (athenaIds.length > 0 && department.length > 0) {
                items.push(<MenuItem onClick={this._openWindow(chartUrl.replace('__id__', athenaIds[0].reference).replace('__department__', department[0]))}>Open Chart</MenuItem>);
            }
        }

        const cancelEnableFlag = process.env.REACT_APP_APPOINTMENT_CANCEL_ENABLED;
        let cancelEnable = cancelEnableFlag.length > 0 ? parseInt(cancelEnableFlag) : 0;
        if (cancelEnable === 1) {
            if(item.type.startsWith('VIRTUAL') && item.status === "WAITING") {
                items.push(<MenuItem onClick={() => {
                    cancelBloc.open();
                    this.__handleMenuClose();
                }}>
                    Cancel
                </MenuItem>);
            }
        }

        if(item.status === "A_REVIEWED") {

            items.push(<MenuItem onClick={this.__forceEndClick}
            >
                <FormattedMessage id={'force.end.encounter'} defaultMessage={"Force End Encounter"}/>
            </MenuItem>);
        }

        return (
            <>
                {
                    items.length > 0 && (
                        <>
                        <IconButton className={classes.cardMenu} aria-label="settings"  aria-controls="simple-menu" aria-haspopup="true"onClick={this.__handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={cardMenuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                open={Boolean(cardMenuAnchorEl)}
                                onClose={this.__handleMenuClose}
                            >
                                { items }
                            </Menu>
                        </>
                    )
                }
            </>
        );
    }

    __forceEndClick = (event) => {
        this.__openConfirmation({
            confirmationTargetTitle: <FormattedMessage id={'force.end.encounter.title'} defaultMessage={'Force end encounter'} />,
            confirmationTargetText: <FormattedMessage id={'force.end.encounter.text'} defaultMessage={'Are you sure you want to end the encounter?'} />,
            confirmTarget: 'FORCE_END',
        });
        this.__handleMenuClose();
    }


    //FIXME: Create a general confirmation dialog component.
    __renderDialogs = (focused, medium, item) => {

        let { openConfirmation, confirmationTargetTitle, confirmationTargetText, confirmTarget } = this.state;
        let { classes } = this.props;

        return (<Dialog
            open={openConfirmation}
            onClose={this.__closeConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{ confirmationTargetTitle }</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { confirmationTargetText }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.__closeConfirmation} className={classes.dialogButton}>
                    Cancel
                </Button>
                <Button variant={"contained"} onClick={this.__processConfirmation(confirmTarget)} color={"primary"} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);
    }

    __openConfirmation = (target) => {

        this.setState({
            openConfirmation: true,
            confirmationTargetTitle: target.confirmationTargetTitle,
            confirmationTargetText: target.confirmationTargetText,
            confirmTarget: target.confirmTarget,
        });
    }

    __closeConfirmation = (event) => {

        this.setState({
            openConfirmation: false,
            confirmationTargetTitle: undefined,
            confirmationTargetText: undefined,
            confirmTarget: undefined,
        });
    }

    __processConfirmation = (target) => (event) => {


        if(target === 'FORCE_END') {

            this.__endAppointment();
        } else {
            this.setState({
                openConfirmation: false,
                confirmationTargetTitle: undefined,
                confirmationTargetText: undefined,
                confirmTarget: undefined,
            });
        }
    }

    __endAppointment = () => {

        let { id } = this.props.item;

        this.setState({loading: true})

        appointmentApi.updateAppointmentState(id, "COMPLETE")
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_END, { id: id, type: "COMPLETE" });
            })
            .catch(reason => {

                this.endCount += 1;
                notificationService.httpError(reason);
            })
            .finally(() => {

                this.setState({
                    openConfirmation: false,
                    confirmationTargetTitle: undefined,
                    confirmationTargetText: undefined,
                    confirmTarget: undefined,
                });
            });
    }

    render() {

        let { person, processing, now, start, cancelBloc } = this.state;
        let { classes, item } = this.props;
        let { focusItem } = this.props.context;

        let seconds = (now - start) / 1000;
        let minutes = seconds / 60;

        let borderColor = classes.okBorder;

        if( ["A_REVIEWED", "A_REVIEWING"].includes(item.status) ) {

            borderColor = classes.cyanishBorder;

        } else if(!(["REVIEWING", "STARTED", "RESERVED"].includes(item.status))) {

            if(item.type.startsWith('VIRTUAL_')) {
                if (minutes > 15) {

                    borderColor = classes.redBorder;
                } else if (minutes > 8) {

                    borderColor = classes.orangeBorder;
                }
            } else {
                if (minutes > 30) {

                    borderColor = classes.redBorder;
                } else if (minutes > 15) {

                    borderColor = classes.orangeBorder;
                }
            }
        }

        const medium = item && item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        const headerStyle = medium === "Telehealth" ? classes.cardHeaderTelehealth : classes.cardHeaderInPerson;

        const focused = focusItem.data && focusItem.data.id === item.id;

        const menu = this.__renderMenu(focused, medium, item);
        const body = this.__renderBody(focused, medium, item);
        const dialogs = this.__renderDialogs(focused, medium, item);

        return (
            <>
                <BaseCard headerStyle={headerStyle} borderColor={borderColor} person={person} menu={menu} item={item}>
                    { processing ? <div className={classes.processingHolder}><CircularProgress /></div> : body }
                    { dialogs }
                </BaseCard>
                <CancelAppointmentDialog bloc={cancelBloc} />
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<WaitingCard context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

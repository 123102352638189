import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import callIcon from '../../../../../../assets/icons/start_call.svg';
import {encounterEventService} from "../../service/encounter.event.service";

const styles = theme => ({
    root: {
        height: "100vh",
        backgroundColor: "#C4C4C430",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapper: {
        margin: "auto",
    },
    text: {
        margin: "auto",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "22px",
        lineHeight: "33px",
        textAlign: "center",

        color: "#FFFFFF",
    },
    callWrapper: {
        display: "flex",
    },
    click: {
        cursor: "pointer",
        margin: "auto",
    },
});


class Retry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            localMessage: this._resolveLocalErrorMessage(this.props.error),
            showRetry: this._resolveIfRetryShouldBeShown(this.props.error),
        };
    }

    _resolveLocalErrorMessage = (error) => {

        if(error.code === 1004) {
            return "The telehealth token has expired. Please contact support to increase the length a token is valid for if needed.";
        }

        return "An error occurred joining the session click to retry. If the problem persists please try refresh your browser or contact support.";
    }

    _resolveIfRetryShouldBeShown = (error) => {

        if(error.code === 1004) {
            return false;
        }

        return true;
    }

    _call = () => {

        encounterEventService.update({
           type: 'ENCOUNTER_CALL_CUSTOMER_START',
           data: {}
        });
    }

    render() {

        let { error, classes } = this.props;
        let { localMessage, showRetry } = this.state;

        return (
            <div className={ classes.root }>
                <div className={classes.wrapper}>
                    { showRetry && (
                    <div className={classes.callWrapper}>
                        <img className={classes.click} onClick={ this._call } src={ callIcon } /><br />
                    </div> )
                    }
                    <div className={classes.text}>{ localMessage }</div>
                    <div className={classes.text}>Error: { error.message }</div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Retry);

import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@material-ui/core";
import DecodedComponent from "../../../shared/DecodedComponent";


const styles = theme => ({
    root: {
    },
    icon: {
    },
});

class CancelAppointmentDialog extends DecodedComponent {

    constructor(props) {
        super(props);

        this.bloc = props.bloc;
        this.bloc.loadCancelReasons();

        this.state = { initialising: true, open: false, cancelReasons: [] };
    }

    componentDidMount() {
        super.componentDidMount();
    }


    componentWillUnmount() {
        super.componentWillUnmount();
    }

    __handleEvent = (event) => {

    }

    __cancelAppointment = () => {

        let { selectedReasonId, selectedReasonText } = this.state;

        const requestData = {
            reason: {
                id: selectedReasonId,
                description: selectedReasonText || "",
            }
        }

        this.bloc.cancelAppointment(requestData);
    }

    __handleReasonChange = (event) => {
        const { cancelReasons } = this.state;
        let change = { selectedReasonText: undefined, };
        change[event.target.name] = event.target.value;
        const selectedReasonOption = cancelReasons.filter(_option => _option.id === event.target.value);
        change.selectedReasonIdText = selectedReasonOption.length > 0 ? selectedReasonOption[0].description : undefined;
        this.setState(change);
    };

    __handleTextChange = (event) => {
        let change = { };
        change[event.target.name] = event.target.value;
        this.setState(change);
    };

    __close = () => {
        this.setState({
            selectedReasonId: undefined,
            selectedReasonIdText: undefined,
            selectedReasonText: undefined,
        });
        this.bloc.close();
    }

    render() {

        let { classes } = this.props;
        let { open, busy, selectedReasonId, selectedReasonIdText, selectedReasonText, cancelReasons } = this.state;

        return (
            <Dialog
                maxWidth={"md"}
                open={open}
                onEnter={ this.bloc.loadCancelReasons }
                onClose={ this.__close }
                onBackdropClick={ this.__close }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ValidatorForm
                    autoComplete='off'
                    onSubmit={this.__cancelAppointment}
                >
                    <DialogTitle id="alert-dialog-title">{"Cancel Appointment"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please specify a reason for cancelling the appointments and confirm.
                        </DialogContentText>
                        <TextValidator
                            id="selectedReasonId"
                            name="selectedReasonId"
                            fullWidth
                            select
                            required
                            label="Reason"
                            value={selectedReasonId}
                            onChange={this.__handleReasonChange}
                        >
                            {cancelReasons.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {this.__formatReasonDescription(option.description)}
                                </MenuItem>
                            ))}
                        </TextValidator>

                        {selectedReasonIdText && selectedReasonIdText.toLowerCase() === "other" &&
                            <TextField
                                autoFocus
                                margin="dense"
                                id="selectedReasonText"
                                name="selectedReasonText"
                                label="Description"
                                type="text"
                                fullWidth
                                required
                                value={selectedReasonText}
                                onChange={this.__handleTextChange}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="button"
                            className={classes.dialogButton}
                            onClick={ this.__close }
                            disabled={ busy }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant={"contained"}
                            color="primary"
                            disabled={ busy }
                        >
                            { busy ? <CircularProgress size="1.5em"/> : "Confirm"}
                        </Button>

                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }

    __formatReasonDescription = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
}

export default withStyles(styles)(CancelAppointmentDialog);

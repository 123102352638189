import React from "react";
import {DraggableItem, DropZone, SwipeEnabledListItem} from "../../CGICommon";
import {useCGIContext} from "../CGIContext";
import {DHChip} from "../../Common";
import {withStyles} from "@material-ui/core";
import {processDrop} from "./DifferentialDX.utils";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import {Skeleton} from "@material-ui/lab";
import {OntologyConstants} from "../../../bloc";
import ItemDetail from "../../Common/ItemDetail";
import OrderRecommendations from "../EvaluationManagement/OrderRecommendations";
import DDxRecommendations from "./DDxRecommendations";

const styles = {
    emptyCDX: {
        color: "#00D1FF",
        fontSize: 12,
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 6,
        paddingBottom: 6,
        textAlign: "center",
        margin: "1em",
        borderWidth: 1,
        borderColor: "#00d1ff",
        borderStyle: "dashed",
        borderRadius: 32,
    },
    occupiedCdx: {
        color: "#00D1FF",
        fontSize: 12,
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 6,
        paddingBottom: 6,
        textAlign: "center",
        margin: "1em",
        borderWidth: 1,
        borderColor: "#00d1ff",
        borderStyle: "dashed",
        borderRadius: 32,
    },
    currentCDX: {
        backgroundColor: "#777777",
    },
};

const allowedSemanticTypes = OntologyConstants.CONDITION.semantic;

class DifferentialDX extends DecodedComponent {

    count = -1

    constructor(props) {
        super(props);
        this.rootRef = React.createRef();
        this.bloc = props.context.bloc;
        this.state = {initialised: false, recommendationOpen: false, recommendationsMessage: undefined, ddxRecommendation: [], mouseDownTimestamp: new Date()};
    }

    __handleState = (state) => {

        const { recommendationOpen, } = this.state;
        const { currentComplaint, } = state;

        let conditions = state.workspaceGraph?.encounterReasonForVisitConditions(currentComplaint, ["differential"])

        if(conditions?.length > 0) {
            if(this.count === 0 && !recommendationOpen) {

                this.__openRecommendation(conditions[0]);
            }
            this.count = conditions.length;
        } else {
            this.count = 0;
        }

        this.setState({
            ...state,
            conditions: conditions,
        });
    }

    __openRecommendation = (condition) => {

        let props = {};

        props.recommendationOpen = true;
        props.recommendationsMessage = undefined;
        props.ddxRecommendation = [];

        this.bloc.conditionRecommendations(condition)
            .then(value => {
                if(value.data.items.length > 0) {

                    const _recommendations = value.data.items.sort((a, b) => a.code.display.toLowerCase().localeCompare(b.code.display.toLowerCase()));

                    this.setState({
                        ddxRecommendation: _recommendations,
                    });
                } else {
                    this.setState({
                        recommendationsMessage: "No results",
                    });
                }
            }, reason => {
                this.setState({
                    recommendationsMessage: "No results",
                });
            })
        ;

        this.setState({
            ...props,
        });
    }

    __handleRecommendationClose = (event) => {

        this.setState({ recommendationOpen: false, recommendationsMessage: undefined, recommendations: []});
    };


    __handleConditionMouseDown = (e) => {
        if(e.button === 2) {
            this.setState({
                mouseDownTimestamp: new Date(),
            });
        }
    }

    __handleConditionMouseUp = (e, condition) => {
        if(e.button === 2) {
            const {mouseDownTimestamp} = this.state;
            const milliseconds = this.__millisecondsBetween(mouseDownTimestamp, new Date());
            if(milliseconds < 200) {
                this.__openRecommendation(condition);
            }
        }
    }

    __millisecondsBetween(startDate, endDate) {
        return Math.abs(endDate - startDate);
    }

    render() {

        const {classes, context} = this.props;
        const {initialised, currentComplaint, differential, conditions, recommendationOpen, ddxRecommendation, recommendationsMessage} = this.state;

        if (!initialised) {
            return <div><Skeleton/></div>;
        }

        return (
            <div ref={this.rootRef}>
                <div style={{overflow: "auto", clear: "both"}}>
                    <DropZone
                        accept={[
                            "drawerChips",
                            "dxList",
                            "strickedDXList",
                            "currentWorkingDX",
                        ]}
                        processDrop={(item) =>
                            processDrop({
                                destination: "dxList",
                                item,
                                allowedSemanticTypes: allowedSemanticTypes,
                                context,
                                currentComplaint,
                            })
                        }
                        allowedSemanticTypes={allowedSemanticTypes}
                        style={{maxHeight: "100%", overflow: "auto"}}
                    >
                        <div
                            style={{
                                overflow: "auto",
                                clear: "both",
                                flexDirection: "column",
                                display: "flex",
                            }}
                        >
                            {conditions?.map((item, index) => {
                                const isSelected = item?.bid === differential.highlightedDx?.bid;
                                return (
                                    <DraggableItem
                                        type={"dxList"}
                                        item={{
                                            ...item,
                                            source: "dxList",
                                        }}
                                        index={index}
                                        key={item?.bid}
                                        accept={["dxList"]}
                                    >
                                        <SwipeEnabledListItem

                                            onRemove={() => {
                                                this.bloc.diagnosis().delete(item);
                                            }}

                                            onLeftSwipe={() => {
                                                processDrop({
                                                    destination: "strickedDXList",
                                                    item: {
                                                        ...item,
                                                        source: "dxList",
                                                        destination: "strickedDXList",
                                                    },
                                                    allowedSemanticTypes: allowedSemanticTypes,
                                                    context,
                                                });
                                            }}
                                            onRightSwipe={() => {
                                            }}
                                            disableRightSwipe
                                            style={{display: "inline-block"}}
                                        >
                                            <DHChip
                                                drawerItem={false}
                                                label={
                                                    item?.code?.value ?? item?.code?.code?.display ?? ""
                                                }
                                                index={index}
                                                colortiles={[
                                                    this.bloc.differentialColour().getColour(currentComplaint.bid, item.bid),
                                                ]}
                                                onClick={(e) => {
                                                    if (isSelected) {
                                                        this.bloc.clearSelectedDX();
                                                    } else {
                                                        this.bloc.setSelectedDX(e.target, item);
                                                    }
                                                }}

                                                onDoubleClick={(e) => {
                                                    this.bloc.setSelectedDX(e.target, item);
                                                    this.__openRecommendation(item);
                                                }}

                                                onMouseDown={this.__handleConditionMouseDown}
                                                onMouseUp={(e) => this.__handleConditionMouseUp(e, item)}
                                                style={{
                                                    backgroundColor: isSelected ? "#00D1FF" : "#fff",
                                                    color: isSelected ? "#fff" : "#333333",
                                                }}
                                            />
                                        </SwipeEnabledListItem>
                                    </DraggableItem>
                                );
                            })}
                        </div>
                    </DropZone>
                </div>
                {this.__renderRecommendations(recommendationOpen, ddxRecommendation, recommendationsMessage)}
            </div>
        );
    }

    __renderRecommendations = (recommendationOpen, ddxRecommendation, recommendationsMessage) => {
        return <ItemDetail id={'ddx-recommendations'} placement={'right'} title={"Related DDx"}
                           open={recommendationOpen} onClickAway={this.__handleRecommendationClose}
                           onCloseClick={this.__handleRecommendationClose} anchorEl={this.rootRef?.current}>
            <DDxRecommendations bloc={this.bloc} message={recommendationsMessage} ddxRecommendation={ddxRecommendation} onCloseClick={this.__handleRecommendationClose}/>
        </ItemDetail>
    }
}


export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<DifferentialDX context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

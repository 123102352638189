import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import EncounterContext from "../../context";

const styles = theme => ({
    root: {
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
    },
    rootHidden: {
    },
    fill: {
        flex: "1 1 auto",
    },
    dialogButton: {
        marginRight: '12px',
    },
});


class RightPanel extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let { classes, size } = this.props;

        return (
            <div className={size !== 'small' ? classes.root : classes.rootHidden} hidden={size === 'small'}>
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<RightPanel context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

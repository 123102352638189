import React, {Component} from 'react';

import {OTPublisher} from 'opentok-react'

import {withStyles} from "@material-ui/core/styles";
import EncounterContext from "../../context";
import {notificationService} from "../../../../../../utils/notification";

const styles = theme => ({
    root: {
        position: "absolute",
        bottom: "64px",
        right: "25px",
        zIndex: "10",
    },
    fill: {
        flex: "1 1 auto",
    },
    video: {
        width: "150px",
        height: "150px",
    },
    error: {
        width: "150px",
        height: "150px",
        backgroundColor: "#FCC123",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    }
});


class Publisher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            audio: true,
            video: true,
            videoSource: 'camera'
        };
    }

    onError = (err) => {

        notificationService.error(`Failed to publish, please ensure you have closed all other video solutions and join the session again. Reason: ${err.message}.`);
        this.setState({error: true});
    }

    render() {

        const { classes } = this.props;
        const { telehealth } = this.props.context;

        return (
            <div className={classes.root}>
                {this.state.error ?
                    <div className={classes.error}>
                        <div className={classes.fill}></div>
                        <div>Camera unavailable.</div>
                        <div className={classes.fill}></div>
                    </div> : null}
                <OTPublisher
                    className={ classes.video }
                    properties={{
                        resolution: "640x480",
                        width: "100%",
                        height: "100%",
                        publishAudio: telehealth.audio,
                        publishVideo: telehealth.video,
                        videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined
                    }}
                    onError={this.onError}
                />
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Publisher context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

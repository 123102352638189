import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import {CircularProgress, FormControl, Link, OutlinedInput} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Alert} from "@material-ui/lab";
import {AnalyticsEvent, analyticsEventLogger} from "../../utils/events";
import {authApi} from "../../utils/services/auth.api";


const styles = theme => ({
    root: {
        padding: '20px 0'
    },
    form: {
        '& > div': {
            marginBottom: '12px'
        }
    },
    fill: {
        flex: "1 1 auto",
    },
    textField: {
        width: '100%',
    },
    submit: {
    },
    actions: {
        display: "flex",
        paddingTop: "24px",
        paddingRight: "20px",
        flexDirection: "row-reverse",
        '& a' : {
            cursor: "pointer",
        },
    },
    progress: {
        color: "#fff",
    }
});


class UsernamePasswordResetRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            form: {},
            showPassword: false,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    __handleChange = (event) => {

        let { form } = this.state;
        form[event.target.name] = event.target.value;

        this.setState({
            form: form,
        });
    }

    __handle = (event) => {

        event.preventDefault();

        const { form } = this.state;

        if(form.email?.length > 3) {

            this.setState({
                message: undefined,
                error: undefined,
                loading: true,
            });

            authApi.resetPassword(form.email, window.location.href)
                .then((response) => {

                    this.setState({
                        form: {email: ""},
                    });

                    analyticsEventLogger.log(AnalyticsEvent.RESET_PASSWORD, {});
                }, reason => {

                    analyticsEventLogger.log(AnalyticsEvent.RESET_PASSWORD_ERROR, {code: reason.code});

                }).finally(() => {
                this.setState({
                    message: "Reset password link sent.",
                    loading: false,
                });
            });
        }
    }

    __toggleReset = () => {

        this.props.toggleReset();
    }

    render() {

        let { classes } = this.props;
        let { loading, form, message, error } = this.state;

        return (
            <div className={classes.root}>
                <form autoComplete="off" className={classes.form} onSubmit={this.__handle}>
                    { error && <Alert severity="error">{ error }</Alert> }
                    { message && <Alert severity="success">{ message }</Alert> }
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            id="email"
                            name="email"
                            placeholder={'Email'}
                            type={'email'}
                            value={form.email}
                            onChange={this.__handleChange}
                        />
                    </FormControl>
                    <Button
                        id={"resetBtn"}
                        type={"submit"}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}>
                        {!loading ? <FormattedMessage id="request.reset" defaultMessage={`Request`}/> : <CircularProgress className={classes.progress} size={"24px"} /> }
                    </Button>
                </form>
                <div className={classes.actions}>
                    <Link onClick={this.__toggleReset}>
                        <FormattedMessage id="remembered.password" defaultMessage={`Switch to login`}/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(UsernamePasswordResetRequest);

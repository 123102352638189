import {Typography, withStyles} from "@material-ui/core";
import React from "react";
import { DraggableItem } from "../../CGICommon";
import { DHChip } from "../../Common";
import { useCGIContext } from "../CGIContext";
import { FormattedMessage } from "react-intl";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import Box from "@material-ui/core/Box";
import DictateComponent from "../../CGICommon/Dictation/DictateComponent";
import DictateInline from "../../CGICommon/Dictation/DictateInline";
import {v4 as uuidv4} from "uuid";
import {physicalExamValueOrigin, physicalExamValueStatus} from "../PhysicalExam/physicalExamUtils";

const styles = {
  chip: {
    width: "auto",
    minWidth: "25px",
    paddingLeft: 24,
    paddingRight: 24,
    marginRight: 12,
    border: "#858585 1px  solid",
    color: "#858585",
    backgroundColor: "transparent",
  },
  typo: {
    color: "#858585",
    marginLeft: 8,
    textTransform: "uppercase",
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 18,
    textTransform: "uppercase",
    maxHeight: "23vh",
    overflow: "auto",
  },
};

class SalientFeatures extends DecodedComponent {

  constructor(props) {
    super(props);
    this.bloc = props.context.bloc;
    this.state = { audioText: undefined };
  }

  __processAudio = (dication) => {
    const { audioText } = this.state;

    if (dication.data.message?.length > 0) {

      let items = dication.data.message
          .split("[ space ]")
          .filter((_item) => _item.trim().length > 0)
          ;

      if (items.length === 0) {
        return;
      }

      this.setState({ audioText: (audioText || "") + items.join(" ") });
    }
  }

  render() {
    const { audioText } = this.state;
    return (
        <div>
          <Box>
            <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
              <DictateInline onProcess={this.__processAudio}  />
            </Box>
            <Typography>
              { audioText || "" }
            </Typography>
          </Box>
          <Typography style={styles.typo}>
            <FormattedMessage
                id={"salient.features"}
                defaultMessage={"Salient features"}
            />
          </Typography>
          <div style={styles.list}>
            {this.bloc.getSalientFeatures().map((item, index) => (
                <DraggableItem
                    type={"observations"}
                    item={{
                      ...item,
                      source: "observations",
                    }}
                    index={index}
                    key={item.bid}
                    isHoverAllowed={false}
                    accept={["observations"]}
                >
                  <DHChip
                      style={styles.chip}
                      label={item?.code?.code?.display ?? item?.code?.value}
                      drawerItem={false}
                  />
                </DraggableItem>
            ))}
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
      {value => {
        return (<SalientFeatures context={value} {...props} />);
      }
      }
    </ClinicalGraphInterfaceContext.Consumer>
));

import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  wrp: {
    display: "flex",
    width: 237,
    maxWidth: 237,
    maxHeight: 32,
    height: 32,
  },
  chip: {
    cursor: "pointer",
    padding: 10,
    height: 22,
    color: "#858585",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #858585",
  },
  left: {
    borderRight: "none",
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
  },
  middle: {
    borderRight: "none",
  },
  right: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  active: {
    color: "white",
    border: "1px solid #00D1FF",
    backgroundColor: "#00D1FF",
  },
});

class DHItemSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  getClassName = (id, index) => {
    const { classes, options, value } = this.props;

    let classNames = classes.chip;
    if (index === 0) {
      classNames += ` ${classes.left}`;
    } else if (index === options.length - 1) {
      classNames += ` ${classes.right}`;
    } else {
      classNames += ` ${classes.middle}`;
    }

    if (id.toUpperCase() === value?.toUpperCase()) {
      classNames += ` ${classes.active}`;
    }

    return classNames;
  };

  handleItemClick = (itemId) => {
    let {onSelect} = this.props;

    if (onSelect) {
      this.props.onSelect(itemId);
    }
  };

  render() {
    const { options, classes, } = this.props;

    return (
      <div className={classes.wrp}>
        {options.map((el, i) => {
          return (
            <div
              key={el}
              className={this.getClassName(el, i)}
              onClick={() => this.handleItemClick(el)}
              style={{ width: 300 / options.length, height: 32 }}
            >
              {el}
            </div>
          );
        })}
      </div>
    );
  }
}
export default withStyles(styles)(DHItemSelector);

import React, {Component} from 'react';

import {OTSubscriber} from 'opentok-react'

import {withStyles} from "@material-ui/core/styles";
import {Fab} from "@material-ui/core";
import CallEndIcon from "@material-ui/icons/CallEnd";


const styles = theme => ({
    root: {
        backgroundColor: "#000000",
        height: "inherit",
        '& :first-child': {
            position: 'absolute',
            top: 0,
            height: "inherit",
            width: "100%",
        }
    },
    dialin: {
        display: "none",
        backgroundColor: "#000000",
        position: 'absolute',
        top: '0',
        left: '0',
    },
    dialinEnd: {
        backgroundColor: "#000000",
        position: 'absolute',
        bottom: '80px',
        right: '0',
    },
    dialinFloat: {
        position: "absolute",
        bottom: "230px",
        zIndex: "1000",
        right: "24px",
        width: "152px",
    },
    dialinFloatText: {
        opacity: "0.9",
        backgroundColor: "#808A94",
        borderRadius: "30px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        height: "60px",
        lineHeight: "60px",
        padding: "0 18px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    dialinFloatBtns: {},
    action: {
        position: "relative",
        marginLeft: "100px",
        top: "-16px",
        height: "50px",
        width: "50px",
        backgroundColor: "rgba(253, 31, 84,1)",
        border: "8px solid #be345b",
        "&:hover": {
            backgroundColor: "rgba(253, 31, 84,0.6)",
        }
    },
    actionIcon: {
        color: "rgb(255, 255, 255)",
        height: "20px",
        width: "20px"
    },
    fill: {
        flex: "1 1 auto",
    },
});


class Subscriber extends Component {

    blocSubscription;

    constructor(props) {
        super(props);

        this.otSubscriber = React.createRef();

        this.state = {
            error: null,
            audio: true,
            video: true,
            dialinText: 'UNKNOWN'
        };

        this.__handleDisconnect = this.__encounterStateHandler.bind(this);
        this.__encounterStateHandler = this.__encounterStateHandler.bind(this);
    }

    componentDidMount() {

        this.blocSubscription = this.props.encounterBloc.subscribeToState(this.__encounterStateHandler);
    }

    componentWillUnmount() {

        this.blocSubscription.unsubscribe();
    }

    __encounterStateHandler = (state) => {

        if(!state) return;

        const { telehealthCall } = state;

        let display = 'WEBRTC';
        let dialinText = 'UNKNOWN';

        if(this.otSubscriber.current?.state?.stream?.connection?.data?.indexOf("\"sip\"") > 0) {

            const data = JSON.parse(this.otSubscriber.current.state.stream.connection.data);
            if(data.sip) {

                display = 'DIALIN';
                dialinText = data.name?.replace(/'/g, "");

                if(!telehealthCall) {

                    const callData = {
                        display: "UNKNOWN",
                        friendlyNumber: dialinText,
                    }

                    const connectionData = {
                        connectionId: this.otSubscriber.current.state.stream.connection.connectionId,
                    }

                    this.props.encounterBloc.setTelehealthCallStatus('ACTIVE', connectionData, callData);
                }
            }
        }

        this.setState({
            display: display,
            video: display === 'WEBRTC',
            dialinText: dialinText,
        });
    }

    __hangup = () => {

        this.props.encounterBloc.telehealthConnectionHangup(this.otSubscriber.current.state.stream.connection.connectionId);
    }

    onError = (err) => {
        this.setState({error: `Failed to subscribe: ${err.message}`});
    }

    render() {

        const {classes } = this.props;
        const { display, dialinText } = this.state;

        let properties = {
            height: "100%",
            width: "100%",
            resolution: "640x480",
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
            videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined
        };

        if(display === 'DIALIN') {
            properties.height = "0";
            properties.width = "0";
            properties.subscribeToVideo = false;
            properties.videoSource = undefined;
        }

        let root = classes.root;

        if(display === 'DIALIN') {
            root = classes.dialin;
        }

        return (
            <>
            <div className={root}>
                {this.state.error ? <div>{this.state.error}</div> : null}
                <OTSubscriber
                    ref={this.otSubscriber}
                    properties={properties}
                    onError={this.onError}
                    retry={true}
                    maxRetryAttempts={3}
                    retryAttemptTimeout={2000}
                />
            </div>
            { display === 'DIALIN' &&
            <div className={classes.dialinFloat}>
                <div className={classes.dialinFloatText}>{ dialinText }</div>
                <div className={classes.dialinFloatBtns}>
                    <Fab onClick={ this.__hangup } className={classes.action} aria-label="hangup">
                        <CallEndIcon className={classes.actionIcon} />
                    </Fab>
                </div>
            </div> }
            </>
        );
    }
}

export default withStyles(styles)(Subscriber);

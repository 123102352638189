import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import {authService} from '../../utils/auth';

import {CircularProgress, FormControl, IconButton, InputAdornment, Link, OutlinedInput} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";


const styles = theme => ({
    root: {
        padding: '20px 0'
    },
    form: {
        '& > div': {
            marginBottom: '12px'
        }
    },
    fill: {
        flex: "1 1 auto",
    },
    textField: {
        width: '100%',
    },
    submit: {
    },
    actions: {
        display: "flex",
        paddingTop: "24px",
        paddingRight: "20px",
        flexDirection: "row-reverse",
        '& a' : {
            cursor: "pointer",
        },
    },
    progress: {
        color: "#fff",
    }
});


class UsernamePasswordLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            form: {},
            showPassword: false,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    __handleChange = (event) => {

        let { form } = this.state;
        form[event.target.name] = event.target.value;

        this.setState({
            form: form,
        });
    }

    __handleLogin = (event) => {

        event.preventDefault();

        const { form, loading } = this.state;

        if(loading) return;

        if(!form.password || form.password.length < 8) {
            this.setState({
                loading: false,
                error: `Invalid credentials.`,
            });
            return;
        }

        this.setState({
            error: false,
            loading: true,
        });


        authService.loginWithUsernamePassword(form.email, form.password)
            .then((userCredential) => {
                this.setState({
                    form: { email: "", password: ""},
                    loading: false,
                });
            }, reason => {

                let error = reason.message;
                if(['auth/user-not-found', 'auth/wrong-password'].includes(reason.code)) {
                    error = `Invalid credentials.`;
                }

                this.setState({
                    form: { email: form.email, password: "" },
                    loading: false,
                    error: error,
                });
            });
    }

    __toggleShowPassword = () => {

        const { showPassword } = this.state;

        this.setState({
            showPassword: !showPassword,
        });
    }

    __toggleReset = () => {

        this.props.toggleReset();
    }


    render() {

        let { classes } = this.props;
        let { loading, form, showPassword, error } = this.state;

        return (
            <div className={classes.root}>
                <form autoComplete="off" className={classes.form} onSubmit={this.__handleLogin}>
                    { error && <Alert severity="error">{ error }</Alert> }
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            id="email"
                            name="email"
                            placeholder={'Email'}
                            type={'email'}
                            value={form.email}
                            onChange={this.__handleChange}
                            required={true}
                        />
                    </FormControl>
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            id="password"
                            name="password"
                            placeholder={'Password'}
                            type={showPassword ? 'text' : 'password'}
                            value={form.password}
                            onChange={this.__handleChange}
                            required={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.__toggleShowPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Button
                            id={"loginBtn"}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}>
                        {!loading ? <FormattedMessage id="login" defaultMessage={`Login`}/> : <CircularProgress className={classes.progress} size={"24px"} /> }
                        </Button>
                </form>
                <div className={classes.actions}>
                    <Link onClick={this.__toggleReset}>
                        <FormattedMessage id="forgot.password" defaultMessage={`Forgot password?`}/>
                    </Link>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(UsernamePasswordLogin);

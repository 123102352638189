
class ErrorResolver {

    resolveDisplay(error) {

        if(error.response && error.response.data && error.response.data.message) {
            if(error.response.data.message.startsWith("[0001-1005]")) {
                return "[0001-1005] This user already exists. Please verify the users details and try again.";
            }

            let message = error.response.data.message.replace(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g, "");
            if(message.indexOf('validation.') > -1 && (message.indexOf('.invalid') > -1 || message.indexOf('.required') > -1 )) {
                message = this.__handleInvalidOrRequiredFields(message);
            }

            return message;
        }

        return error;
    }

    __handleInvalidOrRequiredFields(message) {

        const lastIndexOfRequired = message.lastIndexOf(".required") + ".required".length;
        const lastIndexOfValid = message.lastIndexOf(".invalid") + ".invalid".length;
        const lastIndexOf = lastIndexOfValid > lastIndexOfRequired ? lastIndexOfValid : lastIndexOfRequired;
        const firstIndexOf = message.indexOf("validation.");

        if(firstIndexOf < lastIndexOf) {

            const list = message.slice(firstIndexOf, lastIndexOf)
                .split(', ')
                .map((_item) => _item.replace("validation.", ""))
                .map((_item) => _item.replace(/\./g, " "))
                .map((_item) => _item.replace(/([A-Z])/g, " $1")
                    .toLowerCase()
                );

            return `Please review the following fields as they are invalid or required: ${list.join(', ')}`;

        } else {

            return message;
        }
    }
}

export const errorResolver = new ErrorResolver();

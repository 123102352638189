import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

const styles = (theme) => ({
  formControl: {
    width: 300,
  },
  outlined: {
    borderRadius: 25,
    // height: 32,
    // width: props => !props.size || props.size === "medium" ? "50%" : "100%",
    // minWidth: "100%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
    [theme.breakpoints.up('lg')]: {
      width: props => !props.size || props.size === "medium" ? "50%" : "100%",
    },
  },
});

class DHDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selValue: props.defaultValue || "-1",
    };
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ selValue: value });
    if (this.props.onChange) this.props.onChange(value);
  };

  render() {
    const { selValue } = this.state;
    const { options, classes, disabled } = this.props;

    return (
      <Select
        variant="outlined"
        value={selValue}
        onChange={this.handleChange}
        className={classes.outlined}
        IconComponent={() => (
          <KeyboardArrowDown style={{ marginRight: 24, color: "#C4C4C4" }} />
        )}
        SelectDisplayProps={{
          style: { background: "transparent" },
        }}
        style={{ paddingTop: 4 }}
        disabled={disabled}
      >
        <MenuItem value={-1} disabled>
          Select
        </MenuItem>
        {options.map((el, i) => (
          <MenuItem key={i} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
}

export default withStyles(styles)(DHDropdown);

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";


import {OTSession, OTStreams} from 'opentok-react';

import Publisher from './Publisher';
import Subscriber from './Subscriber';
import Call from "./Call";
import End from "./End";
import EncounterContext from "../../context";
import {FormattedMessage} from "react-intl";
import Retry from "./Retry";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";

const styles = theme => ({
    root: {
        height: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    videoWrapper: {
      height: "calc(100vh)",
      '& > div:first-child': {
          height: "inherit !important",
          '& > div': {
              height: "inherit !important",
          }
      }
    },
    videoStreams: {
      height: "inherit",
    },
    videoSubscriber: {
      height: "inherit",
    },
    callingWrapper: {
        height: "calc(100vh)",
        display: "flex",
        flexDirection: "column",
    },
    callingText: {
        margin: "auto",

        textTransform: "capitalize",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "22px",
        lineHeight: "33px",
        textAlign: "center",

        color: "#FFFFFF",
    },
});


class Display extends Component {


    constructor(props, context) {
        super(props, context);

        const { encounterBloc } = this.props.context;

        this.otSession = React.createRef();

        this.state = {
            error: null,
            connected: false
        };

        this.sessionEvents = {
            sessionConnected: () => {
                setTimeout(() => {
                    encounterBloc.setTelehealthStatus('connected');
                    encounterBloc.setSession(this.otSession);
                }, 2000);
                this.setState({ connected: true });
            },
            sessionDisconnected: () => {
                encounterBloc.setTelehealthStatus('disconnected');
                this.setState({ connected: false });
            },
            streamDestroyed: (event) => {
                this.__handleDialoutDisconnect(event.stream);
            }
        };
    }


    componentDidMount() {
    }


    componentWillUnmount() {
    }

    __handleDialoutDisconnect = (stream) => {

        const { encounterBloc } = this.props.context;

        if(stream?.connection?.data?.indexOf("\"sip\"") > 0 && stream?.connection?.connectionId)  {
            encounterBloc.telehealthConnectionHangup(stream.connection.connectionId);
        }
    }

    __onError = (error) => {

        analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_SESSION_ERROR, { error_code: error.code });
        this.setState({
            error: error,
        });
    }


    render() {

        const { children, value, classes, index, ...other } = this.props;
        const { telehealth, encounterBloc } = this.props.context;

        if(telehealth.state === 'CONNECTED') {
            return (
                <div className={classes.root}>

                    <OTSession
                        ref={this.otSession}
                        apiKey={this.props.apiKey}
                        sessionId={this.props.sessionId}
                        token={this.props.token}
                        eventHandlers={this.sessionEvents}
                        onError={this.__onError}
                    >
                        { this.state.error ? <Retry error={this.state.error}/> : null }

                        <Publisher />
                        { this.state.connected ?
                            <div className={classes.videoWrapper}>
                                <OTStreams>
                                    <Subscriber encounterBloc={encounterBloc} otSession={ this.otSession } />
                                </OTStreams>
                                <End />
                            </div> :
                            <div className={classes.callingWrapper}>
                                <div className={classes.callingText}>
                                    <FormattedMessage id={`calling.text`} defaultMessage={"Connecting"} />
                                </div>
                            </div>
                        }

                    </OTSession>
                </div>
            );
        }


        // eslint-disable-next-line react/react-in-jsx-scope
        return (
            <div className={classes.root}>
                <Call />
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Display context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import {Subject} from 'rxjs';

export class AccountEventService {

    subject = new Subject();

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(type, event) {
        this.subject.next({ type: type, data: event });
    }
}

export const accountEventService = new AccountEventService();

import * as rxjs from 'rxjs';
import {notificationService} from "../../../utils/notification";
import {administrationApi} from "../../../utils/services/administration.api";


export class AdministrationBloc {

    constructor() {

        this.subject = new rxjs.BehaviorSubject({
            initialising: true,
            busy: false,
            switches: [],
        });

        this.events = new rxjs.Subject();
    }

    initialise = () => {
        administrationApi.getSwitches()
            .then((value) => {

                this.subject.next({
                    ...this.subject.value,
                    initialising: false,
                    switches: value.data.items,
                });
            }, reason => notificationService.httpError(reason));
    }


    close = () => {

    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    toggleSwitch = (code) => {

        if(this.subject.value.busy === true) return;

        this.subject.next({
           ...this.subject.value,
           busy: true,
        });

        const _switches = this.subject.value.switches;
        const _switch = _switches.filter(_item => _item.code===code)[0];
        const _value = `${(parseInt(_switch.status)+1) % 2}`

        const data = {
            action: "toggle",
            payload: {
                code: _switch.code,
                value: _value,
            }
        };

        administrationApi.toggleSwitch(data)
            .then((value) => {

                const _switches = this.subject.value.switches;
                const _switch = _switches.filter(_item => _item.code===code)[0];
                _switch.status = value.data.status;

                this.subject.next({
                    ...this.subject.value,
                    switches: _switches,
                    busy: false,
                });
            }, reason => {

                notificationService.httpError(reason);

                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                });
            });
    }

    toggleSwitchFor = (code, durationInMinutes) => {

        if(this.subject.value.busy === true) return;

        this.subject.next({
           ...this.subject.value,
           busy: true,
        });

        const _switches = this.subject.value.switches;
        const _switch = _switches.filter(_item => _item.code===code)[0];
        const _value = `${(parseInt(_switch.status)+1) % 2}`

        const data = {
            action: "toggle",
            payload: {
                code: _switch.code,
                value: _value,
                duration: durationInMinutes,
            }
        };

        administrationApi.toggleSwitch(data)
            .then((value) => {

                const _switches = this.subject.value.switches;
                const _switch = _switches.filter(_item => _item.code===code)[0];
                _switch.status = value.data.status;
                _switch.to = value.data.to;

                this.subject.next({
                    ...this.subject.value,
                    switches: _switches,
                    busy: false,
                });
            }, reason => {

                notificationService.httpError(reason);

                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                });
            });
    }
}

export class Switches {

    static UI_PORTAL_CUSTOMER = 'ui.portal.customer';
    static INTERACTION_APPOINTMENT_VIRTUAL = 'interaction.appointment.virtual';
}

export class AdministrationBlocEvent {

    static JOB_SELECTED = "JOB_SELECTED";
}

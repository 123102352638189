import React, {Component} from 'react';

import {FormattedMessage} from 'react-intl'

import {Button, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography";
import DecodedContainer from "../../../shared/Container";
import SchedulingContext from "../context";
import {scheduleApi} from "../../../../utils/services/schedule.api";
import {notificationService} from "../../../../utils/notification";
import {dateUtil} from "../../../../utils/date";


const styles = theme => ({
    root: {
        flex: "1 1 auto",
        width: "100%",
        padding: "60px 100px",
    },
    fill: {
        flex: "1 1 auto",
    },
    select: {
        width: "100%",
    },
    grid: {
        flex: "1",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    },
    focus: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "72px",
        lineHeight: "80px",
        textAlign: "center",
        color: "#20516A",
    },
    textItem: {
        flex: "1",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    blue: {
        color: theme.palette.primary.main,
    },
    amount: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "5.6em",
        lineHeight: "1.214",
        color: "#0F0F0F",
        marginBottom: "0.44643em",
    },
    confirmation: {
        width: "80%",
        lineHeight: "1.75",
        color: "#575757",
        marginBottom: "1.875em",
        paddingTop: "0.5em",
        padding: "3em",
    },
    button: {
        width: "100%",
        padding: "1em",
        textAlign: "right",
    }
});

class Dashboard extends Component {

    state = {
        loading: false,
        summary: {},
    };

    componentDidMount() {
    }

    __navigateTo = (_next) => {

        return () => {
            this.props.history.push(_next);
        };
    }

    __changeServiceType = (event) => {
        this.setState({
            serviceType: event.target.value,
        });

        const { clinic_id } = this.props.match.params;

        this.setState({ summary: {}, loading: true });

        scheduleApi.scheduleSummary(clinic_id, event.target.value)
            .then(value => {

                this.setState({
                    summary: value.data,
                });
            }).catch(reason => {
            notificationService.error("Error loading the schedule summary.");
        }).finally(() => this.setState({
            loading: false,
        }));
    }

    render() {

        const { clinic_id } = this.props.match.params;
        let { classes, handleNext } = this.props;
        let { schedulingBloc, serviceProviders } = this.props.context;
        let { serviceType, summary, loading } = this.state;

        const hasWaitingTeam = summary.waitTimes && summary.waitTimes.current;

        const displayTime = hasWaitingTeam ? dateUtil.renderTimeFromMinutes(summary.waitTimes.current) : loading ? "Loading" : "No times available.";

        const displayDateTime = summary.firstOpenSlot ? new Date(summary.firstOpenSlot).toLocaleString() : "loading";

        const display = serviceType && serviceType.length > 0 ? <>
            <Typography variant={"h1"} className={classes.focus}>
                {displayTime}
            </Typography>

            {summary.firstOpenSlot &&
            <Typography variant="h2" component={"div"}>
                <FormattedMessage id={"general.waiting.time"}
                                  defaultMessage={"Till the next available time at "}/>
                <span className={classes.blue}> {displayDateTime} </span>
            </Typography>}
        </> : <>
            <Typography variant="h2" component={"div"}>
                <FormattedMessage id={"please.select.service.type"} defaultMessage={"Please select a service to show waiting times."} />
            </Typography>
            </>;

        return (
            <div className={classes.root}>

                <DecodedContainer loading={serviceProviders.length === 0 || loading}>
                    <div className={classes.grid}>

                        <FormControl className={classes.select}>
                            <InputLabel id="select-service-type-label"><FormattedMessage id={"organisation.service_catalog.service"} defaultMessage={"Service"} /></InputLabel>
                            <Select
                                fullWidth
                                id="serviceType"
                                name="serviceType"
                                value={serviceType}
                                labelId="select-service-type-label"
                                onChange={this.__changeServiceType}
                            >
                                <MenuItem value="" disabled><FormattedMessage id={"organisation.service_catalog.service.select"} defaultMessage={"Select service type"} /></MenuItem>
                                { serviceProviders && serviceProviders
                                    .flatMap(_item => _item.serviceCatalogs)
                                    .flatMap(_item => _item.services)
                                    .map(_item => <MenuItem key={`service-select-${_item.id}`} value={_item.id}>{_item.description}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <div className={classes.fill}/>

                        <div className={classes.textItem}>
                            { display }
                        </div>

                        <div className={classes.fill}/>

                        <div className={classes.button}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.__navigateTo(`/scheduling/${clinic_id}/appointment`)}
                            >
                                <FormattedMessage id={"patient.schedule"} defaultMessage={"Schedule"} />
                            </Button>
                        </div>
                    </div>
                </DecodedContainer>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <SchedulingContext.Consumer>
        {value => {
            return (<Dashboard context={value} {...props} />);
        }
        }
    </SchedulingContext.Consumer>
));

import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export function DragableCurrentComplaints({
  children,
  type,
  item,
  index,
  isHoverAllowed = true,
  accept,
}) {
  const ref = useRef(null);
  const currentElement = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
  });
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: () => {
        return { ...item, index };
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [type, item]
  );
  drag(drop(ref));
  return (
    <div ref={currentElement}>
      <div
        key={item.bid}
        data-index={index}
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
      >
        {children}
      </div>
    </div>
  );
}

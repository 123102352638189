import axios from 'axios';

class RpaApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    list() {

        return axios.get(`${this.baseUrl}/api/rpa/manual_steps`);
    }

    get(id) {

        return axios.get(`${this.baseUrl}/api/rpa/manual_steps/${id}`);
    }

    match(appointmentId, action) {

        return axios.get(`${this.baseUrl}/api/rpa/manual_steps/_match`, { params: { appt: appointmentId, action: action }});
    }

    patch(id, data) {

        return axios.patch(`${this.baseUrl}/api/rpa/manual_steps/${id}`, data);
    }
}

export const rpaApi = new RpaApi();

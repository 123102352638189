import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {LinearProgress, Paper,} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: "100%",
        maxHeight: "100%",
        height: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    paper: {
        minHeight: "100%",
        maxHeight: "100%",
        height: "100%",
        border: "0",
        background: "#FFFFFF",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
        borderRadius: "9px",
    },
    paperContent: {
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        minHeight: "100%",
    }
});

class DecodedContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
    }

    render() {

        const paperStyle = {};

        const { classes, padding, loading } = this.props;

        if(padding) {
            paperStyle.padding = padding;
        }

        return (
            <div className={classes.root}>
                <Paper variant="outlined"  elevation={3} className={classes.paper}>
                    { loading && <LinearProgress />}
                    { !loading && <div style={{height: "0.4em"}} />}
                    <div style={{ ...paperStyle }} className={classes.paperContent}>
                        {this.props.children}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(DecodedContainer);

import React, { useState } from "react";
import {
  SwipeableList,
  SwipeableListItem,
  Type as ListType,
} from "react-swipeable-list";
import { leadingActions, trailingActions } from "./swipe.utils";
import "react-swipeable-list/dist/styles.css";

export const SwipeEnabledListItem = ({
  children,
  onRemove,
  onLeftSwipe,
  onRightSwipe,
  item,
  disableRightSwipe = false,
  disableLeftSwipe = false,
  style,
}) => {
  const [isLeftMouseButton, setIsLeftMouseButton] = useState(false);
  return (
    <SwipeableList
      style={{
        maxHeight: "100%",
        height: "auto",
        ...style,
      }}
      type={ListType.IOS}
      fullSwipe
      threshold={0.75}
    >
      <SwipeableListItem
        trailingActions={
          !isLeftMouseButton &&
          !disableLeftSwipe &&
          trailingActions(onLeftSwipe, onRemove || onLeftSwipe, item)
        }
        leadingActions={
          !isLeftMouseButton &&
          !disableRightSwipe &&
          leadingActions(onRightSwipe, item)
        }
        threshold={0.75}
      >
        <div
          onMouseDownCapture={(e) => {
            if (e.button === 0) {
              setIsLeftMouseButton(true);
            }
            if (e.button === 2) {
              setIsLeftMouseButton(false);
            }
          }}
        >
          {children}
        </div>
      </SwipeableListItem>
    </SwipeableList>
  );
};

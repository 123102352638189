import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Button, List, ListItem, ListItemText} from "@material-ui/core";

import DialIcon from '@material-ui/icons/DialerSip';
import EncounterContext from "../../context";
import {globalBloc} from "../../../../global.bloc";
import {phoneUtil} from "../../../../../../utils/phone";

const styles = theme => ({
    root: {
        height: "inherit",
        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        padding: "32px 72px"
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapperContent: {
        margin: "auto",
        paddingBottom: "32px",
    },
    tabWrapper: {
        padding: "24px 64px",
        maxHeight: "50vh",
    },

    list: {
        height: "calc(100% - 120px)",
        maxHeight: "calc(100% - 120px)",
        marginBottom: "24px",
        overflow: "auto",
    },
    listItem: {
    },
    listItemText: {
        color: "#20516A",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
    },
    highlight: {
        color: "#fff",
        backgroundColor: "#07BFF6",
        '& :hover': {
            backgroundColor: "#07BFF6",
            borderColor: "#07BFF6",
            opacity: "0.8",
        }
    },
    dialBtn: {
        height: "60px",
        borderRadius: "30px",
    },
});


class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            selection: "",
        }

        this.__click = this.__click.bind(this);
        this.__onDial = this.__onDial.bind(this);
    }

    componentDidMount() {

        const { person, encounterBloc } = this.props.context;
        const organisation = globalBloc.organisation();

        let contacts = [];

        if(organisation.contactInformation?.contactNumbers?.length > 0) {

            organisation.contactInformation.contactNumbers.forEach((number) => {

                contacts.push({
                    id: organisation.id,
                    display: `${organisation.name} - ${number.location.toLowerCase()}`,
                    formattedNumber: phoneUtil.formatPhoneNumberFullSpaced(number),
                    number: number,
                });
            });
        }

        if(encounterBloc) {

            let count = new Date().getTime();

            encounterBloc.subject.value.addressBook.forEach((_contact) => {

                contacts.push({
                    id: new Date(),
                    display: `${_contact.display}`,
                    formattedNumber: phoneUtil.formatPhoneNumberFullSpaced(_contact.contactNumber),
                    number: _contact.contactNumber,
                });
            });
        }

        if(person.contactNumber) {

            contacts.push({
                id: person.id,
                display: `${person.name.given} ${person.name.family}`,
                formattedNumber: phoneUtil.formatPhoneNumberFullSpaced(person.contactNumber),
                number: person.contactNumber,
            });
        }

        this.setState({
            contacts: contacts,
        });
    }

    __click = (contact) => (event) => {

        this.setState({
            selection: contact,
        });
    }
    __onDial = () => {

        const { selection } = this.state;

        this.props.dialOut({
            type: "TELEPHONE",
            display: selection.display,
            contactNumber: selection.number,
        });
    }

    render() {

        let { classes, telehealthStatus } = this.props;
        let { contacts, selection } = this.state;

        return (
            <div className={classes.root}>
                <List className={classes.list}>
                    { contacts.map(contact => (
                        <ListItem className={`${classes.listItem} ${contact.id === selection?.id ? classes.highlight : ''}`} button onClick={this.__click(contact)}>
                            <ListItemText className={classes.listItemText} primary={`${contact.display}`} secondary={`${contact.formattedNumber}`} />
                        </ListItem>
                    ))}
                </List>
                <div id={"dialpad-dial-container"}>
                    <Button disabled={selection !== undefined && telehealthStatus !== 'connected'}  className={classes.dialBtn} variant={"contained"} color={"primary"} onClick={this.__onDial} fullWidth><DialIcon fontSize={"large"} /></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Contacts context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import React from 'react';

import {withRouter} from 'react-router-dom'
import {
    Badge,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    TextField,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import AppointmentContext from "./context";

import {appointmentEventService} from "./service/appointment.event.service";
import {appointmentDataChange, genders} from "./util";
import {validateForm} from "./util/form";
import {notificationService} from "../../../../utils/notification";
import {FormattedMessage} from "react-intl";

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import {dateUtil} from "../../../../utils/date";
import {DateMask, PhoneTextMask, SsnTextMask} from "../../../shared/InputTextMask";
import DecodedComponent from "../../../shared/DecodedComponent";


const styles = theme => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    focus: {
        lineHeight: "80px",
        textAlign: "center",
        color: "#20516A",
    },
    formGrid: {
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    informationPanel: {
    },
    validation: {
        width: "100%",
    },
    button: {
        textAlign: "end",
    },
    title: {
        paddingTop: "32px",
    },
    alert: {
        margin: "8px 0 8px 0",
    },
    specificTime: {
        marginRight: "18px",
        marginBottom: "10px",
        minWidth: "110px",
    }
});

const left = 7;
const right = 5;

class AppointmentDetail extends DecodedComponent {

    state = {
        localLoading: false,
        allowSchedule: true,
        summary: {},
        staff: [],
    };


    constructor(props) {
        super(props);

        this.bloc = props.context.bloc;
    }

    componentDidMount() {

        super.componentDidMount();

        const { form } = this.props.context;

        if(form.appointmentType && form.appointmentType.length > 0) {

            this.bloc.reloadSelectedSchedule(form.appointmentDate, form.appointmentType);
        }
    }

    __handleTextChange = appointmentDataChange.__handleTextChange;

    __handleCheckboxChange = appointmentDataChange.__handleCheckboxChange;

    __handleIsEmergencyChange = (event) => {

        const { form } = this.props.context;

        let other = {};
        if(event.target.checked) {

            other.isInClinic = true;
            other.appointmentDate = new Date();
            other.appointmentStartTime = "";
            other.appointmentStartTimeSpecific = "";
            this.bloc.reloadSelectedSchedule(other.appointmentDate, form.appointmentType);
        }

        appointmentEventService.update("REGISTRATION_FLAG_CHANGE", {
            target: event.target.name,
            value: event.target.checked,
            other: other,
        });
    }

    __handleAppointmentProviderChange = (event) => {
        appointmentDataChange.__handleProviderChange(event);
        this.bloc.changeProvider(event.target.value, this.props.context.form);
    }

    __handleAppointmentTypeChange = (event) => {

        const serviceId = event.target.value;

        let { serviceProviders } = this.props.context;

        const service = serviceProviders
            .flatMap(_item => _item.serviceCatalogs)
            .flatMap(_item => _item.services)
            .filter(_service => _service.id === serviceId)[0];

        this.bloc.changeAppointmentType(serviceId, event.target.name, service);
    };

    __isLoadingData = () => {

        return this.props.context.loading;
    };


    __isFormValid = () => {

        const { form } = this.props.context;
        const { staff, } = this.state;

        const customerInformationValid = form.givenName && form.givenName !== "" &&
            form.familyName && form.familyName !== "" &&
            form.dob && form.dob !== "";

        const appointmentInformationValid = (form.isEmergency || (form.appointmentStartTime && form.appointmentStartTime !== "")) &&
            (staff.length === 0 || (form.appointmentProvider && form.appointmentProvider !== "")) &&
            form.appointmentType && form.chiefComplaint && form.appointmentType !== "" && form.chiefComplaint !== "";

        return customerInformationValid && appointmentInformationValid;
    }

    __doSubmit = () => {

        if(validateForm(this.props.context.form)) {
            appointmentEventService.update("REGISTRATION_APPOINTMENT_DETAIL", {})
        } else {
            notificationService.error("Invalid registration form please do it again.");
        }
    };

    __handleDateChange = (date) => {

        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: "appointmentDate",
            value: date,
            other: {
                appointmentStartTime: "",
                appointmentStartTimeSpecific: "",
            }
        });

        const { form } = this.props.context;

        this.bloc.reloadSelectedSchedule(date, form.appointmentType);
    }

    __changeAppointmentStartTime = (event) => {

        let { slots } = this.state;
        const { form } = this.props.context;

        const now = new Date();

        let slot = slots ? slots.filter(_slot => _slot.date === event.target.value) : [];

        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
            other: {
                appointmentStartTimeSpecific: slot[0].availableSlots[0].start.split(":")[1],
                isInClinic: (now.getDate() !== dateUtil.toDate(event.target.value).getDate()) ? false : form.isInClinic,
            }
        });
    }

    __selectSpecificSlot = (slotTime) => () => {

        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: "appointmentStartTimeSpecific",
            value: slotTime,
        });
    }

    __renderInformation = () => {

        let { classes } = this.props;

        let { form } = this.props.context;

        let { summary, localLoading, slots, allowSchedule } = this.state;

        const hasWaitingTeam = summary.waitTimes && summary.waitTimes.current;

        const displayTime = hasWaitingTeam ? dateUtil.renderTimeFromMinutes(summary.waitTimes.current) : localLoading ? "Loading" : allowSchedule ? "No times available for today." : "This is an on demand appointment type.";

        const displayDateTime = summary.firstOpenSlot ? new Date(summary.firstOpenSlot).toLocaleString() : "loading";


        if(form.isEmergency || !form.appointmentType || form.appointmentType.length === 0) {
            return <></>;
        }

        let slot = slots ? slots.filter(_slot => _slot.date === form.appointmentStartTime) : [];

        const elevation = 0;

        if(slot.length > 0) {

            return <Paper className={classes.informationPanel} elevation={elevation}>
                <Alert severity="info">
                    <AlertTitle>Approximate time is {dateUtil.formatTimeWithSetMinutes(slot[0].date, slot[0].availableSlots[0].start)}</AlertTitle>
                    <FormattedMessage id={"general.select.specific.below"} defaultMessage={"Optionally, please select a specific time below."}/>
                </Alert>
            </Paper>;
        } else if(slots && slots.length > 0) {
            return <Paper className={classes.informationPanel} elevation={elevation}>
                <Alert severity="info">
                    <AlertTitle>{ slots.length } slots available for the selected date.</AlertTitle>
                </Alert>
            </Paper>;
        } else {
            return <Paper className={classes.informationPanel} elevation={elevation}>
                <Alert severity="info">
                    <AlertTitle>{localLoading ? "Loading..." : "No slots available for the selected date." }</AlertTitle>
                    { summary.firstOpenSlot && <>
                        <FormattedMessage id={"general.waiting.time"} defaultMessage={"Next available time at "}/>
                    <span className={classes.blue}> {displayDateTime} </span></>
                    }
                </Alert>
            </Paper>;
        }
    }

    render() {
        let {classes} = this.props;

        let { form, serviceProviders, } = this.props.context;

        let { localLoading, slots, summary, staff } = this.state;

        const selectedSlot = slots ? slots.filter((_slot) => _slot.date === form.appointmentStartTime ) : [];

        let specificSlots = [];
        if(selectedSlot && selectedSlot.length > 0) {
            selectedSlot[0].availableSlots.forEach((_slot) => {
                if(!specificSlots.includes(_slot.start.split(":")[1])) {
                    specificSlots.push(_slot.start.split(":")[1]);
                }
            });
        }

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ValidatorForm onSubmit={this.__doSubmit} className={classes.validation}>
                    <div className={classes.form}>
                        <div className={classes.formInput}>
                            <Grid container spacing={3}>

                                <Grid className={classes.formGrid} container xs={7}>
                                    <Grid className={classes.formGrid} container spacing={2} xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={"h3"} component="h3">
                                            <FormattedMessage id={"customer.search.title"} defaultMessage={"Customer Detail"} />
                                        </Typography>

                                        <Alert className={classes.alert} severity="info"><FormattedMessage id={"customer.search.description"} defaultMessage={"Please enter the firstname, lastname and date of birth. The remainder of the fields are optional, but they do help with the accuracy of the search."} /></Alert>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            inputProps={{
                                                autoComplete: 'off'
                                            }}
                                            id="givenName"
                                            label="Customer First Name"
                                            name="givenName"
                                            value={form.givenName}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="familyName"
                                            label="Customer Last Name"
                                            type="text"
                                            name="familyName"
                                            value={form.familyName}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="dob"
                                            label="Date of birth"
                                            name="dob"
                                            value={form.dob}
                                            onChange={this.__handleTextChange}
                                            placeholder="MM/dd/yyyy e.g. 02/01/2000"
                                            validators={['matchRegexp:^(0[1-9]|1[012])/(0[0-9]|[12][0-9]|3[01])/(18|19|20)[0-9]{2}$']}
                                            errorMessages={['Invalid date of birth. Please use the format MM/dd/yyyy e.g. 02/01/2000']}
                                            InputProps={{
                                                inputComponent: DateMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="gender"
                                            name="gender"
                                            fullWidth
                                            select
                                            label="Gender (optional)"
                                            value={form.gender}
                                            onChange={appointmentDataChange.__handleGenderChange}
                                        >
                                            {genders.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            fullWidth
                                            id="ssn"
                                            label="Social security number (optional)"
                                            name="ssn"
                                            value={form.ssn}
                                            onChange={this.__handleTextChange}
                                            placeholder="e.g. 888-88-8888"
                                            validators={['matchRegexp:^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$']}
                                            errorMessages={['Invalid social security number.']}
                                            InputProps={{
                                                inputComponent: SsnTextMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            fullWidth
                                            id="number"
                                            label="Mobile Number (optional)"
                                            name="number"
                                            value={form.number}
                                            onChange={this.__handleTextChange}
                                            inputMode="tel"
                                            autoComplete='off'
                                            inputProps={{
                                                autoComplete: 'off'
                                            }}
                                            placeholder="e.g. (202) 555-1234"
                                            validators={['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']}
                                            errorMessages={['Invalid phone number']}
                                            InputProps={{
                                                inputComponent: PhoneTextMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextValidator
                                            fullWidth
                                            id="email"
                                            label="Email Address (optional)"
                                            name="email"
                                            type="email"
                                            value={form.email}
                                            onChange={this.__handleTextChange}
                                            validators={['isEmail']}
                                            errorMessages={['email is not valid']}
                                        />
                                    </Grid>

                                    </Grid>
                                </Grid>

                                <Grid className={classes.formGrid} container xs={5}>

                                </Grid>

                                <Grid className={classes.formGrid} container spacing={2} xs={7}>

                                    <Grid item xs={12}>
                                        <Typography className={classes.title}  variant={"h3"} component="h3">
                                            <FormattedMessage id={"customer.search.title"} defaultMessage={"Reason for visit"} />
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="chiefComplaint"
                                            label="Reason for appointment"
                                            name="chiefComplaint"
                                            value={form.chiefComplaint}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="appointmentType"
                                            name="appointmentType"
                                            fullWidth
                                            select
                                            required
                                            disabled={localLoading}
                                            label="Appointment Type"
                                            value={form.appointmentType}
                                            onChange={this.__handleAppointmentTypeChange}
                                            helperText="Please select the desired appointment type">
                                            { serviceProviders && serviceProviders
                                                .flatMap(_item => _item.serviceCatalogs)
                                                .flatMap(_item => _item.services)
                                                .map(_item => <MenuItem key={`service-select-${_item.id}`} value={_item.id}>{_item.description}</MenuItem>)}
                                        </TextField>
                                    </Grid>

                                    { staff.length > 0 &&
                                        <Grid item xs={12}>
                                            <TextField
                                                id="appointmentProvider"
                                                name="appointmentProvider"
                                                fullWidth
                                                select
                                                required
                                                disabled={localLoading || staff.length === 0}
                                                label="Appointment Provider"
                                                value={form.appointmentProvider}
                                                onChange={this.__handleAppointmentProviderChange}
                                                helperText="Please select the desired provider">
                                                { staff.map(_item => <MenuItem key={`staff-select-${_item.id}`} value={_item.id}>{_item.name.given} {_item.name.family}</MenuItem>)}
                                            </TextField>
                                        </Grid>
                                    }
                                    <Grid item xs={6}>
                                        <DatePicker
                                            fullWidth
                                            disabled={localLoading || form.isEmergency || (!form.appointmentType || form.appointmentType.length === 0) || (staff.length > 0 && (!form.appointmentProvider || form.appointmentProvider === ""))}
                                            label={ <FormattedMessage id={"appointment.detail.book.date"} defaultMessage={"Appointment date"} /> }
                                            id="appointmentDate"
                                            name="appointmentDate"
                                            minDate={new Date()}
                                            value={form.appointmentDate}
                                            onChange={this.__handleDateChange}
                                            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                                const date = dateUtil.formatIsoDateAdjust(day);
                                                let capacity = 0;
                                                if(summary && summary.schedule) {
                                                    const summaryInformation = summary.schedule.filter((_item) => _item.date === date);

                                                    if (summaryInformation.length > 0) {
                                                        capacity = summaryInformation[0].capacity;
                                                    }
                                                }

                                                if(capacity > 0) {
                                                    return <Badge color="primary" variant="dot" anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }} badgeContent={capacity > 0 ? `${capacity}` : undefined }>{dayComponent}</Badge>;
                                                } else {
                                                    return dayComponent;
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="appointmentStartTime"
                                            name="appointmentStartTime"
                                            disabled={localLoading || form.isEmergency || (!slots || slots.length === 0)}
                                            fullWidth
                                            select
                                            required={!form.isEmergency}
                                            label={(!slots || slots.length === 0) ? "No time slots available" : "Appointment interval"}
                                            value={form.appointmentStartTime}
                                            onChange={this.__changeAppointmentStartTime}
                                            helperText={(!slots || slots.length === 0) ? "Please select another day" : "Please select the desired appointment interval"}
                                        >
                                            { slots && slots.length > 0 && slots
                                                .map(_item => <MenuItem key={`service-select-${_item.date}`} value={_item.date}>{_item.start} - {_item.end}</MenuItem>)
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        { this.__renderInformation() }
                                    </Grid>
                                    <Grid item xs={12}>
                                        { specificSlots.length > 0 && specificSlots.map((_available) => <>
                                            <Button id={`slot-${_available}`} className={classes.specificTime} color={"primary"} variant={ _available === form.appointmentStartTimeSpecific ? "contained" : "outlined"} onClick={this.__selectSpecificSlot(_available)}>{ dateUtil.formatTimeWithSetMinutes(selectedSlot[0].date, _available)}</Button>
                                        </>) }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={(!form.appointmentType || form.appointmentType.length === 0)}
                                                    checked={form.isEmergency}
                                                    onChange={this.__handleIsEmergencyChange}
                                                    id="isEmergency"
                                                    name="isEmergency"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <React.Fragment>
                                                    This is an emergency, see patient now and notify patients of delay.
                                                </React.Fragment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={(!form.appointmentType || form.appointmentType.length === 0 || form.isEmergency)}
                                                    checked={form.isInClinic}
                                                    onChange={this.__handleCheckboxChange}
                                                    id="isInClinic"
                                                    name="isInClinic"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <React.Fragment>
                                                    Patient is in the clinic
                                                </React.Fragment>
                                            }
                                        />
                                    </Grid>

                                </Grid>

                                <Grid item spacing={4} xs={5}>
                                </Grid>

                            </Grid>
                        </div>
                        <div className={classes.fill}/>
                        <div className={classes.button}>
                            <Button
                                id={"appointmentDetailSubmitBtn"}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!this.__isFormValid() || this.__isLoadingData()}
                            >
                                {this.__isLoadingData() && <CircularProgress size="1.5em"/>}
                                {!this.__isLoadingData() && "Continue"}
                            </Button>
                        </div>
                    </div>
                </ValidatorForm>
                </MuiPickersUtilsProvider>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<AppointmentDetail context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
)));

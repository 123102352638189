import React from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import AdministrationContext from "./context";
import {AdministrationBloc} from "./administration.bloc";
import PlatformSwitch from "./components/PlatformSwitch";
import {usersApi} from "../../../utils/services/users.api";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
        padding: "24px 12px 0 32px",
        minHeight: "calc(100% - 56px)",
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    title: {

    }
});


class Administration extends React.Component {

    bloc;
    subscription;
    eventSubscription;

    constructor(props) {
        super(props);

        this.bloc = new AdministrationBloc();

        this.state = {
            initialising: true,
            busy: false,
            switches: [],
        };

        this.__handleStateChange = this.__handleStateChange.bind(this);
    }

    componentDidMount() {
        navigationTitleService.update("Administration - Switches");
        this.eventSubscription = this.bloc.subscribeToState(this.__handleStateChange)
        this.bloc.initialise();
    }


    componentWillUnmount() {
        this.bloc.close();
        this.eventSubscription.unsubscribe();
    }


    __handleStateChange = (state) => {

        this.setState({
            ...state,
        });
    }

    __handleEvent = (event) => {

        const type = event.event;
        const data = event.data;
    }


    render() {

        const { classes } = this.props;
        const { initialising, busy, switches } = this.state;

        let context = {
            busy: busy,
            bloc: this.bloc,
        };

        return (
            <AdministrationContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Grid className={classes.title} xs={"12"}>
                            <Typography variant={"h6"} component={"span"}>System Settings</Typography>{ (initialising || busy) && <CircularProgress size={20}/> }
                        </Grid>
                        <Grid xs={"12"}>
                            { switches.map(_switch => this.__renderSwitch(_switch)) }
                        </Grid>
                    </Grid>
                </div>
            </AdministrationContext.Provider>
        );
    }

    __renderSwitch = (_switch) => {
        return <>
            <PlatformSwitch item={_switch} />
        </>;
    }
}

Administration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Administration));

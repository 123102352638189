import React, { useRef } from "react";
import { withStyles } from "@material-ui/core";
import { DraggableItem, DropZone, SwipeEnabledListItem } from "../../CGICommon";
import { DHChip } from "../../Common";
import { processDrop } from "./DifferentialDX.utils";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import {Skeleton} from "@material-ui/lab";
import {OntologyConstants} from "../../../bloc";

const styles = {
  label: {
    textDecoration: "line-through",
  },
};

const allowedSemanticTypes = OntologyConstants.CONDITION.semantic;

class StrickedDifferentialDX extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;
        this.state = {initialised: false};
    }

    render() {

        const { classes, context} = this.props;
        const { initialised, currentComplaint, differential, workspaceGraph } = this.state;

        if(!initialised) {
            return <div><Skeleton /></div>;
        }


        return (
            <div style={{ overflow: "hidden", clear: "both" }}>
                <DropZone
                    accept={["dxList"]}
                    processDrop={(item) =>
                        processDrop({
                            destination: "strickedDXList",
                            item,
                            allowedSemanticTypes: allowedSemanticTypes,
                            context,
                        })
                    }
                    allowedSemanticTypes={allowedSemanticTypes}
                >
                    <div
                        style={{
                            overflow: "hidden",
                            clear: "both",
                            flexDirection: "column",
                            display: "flex",
                            minHeight: 100,
                            marginTop: 12,
                        }}
                    >
                        {workspaceGraph?.encounterReasonForVisitConditions(currentComplaint, ["refuted"]).map((item, index) => {
                            const isSelected = item.bid === differential.highlightedDx?.bid;
                            return (
                                <DraggableItem
                                    type={"strickedDXList"}
                                    item={{
                                        ...item,
                                        source: "strickedDXList",
                                    }}
                                    key={item.bid}
                                    index={index}
                                    accept={["dxList"]}
                                >
                                    <SwipeEnabledListItem
                                        onRightSwipe={() => {
                                            processDrop({
                                                destination: "dxList",
                                                item: {
                                                    ...item,
                                                    source: "strickedDXList",
                                                    destination: "dxList",
                                                },
                                                allowedSemanticTypes,
                                                context,
                                            });
                                        }}
                                        onLeftSwipe={() => {}}
                                        disableLeftSwipe
                                    >
                                        <DHChip
                                            label={item.code.value ?? item.code.code.display}
                                            index={index}
                                            classes={{ label: classes.label }}
                                            drawerItem={false}
                                            onClick={(e) => {
                                                if (isSelected) {
                                                    this.bloc.clearSelectedDX();
                                                } else {
                                                    this.bloc.setSelectedDX(e.target, item);
                                                }
                                            }}
                                            style={{
                                                backgroundColor: isSelected ? "#00D1FF" : "#fff",
                                                color: isSelected ? "#fff" : "#858585",
                                            }}
                                        />
                                    </SwipeEnabledListItem>
                                </DraggableItem>
                            );
                        })}
                    </div>
                </DropZone>
            </div>
        );
    }
}



export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<StrickedDifferentialDX context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

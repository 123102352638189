import * as rxjs from "rxjs";

import {notificationService} from "../../../../../../../../../utils/notification";
import { CommonBlocEvent } from "../Common/bloc";
import {cgiApi} from "../../../../../../../../../utils/services/cgi.api";

import {RecommendationGraph} from "./RecommendationGraph";
import {uuidUtil} from "../../../../../../../../../utils/uuidUtil";

export class Relationship {
    static EVIDENCE_OF = "evidence_of";
    static OBSERVED_ON = "observed_on";
    static BASED_ON = "based_on";
}

export class Bloc {

    alwaysRefresh = true;

    constructor(cgiBloc, formBloc, items) {

        this.subject = new rxjs.BehaviorSubject({
            initialised: false,
            loading: false,
            added: [],
            recommendationGraph: RecommendationGraph.instanceOfRecommendationGraph(),
        });

        this.events = new rxjs.Subject();

        this.parent = cgiBloc;
        this.formBloc = formBloc;
    }

    __updateSubject = (props) => {
        this.subject.next({
            ...this.subject.value,
            ...props,
        });
    }


    initialise = () => {

        this.__load();
        this.formEventSubscription = this.formBloc.subscribeToEvents(this.__handleFormEvents);
    }

    refresh = () => {
        this.__refreshRecommendations();
    }

    __handleFormEvents = (event) => {
        const { type } = event;
        switch (type) {
            case  CommonBlocEvent.NEW_ITEM_ADDED: {
                this.__refreshRecommendations();
            }
                break;
        }
    }

    close = () => {

        if(this.formEventSubscription) {
            this.formEventSubscription.unsubscribe();
        }
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    updateRecommendation = (recommendations) => {}

    __load = () => {
        this.__refreshRecommendations();
    }

    __refreshRecommendations = () => {

        const { clinicId, encounter, } = this.parent.subject.value;
        const { recommendationGraph, loading, } = this.subject.value;

        if(loading) {
            return;
        }

        this.__updateSubject({
            loading: true,
        });

        const added = this.formBloc.childrenUpdated()
            .map(item => {
                return {
                    code: { code: item.code.code },
                    types: [item.semanticType],
                };
            });

        const payload = {
            "concern": "history",
            "context": {
                "include": added
            }
        }

        cgiApi.recommend(clinicId, encounter.id, payload)
            .then(value => {

                recommendationGraph.handle(value.data);

                this.__updateSubject({
                    recommendationGraph: recommendationGraph,
                });
                this.events.next({
                    type: RecommendationsBlocEvent.RECOMMENDATIONS_UPDATED,
                    data: value.data.items,
                });
            }, reason => {
                notificationService.httpError(reason);
            }).finally(() => {
            this.__updateSubject({
                initialised: true,
                loading: false,
            });
        });
    }

    conditions = (items) => {
        const { recommendationGraph, } = this.subject.value;

        const added = this.formBloc.childrenUpdated()
            .map(item => item.code.code);

        let conditions = [];

        const medications = [...items.filter(section => section.code.code === "medications")
            .flatMap(section => section.items)
            .map(item => item.code.code), ...added];

        const ids = recommendationGraph.internal.filterNodes((id, attributes) => {
            const codes = attributes.codes || [];
            for (let i = 0; i < codes.length; i++) {
                if(medications.includes(codes[i].code)) {
                    return true;
                }
            }
            return false;
        })

        let all = {};

        if(ids.length > 0) {

            for (let i = 0; i < ids.length; i++) {

                const id = ids[i];

                recommendationGraph.internal
                    .mapInEdges(id, (key, edge) => edge)
                    .filter((edge) => ["may_be_treated_by"].includes(edge.rel))
                    .map((edge) => recommendationGraph.internal.getNodeAttributes(edge.from))
                    .filter((from) => {
                        return recommendationGraph.internal
                            .mapInEdges(from.bid, (index, edge) => edge)
                            .filter((edge) => ["may_have_condition"].includes(edge.rel) && edge.count > 0).length > 0;
                    })
                    .filter((from) => (from.codes.filter((code) => added.includes(code.code)).length === 0))
                    .forEach((from) => {

                        if (all.hasOwnProperty(from.bid)) {
                            all[from.bid].count++;
                        } else {
                            from.count = 0;
                            all[from.bid] = from;
                            conditions.push(from);
                        }
                    });
            }
        }

        if(conditions.length < 6) {

            const profile = recommendationGraph.internal.filterNodes((id, attributes) => {
                return !attributes.class || attributes.class === 'profile';
            });

            if(profile.length > 0) {

                const added = this.formBloc.childrenUpdated()
                    .map(item => item.code.code);

                const resources = this.formBloc.childrenResources()
                    .filter((resource) => resource.class === "condition")
                    .map(item => item.code.code);

                const codeFilter = [...added, ...resources]

                recommendationGraph.internal
                    .mapOutEdges(profile[0], (key, edge) => edge)
                    .filter((edge) => ["may_have_condition"].includes(edge.rel) && edge.count > 0)
                    .sort((a, b) => b.count - a.count)
                    .forEach((edge) => {
                        let node = recommendationGraph.internal.getNodeAttributes(edge.to);
                        if(!codeFilter.includes(node.codes[0].code)) {
                            if (all.hasOwnProperty(node.bid)) {
                                all[node.bid].count++;
                            } else {
                                node.count = 0;
                                all[node.bid] = node;
                                conditions.push(node);
                            }
                        }
                    });
            }
        }

        return conditions
            .slice(0, 5)
            .sort((a, b) => b.count - a.count);
    }

    medications = (items) => {
        const { recommendationGraph, } = this.subject.value;

        let addedConditions = this.formBloc.childrenUpdated()
            .filter(item => item.class === "condition")
            .map(item => item.code.code);

        let added = this.formBloc.childrenUpdated()
            .map(item => item.code.code);

        let medications = [];

        let conditions = items.filter(section => section.code.code === "problems-list")
            .flatMap(section => section.items)
            .map(item => item.code.code);

        conditions = [...conditions, ...addedConditions]

        const ids = recommendationGraph.internal.filterNodes((id, attributes) => {
            const codes = attributes.codes || [];
            for (let i = 0; i < codes.length; i++) {
                if(conditions.includes(codes[i].code)) {
                    return true;
                }
            }
            return false;
        });

        let all = [];
        let counts = [];

        for (let i = 0; i < ids.length; i++) {

            const id = ids[i];

            let newArray =recommendationGraph.internal
                .mapOutEdges(id, (key, edge) => edge)
                .filter((edge) => ["may_be_treated_by"].includes(edge.rel))
                .sort((a, b) => b.probability - a.probability)
                .map((edge) => {
                    let node = recommendationGraph.internal.getNodeAttributes(edge.to);
                    node.probability = edge.probability;
                    return node;
                })
            ;
            all.push(newArray)
            counts.push(0)
        }

        let array = 0;
        let missed = 0;

        if(all.length > 0) {
            for (let i = 0; i < 10; i++) {

                const start = counts[array];
                const target = all[array];
                if (start >= target.length) {
                    missed += 1;
                } else {
                    counts[array] = start + 1;
                    const medication = target[start];
                    if (medication.codes.filter((code) => added.includes(code.code)).length === 0) {
                        medications.push(target[start]);
                        added.push(medication.codes[0].code);
                    }
                    missed = 0;
                }
                if (missed > all.length) break;
                array = (array + 1) % (ids.length);
            }
        }


        return medications;
    }

    addRecommendation = (item,clazz) => {
        let { updated, working } = this.subject.value;

        if(working) {
            return;
        }

        let semanticType = this.semanticType;
        if(item.types?.length > 0) {
            semanticType = item.types[0].code
        } else if(item.semanticTypes?.length > 0) {
            semanticType = item.semanticTypes[0].tui
        } else if(item.semanticType) {
            semanticType = item.semanticType;
        }

        const code = item.codes[0];

        let value = { id: uuidUtil.next(), class: clazz, semanticType: semanticType, code: { display: item.name, code: code.code, system: code.system}};

        if(clazz==='condition') {
            value.onset = { onset: "" }
        } else if(clazz === 'medication') {
            value.start = { onset: "" }
            value.sig = ""
            value.note = ""
        }

        this.formBloc.addRecommendation(value);
        this.__updateSubject({ working: true });
        setTimeout(() => {
            this.__updateSubject({ working: false });
        }, 2000);
    }
}

export class RecommendationsBlocEvent {

    static RECOMMENDATIONS_UPDATED = "RECOMMENDATIONS_UPDATED";
    static RECOMMENDATION_SELECTED = "RECOMMENDATION_SELECTED";
}

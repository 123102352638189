import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Backdrop, Box, Button, Divider, Drawer, Grid, IconButton, Typography} from "@material-ui/core";

import {Skeleton} from '@material-ui/lab';

import VirtualClinicContext from "../context";

import CloseIcon from '@material-ui/icons/Close';

import EncounterCard from "./components/EncounterCard";
import {FormattedMessage} from "react-intl";
import ReservedCard from "./components/cards/ReservedCard";
import WaitingCard from "./components/cards/WaitingCard";
import BusyCard from "./components/cards/BusyCard";
import {navigationTitleService} from "../../../../utils/title";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {clinicWaitingRoomEventService} from "../service/clinic.waitingroom.event.service";
import {format} from "date-fns";
import ProcessingCard from "./components/cards/ProcessingCard";
import SearchBar from "./components/SearchBar";
import {ReactComponent as CustomersNavIconSvg} from "../../../../assets/icons/nav/customers_nav.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import ScheduleWidget from "../../Scheduling/ScheduleWidget";
import ScheduleWidgetContext from "../../Scheduling/ScheduleWidget/context";


const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 68px)",
        height: "calc(100vh - 68px)",
        backgroundColor: "#fff",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },

    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },

    cardWrapper: {
        display: "flex",
        padding: "14px 35px 50px 50px",
        flexWrap: "wrap",
    },
    card: {
        margin: "16px",
        padding: "17px 21px 20px 12px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },

    cardEncounterPlaceholder: {
        height: "100%",
        display: "flex",
        '& span': {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#98A3AE",
            margin: "auto",
        }
    },

    cardHeader: {
        display: "flex",
        paddingBottom: "20px",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        display: "flex",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "3px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        flex: "1",
    },
    cardFooter: {
        paddingTop: "13px",
        display: "flex",
    },
    footerIndicator: {
        paddingTop: "4px",
        paddingRight: "7px",
        color: "#ff516A",
        height: "20px",
        width: "20px",
    },
    footerText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    footerTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    complaint: {
        minHeight: "115px",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
    },
    datePicker: {
        maxWidth: "14em",
        padding: "1em 2em",
    },
    filterGroup: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "2em",
        maxWidth: "100%",
        width: "100%",
    },
    searchBar: {
        paddingRight: "2em",
        width: "50%",
    },
    filterButton: {
        paddingRight: "2em"
    },
    filterResult: {
        paddingTop: "1em",
        paddingLeft: "2em",
        color: "#6b6a6a",
    },
    loadButton: {
        paddingTop: "1.5em",
    },

    drawer: {
        width: "calc(100vw - 90px)",
        flexShrink: 0,
    },
    drawerControls: {
        display: "flex",
        flexDirection: "row-reverse",
        padding: "32px",
    },
    drawerPaper: {
        width: "calc(100vw - 90px)",
    },
});

const DEFAULT_QUEUE_SIZE = 30;
const QUEUE_INCREMENT_SIZE = 30;

class WaitingRoom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: true,
            cases: [],
            subjects: [],

            isDatePickerOpen: false,
            drawerRight: {},
        };
    }

    componentDidMount() {

        navigationTitleService.update("Waiting Room");

        let { encounterFilter : {filterSwitch, searchDate, queueSize} } = this.props.context;

        const filterEnableFlag = process.env.REACT_APP_APPOINTMENT_FILTER_ENABLED;
        let filterEnabled = filterEnableFlag.length > 0 ? parseInt(filterEnableFlag) : 0;
        if (filterEnabled === 1){

            clinicWaitingRoomEventService.setSearchTerm("");

            switch (filterSwitch) {
                case "date":
                    this.__handleDateChange(searchDate);
                    break;
                case "current":
                    this.__handleCurrentButtonClick();
                    break;
            }
        }
        else {
            this.__handleCurrentButtonClick();
        }
    }

    componentWillUnmount() {
        clinicWaitingRoomEventService.stop();
    }

    handleSearchButtonClick = (searchValue) => {

        clinicWaitingRoomEventService.update({
            type: 'WAITING_LIST_FILTER_UPDATE', data: {
                queueSize: DEFAULT_QUEUE_SIZE,
            }
        });

        clinicWaitingRoomEventService.setSearchTerm(searchValue);
        clinicWaitingRoomEventService.restartAppointmentPoll(DEFAULT_QUEUE_SIZE, 'WAITING_LIST_LOADING')
    }

    __handleDateChange = (date) => {

        clinicWaitingRoomEventService.update({
            type: 'WAITING_LIST_FILTER_UPDATE',
            data: {
                searchDate: date,
                filterSwitch: "date",
                queueSize: DEFAULT_QUEUE_SIZE,
            }
        });

        clinicWaitingRoomEventService.setFilterDate(date);
        clinicWaitingRoomEventService.restartAppointmentPoll(DEFAULT_QUEUE_SIZE, 'WAITING_LIST_LOADING')
    };

    __handleCurrentButtonClick = () => {

        clinicWaitingRoomEventService.update({
            type: 'WAITING_LIST_FILTER_UPDATE', data: {
                filterSwitch: "current",
                searchDate: null,
                queueSize: DEFAULT_QUEUE_SIZE,
            }
        });

        clinicWaitingRoomEventService.clearFilterDate();
        clinicWaitingRoomEventService.restartAppointmentPoll(DEFAULT_QUEUE_SIZE, 'WAITING_LIST_LOADING')
    }

    __handleLoadButtonClick = () => {

        let { encounterFilter : {filterSwitch, searchDate, queueSize} } = this.props.context;

        const newSize = queueSize + QUEUE_INCREMENT_SIZE;

        clinicWaitingRoomEventService.update({
            type: 'WAITING_LIST_FILTER_UPDATE', data: {
                filterSwitch: filterSwitch,
                searchDate: searchDate,
                queueSize: newSize,
            }
        });

        clinicWaitingRoomEventService.restartAppointmentPoll(newSize, 'WAITING_LIST_EXTRA_LOADING')
    }

    __setDatePickerOpen = (value) => {

        this.setState({
            isDatePickerOpen: value
        })
    }

    __openScheduleModal = () => {
        this.setState({ drawerRight: { open: true, component: 'scheduler' } })
    }

    __closeDrawerRight = () => {
        this.setState({ drawerRight: { } })
    }

    __doNothing = () => {
        console.log("Did nothing....");
    }

    render() {

        let { classes } = this.props;
        let { queue, encounters, focusItem, encounterFilter : {filterSwitch, searchDate} } = this.props.context;
        let { isDatePickerOpen, drawerRight } = this.state;

        let busy = queue.busy;

        let queueShowing = queue.items ? queue.items.length : 0;
        let activeShowing = encounters.appointments ? encounters.appointments.length : 0
        let showingEncounters = queueShowing + activeShowing;
        let totalEncounters = queue.total + activeShowing;

        const filterEnableFlag = process.env.REACT_APP_APPOINTMENT_FILTER_ENABLED;
        let filterEnabled = filterEnableFlag.length > 0 ? parseInt(filterEnableFlag) : 0;

        const textSearchEnableFlag = process.env.REACT_APP_APPOINTMENT_TEXT_SEARCH_ENABLED;
        let textSearchEnabled = textSearchEnableFlag?.length > 0 ? parseInt(textSearchEnableFlag) : 0;

        return (
            <div className={classes.root}>

                {filterEnabled === 1 &&
                <div>
                    <div className={classes.filterGroup}>
                        <Grid container item
                              direction="row"
                              justify="flex-start"
                              alignItems="center"

                        >
                            <Grid item className={classes.filterButton}>
                                <Button
                                    onClick={this.__handleCurrentButtonClick}
                                    variant={filterSwitch === "current" ? "contained" : "outlined"}
                                    color={"primary"}
                                >
                                    Current Queue
                                </Button>
                            </Grid>
                            <Grid item className={classes.filterButton}>
                                <Button
                                    onClick={() => this.__setDatePickerOpen(true)}
                                    variant={filterSwitch === "date" ? "contained" : "outlined"}
                                    color={"primary"}
                                >
                                    {searchDate ? format(searchDate, 'MM/dd/yyyy') : "SELECT DATE"}
                                </Button>

                            </Grid>

                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        open={isDatePickerOpen}
                                        onOpen={() => this.__setDatePickerOpen(true)}
                                        onClose={() => this.__setDatePickerOpen(false)}
                                        value={searchDate}
                                        variant="dialog"
                                        showTodayButton
                                        onChange={this.__handleDateChange}
                                        TextFieldComponent={() => null}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        {textSearchEnabled === 1 &&
                            <div className={classes.searchBar}>
                                <SearchBar
                                    handleRequestSearch={this.handleSearchButtonClick}
                                    cancelOnEscape={true}
                                />
                            </div>
                        }
                    </div>

                    <Divider variant="middle"/>

                    {!busy &&
                    <Typography variant="body2" component={"div"} className={classes.filterResult}>
                        <FormattedMessage
                            id={"waitingRoom.filter.result"}
                            defaultMessage={`Showing ${showingEncounters} of ${totalEncounters} appointment(s)`}
                        />
                        <IconButton onClick={this.__openScheduleModal} aria-label="quick-schedule" color="primary">
                            <SvgIcon component={ CustomersNavIconSvg } viewBox="0 0 25 25" />
                        </IconButton>
                    </Typography>
                    }
                </div>}

                <div className={classes.cardWrapper}>

                { busy || !encounters.appointments || encounters.appointments.length === 0 ? <>
                        <div className={classes.card}>
                            <div className={classes.cardEncounterPlaceholder}>
                                <span>Active sessions appears first.</span>
                            </div>
                        </div>
                    </> :
                        <>{ encounters.appointments.sort((a, b) => b.modifiedOn.localeCompare(a.modifiedOn)).map((_appointment) => <EncounterCard key={`waiting-room-${_appointment.id}`} id={`waiting-room-${_appointment.id}`} item={_appointment} />) }</>
                }

                { busy ? (<>
                            { [1,2,3,4].map(_item => <div key={`skeleton-${_item}`} className={classes.card}>
                                <div className={classes.cardHeader}>
                                    <div className={classes.cardAvatar}>
                                        <Skeleton variant="circle" width={49} height={49} />
                                    </div>
                                    <div className={classes.cardTitle}>
                                        <div className={classes.cardTitleText}><Skeleton variant="rect" /></div>
                                        <div className={classes.cardTitleSubText}><Skeleton variant="rect" /></div>
                                    </div>
                                </div>
                            </div>) }
                        </>) :

                    <>
                        {
                            focusItem.data ?
                                <WaitingCard key={`waiting-room-${focusItem.data.id}`}
                                             id={`waiting-room-${focusItem.data.id}`} item={focusItem.data}/> :
                                <></>
                        }

                        {
                            queue.items
                                .filter(_item => _item.customer && !encounters.appointmentIds.includes(_item.id) && _item.id !== focusItem.appointmentId)
                                .map(_item => this.__renderCard(_item))
                        }

                        {
                            queue.items.length < queue.total &&
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item className={classes.loadButton}>
                                    <Button
                                        onClick={this.__handleLoadButtonClick}
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={queue.backgroundLoading}
                                    >
                                        {queue.backgroundLoading? "Loading..." : "Load More"}

                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </>
                }
                </div>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={drawerRight?.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={this.__closeDrawerRight}
                >
                    <Box className={classes.drawerContent}>
                        <Box className={classes.drawerControls}>
                            <IconButton onClick={this.__closeDrawerRight}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box>
                            { this.__renderDrawerRightComponent(classes, drawerRight) }
                        </Box>
                    </Box>
                </Drawer>
                <Backdrop style={{ zIndex: "auto", }} open={drawerRight?.open} />
            </div>
        );
    }

    __renderCard = (_item) => {

        if(["A_REVIEWING", "REVIEWING", "STARTED", "A_STARTED"].includes(_item.status)) return <BusyCard key={`waiting-room-${_item.id}`} id={`waiting-room-${_item.id}`} item={_item} />;

        if(["REQUESTED", "CHECKING_IN", "TRANSITIONING"].includes(_item.status)) return <ProcessingCard key={`waiting-room-${_item.id}`} id={`waiting-room-${_item.id}`} item={_item} />;

        const medium = _item && _item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        if(medium === "In Person") {

            if(_item.status === "RESERVED") {
                return <ReservedCard key={`waiting-room-${_item.id}`} id={`waiting-room-${_item.id}`} item={_item} />;
            }
        }

        return <WaitingCard key={`waiting-room-${_item.id}`} id={`waiting-room-${_item.id}`} item={_item} />;
    }

    __renderDrawerRightComponent = (classes, drawerRight) => {

        if(drawerRight?.component) {

            switch (drawerRight.component) {
                case "scheduler": {
                    return (<ScheduleWidget onComplete={this.__closeDrawerRight} />)
                }
            }
        }

        return (<>Nothing to see here</>);
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<WaitingRoom context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

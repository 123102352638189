import {Box, ButtonBase, ClickAwayListener, Popper, Typography, withStyles} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import React from "react";
import {OntologyConstants} from "../../../bloc";
import {ContextMenu, MultiOptionalContextMenuWrapper,} from "../../CGICommon/ContextMenu";
import {DHAccordion} from "../../Common";
import { Skeleton } from '@material-ui/lab';

import { v4 as uuidv4 } from 'uuid';

import Template from "./Template";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {FormattedMessage} from "react-intl";
import {utilityApi} from "../../../../../../../utils/services/utility.api";
import WizardContextMenuWrapper from "../../CGICommon/ContextMenu/WizardContextMenuWrapper";


const styles = {
    intentionChips: {
        marginTop: 8,
        marginBottom: 8,
        flex: 1,
        justifyContent: "flex-start",
        width: "100%",
    },

    SectionHeader2Text: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
    },
    sectionHeader: {
        color: "#777777",
        textTransform: "uppercase",
        padding: "23px 0",
        textAlign: "left",
    },
    phyEx: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: 12,
    },
    chips: {
        backgroundColor: "transparent",
        border: "1px solid #777777",
        borderRadius: 22,
        height: 25,
    },
    outlineChip: {
        color: "#CDE0E4",
    },
    empty: {
        color: "rgba(0,0,0,0.3)",
    },
};

const allowedSemanticTypes = [];

class ArrivalAndDisposition extends DecodedComponent {


    constructor(props) {

        super(props);

        this.bloc = props.bloc;

        this.popAnchor = React.createRef();

        this.state = {management: {open: false,}, arrival: { loading: true, options: []}, disposition: { loading: true, options: []}, selectedOption: {}, uuid: uuidv4(), recommendations: [] };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({recommendationOpen: false})
        Promise.all([utilityApi.codeset('arrival'), utilityApi.codeset('disposition')])
            .then(values => {

                let arrivals = values[0].data.items.map(_val => _val.value);
                let dispositions = values[1].data.items.map(_val => _val.value);

                this.setState({
                    arrival: { loading: false, options: arrivals},
                    disposition: { loading: false, options: dispositions},
                })

            }, reason => { })
    }

    __setSelectedOption = (option) => {
        this.setState({selectedOption: option});
        setTimeout(() => {
            if(option.options) {
                const el = document.getElementById('result-0');
                if(el) el.focus();
            }
        },200)
    }

    __add = (label, item, detail) => {
        console.log(label, item, detail)
        this.setState({ selectedOption: {} });
        this.bloc.arrivalDisposition().toggleMenu();
        if(label==="Arrival") {
            this.bloc.arrivalDisposition().addArrival(item, detail);
        } else if(label==="Disposition") {
            this.bloc.arrivalDisposition().addDisposition(item, detail);
        }
    }

    render() {

        const {classes} = this.props;
        const {uuid, arrivalDisposition, selectedOption, workspaceGraph, arrival, disposition} = this.state;

        const local = workspaceGraph?.encounterArrivalAndDisposition().sort((a, b) => {

            const a_description = a.description || a.code.value;
            const b_description = b.description || b.code.value;

            return  a_description.toLowerCase().localeCompare(b_description.toLowerCase())
        }) || [];

        return (
            <>
                <DHAccordion

                    onHeaderDropAccept={[]}
                    onHeaderDropType={allowedSemanticTypes}
                    onHeaderDrop={(dItem) => {} }
                    onHeaderDropKey={`${uuid}`}
                    expandedInitial={true}

                    header={
                        <ContextMenu
                            isOpen={arrivalDisposition?.open}
                            modal
                            heading={
                                <ButtonBase className={classes.sectionHeader} onClick={() => {
                                    this.bloc.arrivalDisposition().toggleMenu();
                                }}>
                                    <Typography component={"span"}>
                                        Arrival and Disposition
                                    </Typography>
                                </ButtonBase>
                            }
                            onClose={() => {
                                this.bloc.arrivalDisposition().toggleMenu();
                                this.__setSelectedOption({});
                            }}
                        >
                            <WizardContextMenuWrapper
                                selectedContextMenuOption={selectedOption}
                                setSelectedContextMenuOption={this.__setSelectedOption}
                                onSave={this.__add}
                                options={[
                                    {
                                        label: "Arrival",
                                        component: 'select',
                                        options: arrival.options,
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onSave: (value) => {
                                            this.bloc.arrivalDisposition().addArrival(value, []);
                                        },
                                    },
                                    {
                                        label: "Disposition",
                                        component: 'select',
                                        options: disposition.options,
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onSave: (value) => {
                                            this.bloc.arrivalDisposition().addDisposition(value, []);
                                            this.bloc.arrivalDisposition().toggleMenu();
                                            this.setState({ selectedOption: {} });
                                        },
                                    },
                                ]}
                            />
                        </ContextMenu>
                    }
                >
                    <Box className={classes.content} ref={this.popAnchor}>
                        {!local && <Skeleton animation="wave" /> }
                        {local && local.length ? local.map((item) => (
                            <Template popperAnchor={this.props.popperAnchor} bloc={this.bloc} item={item}/>
                        )) : <div className={classes.empty}><FormattedMessage id={"empty"} defaultMessage={"Empty"} /></div>}
                    </Box>
                </DHAccordion>
            </>
        );
    }
}

export default withStyles(styles)(ArrivalAndDisposition);

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import {ReactComponent as VirtualSVG} from "../../../../../../assets/cards/virtual.svg";
import {ReactComponent as InPersonSVG} from "../../../../../../assets/cards/inperson.svg";

import {Avatar, SvgIcon} from '@material-ui/core';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "261px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    fill: {
        flex: "1 1 auto",
    },
    header: {
        display: "flex",
        padding: "30px 16px 0 16px",
    },
    actionButton: {
      paddingLeft: "24px",
    },
    notificationText: {
      paddingRight: "64px",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
});

class BaseCard extends Component {

    state = {
        open: false,
        localLoading: false,
    }

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
    }

    render() {

        const { classes, person, item } = this.props;

        return (
            <>
                <div className={`${classes.root}`}>
                    <div className={classes.header}>
                        <div className={classes.cardAvatar}>
                            <Avatar className={classes.avatar}>{person ? <>{person.name.given.charAt(0)}</> : <>U</>}</Avatar>
                        </div>
                        <div className={classes.cardTitle}>
                            <div className={classes.cardTitleText}>{person ? <>{person.name.given}<br/>{person.name.family}</> : <>Unknown<br/>Unknown</>}</div>
                        </div>
                        <div className={classes.cardIcon}>
                            { item.type === "VIRTUAL" ?
                                <><SvgIcon component={ VirtualSVG } viewBox="0 0 25 25" /></> :
                                <><SvgIcon component={ InPersonSVG } viewBox="0 0 25 25" /></> }
                        </div>
                    </div>
                    { this.props.children }
                </div>
            </>
        );
    }
}

export default withStyles(styles)(BaseCard);

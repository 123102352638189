import {Subject} from 'rxjs';

export class SchedulingEventService {

    subject = new Subject();

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(event) {
        this.subject.next(event);
    }
}

export const schedulingEventService = new SchedulingEventService();

export class SchedulingEvent {

    static SERVICE_PROVIDERS_LOADED = "SERVICE_PROVIDERS_LOADED";
    static SERVICE_PROVIDERS_STAFF_LOADED = "SERVICE_PROVIDERS_STAFF_LOADED";
    static SERVICE_PROVIDERS_LOADING_ERROR = "SERVICE_PROVIDERS_LOADING_ERROR";
    static SERVICE_PROVIDERS_STAFF_LOADING_ERROR = "SERVICE_PROVIDERS_STAFF_LOADING_ERROR";
}

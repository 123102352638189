import {ButtonBase, TextField, Typography} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import debounce from 'lodash/debounce';
import { SearchForContextMenu } from ".";
import AddItemContextMenu from "./AddItemContextMenu";
import { createFirstItemOfSearchResult } from "../Drawer/Drawer.utils";
import { withStyles } from "@material-ui/styles";

import {notificationService} from "../../../../../../../utils/notification";
import DecodedComponent from "../../../../../../shared/DecodedComponent";


// class AddObservationsAndDXContextMenu extends DecodedComponent {
//
//     constructor(props) {
//         super(props);
//
//         this.bloc = props.bloc;
//
//         this.state = {
//             toggleAddItem: true,
//             query: "",
//             selectedIndex: -1,
//             results: [],
//         };
//     }
//
//     componentDidMount() {
//         super.componentDidMount();
//     }
//
//     componentWillUnmount() {
//         super.componentWillUnmount();
//     }
//
// }

function AddObservationsAndDXContextMenu({
  bloc,
  label,
  ontologyConstants,
  vocabulary,
  disableFreeText,
  searchPlaceHolder,
  onItemPress,
  classes,
}) {
  const [toggleAddItem, setToggleAddItem] = useState(true);
  const [query, setQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [results, setResults] = useState([]);

    const queryChangeHandler = (event) => {
        setQuery(event.target.value);
    };

    const handleUserKeyPress = (event) => {
        const { key, keyCode } = event;

        if(selectedIndex > -1 && results.length > 0) {

            let max = disableFreeText ? results.length : results.length + 1;

            let shouldScroll = false;

            if(keyCode === 38) {
                //up
                if(selectedIndex !== 0) {
                    setSelectedIndex(selectedIndex - 1);
                    shouldScroll = document.getElementById(`result-${(selectedIndex - 1)}`);
                } else if(selectedIndex === 0) {
                    setSelectedIndex(max - 1);
                    shouldScroll = document.getElementById(`result-${(max - 1)}`);
                }
            } else if(keyCode === 40) {
                //down
                if(selectedIndex < (max - 1)) {
                    setSelectedIndex(selectedIndex + 1);
                    shouldScroll = document.getElementById(`result-${(selectedIndex + 1)}`);
                } else if(selectedIndex + 1 >= max) {
                    setSelectedIndex(0);
                    shouldScroll = document.getElementById(`result-0`);
                }
            } else if(keyCode === 13) {
                if(!disableFreeText && selectedIndex >= results.length) {
                    const freeText = {
                        canonicalName: query,
                        index: [query],
                    }
                    onItemPress(freeText);
                } else {
                    onItemPress(results[selectedIndex]);
                }
            }

            if(shouldScroll) {
                shouldScroll.scrollIntoView();
            }
        }

        if(keyCode === 38 || keyCode === 40 || keyCode === 13) {
            event.preventDefault();
        }
    };

  const fetch = React.useMemo(
      () => {
          return debounce((request, callback) => {
            bloc.searchOntology(request, ontologyConstants, vocabulary)
                .then(value => {

                    callback(value.data.results);

                }, reason => notificationService.httpError(reason));
          }, 300, { 'maxWait': 1000 });
      },
      [],
  );


  useEffect(() => {
    (() => {

      if(query.length <= 2) {
        setResults([]);
        setSelectedIndex(-1)
        return;
      }

      fetch(query, _results => {
          setSelectedIndex(_results.length > 0 ? 0 : -1)
          setResults(_results.splice(0, 15));
      });

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);


    React.useEffect(() => {
        return () => {
            fetch.cancel();
        };
    }, [fetch]);

  return (
    <div style={{ width: 320, marginLeft: 8, maxHeight: "460px", overflow:"hidden" }} onKeyDown={handleUserKeyPress} >
      <AddItemContextMenu
        label={label}
        isSelected={toggleAddItem}
        onPress={() => {
          setToggleAddItem(!toggleAddItem);
        }}
      />
      {toggleAddItem && (
        <div
          style={{
            marginTop: 12,
            backgroundColor: "#fff",
            borderRadius: 32,
          }}
        >
            <div style={{marginRight: 16, marginLeft: 16}} >
                <SearchForContextMenu
                  value={query}
                  onChange={queryChangeHandler}
                  placeHolder={searchPlaceHolder || "Search"}
                />
            </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
                maxHeight: disableFreeText ? "280px" : "205px",
                overflowY: "auto",
            }}
          >
            {results?.map((res, index) => {
              return (
                <ButtonBase
                  style={{
                    backgroundColor: selectedIndex === index ? "rgb(0, 209, 255)" : "#F9F9F9",
                    borderRadius: 32,
                    width: 290,
                    marginBottom: 16,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: 21,
                    alignItems: "start",
                  }}
                  onClick={() => {
                    onItemPress(res);
                  }}
                  key={`${res.conceptId}`}
                  id={`result-${index}`}
                >
                  <Typography
                    style={{
                      marginVertical: 7,
                      marginHorizontal: 21,
                      maxWidth: 248,
                      lineBreak: "anywhere",
                      textAlign: "left",
                        padding: "7px 0",
                    }}
                    classes={{ body1: classes.body1 }}
                  >
                    {res?.canonicalName}
                  </Typography>
                </ButtonBase>
              );
            })}

          </div> { query?.length > 0 && !disableFreeText &&
            <div style={{
                paddingTop: 10,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
            }}>
                <Typography style={{ textAlign: "start", width: "90%" }} variant={"caption"}>Add concept*</Typography>
                <ButtonBase
                    style={{
                        backgroundColor: selectedIndex === results?.length ? "rgb(0, 209, 255)" : "#F9F9F9",
                        borderRadius: 32,
                        width: 290,
                        marginBottom: 16,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: 21,
                        alignItems: "start",
                    }}
                    onClick={() => {
                        const freeText = {
                            canonicalName: query,
                            index: [query],
                        }
                        onItemPress(freeText);
                    }}
                    key={`free-text`}
                    id={`result-${results?.length || 0}`}
                >
                    <Typography
                        style={{
                            marginVertical: 7,
                            marginHorizontal: 21,
                            maxWidth: 248,
                            lineBreak: "anywhere",
                            textAlign: "left",
                            padding: "7px 0",
                        }}
                        classes={{ body1: classes.body1 }}
                    >
                        {query}
                    </Typography>
                </ButtonBase>
            </div> }
        </div>
      )}
    </div>
  );
}
const styles = {
  body1: {
    fontFamily: "inter !important",
    fontSize: 15,
  },
};
export default withStyles(styles)(AddObservationsAndDXContextMenu);

import {Box, ButtonBase, ClickAwayListener, Popper, Typography, withStyles} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import React from "react";
import {OntologyConstants} from "../../../bloc";
import {ContextMenu, MultiOptionalContextMenuWrapper} from "../../CGICommon/ContextMenu";
import {DHAccordion} from "../../Common";
import { Skeleton } from '@material-ui/lab';

import { v4 as uuidv4 } from 'uuid';

import Template from "./Template";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {FormattedMessage} from "react-intl";
import OrderRecommendations from "./OrderRecommendations";
import ItemDetail from "../../Common/ItemDetail";

const styles = {
    intentionChips: {
        marginTop: 8,
        marginBottom: 8,
        flex: 1,
        justifyContent: "flex-start",
        width: "100%",
    },

    SectionHeader2Text: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
    },
    sectionHeader: {
        color: "#777777",
        textTransform: "uppercase",
        padding: "23px 0",
        textAlign: "left",
    },
    phyEx: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: 12,
    },
    chips: {
        backgroundColor: "transparent",
        border: "1px solid #777777",
        borderRadius: 22,
        height: 25,
    },
    outlineChip: {
        color: "#CDE0E4",
    },
    empty: {
        color: "rgba(0,0,0,0.3)",
    },
};

const allowedSemanticTypes = OntologyConstants.CONDITION.semantic;

class EvaluationManagement extends DecodedComponent {


    constructor(props) {

        super(props);

        this.bloc = props.bloc;

        this.popAnchor = React.createRef();

        this.state = {management: {open: false,}, selectedOption: {}, uuid: uuidv4(), recommendations: [] };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({recommendationOpen: false})
    }

    __setSelectedOption = (option) => {
        this.setState({selectedOption: option});
    }

    __contextMenu = () => {

        return [{
            ontologyConstants: OntologyConstants.ORDERS_REQUEST.semantic,
            vocabularies: OntologyConstants.ORDERS_REQUEST.vocabulary,
            searchPlaceHolder: "Start typing...",
            label: "Orders",
            icon: (
                <Add
                    style={{
                        color: "#00D1FF",
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                />
            ),
            onItemPress: (value) => {
                this.bloc.evaluationManagement().add(value, []);
                this.bloc.evaluationManagement().toggleMenu();
            },
        }];
    }


    render() {

        const {classes} = this.props;
        const {uuid, orders, selectedOption, workspaceGraph, currentComplaint, recommendationOpen, recommendations, recommendationsMessage, recommendationsTarget} = this.state;

        const local = workspaceGraph?.encounterOrders(currentComplaint).sort((a, b) => {

            const a_description = a.description || a.code.value;
            const b_description = b.description || b.code.value;

            return  a_description.toLowerCase().localeCompare(b_description.toLowerCase())
        }) || [];

        const contextMenuOptions = this.__contextMenu();

        return (
            <>
                <DHAccordion

                    onHeaderDropAccept={["dxList", "observations", "currentWorkingDX"]}
                    onHeaderDropType={allowedSemanticTypes}
                    onHeaderDrop={(dItem) => {

                        if(recommendationOpen) {
                            return
                        }

                        this.setState({
                            recommendations: [],
                            recommendationsTarget: undefined,
                            recommendationsMessage: undefined,
                            recommendationOpen: true,
                        });

                        this.bloc.evaluationManagement().recommend(dItem)
                            .then(value => {
                                if(value.data.items.length > 0) {

                                    const _recommendations = value.data.items.sort((a, b) => a.code.display.toLowerCase().localeCompare(b.code.display.toLowerCase()));

                                    this.setState({
                                        recommendationsTarget: value.data.bid,
                                        recommendations: _recommendations,
                                    });
                                } else {
                                    this.setState({
                                        recommendationsMessage: "No results",
                                    });
                                }
                            })
                    } }
                    onHeaderDropKey={`${uuid}`}
                    expandedInitial={true}

                    header={
                        <ContextMenu
                            isOpen={orders?.open}
                            modal
                            heading={
                                <ButtonBase className={classes.sectionHeader} onClick={() => {
                                    this.bloc.evaluationManagement().toggleMenu();
                                }}>
                                    <Typography component={"span"}>
                                        Evaluation and Management
                                    </Typography>
                                </ButtonBase>
                            }
                            onClose={() => {
                                this.bloc.evaluationManagement().toggleMenu();
                                this.__setSelectedOption({});
                            }}
                        >
                            <MultiOptionalContextMenuWrapper
                                selectedContextMenuOption={selectedOption}
                                setSelectedContextMenuOption={this.__setSelectedOption}
                                options={[
                                    {
                                        ontologyConstants: OntologyConstants.ORDERS_REQUEST.semantic,
                                        vocabularies: OntologyConstants.ORDERS_REQUEST.vocabulary,
                                        searchPlaceHolder: "Start typing...",
                                        label: "Orders",
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onItemPress: (value) => {
                                            this.bloc.evaluationManagement().add(value, []);
                                            this.bloc.evaluationManagement().toggleMenu();
                                            this.setState({ selectedOption: {} });
                                        },
                                    },
                                    {
                                        ontologyConstants: OntologyConstants.PROCEDURE_ORDERS_REQUEST.semantic,
                                        vocabularies: OntologyConstants.PROCEDURE_ORDERS_REQUEST.vocabulary,
                                        searchPlaceHolder: "Start typing...",
                                        label: "Procedures",
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onItemPress: (value) => {
                                            this.bloc.evaluationManagement().add(value, []);
                                            this.bloc.evaluationManagement().toggleMenu();
                                            this.setState({ selectedOption: {} });
                                        },
                                    },
                                    {
                                        ontologyConstants: OntologyConstants.LAB_ORDERS_REQUEST.semantic,
                                        vocabularies: OntologyConstants.LAB_ORDERS_REQUEST.vocabulary,
                                        searchPlaceHolder: "Start typing...",
                                        label: "Labs",
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onItemPress: (value) => {
                                            this.bloc.evaluationManagement().add(value, []);
                                            this.bloc.evaluationManagement().toggleMenu();
                                            this.setState({ selectedOption: {} });
                                        },
                                    },
                                    {
                                        ontologyConstants: OntologyConstants.MEDICATION_ORDERS_REQUEST.semantic,
                                        vocabularies: OntologyConstants.MEDICATION_ORDERS_REQUEST.vocabulary,
                                        termTypes: OntologyConstants.MEDICATION_ORDERS_REQUEST.termTypes,
                                        disableFreeText: true,
                                        searchPlaceHolder: "Start typing...",
                                        label: "Rx",
                                        icon: (
                                            <Add
                                                style={{
                                                    color: "#00D1FF",
                                                    marginLeft: 8,
                                                    marginRight: 8,
                                                }}
                                            />
                                        ),
                                        onItemPress: (value) => {
                                            this.bloc.evaluationManagement().add(value, []);
                                            this.bloc.evaluationManagement().toggleMenu();
                                            this.setState({ selectedOption: {} });
                                        },
                                    }
                                ]}
                            />
                        </ContextMenu>
                    }
                >
                    <Box className={classes.content} ref={this.popAnchor}>
                        {!local && <Skeleton animation="wave" /> }
                        {local && local.length ? local.map((item) => (
                            <Template popperAnchor={this.props.popperAnchor} bloc={this.bloc} item={item}/>
                        )) : <div className={classes.empty}><FormattedMessage id={"empty"} defaultMessage={"Empty"} /></div>}
                    </Box>
                </DHAccordion>
                { this.__renderRecommendations(recommendationOpen, recommendations, recommendationsMessage, recommendationsTarget) }
            </>
        );
    }

    __handleRecommendationClose = (event) => {

        const {recommendationEl} = this.state;

        this.setState({ recommendationOpen: false});
    };


    __renderRecommendations = (recommendationOpen, recommendations, recommendationsMessage, recommendationsTarget) => {
        return  <ItemDetail  id={'order-recommendations'} title={"Related Orders"} open={recommendationOpen} onClickAway={this.__handleRecommendationClose} onCloseClick={this.__handleRecommendationClose} anchorEl={this.props.popperAnchor?.current}>
            <OrderRecommendations bloc={this.bloc} onCloseClick={this.__handleRecommendationClose} message={recommendationsMessage} recommendations={recommendations} bid={recommendationsTarget}/>
        </ItemDetail>
    }
}

export default withStyles(styles)(EvaluationManagement);

import * as rxjs from 'rxjs';
import {appointmentApi} from "../../../../../../utils/services/appointments.api";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../../../utils/events";
import {clinicEventService} from "../../../service/clinic.event.service";
import {notificationService} from "../../../../../../utils/notification";

export class ActionBarBloc {

    constructor(
        encounter
    ) {

        this.subject = new rxjs.BehaviorSubject({
            encounter: encounter,
        });

        this.events = new rxjs.Subject();
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);


    handleEncounterAction = (action, data) => {

        const { encounter } = this.subject.value;

        let type = "";

        switch (action) {

            case "cancel": {
                type = "CANCEL";
            }
                break;
            case "transition": {
                type = "TRANSITIONING";
            }
                break;
            case "end":
            case "complete": {
                type = "COMPLETE";
            }
                break;
            case "intake": {
                type = "A_REVIEWED";
            }
                break;
            case "reset": {
                type = "WAITING";
            }
                break;
        }

        if("CANCEL" === type) {

            appointmentApi.cancel(encounter.id, {
                reason: {
                    id: 0,
                    description: "No show"
                }
            }).then(value => {

                    analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_EVENT, { id: encounter.id, type: type });

                    this.events.next({
                        type: ActionBarBlocEvent.APPOINTMENT_UPDATED,
                        data: {
                            encounter: encounter,
                            updateState: type,
                        },
                    });

                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_UPDATED',
                        data: encounter,
                    });
                })
                .catch(reason => {
                    notificationService.update({
                        error: "Unable to end the appointment. " + reason,
                    });
                }).finally(() => {
                this.__stopProcessing({confirmEncounterActionTarget: undefined})
            });
        } else {

            appointmentApi.updateAppointmentState(encounter.id, type, data?.type)
                .then(value => {

                    analyticsEventLogger.log(AnalyticsEvent.ENCOUNTER_SESSION_EVENT, { id: encounter.id, type: type });

                    this.events.next({
                        type: ActionBarBlocEvent.APPOINTMENT_UPDATED,
                        data: {
                            encounter: encounter,
                            updateState: type,
                        },
                    });

                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_UPDATED',
                        data: encounter,
                    });
                })
                .catch(reason => {
                    notificationService.update({
                        error: "Unable to end the appointment. " + reason,
                    });
                }).finally(() => {
                this.__stopProcessing({confirmEncounterActionTarget: undefined})
            });
        }
    }

    __stopProcessing = (additionalData) => {
        this.subject.next({
           ...this.subject.value,
           ...additionalData,
           processing: false,
        });
    }
}

export class ActionBarBlocEvent {
    static APPOINTMENT_UPDATED = 'APPOINTMENT_UPDATED';
}

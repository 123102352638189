import * as rxjs from "rxjs";
import {globalBloc} from "../../global.bloc";
import {organisationsApi} from "../../../../utils/services/organisations.api";
import {SchedulingEvent} from "../service/scheduling.event.service";
import {notificationService} from "../../../../utils/notification";
import {dateUtil} from "../../../../utils/date";
import {phoneUtil} from "../../../../utils/phone";
import {customersApi} from "../../../../utils/services/customers.api";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../utils/events";
import {PatientFormMapper} from "../formUtil";
import {errorResolver} from "../Appointment/util/error.resolver";
import {tenantUtil} from "../../../../utils/tenant";

export const STATES = [{ "code":"AL", "display": "Alabama" },
    { "code":"AK", "display": "Alaska" },
    { "code":"AZ", "display": "Arizona" },
    { "code":"AR", "display": "Arkansas" },
    { "code":"CA", "display": "California" },
    { "code":"CO", "display": "Colorado" },
    { "code":"CT", "display": "Connecticut" },
    { "code":"DE", "display": "Delaware" },
    { "code":"DC", "display": "District Of Columbia" },
    { "code":"FL", "display": "Florida" },
    { "code":"GA", "display": "Georgia" },
    { "code":"HI", "display": "Hawaii" },
    { "code":"ID", "display": "Idaho" },
    { "code":"IL", "display": "Illinois" },
    { "code":"IN", "display": "Indiana" },
    { "code":"IA", "display": "Iowa" },
    { "code":"KS", "display": "Kansas" },
    { "code":"KY", "display": "Kentucky" },
    { "code":"LA", "display": "Louisiana" },
    { "code":"ME", "display": "Maine" },
    { "code":"MD", "display": "Maryland" },
    { "code":"MA", "display": "Massachusetts" },
    { "code":"MI", "display": "Michigan" },
    { "code":"MN", "display": "Minnesota" },
    { "code":"MS", "display": "Mississippi" },
    { "code":"MO", "display": "Missouri" },
    { "code":"MT", "display": "Montana" },
    { "code":"NE", "display": "Nebraska" },
    { "code":"NV", "display": "Nevada" },
    { "code":"NH", "display": "New Hampshire" },
    { "code":"NJ", "display": "New Jersey" },
    { "code":"NM", "display": "New Mexico" },
    { "code":"NY", "display": "New York" },
    { "code":"NC", "display": "North Carolina" },
    { "code":"ND", "display": "North Dakota" },
    { "code":"OH", "display": "Ohio" },
    { "code":"OK", "display": "Oklahoma" },
    { "code":"OR", "display": "Oregon" },
    { "code":"PA", "display": "Pennsylvania" },
    { "code":"RI", "display": "Rhode Island" },
    { "code":"SC", "display": "South Carolina" },
    { "code":"SD", "display": "South Dakota" },
    { "code":"TN", "display": "Tennessee" },
    { "code":"TX", "display": "Texas" },
    { "code":"UT", "display": "Utah" },
    { "code":"VT", "display": "Vermont" },
    { "code":"VA", "display": "Virginia" },
    { "code":"WA", "display": "Washington" },
    { "code":"WV", "display": "West Virginia" },
    { "code":"WI", "display": "Wisconsin" },
    { "code":"WY", "display": "Wyoming" },];

const BLANK_FORM = {
        givenName: "",
        familyName: "",
        dob: "",
        ssn: "",
        email: "",
        number: "",
        addressLine1: "",
        addressLine2: "",
        addressCity: "",
        addressAdministrativeArea: "",
        addressPostcode: "",
        isPolicyHolder: true,
        isInValidLocation: false,
        memberId: "",
        isInClinic: false,
        appointmentType: "",
        reasonForVisit: "",
        timeslot: "",
}

export class Bloc {

    constructor(overrides) {

        let defaults = { ...BLANK_FORM, ...overrides};

        this.subject = new rxjs.BehaviorSubject({
            form: { ...defaults },
            serviceProviders: [],
            staff: [],
            match: {},
            initialised: false,
        });

        this.events = new rxjs.Subject();

        this.globalBlocEventSubscription = globalBloc.subscribeToEvents(this.__listenToGlobalEvents);
        this.globalBlocStateSubscription = globalBloc.subscribeToState(this.__listenToGlobalState);
    }

    __listenToGlobalEvents = (e) => {
    }

    __listenToGlobalState = (s) => {
        this.location = s.location;
    }

    __updateSubject = (newProps) => {

        const _current = this.subject.value;

        this.subject.next({
            ..._current,
            ...newProps,
        })
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    initialise = () => {

        organisationsApi.listServiceProviders(this.location)
            .then(value => {
                this.__updateSubject({ initialised: true, serviceProviders: value.data.items, })
            }, reason => notificationService.httpError(reason));
    }

    loadServiceProviders = () => {

        organisationsApi.listServiceProviders(this.location)
            .then(value => {

                this.__updateSubject({ serviceProviders: value.data.items })

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_LOADED,
                    data: {
                        serviceProviders: value.data.items,
                    },
                });
            }, reason => {

                this.events.next({
                    type: SchedulingEvent.SERVICE_PROVIDERS_LOADING_ERROR,
                    data: {
                        reason: reason,
                    },
                });

                notificationService.error(`Error reading the service providers to retrieve service catalog due to ${reason}.`);
            });
    }

    resetServiceProvidersStaff = () => {

        this.__updateSubject({ staff: [] })

        this.events.next({
            type: SchedulingEvent.SERVICE_PROVIDERS_STAFF_LOADED,
            data: { staff: [], },
        });
    }

    handleFormTextChange = (formField, formValue) => {

        let { form } = this.subject.value;
        form[formField] = formValue;

            this.__updateSubject({
            form: form,
        });
    }

    tryCustomerMatch = () => {

        let { form, match, } = this.subject.value;

        if(match?.loading) {
            return;
        }

        match.loading = true;

        this.__updateSubject({ match: match });

        let request = {
            name: {
                given: form.givenName,
                family: form.familyName,
            },
            dob: dateUtil.formatDate(form.dob),
            contactDetails: {
                email: form.email,
                number: phoneUtil.formatPhoneNumberForRegistration(form.number),
                address: {
                    line1: form.addressLine1,
                    line2: form.addressLine2,
                    city: form.addressCity,
                    locality: form.addressAdministrativeArea,
                    postcode: form.addressPostcode,
                    country: "US",
                }
            },
            externalReferences: []
        };

        if(form.ssn.length > 0) {

            request.externalReferences.push(
                {
                    system: "http://hl7.org/fhir/vs/identifier-type",
                    code: "SS",
                    reference: form.ssn,
                }
            );
        }

        customersApi.match(request)
            .then(value => {

                if(value.data && value.data.page) {

                    let page = value.data.page;

                    analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_MATCH_SUCCESS, { count: page.size, org: form.organisationId });

                    if(page.size > 0) {

                        match.candidates = value.data.items;
                    } else {

                        notificationService.success("No matches found.");

                        customersApi
                            .reserveId()
                            .then(value => {
                                    if(value.data) {
                                        form.id = value.data.id;
                                        this.__updateSubject({ form: form });
                                    }
                                },
                                reason => {});
                        return;
                    }
                }

                match.loading = false;

                this.__updateSubject({ match: match });

            }, reason => {
                notificationService.httpError(reason);
                this.__updateSubject({
                    match: {},
                });
            });
    }

    matchHighlight = (id) => {

        let { match, } = this.subject.value;

        match.highlight = id;

        this.__updateSubject({
            match: match,
        });
    }

    matchConfirm = (candidate) => {

        let { match, form } = this.subject.value;

        match.highlight = undefined;
        match.candidates = undefined;
        match.loading = false;

        form = PatientFormMapper.mapPatient(candidate, form);

        this.__updateSubject({
            match: match,
            form: form,
        });
    }

    matchReset = () => {

        let { match, } = this.subject.value;

        match.highlight = undefined;
        match.candidates = undefined;
        match.loading = false;

        this.__updateSubject({
            match: match,
        });
    }

    scheduleAppointment = (callback) => {

        this.__updateSubject({
            loading: true,
        });

        const request = this.__createScheduleRequest();

        customersApi.schedule(request)
            .then(value => {

                analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_SUCCESS, { });
                notificationService.success("Success.");

                callback();
            }).catch(reason => {

            analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_ERROR, { });
            notificationService.success(`An error occurred - ${errorResolver.resolveDisplay(reason)}.`);

            this.__updateSubject({
                loading: false,
            });
        });
    }


    __createScheduleRequest = () => {

        let { form, serviceProviders, } = this.subject.value;

        const service = serviceProviders
            .flatMap(_item => _item.serviceCatalogs)
            .flatMap(_item => _item.services)
            .filter(_service => _service.id === form.appointmentType)[0];

        let paymentInformation = {
            type: "OUTOFPOCKET"
        };

        let appointmentInformation = {
            isInClinic: true,
            type: form.appointmentType,
            channel: form.channel || "IN_PERSON",
            organisation: {
                id: form.organisationId || this.location,
            },
            reason: form.reasonForVisit,
            priority: 1,
        };

        let participants = [];

        let request = {
            code: "register.and.schedule",
            payload: {
                id: form.id,
                name: {
                    given: form.givenName,
                    family: form.familyName,
                },
                dob: dateUtil.formatDate(form.dob),
                gender: form.gender,
                externalReferences: [],
                contactDetails: {
                    address: {
                        line1: form.addressLine1,
                        line2: form.addressLine2,
                        city: form.addressCity,
                        locality: form.addressAdministrativeArea,
                        postcode: form.addressPostcode,
                        country: "US"
                    },
                    number: phoneUtil.formatPhoneNumberForRegistration(form.number),
                    email: form.email,
                },
                acceptTerms: form.acceptTerms,
                acceptLocationTerm: form.isInValidLocation,
                communicationConsent: form.acceptTerms,
                paymentInformation: paymentInformation,
                appointmentInformation: appointmentInformation,
                participants: participants,
            }
        };

        if(form.ssn.length > 0) {

            request.payload.externalReferences.push(
                {
                    system: "http://hl7.org/fhir/vs/identifier-type",
                    code: "SS",
                    reference: form.ssn,
                }
            );
        }

        return request;
    }

    close = () => {

        this.globalBlocEventSubscription.unsubscribe();
        this.globalBlocStateSubscription.unsubscribe();
    }
}

import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    TextField
} from '@material-ui/core';
import {DateMask, PhoneTextMask, SsnTextMask, ZipTextMask} from "../../../shared/InputTextMask";
import withStyles from '@material-ui/core/styles/withStyles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import DateFnsUtils from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";

import {MuiPickersUtilsProvider,} from '@material-ui/pickers';

import AppointmentContext from "./context";
import {appointmentEventService} from "./service/appointment.event.service";
import {appointmentDataChange, genders} from "./util";
import {customersApi} from "../../../../utils/services/customers.api";
import {dateUtil} from "../../../../utils/date";
import {phoneUtil} from "../../../../utils/phone";
import {AnalyticsEvent, analyticsEventLogger} from "../../../../utils/events";


const styles = theme => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        textAlign: "end",
    }
});


const states = [{ "code":"AL", "display": "Alabama" },
    { "code":"AK", "display": "Alaska" },
    { "code":"AZ", "display": "Arizona" },
    { "code":"AR", "display": "Arkansas" },
    { "code":"CA", "display": "California" },
    { "code":"CO", "display": "Colorado" },
    { "code":"CT", "display": "Connecticut" },
    { "code":"DE", "display": "Delaware" },
    { "code":"DC", "display": "District Of Columbia" },
    { "code":"FL", "display": "Florida" },
    { "code":"GA", "display": "Georgia" },
    { "code":"HI", "display": "Hawaii" },
    { "code":"ID", "display": "Idaho" },
    { "code":"IL", "display": "Illinois" },
    { "code":"IN", "display": "Indiana" },
    { "code":"IA", "display": "Iowa" },
    { "code":"KS", "display": "Kansas" },
    { "code":"KY", "display": "Kentucky" },
    { "code":"LA", "display": "Louisiana" },
    { "code":"ME", "display": "Maine" },
    { "code":"MD", "display": "Maryland" },
    { "code":"MA", "display": "Massachusetts" },
    { "code":"MI", "display": "Michigan" },
    { "code":"MN", "display": "Minnesota" },
    { "code":"MS", "display": "Mississippi" },
    { "code":"MO", "display": "Missouri" },
    { "code":"MT", "display": "Montana" },
    { "code":"NE", "display": "Nebraska" },
    { "code":"NV", "display": "Nevada" },
    { "code":"NH", "display": "New Hampshire" },
    { "code":"NJ", "display": "New Jersey" },
    { "code":"NM", "display": "New Mexico" },
    { "code":"NY", "display": "New York" },
    { "code":"NC", "display": "North Carolina" },
    { "code":"ND", "display": "North Dakota" },
    { "code":"OH", "display": "Ohio" },
    { "code":"OK", "display": "Oklahoma" },
    { "code":"OR", "display": "Oregon" },
    { "code":"PA", "display": "Pennsylvania" },
    { "code":"RI", "display": "Rhode Island" },
    { "code":"SC", "display": "South Carolina" },
    { "code":"SD", "display": "South Dakota" },
    { "code":"TN", "display": "Tennessee" },
    { "code":"TX", "display": "Texas" },
    { "code":"UT", "display": "Utah" },
    { "code":"VT", "display": "Vermont" },
    { "code":"VA", "display": "Virginia" },
    { "code":"WA", "display": "Washington" },
    { "code":"WV", "display": "West Virginia" },
    { "code":"WI", "display": "Wisconsin" },
    { "code":"WY", "display": "Wyoming" },];


class CustomerDetail extends Component {

    state = {
        conflictConfirmationOpen: false,
        conflicts: [],
    }

    __continue = () => {

        const { form, track } = this.props.context;

        let request = {
            id: form.id,
            name: {
                given: form.givenName,
                family: form.familyName,
            },
            dob: dateUtil.formatDate(form.dob),
            contactDetails: {
                email: form.email,
                number: phoneUtil.formatPhoneNumberForRegistration(form.number),
                address: {
                    line1: form.addressLine1,
                    line2: form.addressLine2,
                    city: form.addressCity,
                    locality: form.addressAdministrativeArea,
                    postcode: form.addressPostcode,
                    country: "US",
                }
            },
            externalReferences: []
        };

        customersApi.validate(request)
            .then(value => {

                if(value.data.conflicts && value.data.conflicts.length > 0) {

                    analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_CONFLICT, { org: form.organisationId, conflicts: value.data.conflicts.length, track: track });

                    this.setState({
                       conflictConfirmationOpen: true,
                       conflicts: value.data.conflicts,
                    });
                } else {

                    appointmentEventService.update("REGISTRATION_SUBMIT_USER_DETAIL", {});
                }
            }, reason => {

                appointmentEventService.update("REGISTRATION_SUBMIT_USER_DETAIL", { });
            });
    }

    __handleTextChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };

    __handleCheckboxChange = (event) => {
        appointmentEventService.update("REGISTRATION_FLAG_CHANGE", {
            target: event.target.name,
            value: event.target.checked,
        });
    };

    __isFormValid = () => {

        let { form, loading } = this.props.context;

        return form.givenName && form.givenName !== "" &&
            form.familyName && form.familyName !== "" &&
            form.gender && form.gender !== "" &&
            form.dob && form.dob !== "" &&
            form.addressLine1 && form.addressLine1 !== "" &&
            form.addressCity && form.addressCity !== "" &&
            form.addressAdministrativeArea && form.addressAdministrativeArea !== "" &&
            form.addressPostcode && form.addressPostcode !== "" &&
            form.number && form.number !== "" &&
            (!form.ssn || form.ssn === "" || form.ssn.length > 8) &&
            form.acceptTerms && form.acceptTerms !== "";
    }

    __confirmContinue = () => {

        let { form, track } = this.props.context;

        analyticsEventLogger.log(AnalyticsEvent.SCHEDULE_PATIENT_CONFLICT, { org: form.organisationId, value: "force_continue", track: track });

        appointmentEventService.update("REGISTRATION_SUBMIT_USER_DETAIL", { });
    }

    __closeConfirmation = () => {

        this.setState({
            conflictConfirmationOpen: false,
            conflicts: [],
        })
    }

    render() {
        let {conflictConfirmationOpen, conflicts} = this.state;
        let {classes, handleNext} = this.props;
        let {form, loading} = this.props.context;

        const terms = `${process.env.REACT_APP_TERMS_URL}`;

        return (
            <div className={classes.root}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatorForm
                        autoComplete='off'
                        onSubmit={this.doSubmit}
                        className={classes.validation}
                    >
                        <div className={classes.form}>
                            <div className={classes.formInput}>
                                <Grid container spacing={4}>

                                    <Grid item xs={12}>
                                    <Typography variant={"h3"} component="h3">
                                        Details
                                    </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            autoComplete='off'
                                            inputProps={{
                                                autoComplete: 'off'
                                            }}
                                            id="givenName"
                                            label="Customer First Name"
                                            name="givenName"
                                            value={form.givenName}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="familyName"
                                            label="Customer Last Name"
                                            type="text"
                                            name="familyName"
                                            value={form.familyName}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            id="gender"
                                            name="gender"
                                            fullWidth
                                            select
                                            required
                                            label="Gender"
                                            value={form.gender}
                                            onChange={appointmentDataChange.__handleGenderChange}
                                        >
                                            {genders.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="dob"
                                            label="Date of birth"
                                            name="dob"
                                            value={form.dob}
                                            onChange={this.__handleTextChange}
                                            placeholder="MM/dd/yyyy e.g. 02/01/2000"
                                            validators={['matchRegexp:^(0[1-9]|1[012])/(0[0-9]|[12][0-9]|3[01])/(18|19|20)[0-9]{2}$']}
                                            errorMessages={['Invalid date of birth. Please use the format MM/dd/yyyy e.g. 02/01/2000']}
                                            InputProps={{
                                                inputComponent: DateMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            fullWidth
                                            id="ssn"
                                            label="Social security number (optional)"
                                            name="ssn"
                                            value={form.ssn}
                                            onChange={this.__handleTextChange}
                                            placeholder="e.g. 888-88-8888"
                                            validators={['matchRegexp:^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$']}
                                            errorMessages={['Invalid social security number.']}
                                            InputProps={{
                                                inputComponent: SsnTextMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                    <Typography variant={"h3"} component="h3">
                                        Contact Details
                                    </Typography>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Typography variant={"h4"} component="h4">
                                            Email
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            type="email"
                                            value={form.email}
                                            onChange={this.__handleTextChange}
                                            validators={['isEmail']}
                                            errorMessages={['email is not valid']}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant={"h4"} component="h4">
                                            Contact Number
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="number"
                                            label="Mobile Number"
                                            name="number"
                                            value={form.number}
                                            onChange={this.__handleTextChange}
                                            inputMode="tel"
                                            autoComplete='off'
                                            inputProps={{
                                                autoComplete: 'off'
                                            }}
                                            placeholder="e.g. (202) 555-1234"
                                            validators={['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']}
                                            errorMessages={['Invalid phone number']}
                                            InputProps={{
                                                inputComponent: PhoneTextMask,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                    <Typography variant={"h4"} component="h4">
                                        Address
                                    </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="addressLine1"
                                            label="Line 1"
                                            type="text"
                                            name="addressLine1"
                                            value={form.addressLine1}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            fullWidth
                                            id="addressLine2"
                                            label="Line 2"
                                            type="text"
                                            name="addressLine2"
                                            value={form.addressLine2}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            fullWidth
                                            id="addressCity"
                                            label="City"
                                            type="text"
                                            name="addressCity"
                                            value={form.addressCity}
                                            onChange={this.__handleTextChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>

                                        <TextField
                                            id="addressAdministrativeArea"
                                            name="addressAdministrativeArea"
                                            fullWidth
                                            select
                                            required
                                            label="State"
                                            value={form.addressAdministrativeArea}
                                            onChange={appointmentDataChange.__handleTextChange}
                                        >
                                            {states.map((option) => (
                                                <MenuItem key={option.code} value={option.code}>
                                                    {option.display}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextValidator
                                            required
                                            id="addressPostcode"
                                            name="addressPostcode"
                                            label="Zip Code"
                                            fullWidth
                                            value={form.addressPostcode}
                                            onChange={this.__handleTextChange}
                                            validators={['matchRegexp:(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)']}
                                            errorMessages={['Invalid zip code']}
                                            InputProps={{
                                                inputComponent: ZipTextMask,
                                            }}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    required
                                                    checked={form.acceptTerms}
                                                    onChange={this.__handleCheckboxChange}
                                                    name="acceptTerms"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <>
                                                    Patient acknowledges and consents to <Link
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href={terms}
                                                >
                                                         The Terms of Use, Consent to Treatment, and Notice of Privacy Practices
                                                </Link>
                                                </>
                                            }
                                        />
                                    </Grid>

                                </Grid>
                            </div>

                            <div className={classes.fill}/>

                            <div className={classes.button}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.__isFormValid() || loading}
                                    onClick={this.__continue}
                                >
                                    {loading && <CircularProgress size="1.5em"/>}
                                    {!loading && "Submit"}
                                </Button>
                            </div>
                        </div>
                    </ValidatorForm>
                </MuiPickersUtilsProvider>
                <Dialog
                    open={conflictConfirmationOpen}
                    onClose={this.__closeConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Potential conflicts"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The following are potential matches for the entered information. Click continue to complete the scheduling patient or cancel to validate the information before continuing.
                            <List dense={true}>
                                { conflicts.map((_conflict) => <ListItem>
                                    <ListItemText
                                        primary={ `${_conflict.name.given} ${_conflict.name.family} (${_conflict.dob})` }
                                    />
                                </ListItem>) }
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.__closeConfirmation} variant="contained"
                                color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.__confirmContinue} variant="contained"
                                color="primary" autoFocus>
                            Force continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<CustomerDetail context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, IconButton, Menu,} from "@material-ui/core";
import VirtualClinicContext from "../../../context";
import {customersApi} from "../../../../../../utils/services/customers.api";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BaseCard from "./Card";
import {dateUtil} from "../../../../../../utils/date";


const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    redBorder: {
        border: "1px solid #FD1F54",
    },
    cyanishBorder: {
        border: "1px solid #00EBFF",
    },
    cyanishIndicator: {
        color: "#00EBFF",
    },
    orangeBorder: {
        border: "1px solid #FDCC1F",
    },
    okBorder: {
        border: "1px solid #F5F5F5",
    },
    redIndicator: {
        color: "#FD1F54",
    },
    orangeIndicator: {
        color: "#FDCC1F",
    },
    okIndicator: {
        color: "#BFE81C",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeaderInPerson: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.alternate}`,
    },
    cardHeaderTelehealth: {
        padding: "12px 0px 0px 12px",
        display: "flex",
        borderRadius: "22px",
        borderTop: `5px solid ${theme.palette.primary.main}`,
    },
    cardBodyContentClaimed: {
        padding: "17px 21px 0px 12px",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "5px",
        }
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardMenu: {

    },
    cardTitleSubText: {},
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        overflow: "hidden",
        padding: "0px 14px 0px 12px",
        display: "flex",
        flex: "1 1 auto",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "6px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "1",
    },
    cardSubHeader: {
        padding: "10px 14px 10px 12px",
        display: "flex",
    },
    cardSubHeader2: {
        padding: "0px 14px 10px 0px",
        display: "flex",
    },
    cardFooter: {
        display: "flex",
        flexDirection: "column",
        padding: "10px 12px 16px 12px",
        '& button': {
            marginTop: "8px",
        },
    },

    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "21px",
        lineHeight: "21px",
        paddingTop: "3px",
        paddingRight: "4px",
        color: "#8BA3B0",
    },
    sessionType: {
        textAlign: "left",
        paddingBottom: "10px",
        paddingLeft: "70px",
    },
    complaint: {
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },
    complaintIntakeComplete: {
        overflow: "auto",
        padding: "7px 18px",
        background: "rgba(0,235,255, 0.1)",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        height: "100%",
        color: "#000000",
    },

    button: {
        width: "100%",
    },

    cardBodyLoading: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyLoadingContent: {
        margin: "auto",
    },
    cardBodyBusy: {
        marginTop: "40px",
        display: "flex",
        minHeight: "207px",
        maxHeight: "207px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyBusyContent: {
        margin: "16px 10px",
        flex: "1",
        display: "flex",
        flexDirection: "column",
    },
    cardBodyBusyFooter: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingLeft: "12px",
        paddingRight: "12px",
        textAlign: "center",
    },
    cardBodyLoadingProgress: {
        textAlign: "center",
        paddingTop: "20px",
        marginBottom: "10px",
    },
    cardBodyLoadingText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#98A3AE",
    },


    cardBodyContent: {
        maxHeight: "136px",
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.6",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        color: "#000000",
    },



    dialogHeader: {
        display: "flex",
        paddingBottom: "32px",
    },

    dialogHeaderAvatar: {
        marginRight: "24px",
    },

    dialogHeaderTitle: {
        paddingTop: "12px",
    },
});

class ProcessingCard extends Component {

    interval;

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            start: Date.parse(props.item.createdOn),
            delay: "",
            cancelDialogOpen: false,
        };
    }

    componentDidMount() {
        const {customer} = this.props.item;

        if(customer) this._loadSubject(customer);

        this.interval = setInterval(this.__updateInformation, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = undefined;
    }

    __updateInformation = () => {

        this.setState({
            now: new Date().getTime(),
        });
    }


    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
                this.setState({
                    person: value.data,
                });
            });
    };

    __renderBody = (focused, medium, appointmentInformation) => {

        return this.__renderStarting(medium, appointmentInformation);
    }

    __renderStarting = (medium, appointmentInformation) => {

        let { classes } = this.props;

        return (<div className={classes.cardBodyLoading}>
            <div className={classes.cardBodyLoadingContent}>
                <div className={classes.cardBodyLoadingProgress}><CircularProgress /></div>
                <div className={classes.cardBodyLoadingText}> { this.__renderFriendlyStatus(appointmentInformation, appointmentInformation?.status) }</div>
            </div>
        </div>);
    }

    __renderFriendlyStatus = (appointmentInformation, status) => {

        const dateTime = dateUtil.parseDate(appointmentInformation.createdOn);
        const dateTimeString = dateTime.toLocaleString();

        if(status === "REQUESTED") return <>Registering<br />Started: {dateTimeString}</>;
        if(status === "CHECKING_IN") return <>Checking in<br />Started: {dateTimeString}</>;
        if(status === "TRANSITIONING") return <>Transitioning<br />Started: {dateTimeString}</>;

        return "Processing";
    }

    __handleMenuOpen = (event) => {

        this.setState({
            cardMenuAnchorEl: event.currentTarget,
        });
    }

    __handleMenuClose = () => {

        this.setState({
            cardMenuAnchorEl: undefined,
        });
    }

    __renderMenu = () => {

        const { classes } = this.props;
        const { cardMenuAnchorEl } = this.state;

        let items = [];

        return (
            <>
                {
                    items.length > 0 && (
                        <>
                        <IconButton className={classes.cardMenu} aria-label="settings"  aria-controls="simple-menu" aria-haspopup="true"onClick={this.__handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={cardMenuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                open={Boolean(cardMenuAnchorEl)}
                                onClose={this.__handleMenuClose}
                            >
                                { items }
                            </Menu>
                        </>
                    )
                }
            </>
        );
    }

    render() {

        let { person } = this.state;
        let { classes, item } = this.props;
        let { focusItem } = this.props.context;

        let borderColor = classes.okBorder;

        const medium = item && item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        const headerStyle = medium === "Telehealth" ? classes.cardHeaderTelehealth : classes.cardHeaderInPerson;

        const focused = focusItem.data && focusItem.data.id === item.id;

        const menu = this.__renderMenu(focused, medium, item);
        const body = this.__renderBody(focused, medium, item);

        return (
            <BaseCard
                headerStyle={headerStyle} borderColor={borderColor} person={person} menu={menu}
                item={item}>
                { body }
            </BaseCard>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<ProcessingCard context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

import React, {Component} from 'react';

import {Redirect} from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import SchedulingContext from "./context";
import {globalBloc} from "../global.bloc";


const styles = theme => ({
    root: {
        flex: "1 1 auto",
        width: "100%",
        padding: "60px 100px",
    },
});

class DashboardRedirect extends Component {

    componentDidMount() {
    }

    render() {

        let { classes } = this.props;

        return (
            <div className={classes.root}>
                <Redirect to={`/scheduling/${globalBloc.subject.value.location}/appointment`} />
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <SchedulingContext.Consumer>
        {value => {
            return (<DashboardRedirect context={value} {...props} />);
        }
        }
    </SchedulingContext.Consumer>
));

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import RecordRTC, {StereoAudioRecorder} from "recordrtc";

import MicIcon from '@material-ui/icons/Mic';
import {AudioWebsocket} from "../../../../../../../utils/services/audio.websocket";
import {notificationService} from "../../../../../../../utils/notification";
import {Box, Button, Fab} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import StopIcon from '@material-ui/icons/Stop';
import ClinicalGraphInterfaceContext from "../../../context";
import {fromString} from "../../ClinicalGraph/PhysicalExam/bloc";

const styles = theme => ({
    root: {
        display: "inline",
        maxHeight: "37px",
        zIndex: 100,
        minWidth: "207px",
        maxWidth: "207px",
        marginRight: "12px",
    },
    button: {
        minWidth: "207px",
        background: "#FFFFFF",
        border: "1px solid #E3E3E3",
    },
    recorderHidden: {
        display: "none",
    },
    recorder: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "36px 62px",
        marginTop: "12px",

        position: "relative",
        width: "207px",
        height: "193.93px",

        background: "#FFFFFF",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
        zIndex: 100,
    },
    recorderTime: {

        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "22px",
    },
    recorderAction: {

        paddingTop: "30px",
    },
    recorderActionButton: {
        height: "70px",
        width: "70px",
        color: "#FFFFFF",
        backgroundColor: "#CD2863",
        boxShadow: "0px 0px",
        border: "9px solid rgba(205,40,99,0.7)",
        "&:hover": {
            backgroundColor: "rgba(205,40,99,0.7)",
        }
    },
    recorderActionIcon: {
        color: "rgb(255, 255, 255)",
        height: "30px",
        width: "30px"
    },
    fill: {
        flex: "1 1 auto",
    },
});


class DictateComponent extends Component {

    sampleRate = 16000;

    buffer = [];

    timer;

    constraints = { video: false, audio: {
            deviceId: "default",
            sampleRate: this.sampleRate,
            sampleSize: 16,
            channelCount: 1
        } };

    constructor(props, context) {
        super(props, context);

        const { targetSection, physicalExamBloc } = props;

        this.bloc = physicalExamBloc;
        this.targetSection = targetSection;

        const audioSocket = new AudioWebsocket();
        this.wsSubscription = audioSocket.registerDataCallback(this.__updateMessage)
        audioSocket.connect();

        this.state = { seconds: 0, recording: false, audioSocket: audioSocket };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        const { audioSocket, } = this.state;
        if(this.wsSubscription) {
            audioSocket.send("/stop");
            this.wsSubscription.unsubscribe();
        }
    }

    __updateMessage = (message) => {
        if(message.length > 2 && message.startsWith("{")) {
            const data = fromString(message);
            if (data?.type === "provider.dication") {
                if (data.data?.status === "final") {
                    this.bloc.processDictation(this.targetSection, data);
                }
                this.setState({message: data});
            }
        }
    }

    __toggleRecording = () => {

        const { audioSocket, stream, recordAudio } = this.state;

        if(stream) {
            stream.getTracks().forEach(function(track) {
                track.stop();
            });
            recordAudio.destroy();
            audioSocket.send("/stop");
            if(this.timer) {
                clearInterval(this.timer);
            }
            this.setState({ seconds: 0, recording: false, stream: undefined, audioContext: undefined, pcmWorker: undefined, recordAudio: undefined })
        } else {

            const $this = this;

            navigator.mediaDevices.getUserMedia(this.constraints)
                .then(function(stream) {

                    let recordAudio = new RecordRTC(stream, {
                        type: 'audio',
                        mimeType: 'audio/wav',
                        sampleRate: 44100,

                        recorderType: StereoAudioRecorder,

                        numberOfAudioChannels: 1,

                        timeSlice: 100,

                        ondataavailable: function(blob) {
                            let reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = function() {
                                let base64data = reader.result;
                                audioSocket.send(base64data);
                            }
                        },

                        desiredSampRate: 16000
                    });
                    audioSocket.send("/start");

                    recordAudio.startRecording();
                    if($this.timer) {
                        clearInterval($this.timer);
                    }
                    $this.timer = setInterval(() => { $this.setState({ seconds: ($this.state.seconds + 1) }) }, 1000);

                    $this.setState({seconds: 0, recording: true, stream: stream, recordAudio: recordAudio });

                })
                .catch(function(error) {
                    notificationService.error(error);
                    $this.setState({recording: false})
                    audioSocket.send("/stop");
                });
        }
    }

    render() {

        const { classes } = this.props;
        const { stream, seconds, message } = this.state;
        const recorder = stream ? classes.recorder : classes.recorderHidden;

        return <Box className={classes.root}>
            <Button
                className={classes.button}
                startIcon={<MicIcon />}
                variant={"contained"}
                onClick={this.__toggleRecording}
            ><FormattedMessage id={"physical.exam.dictate.finding"} defaultMessage={"Dictate finding"} /></Button>
            <Box className={ recorder }>
                <Box className={classes.recorderTime}>{this.__formatSeconds(seconds)}</Box>
                <Box className={classes.recorderAction}>
                    <Fab onClick={ this.__toggleRecording } className={classes.recorderActionButton} aria-label="stop">
                        <StopIcon className={classes.recorderActionIcon} />
                    </Fab>
                </Box>
            </Box>
        </Box>
    }

    __formatSeconds = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
        let hoursDisplay = hours < 10 ? `0${hours}` : `${hours}`;

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let minutesDisplay = minutes < 10 ? `0${minutes}` : `${minutes}`;

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let secondsDisplay = seconds < 10 ? `0${seconds}` : `${seconds}`;

        return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
    }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<DictateComponent context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

import { v4 as uuidv4 } from 'uuid';

export class UuidUtil {

    next() {

        return uuidv4();
    }
}

export const uuidUtil = new UuidUtil();

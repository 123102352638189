import rules from './rules';
import {authService} from "../auth";

export const checkRulesAllow = (action) => {

    const roles = authService.getUserRoles();
    const rules = rules;

    let allowed = false;

    for (let idx = 0; idx < roles.length; idx += 1) {

        let role = roles[idx];

        const permissions = rules[role];

        if (!permissions) {
            continue;
        }

        const staticPermissions = permissions.static;

        allowed =  staticPermissions && staticPermissions.includes(action);
        if(allowed) break;
    }

    return allowed;
};


export const checkAllowed = (rules, roles, action) => {

    let allowed = false;

    for (let idx = 0; idx < roles.length; idx += 1) {

        let role = roles[idx];

        const permissions = rules[role];

        if (!permissions) {
            continue;
        }

        const staticPermissions = permissions.static;

        allowed =  staticPermissions && staticPermissions.includes(action);
        if(allowed) break;
    }

    return allowed;
};


const Allowed = props => checkAllowed(rules, props.roles, props.priviledge) ? props.yes() : props.no();

Allowed.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Allowed;

import {Box} from "@material-ui/core";
import React from "react";

import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";
import PhysicalExam from "./PhysicalExam";
import DecodedComponent from "../../../../../../shared/DecodedComponent";

const styles = theme => ({
    root: {
        maxHeight: "100%",
        overflow: "hidden",
    },
});


class PhysicalExamTab extends DecodedComponent {

    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;
        this.state = {initialised: false, physicalExam: {open: false}};
    }

    render() {

        const {classes} = this.props;

        return (<Box className={classes.root}><PhysicalExam parentBloc={this.bloc}/></Box>);
    }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<PhysicalExamTab context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

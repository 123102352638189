import {ButtonBase, Typography, withStyles} from "@material-ui/core";
import React from "react";
import DrawerChipItems from "./DrawerChipItems";
import { DHAccordion } from "../../Common";
import { useCGIContext } from "../../ClinicalGraph/CGIContext";

const styles = {
  drawerItems: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 40,
  },
  sectionHeader: {
    color: "#777777",
    textTransform: "uppercase",
  },
  iconColor: {
    color: "#C4C4C4",
  },
};
export const DrawerItem = ({ item, classes }) => {
  const processState = useCGIContext();
  const {
    drawerContextMenu,
    setDrawerContextMenu,
    setSelectedDrawerContextMenu,
    selectedDrawerContextMenu,
  } = processState;
  return (
    <>
      <div style={{ marginLeft: 16, marginRight: 16 }}>
        {item?.map((sec, index) => (
          <React.Fragment key={`${sec.bid}-${index}`}>
            <DHAccordion
                expandedInitial={true}
                header={<ButtonBase>
                  <Typography
                      className={classes.sectionHeader}
                      component={"span"}>
                  {sec.code.display}
                  </Typography>
            </ButtonBase>}
              style={{ marginTop: 40 }}
              onHeaderTap={() => {
                if (
                  selectedDrawerContextMenu === sec.code.display ||
                  !drawerContextMenu
                ) {
                  setDrawerContextMenu(!drawerContextMenu);
                }
                setSelectedDrawerContextMenu(sec.code.display);
              }}
            >
              <DrawerChipItems items={sec.items} source={sec} />
            </DHAccordion>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default withStyles(styles)(DrawerItem);

import React, {Component, useEffect} from "react";
import {AddCircleSharp, Clear} from "@material-ui/icons";
import {withStyles} from "@material-ui/styles";
import {Box, IconButton, Typography, Button} from "@material-ui/core";
import {useCGIContext} from "../../ClinicalGraph/CGIContext";

import Allergies from "./Forms/Allergy/Allergies";
import Medications from "./Forms/Medication/Medications";
import Procedures from "./Forms/Procedure/Procedures";
import Conditions from "./Forms/Condition/Conditions";
import Immunizations from "./Forms/Immunization/Immunizations";
import ClinicalGraphInterfaceContext from "../../../context";
import {Bloc} from "./Forms/bloc";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import Recommendations from "./Forms/Recommendations/Recommendations";

const styles = (theme) => ({
    root: {
    },
    divider: {
        marginTop: 16,
        marginRight: 16,
        marginBottom: 16,
    },
    closeButton: {
        paddingTop: "2em",
        paddingLeft: 64,
        paddingRight: 64,
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        height: "78px",
    },
    buttonGroup: {
        justifyContent: "flex-end",
        display: "flex",
        paddingTop: "2em",
        paddingBottom: "2em",
        paddingLeft: 64,
        paddingRight: 64
    },
    cancelBtn: {
        marginRight: 12,
        color: theme.palette.CGIPrimary.main,
    },
    saveBtn: {
        backgroundColor: "#00D1FF",
        color: "#fff",
    },

    box: {
        background: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    chart: {
        paddingTop: 24,
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        overflow: "hidden",
    },
    chartInfo: {
        marginTop: "2em",
        flexGrow: "1",
        overflowY: "scroll"
    },
    recommendationsContainer: {
        maxHeight: "400px",
        marginLeft: 64,
        marginRight: 64,
    },
});

class DrawerContextPopOver extends DecodedComponent {


    constructor(props,) {
        super(props,);

        const {bloc,} = this.props.context;

        this.bloc = new Bloc(bloc);
        this.state = {};
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.bloc.close();
    }

    render() {

        const { classes, handleClose } = this.props;
        const {drawerItems, selectedDrawerContextMenu, bloc} = this.props.context;
        const { loading } = this.state;

        const element = document.getElementById(selectedDrawerContextMenu ?? "id");
        if (element) {
            element.scrollIntoView();
        }

        const items = drawerItems?.sections || [];

        if(!drawerItems) {
            return <></>;
        }

        return (
            <>
                <Box className={classes.closeButton}>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleClose()}>
                        <Clear style={{color: "#C4C4C4"}}/>
                    </IconButton>
                </Box>

                <Box className={classes.chart}>
                    <div className={classes.recommendationsContainer}>
                        <Recommendations cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                    </div>
                    <div className={classes.chartInfo}>
                        <div style={{
                            marginLeft: 64,
                            marginRight: 64,
                        }}>
                            <Allergies cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                            <Medications cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                            <Immunizations cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                            <Conditions cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                            <Procedures cgiBloc={bloc} formBloc={this.bloc} items={items}/>
                        </div>
                    </div>
                </Box>

                <div className={classes.buttonGroup}>
                    <Button
                        className={classes.cancelBtn}
                        onClick={() => handleClose()}
                        variant="outlined">
                        Close
                    </Button>
                    <Button
                        disabled={loading}
                        className={classes.saveBtn}
                        variant="contained"
                        color="CGISecondary"
                        onClick={() => this.bloc.save()}>
                        Update
                    </Button>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<DrawerContextPopOver context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

const rules = {
    TNT_NEXT: {
        static: [
            "interaction:encounter:cgi",
        ],
    },
    TNT_USER: {
        static: [
            "account:update:me",
            "global:location:switch",
        ],
    },
    PROVIDER: {
        static: [
            "interaction:encounter:cgi",
            "interaction:encounter:*",
            "interaction:telehealth:*",

            "interaction:encounter:start",
            "interaction:encounter:back",
            "interaction:encounter:end",
            "interaction:encounter:cancel",
            "interaction:encounter:send-patient-link",

            "interaction:telehealth:start",

            "interaction:appointment:checkin",
            "interaction:appointment:delay",

            "rpa:manual:*",
            "admin:settings:*",
        ],
    },
    TNT_ASSIST: {
        static: [
            "interaction:encounter:*",
            "interaction:appointment:*",

            "interaction:encounter:start",
            "interaction:encounter:update",
            "interaction:encounter:back",
            "interaction:encounter:end",
            "interaction:encounter:cancel",
            "interaction:encounter:send-patient-link",

            "interaction:telehealth:start",

            "interaction:appointment:checkin",
            "interaction:appointment:delay",
        ],
    },
    TNT_CLERK: {
        static: [
            "interaction:encounter:*",
            "interaction:appointment:*",

            "interaction:appointment:checkin",
            "interaction:appointment:delay",
            "rpa:manual:*",
            "admin:settings:*",
        ],
    },
    TNT_OFFICE: {
        static: [
            "workflow:manual:*",
        ],
    },
    TNT_DATA: {
        static: [
            "analytics:dashboard:view",
        ],
    },
};

export default rules;

import React from "react";
import {DHChip} from "../../Common";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import {Box, CircularProgress, IconButton} from "@material-ui/core";
import {FormatListBulleted} from "@material-ui/icons";
import {AddObservationsAndDXContextMenu, ContextMenu} from "../../CGICommon/ContextMenu";
import {OntologyConstants} from "../../../bloc";
import {onAddCondition} from "../DiffrentialDX";
import ClinicalGraphInterfaceContext from "../../../context";

const styles = theme => ({
  root: {
      display: "flex",
      maxHeight: "130px",
      height: "130px",
      padding: "16px 0 16px 0",
  },
    controls: {
        width: "80px",
        height: "inherit",
    },
    differential: {
        overflow: "auto",
    },
    rotateIcon: {
        webkitTransform: "scaleX(-1)",
        transform: "scaleX(-1)",
    },
    button: {
        margin: "10px 0 0 8px",
        backgroundColor: "#fff",
    },
    buttonHighlighted: {
        margin: "10px 0 0 8px",
        backgroundColor: "#FF48E2",
        color: "#fff",
    }
});

class PhysicalLinkWorkspace extends DecodedComponent {

  constructor(props) {
    super(props);
    this.bloc = props.bloc;
    this.state = { initialised: false, contextOpen: false, conditions: [], ddxContext: [], selected: []};
  }

  componentDidMount() {
      super.componentDidMount();
  }

  componentWillUnmount() {
      super.componentWillUnmount();
  }

    __clearSelected = (e) => {
        this.bloc.clearDDxContext();
    }

    __toggleContext = () => {
      this.setState({
          contextOpen: !this.state.contextOpen,
      });
    }

    __toggleSelected = (item) => (e) => {
      this.bloc.setDDxContext(item);
    }

  render() {

    const { initialised, contextOpen, ddxContext, conditions } = this.state;
    const { classes } = this.props;

    if(!initialised) {
      return (
          <Box style={{
            height: "100%",
            padding: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <CircularProgress />
        </Box>);
    }

    return (
        <Box className={classes.root}>
          <Box className={classes.controls}>
              <ContextMenu
                  isOpen={contextOpen}
                  modal
                  heading={
                      <IconButton onClick={this.__toggleContext} className={classes.rotateIcon}><FormatListBulleted /></IconButton>
                  }
                  onClose={this.__toggleContext}
              >
                  <AddObservationsAndDXContextMenu
                      bloc={this.bloc.parent}
                      label="Add Condition"
                      ontologyConstants={OntologyConstants.CONDITION.semantic}
                      vocabulary={OntologyConstants.CONDITION.vocabulary}
                      disableFreeText={true}
                      onItemPress={(item) => {
                          this.__toggleContext();
                          onAddCondition(item, this.bloc.parent.subject.value.currentComplaint, this.props.context);
                      }}
                  />
              </ContextMenu>
              {/*<IconButton onClick={this.__clearSelected}><HighlightOff /></IconButton>*/}
          </Box>
            <Box className={classes.differential}>
                { conditions.map((_item, index) => <>
                    <DHChip
                        drawerItem={false}
                        label={
                            _item.description
                        }
                        index={index}
                        colortiles={[
                            //this.bloc.differentialColour().getColour(currentComplaint.bid, item.bid),
                        ]}
                        onClick={this.__toggleSelected(_item)}
                        style={{
                            minWidth: 100,
                            marginRight: 12,
                            backgroundColor: ddxContext.includes(_item.bid) ? "#FF48E2" : "#fff",
                            color: ddxContext.includes(_item.bid) ? "#fff" : "#333333",
                        }}
                    />

                {/*<Button onClick={this.__toggleSelected(_item)} className={ ddxContext.includes(_item.bid) ? classes.buttonHighlighted : classes.button} variant={"contained"}>{_item.description}</Button>*/}
                    </>) }
            </Box>

        </Box>
    );
  }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<PhysicalLinkWorkspace context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, TextField,} from '@material-ui/core';
import {DateMask} from "../../../shared/InputTextMask";
import withStyles from '@material-ui/core/styles/withStyles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Typography from "@material-ui/core/Typography";
import AppointmentContext from "./context";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {FormattedMessage} from "react-intl";
import {appointmentEventService} from "./service/appointment.event.service";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {appointmentDataChange, gendersMandatory} from "./util";
import {notificationService} from "../../../../utils/notification";
import {insurancesApi} from "../../../../utils/services/insurances.api";
import InsuranceDisplay from "./components/InsuranceDisplay";

const styles = theme => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    formContainer: {
        width: "inherit",
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    formInputTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.primary.main,
    },
    button: {
        paddingTop: "26px",
        display: "flex",
    }
});

const defaultPlanTypes = [
    "I'm not sure",
    "Behavioral health",
    "Contracts",
    "EPO",
    "FFS",
    "HMO",
    "Indemnity",
    "Legal",
    "MVA",
    "Medicare Supplement",
    "PFFS",
    "POS",
    "POS II",
    "PPO",
    "QPOS",
    "Radiology",
    "TRICARE Supplement",
    "Vision",
    "Workers Comp",
    "Other"
]


const paymentTypes = [
    {
        label: "Self pay",
        value: "outofpocket",
    },
    {
        label: "Insurance",
        value: "insurance",
    },
]

class InsuranceDetail extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {
            planTypeList: [],
            planList:[],
        }
    }

    __continue = () => {

        const { editting } = this.state;

        if(editting) {
            appointmentEventService.update("REGISTRATION_SUBMIT_INSURANCE_DETAIL", { updated: true });
        } else {
            appointmentEventService.update("REGISTRATION_SUBMIT_INSURANCE_DETAIL", {});
        }
    }

    __reset_form = () => {
        appointmentEventService.update("REGISTRATION_RESET_INSURANCE", { });

        this.setState({
            editting: false,
            planTypeList: [],
            planList:[],
        });
    }

    __handleTextChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };

    __handlePaymentTypeChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
            other: {
                policyHolderGivenName: undefined,
                policyHolderFamilyName: undefined,
                policyHolderDob: undefined,
                policyHolderGender: undefined,
                provider: "",
                isPolicyHolder: true,
                memberId: "",
                planType: "",
                userPlan: {},
            }
        });

        this.setState({
            planTypeList: [],
            planList:[],
        })
    };

    __handleCheckboxChange = (event) => {
        appointmentEventService.update("REGISTRATION_FLAG_CHANGE", {
            target: event.target.name,
            value: event.target.checked,
        });
    };

    __handleProviderChange = (event, newValue) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: "provider",
            value: newValue,
            other: {
                planType: "",
                userPlan: {},
            }
        });

        let provider = this.props.context.providerList.find((provider) => provider["providerName"] === newValue)

        this.setState({
            planLoading: true,
            planTypeList: [],
            planList:[],
        })

        setTimeout(() => {
            this.setState({
                planLoading: false,
                planTypeList: provider ? provider["planTypes"] : defaultPlanTypes,
            });
        }, 500);
    };
    __handlePlanTypeChange = (event) => {

        const { form } = this.props.context;

        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });

        this.setState({
            planList:[],
            planLoading: true,
        });

        let request = {
            providerName: form.provider.toLowerCase(),
            memberId: form.memberId,
            planType: event.target.value.toLowerCase(),
        }

        insurancesApi.searchPlan(request).then((value => {

            this.setState({
                planList: value.data.items,
                userPlan: ''
            })
        })).catch((reason) => {

            notificationService.error("Error searching for insurance plans. Error - " + reason);
        }).finally(() => {

            this.setState({
                planLoading: false
            })
        });
    };
    __handleUserPlanChange = (event) => {
        appointmentEventService.update("REGISTRATION_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };


    __handleDateChange = appointmentDataChange.__handleDateChange;

    __isLoadingData = () => {

        const { planLoading } = this.state;

        return planLoading;
    };

    __edit_form = () => {

        this.setState({
           editting: true,
        });
    };


    __isFormValid = () => {

        let { form } = this.props.context;

        if((!form.memberId || form.memberId === "") && (!form.provider || form.provider === "")) return true;

        return form.provider && form.planType && form.userPlan &&
            form.provider !== "" && form.planType !== "" && form.userPlan.planId && form.userPlan.planName && form.userPlan.planName !== "";
    }


    __renderPolicyHolderForm = () => {
        let { classes } = this.props;
        let { form } = this.props.context;

        return (
            <React.Fragment>

                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.title}>
                        <FormattedMessage id="policyholder" defaultMessage={`Policy Holder`} />
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextValidator
                        required
                        fullWidth
                        id="policyHolderGivenName"
                        label="First Name"
                        name="policyHolderGivenName"
                        autoComplete="given-name"
                        value={form.policyHolderGivenName}
                        onChange={this.__handleTextChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextValidator
                        required
                        fullWidth
                        id="policyHolderFamilyName"
                        label="Last Name"
                        name="policyHolderFamilyName"
                        autoComplete="family-name"
                        value={form.policyHolderFamilyName}
                        onChange={this.__handleTextChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextValidator
                        required
                        fullWidth
                        id="policyHolderDob"
                        label="Date of birth"
                        name="policyHolderDob"
                        value={form.policyHolderDob}
                        onChange={this.__handleTextChange}
                        placeholder="MM/dd/yyyy e.g. 02/01/2000"
                        validators={['matchRegexp:^(0[1-9]|1[012])/(0[0-9]|[12][0-9]|3[01])/(18|19|20)[0-9]{2}$']}
                        errorMessages={['Invalid date of birth. Please use the format MM/dd/yyyy e.g. 02/01/2000']}
                        InputProps={{
                            inputComponent: DateMask,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>

                    <TextField
                        id="policyHolderGender"
                        name="policyHolderGender"
                        fullWidth
                        select
                        required
                        label="Gender"
                        value={form.policyHolderGender}
                        onChange={appointmentDataChange.__handleGenderChange}
                        helperText="Please select your gender"
                    >
                        {gendersMandatory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>


            </React.Fragment>
        )
    }

    renderExistingInsurance = () => {

        return (
            <InsuranceDisplay editForm={this.__edit_form} />
        );
    }

    render() {
        let { planList, planTypeList, editting, planLoading } = this.state;
        let { classes, handleNext } = this.props;
        let { form, providerList, existingInsurance, loading } = this.props.context;

        let insuranceRequiredProps = {};
        if(form.memberId && form.memberId.length !== 0) {
            insuranceRequiredProps.required = true;
        }

        if(existingInsurance && !existingInsurance.updated && !editting) {

            return this.renderExistingInsurance(classes, existingInsurance);
        }

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ValidatorForm className={classes.formContainer} onSubmit={this.__continue}>
                    <div className={classes.form}>
                        <div className={classes.formInput}>
                            <Grid container spacing={3}>

                                <Grid item xs={12}>
                                    <Typography variant={"h5"}>
                                        <FormattedMessage id={"patient.payment.type"} defaultMessage={"Payment type"} />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="paymentType"
                                        name="paymentType"
                                        fullWidth
                                        select
                                        label="Payment type"
                                        value={form.paymentType}
                                        onChange={this.__handlePaymentTypeChange}
                                    >
                                        {paymentTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                { form.paymentType === "insurance" && <>
                                <Grid item xs={12}>
                                    <Typography variant={"h5"}>
                                        <FormattedMessage id={"patient.is.policy.holder.title"} defaultMessage={"Patient is the policy holder"} />
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.isPolicyHolder}
                                                onChange={this.__handleCheckboxChange}
                                                id="isPolicyHolder"
                                                name="isPolicyHolder"
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <>
                                                <FormattedMessage id={"patient.is.policy.holder"} defaultMessage={"Patient is the policy holder"} />
                                            </>
                                        }
                                    />
                                </Grid>

                                { !form.isPolicyHolder && this.__renderPolicyHolderForm() }

                                <Grid item xs={12}>
                                    <Typography variant="h5" className={classes.title}>
                                        <FormattedMessage id="insurance" defaultMessage={`Insurance`} />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        id="memberId"
                                        label="Member ID"
                                        name="memberId"
                                        value={ form.memberId }
                                        onChange={this.__handleTextChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="provider"
                                        name="provider"
                                        freeSolo={true}
                                        inputValue={ form.provider }
                                        { ...insuranceRequiredProps }
                                        disabled={!(form.memberId && form.memberId.length !== 0)}
                                        value={form.provider}
                                        onInputChange={this.__handleProviderChange}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                helperText="Please select your insurance provider"
                                                label="Insurance Provider" margin="normal"
                                            />}
                                        options={providerList.map((option) => option.providerName)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="planType"
                                        name="planType"
                                        fullWidth
                                        select

                                        { ...insuranceRequiredProps }

                                        disabled={!(form.provider && form.provider.length !== 0)}
                                        label="Insurance Plan Type"
                                        value={form.planType}
                                        onChange={this.__handlePlanTypeChange}
                                        helperText="Please select an insurance plan type"
                                    >
                                        {planTypeList.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="userPlan"
                                        name="userPlan"
                                        fullWidth
                                        select

                                        { ...insuranceRequiredProps }

                                        disabled={!(planList && planList.length !== 0)}
                                        label="Your Insurance Plan"
                                        value={form.userPlan}
                                        onChange={this.__handleUserPlanChange}
                                        helperText="Please select your insurance plan"
                                    >
                                        {planList.map((option) => (
                                            <MenuItem key={option.planName} value={option}>
                                                {option.planName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                {form.userPlan && form.userPlan.planId === "-1" &&
                                <Grid item xs={12}>
                                    <TextValidator
                                        id="customUserPlanName"
                                        name="customUserPlanName"
                                        fullWidth
                                        disabled={!form.planType}
                                        label="Your Insurance Plan Name"
                                        value={form.customUserPlanName}
                                        onChange={this.__handleTextChange}
                                        helperText="Please provide your insurance plan name if you know."
                                    />
                                </Grid>}
                                </> }
                            </Grid>
                        </div>
                        <div className={classes.fill} />
                        <div className={classes.button}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={this.__reset_form}
                            >
                                Reset
                            </Button>
                            <div className={classes.fill}></div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!this.__isFormValid() || this.__isLoadingData()}
                            >
                                {this.__isLoadingData() && <CircularProgress size="1.5em" />}
                                {!this.__isLoadingData() && "Continue"}
                            </Button>
                        </div>
                    </div>
                </ValidatorForm>
                </MuiPickersUtilsProvider>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<InsuranceDetail context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

import React, { Component } from 'react';
import DHFormRow from "../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {Button, IconButton, Typography} from "@material-ui/core";


const styles = theme => ({
    suggestionBtn: {
        '& span': {
            fontSize: "10px"
        }
    }
})

const default_target = "onset";

const default_selection = [
    { display: "> 1 yrs", value: "More than 1 years ago" },
    { display: "< 1 yr", value: "Less than 1 year ago" },
    { display: "< 6 mnths", value: "Less than 6 months ago" },
    { display: "< 3 mnths", value: "Less than 3 months ago" },
    { display: "< 4 wks", value: "Less than 4 weeks ago" },
    { display: "< 2 wks", value: "Less than 2 weeks ago" },
    { display: "< 1 wk", value: "Less than 1 week ago" },
    { display: "< 3 days", value: "Less than 3 days ago" },
].reverse()

class QuickSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            target: props.target || default_target,
            values: props.selections || default_selection,
        };
    }

    __populateSuggestion = (target, value) => (e) => {
        this.props.onClick(target, value);
    }

    render() {

        let { classes, } = this.props
        let { target, values } = this.state

        return (
            <React.Fragment>
                <Typography style={{lineHeight: "3"}} variant={"caption"}>Quick select: </Typography>
                { values.map(_val => <Button className={classes.suggestionBtn} onClick={this.__populateSuggestion(target, _val.value)}> { _val.display } </Button>) }
            </React.Fragment>
        )

    }
}

export default withStyles(styles)(QuickSelection);

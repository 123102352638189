import axios from 'axios';

class OntologyApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    search(query, dataTypes, vocabularies, termTypes) {

        let request = { query: query, semanticTypes: dataTypes, criteria: {}};
        if(vocabularies) {

            vocabularies.forEach(vocabulary => {
                request.criteria[vocabulary] = {};
                if(termTypes) {
                    request.criteria[vocabulary]['termTypes'] = termTypes;
                }
            });
        }

        return axios.post(`${this.baseUrl}/next/ontology/concepts/_search`, request);
    }
}

export const ontologyApi = new OntologyApi();

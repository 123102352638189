import axios from 'axios';

class ChartApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    getPersonChartSummary(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart`)
    }

    getPersonChartAllergies(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/allergies`)
    }

    getPersonChartConditions(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/conditions`)
    }

    getPersonChartMedications(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/medications`)
    }

    getPersonChartImmunizations(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/immunizations`)
    }

    getPersonChartProcedures(organisationId, encounterId) {

        return axios.get(`${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/procedures`)
    }


    saveChartHistory(organisationId, encounterId, payload) {
        return axios.post(
            `${this.baseUrl}/next/organisations/${encodeURI(organisationId)}/encounters/${encodeURI(encounterId)}/chart/_command`,
            { command: "history_bulk_update", payload: payload }
        );
    }
}

export const chartApi = new ChartApi();

import axios from 'axios';

class AccountApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    preAuthCheck() {

        return axios.get(`${this.baseUrl}/api/users/me/_preauth_check`);
    }

    getPermissions() {

        return axios.get(`${this.baseUrl}/api/users/me/permissions`);
    }

    completeEnrollment(data) {

        return axios.post(`${this.baseUrl}/api/users/me/_enroll`, data);
    }

    me() {

        return axios.get(`${this.baseUrl}/api/users/me`);
    }

    preferences() {
        return axios.get(`${this.baseUrl}/next/accounts/me/preferences`);
    }

    user(id) {

        return axios.get(`${this.baseUrl}/api/users/${id}`);
    }

    updateMe(profile) {

        return axios.patch(`${this.baseUrl}/api/users/me`, profile);
    }
}

export const accountApi = new AccountApi();

import React from 'react';
import {Route, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Grid} from "@material-ui/core";
import {navigationTitleService} from "../../../utils/title";
import Provider from "./Provider";
import EnrollmentContext from "./context";
import {enrollmentEventService} from "./service/enrollment.event.service";
import {authService} from "../../../utils/auth";
import {secureEventService} from "../service/secure.event.service";


const styles = theme => ({
    root: {
        flexGrow: 1,
        overscrollBehavior: "contain",
        touchAction: "none",
    },
    grid: {
      minHeight: "100%",
      overscrollBehavior: "contain",
      touchAction: "none",
    },
});

class Enrollment extends React.Component {

    subscription;

    state = {
        complete: false,
    };

    componentDidMount() {

        navigationTitleService.update("Enrollment");

        let user = authService.getUser();
        this.setState({
           user: user,
        });

        this.subscription = enrollmentEventService.registerStateCallback(this.handleEvent);
    }


    componentWillUnmount() {

        this.subscription.unsubscribe();
    }


    handleEvent = (event) => {

        const { data, type } = event;

        switch (type) {
            case 'ENROLLMENT_COMPLETE': {

                secureEventService.update(event);
                this.setState({
                    complete: true,
                });
            }
            break;
        }
    };


    render() {

        const { classes } = this.props;
        const { user } = this.state;

        let context = {
            user: user,
        };

        if(!user) {
            return <CircularProgress />
        }

        return (
            <EnrollmentContext.Provider value={context}>
                <div className={classes.root}>
                    <Grid container className={classes.grid}>
                        <Route path="/enrollment" exact component={ Provider } />
                    </Grid>
                </div>
            </EnrollmentContext.Provider>
        );
    }
}

Enrollment.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Enrollment));

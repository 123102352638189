import {Clear} from "@material-ui/icons";
import React from "react";
import {LeadingActions, SwipeAction, TrailingActions,} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import {ButtonBase} from "@material-ui/core";

export const trailingActions = (onTrailingAction, onRemove, item) => (
    <TrailingActions>
        <SwipeAction destructive={false}
                     onClick={() => {
                         onRemove(item);
                     }}>
            <div style={{alignSelf: "center"}}>
                <ButtonBase
                    tabIndex={"-1"}
                    style={{
                        backgroundColor: "#F60048",
                        color: "#fff",
                        borderRadius: 20,
                        height: 27,
                        width: 27,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Clear
                        style={{
                            color: "#fff",
                        }}
                    />
                </ButtonBase>
            </div>
        </SwipeAction>
        <SwipeAction
            destructive={false}
            main={true}
            onClick={() => {
                onTrailingAction(item);
            }}
        ></SwipeAction>

    </TrailingActions>
);
export const leadingActions = (onLeadingAction, item) => (
    <LeadingActions>
        <SwipeAction destructive={false}
                     onClick={() => {
                         onLeadingAction(item);
                     }}>
        </SwipeAction>
    </LeadingActions>
);

import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import {FormControl, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {GlobalEvent, globalEventService} from "../../Secure/service/global.event.service";
import {withRouter} from "react-router-dom";
import GlobalContext from "../../Secure/context";
import {globalBloc} from "../../Secure/global.bloc";


const styles = theme => ({
    fullscreen: {
        display: "flex",
        position: "fixed",
        right: "0",
        bottom: "0",
        minWidth: "100%",
        minHeight: "100%",
        backgroundColor: "#ffffff",
        margin: "0 auto",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        margin: "0 auto",
        height: "80px",
    },
});


class LocationSelect extends Component {

    globalSubscription;

    state = {
        organisations: [],
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.globalSubscription = globalBloc.subject.subscribe(this.__globalUpdate);
    }

    componentWillUnmount() {
        this.globalSubscription.unsubscribe();
        this.globalSubscription = undefined;
    }

    __globalUpdate = (_value) => {

        this.setState({
            organisations: _value.organisations,
        });
    }

    __handleChangeLocation = (event) => {

        const location = event.target.value;

        globalEventService.update(GlobalEvent.LOCATION_UPDATED, { newLocation: location });
    }

    __lookupOrganisation = (_item) => {

        const { organisations } = this.state;

        if(organisations) {

            const organisation = organisations.filter((_org) => _org.id === _item.partyIdentifier.value);

            if (organisation && organisation.length > 0) {

                return {
                    id: organisation[0].id,
                    name: organisation[0].name
                }
            }
        }

        return {};
    }

    render() {

        let { classes, audience } = this.props;
        let { location } = this.props.context;

        return (
            <FormControl className={classes.root}>
                <Select
                    value={location}
                    onChange={this.__handleChangeLocation}
                    id="location-select"
                    name="location-select"
                    displayEmpty
                >
                    <MenuItem value="" disabled><FormattedMessage id={"organisation.location.select"} defaultMessage={"Select location"} /></MenuItem>
                    { audience && audience
                        .map(_item => this.__lookupOrganisation(_item))
                        .filter(_item => _item.id)
                        .map(_item => <MenuItem key={`location-select-${_item.id}`} value={_item.id}>{_item.name}</MenuItem>) }
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(withRouter(props => (
    <GlobalContext.Consumer>
        {value => {
            return (<LocationSelect context={value} {...props} />);
        }
        }
    </GlobalContext.Consumer>
)));

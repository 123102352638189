import {Box, CircularProgress, LinearProgress, Typography, withStyles} from "@material-ui/core";
import React from "react";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import DHItemSelector from "../../Common/DHItemSelector";
import {cgiApi} from "../../../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../../../utils/notification";

const styles = {
  root: {
    display: "flex",
  },
  label: {
    marginRight: "12px",
    lineHeight: 2,
    marginLeft: 8,
    color: "#858585",
  },
  chip: {
    width: "auto",
    minWidth: "25px",
    paddingLeft: 24,
    paddingRight: 24,
    marginRight: 12,
    border: "#858585 1px  solid",
    color: "#858585",
    backgroundColor: "transparent",
  },
  typo: {
    color: "#858585",
    marginLeft: 8,
    textTransform: "uppercase",
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 18,
    textTransform: "uppercase",
    maxHeight: "23vh",
    overflow: "auto",
  },
};

const ROS = ["Completed", "Limited"];

class ActionPanel extends DecodedComponent {

  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;

    const {workspaceGraph,} = props.context.bloc.subject.value;

    const rosList = workspaceGraph?.encounterROS() || [];
    let ros = undefined;
    if(rosList.length > 0) {
      ros = rosList[0];
    }

    this.state = {
      ros: ros,
      busy: false,
    };
  }


  __updateRosSelectOption = (_value) => {

    const { busy, ros, } = this.state;

    if(!busy) {


      const {clinicId, encounter, workspaceGraph,} = this.bloc.subject.value;

      this.setState({busy: true});

      if (_value === ros.code.code.display) {
        _value = "Unknown";
      }

      let payload = { code: { system: ros.code.code.system, code: _value.toLowerCase(), display: _value } };
      cgiApi.updateROS(clinicId, encounter.id, ros.bid, payload)
          .then(value => {
                if(value.data) {
                  workspaceGraph.updateNode(value.data);
                  this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
                  ros.description = _value;
                  ros.code.code.code = ros.description.toLowerCase();
                  ros.code.code.display = ros.description;
                  ros.code.value = ros.description;
                  this.setState({ros: ros});
                }
              },
                  reason => notificationService.httpError(reason))
          .finally(() => { this.setState({busy: false,}) });
    }
  }

  render() {

    const { classes } = this.props;
    const { ros, busy } = this.state;

    return (
        <Box className={classes.root}>
          { busy && <LinearProgress size={4} /> }
          { ros && <>
          <Typography className={classes.label}>ROS: </Typography>
          <DHItemSelector id={`ros-quick-selection`} value={ros.code.code.display} options={ROS} onSelect={this.__updateRosSelectOption}/>
          </>}
        </Box>
    );
  }
}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
      {value => {
        return (<ActionPanel context={value} {...props} />);
      }
      }
    </ClinicalGraphInterfaceContext.Consumer>
));

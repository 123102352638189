import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography, withStyles} from "@material-ui/core";
import Delete from "../../../../../../assets/delete.svg";
import DownLoad from "../../../../../../assets/download.svg";

const styles = () => ({
    keyWrp: {
        fontSize: 15,
        lineHeight: "18px",
    },
    item: {
        display: "flex",
        justifyContent: "end",
    },
});

class DHFormRow extends Component {

    render() {
        let {classes, label, children} = this.props;

        return (
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6} style={{alignSelf: "center"}}>
                    <span className={classes.keyWrp}>{label}</span>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.item}>

                    {children}
                    {/*<div*/}
                    {/*    style={{*/}
                    {/*      display: "flex",*/}
                    {/*      justifyContent: "space-between",*/}
                    {/*      height: 32,*/}
                    {/*      alignItems: "center",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  <div>{children}</div>*/}
                    {/*</div>*/}
                </Grid>

            </Grid>
        )
    }
}

export default withStyles(styles)(DHFormRow);
import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        maxHeight: "100%",
        padding: "30px 25px",
        color: "#3A4559",
        overflow: "auto",
    },
    wrapper: {
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    in: {
        fontSize: "21px",
        fontWeight: "300",
    },
    out: {
        fontSize: "18px",
        fontWeight: "300",
    },
    messageTitle: {
        display: "flex",
        paddingTop: "12px",
        paddingBottom: "12px",
    },
    messageLabel: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#20516A",
    },
    messageTimestamp: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#20516A",
        opacity: "0.5",
    },
    messageContent: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    highlight: {
        color: "#3A4559",
        padding: "2px 5px",
        background: "#00FFFF 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 12px #587BBC29",
        borderRadius: "8px",
        opacity: "1",
    },
});

class ConversationPanel extends Component {


    constructor(props) {
        super(props);
        this.state = { position: 0 };
    }


    shouldRenderWhenFocusedEntity = (focusedNamedEntity, messages, message, index) => {
        if(messages.length > index) {

            if(index === 0) {

                return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0 ||
                    (messages[index+1] && messages[index+1].metadata && messages[index+1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0);
            }

            return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0 ||
                (messages[index-1] && messages[index-1].metadata && messages[index-1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0) ||
                (messages[index+1] && messages[index+1].metadata && messages[index+1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0);
        } else {

            if(index === 0) {
                return message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0;
            }

            return (messages[index-1] && messages[index-1].metadata && messages[index-1].metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0) ||
                message.metadata.entities.filter((entity) => entity.id === focusedNamedEntity).length > 0;
        }
    };

    onScroll = () => {

        this.setState({
            position: ((this.state.position + 1) % 1000),
        });
    };


    render() {

        let { classes, messages } = this.props;
        let { focusedNamedEntity, position } = this.state;

        return (
            <div className={classes.root} data-scroll={position} onScroll={this.onScroll}>
                <div>
                {messages && messages
                    .sort((a, b) => {

                        const first = Date.parse(a.createdOn);
                        const second = Date.parse(b.createdOn);

                        if (first === second) return 0;

                        return (first > second) ? 1 : -1
                    })
                    .filter((message, index) => {

                        if (focusedNamedEntity) {

                            return this.shouldRenderWhenFocusedEntity(focusedNamedEntity, messages, message, index);
                        } else {

                            return !message.id.endsWith("-DER");
                        }
                    }).map((message) => (

                            <div className={message.direction === "IN" ? classes.in : classes.out}>
                                <div className={classes.messageTitle}>
                                    <div className={classes.messageLabel}>
                                        { message.direction === "IN" ? "Patient" : "Quinn" }
                                    </div>
                                    <div className={classes.fill}>
                                    </div>
                                    <div className={classes.messageTimestamp}>
                                        at { message.createdOn }
                                    </div>
                                </div>
                                <div className={classes.messageContent}>
                                    { message.content.map( text => text.startsWith("{{") ? <span className={classes.highlight}>{text.substring(2, text.indexOf(":"))}</span> : <>{text}</> ) }
                                </div>
                            </div>
                        )
                    )
                }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ConversationPanel);

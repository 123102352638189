import React from "react";
import {
  AddCircleOutline,
  AddCircleSharp,
  CancelSharp,
  CancelOutlined,
  HelpSharp,
  RemoveCircleOutline,
  RemoveCircleSharp,
} from "@material-ui/icons";


export const ObservationButtonState = {
  confirm: "preliminary",
  deny: "cancelled",
  unknown: "unknown",
};

export const getIconForObservationFromState = (state, item, props) => {
  const type = getObservationType(item)
  switch (state) {
    case ObservationButtonState.confirm:
      return ObservationTypes.finalPositive === type ? <AddCircleSharp style={{color: "#00D1FF"}}  {...props}/> : <AddCircleOutline  style={{color: "#CDE0E4"}}  {...props}/>;
    case ObservationButtonState.deny:
      return ObservationTypes.finalNegative === type ? <RemoveCircleSharp style={{color: "#CD2863"}} {...props} /> : <RemoveCircleOutline style={{color: "#CDE0E4"}} {...props} />;
    case ObservationButtonState.unknown:
    default:
      return ObservationTypes.cancelled === type ? <CancelSharp style={{color: "#CD2863"}} {...props} /> : <CancelOutlined style={{color: "#CDE0E4"}} {...props} />;
  }
};


export const getIconForObservation = (state, item, props) => {
  switch (getObservationType(item)) {
    case ObservationTypes.cancelled:
      return <CancelSharp {...props} />;
    case ObservationTypes.unknown:
      return <HelpSharp {...props} />;
    case ObservationTypes.preliminary:
      return <HelpSharp {...props} />;
    case ObservationTypes.registeredPositive:
      return <AddCircleOutline {...props} />;
    case ObservationTypes.registeredNegative:
      return <RemoveCircleOutline {...props} />;
    case ObservationTypes.finalPositive:
      return <AddCircleSharp {...props} />;
    case ObservationTypes.finalNegative:
      return <RemoveCircleSharp {...props} />;
    default:
      return <HelpSharp {...props} />;
  }
};

export const getObservationType = (item) => {
  if (
    item.status === AllStatusForObservation.unknown ||
    item.status === AllStatusForObservation.preliminary
  ) {
    return ObservationTypes.unknown;
  }
  if (item.status === AllStatusForObservation.cancelled) {
    return ObservationTypes.cancelled;
  }
  if (item.status === AllStatusForObservation.final) {
    if (item.polarity === polarity.negative) {
      return ObservationTypes.finalNegative;
    }
    if (item.polarity === polarity.positive) {
      return ObservationTypes.finalPositive;
    }
    return ObservationTypes.unknown;
  }
  if (item.status === AllStatusForObservation.registered) {
    if (item.polarity === polarity.negative) {
      return ObservationTypes.registeredNegative;
    }
    if (item.polarity === polarity.positive) {
      return ObservationTypes.registeredPositive;
    }
    return ObservationTypes.unknown;
  }
  return ObservationTypes.unknown;
};
export const AllStatusForObservation = {
  registered: "registered",
  final: "final",
  preliminary: "preliminary",
  cancelled: "cancelled",
  unknown: "unknown",
};

export const polarity = {
  positive: "positive",
  negative: "negative",
};
export const ObservationTypes = {
  registeredPositive: "registeredPositive",
  registeredNegative: "registeredNegative",
  finalPositive: "finalPositive",
  finalNegative: "finalNegative",
  preliminary: "preliminary",
  unknown: "unknown",
  cancelled: "cancelled",
};
export const onIconClickWithState = async (state, item, context, setLoading) => {

  let timeout = setTimeout(() => { setLoading(false); }, 2000);

  let newState;

  const type = getObservationType(item)

  switch (state) {
    case ObservationButtonState.confirm:
      newState = ObservationTypes.finalPositive === type ? { polarity: polarity.positive, status: AllStatusForObservation.registered, } : { polarity: polarity.positive, status: AllStatusForObservation.final, };
      break;
    case ObservationButtonState.deny:
      newState = ObservationTypes.finalNegative === type ? { polarity: polarity.negative, status: AllStatusForObservation.registered, } : { polarity: polarity.negative, status: AllStatusForObservation.final, };
      break;
    case ObservationButtonState.unknown:
    default:
      newState = ObservationTypes.cancelled === type ? { polarity: polarity.positive, status: AllStatusForObservation.unknown, } : { polarity: polarity.positive, status: AllStatusForObservation.cancelled, };
  }

  const payload = {
    observationId: item.bid,
    ...newState,
  };

  await context.bloc.__updateObservation(payload);
  clearTimeout(timeout);
  setLoading(false);

  return;
};
export const onIconClick = async (item, context, setLoading) => {

  let timeout = setTimeout(() => { setLoading(false); }, 2000);

  if (
    item.polarity === polarity.negative ||
    item.polarity === polarity.positive
  ) {

    const payload = {
      observationId: item.bid,
      ...getStatusAndPolarity(
        item.status === AllStatusForObservation.final
          ? AllStatusForObservation.registered
          : AllStatusForObservation.final,
        item
      ),
    };

    await context.bloc.__updateObservation(payload);
    clearTimeout(timeout);
    setLoading(false);
  }
  return;
};
export const onLeftSwipe = async (item, context) => {
  const payload = {
    observationId: item.bid,
    ...getStatusAndPolarity(AllStatusForObservation.cancelled, item),
  };
  await context.bloc.__updateObservation(payload);
};
export const onRightSwipe = async (item, context) => {
  const payload = {
    observationId: item.bid,
    ...getStatusAndPolarity(AllStatusForObservation.registered, item),
  };
  await context.bloc.__updateObservation(payload);
};
export const onAddObservation = async (item, context) => {
  const encounterId = context?.encounter?.id;
  const { workspaceGraph, differential } = context.bloc.subject.value;
  const patient = workspaceGraph.encounterPatient();

  const payload = {
    command: "add_observation",
    payload: {
      participants: [
        {
          role: "patient",
          identifier: { value: patient.reference.value },
        },
      ],
      conditionId: differential.highlightedDx?.bid,
      identifiers: [
        {
          value: item?.canonicalName,
          code: {
            code: item?.conceptId,
            system: item?.conceptId
                ? "http://www.nlm.nih.gov/research/umls"
                : undefined,
          },
        },
      ],
    },
  };
  await context.bloc.__addObservation(payload);

  return;
};
export const processObservationDrop = (context, item, selectedDX) => {
  const { workspace, bloc } = context;
  const { differential, workspaceGraph, } = bloc.subject.value;
  if(item.source === 'drawer') {

    const patient = workspaceGraph?.encounterPatient();

    const now = new Date();

    let note = `Origin: chart extracted at ${now.toISOString()}.`;
    if(item.dateTime?.value) {
      note = `${item.dateTime?.code.display || item.dateTime?.code.code || "Effective"} ${item.dateTime?.value}. ` + note;
    }
    if(item.category?.code?.display) {
      note = `${item.category.code.display}. ` + note;
    }

    const payload = {
      command: "add_observation",
      payload: {
        participants: [
          {
            role: "patient",
            identifier: { value: patient.reference.value },
          },
        ],
        conditionId: differential.highlightedDx?.bid,
        items: [
          {
            code: {
              code: item.code.code,
              system: item.code.system,
              display: item.code.display,
            },
            source: {
              code: "history",
              system: "decoded/chart",
            },
            note: note,
            category: item.category.code,
          },
        ],
      },
    };

    context.bloc.__addObservation(payload);

  } else if (item.class === "observation") {
    if (selectedDX) {
      const patient = workspaceGraph?.encounterPatient();

      const payload = {
        command: "add_observation",
        payload: {
          participants: [
            {
              role: "patient",
              identifier: patient.reference,
            },
          ],
          conditionId: selectedDX.bid,
          identifiers: [
            {
              code: {
                system: "http://decodedhealth.com/identifier",
                code: "bid",
              },
              value: item.bid,
            },
          ],
        },
      };

      bloc.addObservation(payload);
    }
  }
};

export const getStatusAndPolarity = (status, item) => {
  switch (status) {
    case AllStatusForObservation.cancelled:
      return {
        status: AllStatusForObservation.cancelled,
        polarity: item.polarity,
      };
    case AllStatusForObservation.registered:
      return {
        status: AllStatusForObservation.registered,
        polarity: item.polarity,
      };
    case AllStatusForObservation.final:
      return {
        status: AllStatusForObservation.final,
        polarity: item.polarity,
      };
    case AllStatusForObservation.unknown:
      return {
        status: AllStatusForObservation.unknown,
        polarity: item.polarity,
      };
    case ObservationTypes.finalPositive:
      return {
        status: AllStatusForObservation.final,
        polarity: polarity.positive,
      };
    case ObservationTypes.finalNegative:
      return {
        status: AllStatusForObservation.final,
        polarity: polarity.negative,
      };
    case ObservationTypes.registeredPositive:
      return {
        status: AllStatusForObservation.registered,
        polarity: polarity.positive,
      };
    case ObservationTypes.registeredNegative:
      return {
        status: AllStatusForObservation.registered,
        polarity: polarity.negative,
      };
    default:
      return {
        status: item.status ?? AllStatusForObservation.unknown,
        polarity: item.polarity,
      };
  }
};
export const onStatusChangeBtnClick = async (status, item, context) => {
  const payload = {
    observationId: item.bid,
    ...getStatusAndPolarity(status, item),
  };
  await context.bloc.__updateObservation(payload);
};

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Switch,
    TextField
} from "@material-ui/core";
import AdministrationContext from "../context";
import {notificationService} from "../../../../utils/notification";
import {dateUtil} from "../../../../utils/date";


const styles = theme => ({
    root: {
        margin: "16px",
        width: "80%",
    },
    title: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        opacity: "0.8",
    },
    fill: {
        flex: "1 1 auto",
    },
    subtitleContainer: {

    },
    subtitleTextSecondary: {
        color: "#C92D5C",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "21px",
        display: "flex",
    },
    radioRowFirst: {
        paddingBottom: "24px",
        borderBottom: "1px solid #EEEFF0"
    },
    radioRow: {
        paddingTop: "32px",
        paddingBottom: "32px",
        borderBottom: "1px solid #EEEFF0"
    },
    radioRowContent: {
    },
    radioRowContentTimeInput: {
        width: "40px",
    },
    radioRowContentTimeInputDivider: {
        lineHeight: "60px",
        padding: "8px",
    },
});

class PlatformSwitch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subject: undefined,
            confirm: false,
            selection: "now",
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    __toggleConfirmation = () => {
        this.setState({
            confirm: true,
        });
    }

    __toggle = () => {
        const { item } = this.props;
        const { bloc } = this.props.context;
        const { selection } = this.state;

        if(selection === "till") {
            let hoursString = document.getElementById(`${item.id}-switch-duration-hours`).value;
            let minutesString = document.getElementById(`${item.id}-switch-duration-minutes`).value;
            let total = 0;
            if(hoursString) {
                const hours = parseInt(hoursString);
                if(hours > 99) {
                    notificationService.error("A maximum of 99 hours is allowed.")
                    return;
                }
                total = total + (60 * hours)
            }
            if(minutesString) {
                const minutes = parseInt(minutesString);
                if(minutes > 99) {
                    notificationService.error("A maximum of 99 minutes is allowed, please specify hours if needed.")
                    return;
                }
                total = total + minutes
            }
            if(total === 0) {
                notificationService.error("Please ensure you have entered a valid number for hours and/or minutes.")
                return;
            }
            bloc.toggleSwitchFor(item.code, total);
        } else {

            bloc.toggleSwitch(item.code);
        }

        this.setState({
            confirm: false,
            selection: "now",
        });

        this.__resetDialogInputs();
    }

    __closeDialog = () => {

        const { item } = this.props;

        this.setState({
            confirm: false,
            selection: "now",
        });

        this.__resetDialogInputs();
    }

    __resetDialogInputs = () => {
        const { item } = this.props;

        document.getElementById(`${item.id}-switch-duration-hours`).value = "";
        document.getElementById(`${item.id}-switch-duration-minutes`).value = "";
    }

    render() {

        let { busy } = this.props.context;
        let { classes, item } = this.props;

        return (
            <div className={classes.root}>
                <FormGroup row>
                    <FormControlLabel
                        className={classes.title}
                        control={
                            <Switch
                                disabled={busy}
                                checked={item.status === "1"}
                                onChange={this.__toggleConfirmation}
                                name={item.id}
                                id={item.id}
                                color={"primary"}
                            />
                        }
                        label={item.display}
                    />
                </FormGroup>
                <div className={classes.subtitleContainer}>
                    { item.status === "0" && <div className={classes.subtitleTextSecondary}>
                        Turned off until { this.__formatDate(item) }
                    </div>}
                </div>
                { this.__renderConfirmationDialog() }
            </div>
        );
    }

    __formatDate = (item) => {

        const date = dateUtil.parseDate(item.to);
        if(date.getFullYear() > 9999) return "turned on.";

        return date.toLocaleString();
    }

    __formatType = (type) => {
        return type.replaceAll(".", " ");
    }

    __handleRadioChange = (event) => {
        this.__resetDialogInputs();
        this.setState({
            selection: event.target.value,
        });
    }

    __renderConfirmationDialog = () => {

        const { classes, item } = this.props;
        const { confirm, selection } = this.state;

        let buttons = [
            <Button onClick={this.__closeDialog} className={classes.dialogButton}>
                Cancel
            </Button>,
            <Button variant={"contained"} color={"primary"} onClick={this.__toggle} className={classes.dialogButton}>
                Confirm
            </Button>
        ]

        const title = item.status === "1" ? "Switch off " + item.display : "Switch on " + item.display;
        const toggle = item.status === "1" ? "off" : "on";
        const toggleOpposite = item.status === "0" ? "off" : "on";

        return <Dialog
            open={confirm}
            onClose={this.__closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <RadioGroup aria-label="switch" name="switch" value={ selection } onChange={this.__handleRadioChange}>
                    <div className={classes.radioRowFirst}>
                        <FormControlLabel value="now" control={<Radio />} label={`Turn ${toggle} now`} />
                        <div className={classes.radioRowContent}>
                            Until manually turn { toggleOpposite } again
                        </div>
                    </div>
                    <div className={classes.radioRow}>
                        <FormControlLabel value="till" control={<Radio />} label={`Turn ${toggle} now for the next`} />
                        <div className={classes.radioRowContent}>
                            <TextField disabled={selection !== "till"} inputProps={{ 'type': 'numbers', 'maxlength': 2 }} id={`${item.id}-switch-duration-hours`} label="HH" className={classes.radioRowContentTimeInput} /> <span  className={classes.radioRowContentTimeInputDivider}>:</span> <TextField disabled={selection !== "till"} inputProps={{ 'type': 'numbers', 'maxlength': 2 }} id={`${item.id}-switch-duration-minutes`} label="mm" className={classes.radioRowContentTimeInput} />
                        </div>
                    </div>
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                { buttons }
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles)(props => (
    <AdministrationContext.Consumer>
        {value => {
            return (<PlatformSwitch context={value} {...props} />);
        }
        }
    </AdministrationContext.Consumer>
));

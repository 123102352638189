import React from "react";
import { useDrop } from "react-dnd";
import { isDropAllowed } from "./dndUtils";
import {withStyles} from "@material-ui/core";

const styles =  (theme) => ({
  place: {
    backgroundColor: "transparent",
  },
  animatedDropzone: {
    animation: `$dzHighlightZoneAnimation 3000ms forwards`,
  },
  animatedAccept: {
    animation: `$dzHighlightZoneAnimation 3000ms forwards`,
  },
  "@keyframes dzHighlightZoneAnimation": {
    "from": {backgroundColor: "rgba(0, 209, 255, 0.35)"},
    "to": {backgroundColor: "rgba(0, 209, 255, 0.05)"}
  }
});

function DropZone({
  children,
  accept,
  processDrop,
  allowedDropBackgroundColor,
  allowedSemanticTypes,
  style,
  classes,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: processDrop,
    canDrop: (item) => {
      return isDropAllowed({ item, allowedSemanticTypes }) || item?.forceDrop;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;
  let background = classes.place;
  if (isActive) {
    background = classes.animatedDropzone;
  } else if (canDrop) {
    if(allowedDropBackgroundColor) {
      background = classes.animatedAccept;
    } else {
      background = classes.animatedDropzone;
    }
  }

  return (
    <div
      ref={drop}
      accept={accept}
      role="DropZone"
      className={background}
      style={{ minHeight: 30, ...style, }}
    >
      {children}
    </div>
  );
}

export default withStyles(styles)(DropZone);

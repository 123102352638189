import * as rxjs from 'rxjs';

import {appointmentApi} from "../../../../utils/services/appointments.api";
import {notificationService} from "../../../../utils/notification";


export class Bloc {

    constructor(appointmentId, parent) {

        this.parent = parent;

        this.subject = new rxjs.BehaviorSubject({
            open: false,
            appointmentId: appointmentId,
            cancelReasons: this.parent ? this.parent.subject.value.cancelReasons || [] : [],
        });

        this.events = new rxjs.Subject();
    }


    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __updateStatus = (data) => {
        this.subject.next({
            ...this.subject.value,
            ...data,
        });
    }

    open = () => this.__updateStatus({ open: true });

    close = () => this.__updateStatus({ open: false });

    loadCancelReasons = () => {

        if(this.subject.value.cancelReasons && this.subject.value.cancelReasons.length > 0) {
            this.events.next({ type: BlocEvent.APPOINTMENT_CANCEL_REASONS_LOADED, data: this.subject.value.cancelReasons });
            this.__updateStatus({ busy: false, });
            return;
        }

        if(this.parent) {
            if(this.parent.subject.value.cancelReasons?.length > 0) {
                this.__updateStatus({
                    cancelReasons: this.parent.subject.value.cancelReasons,
                    busy: false,
                });
                this.events.next({ type: BlocEvent.APPOINTMENT_CANCEL_REASONS_LOADED, data: { items: this.parent.subject.value.cancelReasons }});
                return;
            }
        }

        appointmentApi.getCancelReasons()
            .then(value => {

                this.__updateStatus({
                    cancelReasons: value.data.items,
                    busy: false,
                });
                this.events.next({ type: BlocEvent.APPOINTMENT_CANCEL_REASONS_LOADED, data: value.data });
            })
            .catch(reason => {

                notificationService.update({
                    error: "Unable to load cancel appointment reasons due to - " + reason,
                });

                this.__updateStatus({
                    busy: false,
                });
            });
    }

    cancelAppointment = (reason) => {

        this.__updateStatus({
            busy: true,
        });

        appointmentApi.cancel(this.subject.value.appointmentId, reason)
            .then(value => {
                this.events.next({ type: BlocEvent.APPOINTMENT_CANCELLED, data: {} });
                this.close();
            }, reason => {
                notificationService.httpError(reason);
            }).finally(() => this.__updateStatus({ busy: false, }));
    }
}

export class BlocEvent {
    static APPOINTMENT_CANCEL_REASONS_LOADED = "APPOINTMENT_CANCEL_REASONS_LOADED";
    static APPOINTMENT_CANCELLED = "APPOINTMENT_CANCELLED";
}

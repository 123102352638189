import * as rxjs from "rxjs";
import {notificationService} from "../../../../../../../../utils/notification";

export class Bloc {

    subscriptions = []

    constructor(cgiBloc,) {
        this.parent = cgiBloc;

        this.subject = new rxjs.BehaviorSubject({
            initialised: false,
            loading: false,
            children: [],
        });

        this.events = new rxjs.Subject();
    }

    __updateSubject = (props) => {
        this.subject.next({
            ...this.subject.value,
            ...props,
        });
    }

    close = () => {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }

    registerBloc = (bloc) => {
        let { children } = this.subject.value;
        children.push(bloc);
        this.__updateSubject({ children });
        this.subscriptions.push(bloc.subscribeToEvents(this.__childEventListener));
    }

    __childEventListener = (event) => {
        const { type } = event;
        switch (type) {
            case  "NEW_ITEM_ADDED": {
                this.events.next(event);
            }
            break;
        }
    }

    addRecommendation = (recommendation) => {
        let { children } = this.subject.value;
        children.forEach(child => {
            if(child._class === recommendation.class) {
                child.addSilent(recommendation);
            }
        });
        this.save(true);
    }

    childrenRecommendations = (recommendations) => {
        let { children } = this.subject.value;
        children.forEach(child => {
            child.updateRecommendation(recommendations);
        });
    }

    childrenUpdated = () => {
        let { children } = this.subject.value;
        let result = [];

        children.forEach(child => {
            const { updated, } = child.subject.value;
            if(updated) {
                updated.filter(resource => resource.dirty !== -1).forEach(resource => result.push(resource))
            }
        })

        return result;
    }

    childrenResources = () => {
        let { children } = this.subject.value;
        let result = [];

        children.forEach(child => {
            const { resources, } = child.subject.value;
            if(resources) {
                resources.filter(resource => resource.class).forEach(resource => result.push(resource));
            }
        })

        return result;
    }

    save = (silent) => {
        let { children } = this.subject.value;
        let request = [];

        this.__updateSubject({loading: true});

        children.forEach(child => {
            const { resources, updated } = child.subject.value;
            if(resources) {
                resources.filter(resource => resource.dirty && resource.dirty !== 0).forEach(resource => request.push(resource))
            }
            if(updated) {
                updated.filter(resource => resource.dirty !== -1).forEach(resource => request.push(resource))
            }
        })
        this.parent.saveChartHistory({ updates: request }, (value) => {
            if(!silent) {
                notificationService.success("Saved")
            }
            children.forEach(child => {
                const { resources, updated } = child.subject.value;
                if(resources && updated && (resources.filter(resource => resource.dirty && resource.dirty !== 0 ).length > 0 || updated.length > 0)) {
                    child.refresh();
                }
            });
            setTimeout(() => {
                children.forEach(child => {
                    if(child.alwaysRefresh) {
                        child.refresh();
                    }
                });
            },  1000);
        }).finally(() => this.__updateSubject({loading: false}));
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);
}

export class BlocEvent {

    static SAVED = "CHART_SAVED";
    static ERROR = "CHART_SAVE_ERROR";
}

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {Button, Typography} from "@material-ui/core";

import EncounterContext from "../../context";
import CallEndIcon from '@material-ui/icons/CallEnd';
import {phoneUtil} from "../../../../../../utils/phone";

const styles = theme => ({
    root: {
        maxHeight: "inherit",
        height: "inherit",
        width: "100%",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        padding: "32px 72px"
    },
    fill: {
        flex: "1 1 auto",
    },
    title: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "54px",
        textTransform: "capitalize",
        color: "#222222",
    },
    titleNumber: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "36px",
        textTransform: "capitalize",
        color: "#808A94",
    },
    status: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "28px",
        lineHeight: "42px",
        textTransform: "capitalize",
        color: "#808A94",
    },
    dialBtnWrapper: {
      display: "contents",
    },
    dialBtn: {
        height: "60px",
        borderRadius: "30px",
    }
});


class Disconnect extends Component {

    blocSubscription;

    constructor(props) {
        super(props);

        this.state = {
            "display": props.number,
            "status": "Dialing",
        }

        this.__encounterStateHandler = this.__encounterStateHandler.bind(this);
        this.__onDisconnect = this.__onDisconnect.bind(this);
    }

    componentDidMount() {

        const { encounterBloc } = this.props.context;

        this.blocSubscription = encounterBloc.subscribeToState(this.__encounterStateHandler);
    }

    componentWillUnmount() {

        this.blocSubscription.unsubscribe();
    }


    __encounterStateHandler = (state) => {

        const { sessionInformation, telehealthCall } = state;

        let number = telehealthCall.local?.friendlyNumber || phoneUtil.formatPhoneNumberFull(telehealthCall.local.contactNumber);

        const eligible = sessionInformation.participants.filter(value =>
            value.identifier?.code === "connectionId" &&
            value.identifier?.value === telehealthCall?.data?.connectionId);
        let callStatus = 'DIALING';
        if(eligible.length > 0) {
            const candidate = eligible[0];
            callStatus = candidate.status;
        }

        this.setState({
            display: telehealthCall?.local?.display || "Unknown",
            number: number,
            state: callStatus.toLowerCase(),
        });
    }


    __onDisconnect = () => {

        this.props.dialOutDisconnect();
    }

    render() {

        let { classes } = this.props;
        let { display, number, state } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.title} align={"center"} variant={"h5"}>{ number }</Typography>
                <Typography className={classes.titleNumber} align={"center"} variant={"h6"}>{ display }</Typography>
                <div className={classes.fill}>
                </div>
                <Typography className={classes.status} align={"center"} variant={"h5"}>{ state }</Typography>
                <div className={classes.fill}>
                </div>
                <div id={"dialpad-dial-container"}  className={classes.dialBtnWrapper}>
                    <Button className={classes.dialBtn} variant={"contained"} color={"secondary"} onClick={this.__onDisconnect}><CallEndIcon fontSize={"large"} /></Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<Disconnect context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

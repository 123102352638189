import * as rxjs from "rxjs";
import {notificationService} from "../../../../utils/notification";
import {conversationApi} from "../../../../utils/services/conversation.api";
import {customersApi} from "../../../../utils/services/customers.api";
import {encounterApi} from "../../../../utils/services/encounter.api";
import {ontologyApi} from "../../../../utils/services/ontology.api";
import {cgiApi} from "../../../../utils/services/cgi.api";
import {DecodedGraph, EvaluationUtil, ManagementUtil, SalientFeatureUtil} from "./util";
import {globalBloc} from "../../global.bloc";
import {DiagnosisUtil} from "./util/DiagnosisUtil";
import {EvaluationManagementUtil} from "./util/EvaluationManagementUtil";
import {ObservationUtil} from "./util/ObservationUtil";
import {chartApi} from "../../../../utils/services/chart.api";
import {DifferentialColour} from "./util/ColourUtil";
import {ArrivalDispositionUtil} from "./util/ArrivalDispositionUtil";

const initial = {
    initialised: false,
    clinicalSummary: undefined,
    clinicId: undefined,
    encounterId: undefined,
    encounter: undefined,
    person: undefined,
    currentComplaint: undefined,
    differential: {
        highlightedDx: undefined,
        colour: {},
    },
    physicalExam: {open: false},
    evaluation: {open: false},
    management: {open: false},
    orders: {open: false},
    arrivalDisposition: {open: false},
};

export class Bloc {

    constructor(props) {
        this.subject = new rxjs.BehaviorSubject({
            ...initial,
            ...props,
        });

        this.events = new rxjs.Subject();

        this.observationDelegate = new ObservationUtil(this);
        this.diagnosisDelegate = new DiagnosisUtil(this);
        this.managementDelegate = new ManagementUtil(this);
        this.evaluationDelegate = new EvaluationUtil(this);
        this.evaluationManagementDelegate = new EvaluationManagementUtil(this);
        this.differentialColourDelegate = new DifferentialColour(this);
        this.arrivalDispositionDelegate = new ArrivalDispositionUtil(this);
    }

    __updateSubject = (value) =>
        this.subject.next({
            ...this.subject.value,
            ...value,
        });

    __publishEvent = (type, data) =>
        this.events.next({
            type: type,
            data: data,
        });

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    initialise = () => {
        if (this.subject.value.initialised) return;

        this.__loadEncounter().then(
            (encounter) => {
                this.__updateSubject({encounter: encounter});

                this.__loadEncounterPatient(encounter.customer);

                Promise.all([
                    this.__loadClinicalSummary(),
                    this.__loadWorkspace({encounter: encounter}),
                    this.__loadChartSummary({encounter: encounter}),
                ]).finally(() => this.__updateSubject({initialised: true}));
            },
            (reason) => {
                notificationService.httpError(reason);
            }
        );
    };

    __loadEncounterPatient = (subjectIdentifier) => {
        const {person} = this.subject.value;

        if (!person) {
            customersApi
                .getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
                .then(
                    (value) => {
                        this.__updateSubject({person: value.data});
                    },
                    (reason) => {
                        notificationService.httpError(reason);
                    }
                );
        }
    };

    __loadEncounter = () => {
        const {encounterId, encounter} = this.subject.value;

        if (encounter) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(encounter);
                    this.events.next({
                        type: BlocEvent.ENCOUNTER_LOADED,
                        data: encounter,
                    });
                }, 100);
            });
        } else {
            //TODO: Create api to retrieve.
            return undefined;
        }
    };

    __loadWorkspace = (props) => {
        let encounterId = props.encounter?.id;

        const {clinicId, encounter} = this.subject.value;

        if (!encounterId) {
            encounterId = encounter.id;
        }

        return encounterApi.workspace(clinicId, encounterId).then(
            (value) => {
                const graph = DecodedGraph.instanceOfWorkspace(encounterId, value.data);
                this.__updateSubject({workspaceGraph: graph});
                this.__publishEvent(BlocEvent.WORKSPACE_LOADED, value.data);
            },
            (reason) => notificationService.httpError(reason)
        );
    };

    refreshChartSummary = (props) => {
        return this.__loadChartSummary(props);
    }

    __loadChartSummary = (props) => {
        let encounterId = props.encounter?.id;

        const accountPreferences = globalBloc.account().preferences();

        const {clinicId, encounter} = this.subject.value;
        if (!encounterId) {
            encounterId = encounter.id;
        }

        return chartApi.getPersonChartSummary(clinicId, encounterId).then(
            (value) => {
                this.__updateSubject({
                    chartSummary: value.data,
                    accountPreferences:
                        accountPreferences?.groups?.[0]?.preferences || [],
                });
            },
            (reason) => notificationService.httpError(reason)
        );
    };

    __loadClinicalSummary = () => {
        const {encounter, clinicalSummary} = this.subject.value;

        if(encounter.conversationId) {

            if (clinicalSummary) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(clinicalSummary);
                        this.__publishEvent(BlocEvent.CLINICAL_SUMMARY_LOADED, clinicalSummary);
                    }, 100);
                });
            } else {
                return conversationApi
                    .conversationClinicalSummary(encounter.conversationId)
                    .then(
                        (value) => {
                            this.__updateSubject({clinicalSummary: value.data});
                            this.__publishEvent(BlocEvent.CLINICAL_SUMMARY_LOADED, clinicalSummary);
                            return value.data;
                        },
                        (reason) => notificationService.httpError(reason)
                    );
            }
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(clinicalSummary);
                }, 100);
            });
        }
    };

    /**
     * Search the decoded health clinical ontology.
     *
     * @param query - search query
     * @param semanticTypes - @see OntologyConstants below
     * @returns {Promise<AxiosResponse<any>>}
     */
    searchOntology = (query, semanticTypes, vocabularies, termTypes) => {
        return ontologyApi.search(query, semanticTypes, vocabularies, termTypes);
    };

    __addCondition = async (payload) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;
        return cgiApi.addCondition(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
                this.__publishEvent(BlocEvent.CONDITION_ADDED, { })
            },
            (reason) => notificationService.httpError(reason)
        );
    };


    __updateCondition = async (payload) => {
        const {clinicId, encounter, workspaceGraph} = this.subject.value;
        return cgiApi
            .updateCondition(
                clinicId,
                encounter.id,
                payload.conditionId,
                payload.data
            )
            .then(
                (value) => {

                    workspaceGraph.updateNode(value?.data);
                    this.__updateSubject({workspaceGraph: workspaceGraph});
                },
                (reason) => notificationService.httpError(reason)
            );
    };

    __addObservation = async (payload) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;
        return cgiApi.addObservation(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
            },
            (reason) => notificationService.httpError(reason)
        );
    };

    addObservation = (payload) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;

        cgiApi.addObservation(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
            },
            (reason) => notificationService.httpError(reason)
        );
    };
    /**
     * Post patch observation.
     *
     * @param observationId - itemid that is going to update
     * @param status - @see observationStatus below
     * @param polarity - @see observationPolarity below
     * @returns Once you receive the new vertex you should update your local vertex
     */
    __updateObservation = async (payload) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;
        return cgiApi
            .updateObservation(
                clinicId,
                encounter.id,
                payload.observationId,
                payload.status,
                payload.polarity
            )
            .then(
                (value) => {
                    if (value?.data.bid) {
                        workspaceGraph.updateNode(value.data);
                        this.__updateSubject({
                            workspaceGraph: workspaceGraph,
                        });
                    }
                },

                (reason) => notificationService.httpError(reason)
            );
    };

    setCurrentComplaint = (currentComplaint) => {
        this.__updateSubject({currentComplaint: currentComplaint});
    }

    togglePhysicalExam = () => {
        let {physicalExam} = this.subject.value;
        physicalExam.open = !physicalExam.open;
        this.__updateSubject({physicalExam: physicalExam, ddxContext: []});
    }

    addPhysicalExam = (template, callback, error) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;

        const patient = workspaceGraph.encounterPatient();

        const payload = {
            command: "add_physical_exam",
            payload: {
                templateId: template.bid,
                participants: [
                    {
                        identifier: patient.reference,
                        role: "SUBJECT",
                    },
                ],
            },
        };

        cgiApi.addPhysicalExam(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
                callback(workspaceGraph.encounterPatientPhysicalExam());
            },
            (reason) => {
                error(reason);
                notificationService.httpError(reason)
            }
        );
    };

    physicalExam = () => {
        const {workspaceGraph} = this.subject.value;
        return workspaceGraph?.encounterPatientPhysicalExam();
    };

    __updatePhysicalExam = async (payload) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;
        return cgiApi
            .updatePhysicalExam(clinicId, encounter.id, payload.id, payload.data)
            .then(
                (value) => {
                    workspaceGraph.merge(value?.data?.graph);
                    this.__updateSubject({workspaceGraph: workspaceGraph});
                },
                (reason) => notificationService.httpError(reason)
            );
    };


    __addCurrentComplaints = async (item) => {
        const {clinicId, encounter, workspaceGraph} =
            this.subject.value;
        const payload = {
            command: "add_reason_for_visit",
            payload: {
                reason: item.canonicalName,
                participants: [
                    {
                        identifier: {
                            code: {
                                code: workspaceGraph.encounterPatient().reference.code.code,
                                system: workspaceGraph.encounterPatient().reference.code.system,
                            },
                            value: workspaceGraph.encounterPatient().reference.value,
                        },
                        role: "SUBJECT",
                    },
                ],
            },
        };
        return cgiApi.addReasonForVisit(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
            },
            (reason) => notificationService.httpError(reason)
        );
    };

    __handleCommandResponse = (promise, source, callback) => {
        promise.then(
            (value) => {
                let workspaceGraph = this.subject.value.workspaceGraph;
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({workspaceGraph: workspaceGraph});
                if(callback) callback()
            },
            (reason) => {
                notificationService.httpError(reason);
                if(callback) callback(reason);
            });
    }

    setSelectedDX = (target, item) => {
        let {differential} = this.subject.value;
        let buttonRect = target.getBoundingClientRect();
        let top = buttonRect.top - 160;
        let left = buttonRect.left;

        differential.highlightedDx = item;

        this.__updateSubject({differential: differential, selectedDx: {top: top, left: left}});
    };

    clearSelectedDX = () => {
        let {differential} = this.subject.value;
        differential.highlightedDx = undefined;
        this.__updateSubject({differential: differential, selectedDx: undefined});
    };

    getSalientFeatures = () => {
        let {differential} = this.subject.value;
        return SalientFeatureUtil.getFeatures(this, differential.highlightedDx);
    };


    conditionRecommendations = (item) => {

        const {clinicId, encounter, workspace, workspaceGraph} =
            this.subject.value;

        return cgiApi.recommendDDx(clinicId, encounter.id, item.bid)
    }

    mergeCurrentComplaints = (items) => {
        const {clinicId, encounter, workspace, workspaceGraph} =
            this.subject.value;

        const payload = {
            command: "merge_reason_for_visit",
            payload: {
                bids: [items[0].bid, items[1].bid],
            },
        };
        return cgiApi.addReasonForVisit(clinicId, encounter.id, payload).then(
            (value) => {
                workspaceGraph.merge(value?.data?.graph);
                this.__updateSubject({
                    workspaceGraph: workspaceGraph,
                });
            },
            (reason) => notificationService.httpError(reason)
        );
    };

    openChart = () => {

        let {encounter} = this.subject.value;

        encounter.links && encounter.links.forEach((_link) => {
            if (_link.type === "chart") {
                window.open(_link.details.deeplink, "Chart Launch", "toolbar=yes,top=0,left=0,width=640,height=500");
            }
        });
    };

    triggerReload = () => {
        this.events.next({
            type: BlocEvent.RELOAD_TRIGGERED
        })
    }

    saveChartHistory = (updates, success) => {

        const {clinicId, encounter,} = this.subject.value;

        return chartApi.saveChartHistory(clinicId, encounter.id, updates)
            .then(
                value => {
                    this.events.next({
                        type: BlocEvent.CHART_UPDATED
                    })
                    success(value);
                    this.refreshChartSummary({ encounter })
                },
                reason => {
                    notificationService.httpError(reason)
                    this.events.next({
                        type: BlocEvent.CHART_UPDATE_ERROR
                    })
                })
        ;
    }

    observations = () => this.observationDelegate;
    diagnosis = () => this.diagnosisDelegate;
    evaluation = () => this.evaluationDelegate;
    management = () => this.managementDelegate;
    evaluationManagement = () => this.evaluationManagementDelegate;
    arrivalDisposition = () => this.arrivalDispositionDelegate;
    differentialColour = () => this.differentialColourDelegate;
}

const LOINC = "LOINC";
const SNOMED = "SNOMED";
const RXNORM = "RXNORM";

/**
 * These constants are used as the dataTypes for the search ontology method in the bloc above.
 */
export class OntologyConstants {

    static ALLERGIES = {
        vocabulary: [SNOMED],
        semantic: ["T168", "T195", "T109"]
    };

    static IMMUNIZATION = {
        vocabulary: [SNOMED],
        semantic: ["T129", "T121"]
    };

    static CONDITION = {
        vocabulary: [SNOMED],
        semantic: ["T033", "T037", "T046", "T047", "T048", "T184", "T191"]
    };
    static OBSERVATION = {
        vocabulary: [SNOMED],
        semantic: ["T184", "T090", "T057", "T097", "T098", "T101", "T055", "T054", "T056", "T070", "T109", "T114", "T116", "T121", "T125", "T129", "T168", "T197", "T040", "T073", "T034", "T033", "T037", "T047"],
    };
    static ORDERS_REQUEST = {
        vocabulary: [LOINC, SNOMED],
        semantic: ["T058", "T065", "T074", "T203", "T168", "T197", "T125", "T002", "T007",],
    };
    static PROCEDURE_ORDERS_REQUEST = {
        vocabulary: [LOINC, SNOMED],
        semantic: ["T061", "T060",],
    };
    static LAB_ORDERS_REQUEST = {
        vocabulary: [LOINC, SNOMED],
        semantic: ["T059",],
    };
    static MEDICATION_ORDERS_REQUEST = {
        vocabulary: [RXNORM],
        termTypes: ["PSN", "SBD", "SCD", "BPCK", "GPCK"],
        semantic: ["T200", "T121", "T109",],
    };
}

export class BlocEvent {
    static ENCOUNTER_LOADED = "ENCOUNTER_LOADED";
    static CLINICAL_SUMMARY_LOADED = "CLINICAL_SUMMARY_LOADED";
    static WORKSPACE_LOADED = "WORKSPACE_LOADED";
    static CONDITION_ADDED = "CONDITION_ADDED";
    static SALIENT_FEATURES_RELOADED = "SALIENT_FEATURES_RELOADED";
    static RELOAD_TRIGGERED = "RELOAD_TRIGGERED";

    static CHART_UPDATED = "CHART_UPDATED";
    static CHART_UPDATE_ERROR = "CHART_UPDATE_ERROR";
}

export class observationStatus {
    static registered = "registered";
    static preliminary = "preliminary";
    static final = "final";
    static cancelled = "cancelled";
    static entered_in_error = "entered-in-error";
    static unknown = "unknown";
}

export class observationPolarity {
    static positive = "positive";
    static negative = "negative";
    static unsure = "unsure";
}

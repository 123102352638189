import {isDropAllowed} from "../../CGICommon/DragAndDrop/dndUtils";
import {sortDxList} from "../CGIContext/CGIUtils";

export const dropTypes = {
  CURRENT_WORKING_DX: "CURRENT_WORKING_DX",
  CURRENT_WORKING_DX_TO_DX: "CURRENT_WORKING_DX_TO_DX",
  DRAWER_ITEM_TO_DX: "DRAWER_ITEM_TO_DX",
  DX_TO_STRICKED_DX: "DX_TO_STRICKED_DX",
  STRICKED_TO_DX: "STRICKED_TO_DX",
};
export const processDrop = (payload) => {
  if (!isDropAllowed(payload)) {
    return;
  }
  detectDropType(payload, payload.item.source, payload.destination);
};

export const getDropVerificationStatusCode = (dropType) => {
  switch (dropType) {
    case dropTypes.DX_TO_STRICKED_DX:
      return "refuted";
    case dropTypes.CURRENT_WORKING_DX:
      return "confirmed";
    case dropTypes.STRICKED_TO_DX:
      return "differential";
    case dropTypes.DRAWER_ITEM_TO_DX:
      return "differential";
    case dropTypes.CURRENT_WORKING_DX_TO_DX:
      return "differential";
    default:
      return "differential";
  }
};
const detectDropType = async (payload, source, destination) => {
  if (source === destination) {
    if(destination === "dxList") {
      payload.context.bloc.diagnosis().processReorder(payload.item, payload.item.hoverIndex);
    }
  } else if (source === "currentWorkingDX" && destination === "dxList") {
    await updateCondition({
      statusCode: getDropVerificationStatusCode(
        dropTypes.CURRENT_WORKING_DX_TO_DX
      ),
      state: payload.context,
      item: payload.item,
    });
  } else if (source === "dxList" && destination === "currentWorkingDX") {
    await updateCondition({
      statusCode: getDropVerificationStatusCode(dropTypes.CURRENT_WORKING_DX),
      state: payload.context,
      item: payload.item,
    });
    if (payload.currentWorkingDX) {
      await updateCondition({
        statusCode: getDropVerificationStatusCode(
          dropTypes.CURRENT_WORKING_DX_TO_DX
        ),
        state: payload.context,
        item: payload.currentWorkingDX,
      });
    }
  } else if (source === "drawer" && destination === "dxList") {
    onAddCondition(
      { conceptId: payload.item.code.code },
      payload.currentSelectedComplaint,
      payload.context
    );
  } else if (source === "dxList" && destination === "strickedDXList") {

    const {differential,} = payload.context.bloc.subject.value;

    if(differential?.highlightedDx?.bid === payload.item.bid) {
      payload.context.bloc.clearSelectedDX();
    }

    updateCondition({
      statusCode: getDropVerificationStatusCode(dropTypes.DX_TO_STRICKED_DX),
      state: payload.context,
      item: payload.item,
    });
  } else if (source === "strickedDXList" && destination === "dxList") {
    updateCondition({
      statusCode: getDropVerificationStatusCode(dropTypes.STRICKED_TO_DX),
      state: payload.context,
      item: payload.item,
    });
  }
};

export const onAddCondition = async (
  item,
  currentSelectedComplaint,
  state
) => {

  const currentComplaint = currentSelectedComplaint || state.bloc.subject.value.currentComplaint;

  const conditions = sortDxList({
    workspace: state.workspace,
    selectedComplaint: currentComplaint,
  });
  const isElementPresent =
    conditions.filter((c) => c?.code?.code?.code === item.conceptId)?.length >
    0;
  if (!isElementPresent) {
    const payload = {
      command: "add_condition",
      payload: {
        reasonForVisitBid: currentComplaint.bid,
        identifiers: [
          {
            value: item?.canonicalName,
            code: {
              code: item?.conceptId,
              system: item?.conceptId ? "http://www.nlm.nih.gov/research/umls": undefined
            },
          },
        ],
      },
    };
    await state.bloc.__addCondition(payload);
  }
  return;
};

export const updateCondition = async ({ statusCode, state, item }) => {
  const payload = {
    data: {
      verificationStatus: {
        system: "http://terminology.hl7.org/CodeSystem/condition-ver-status",
        code: statusCode,
      },
    },
    conditionId: item.bid,
  };
  await state.bloc.__updateCondition(payload);
};

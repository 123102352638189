import React, { Component } from 'react';
import {DHCalendar, DHDivider, DHFormInput} from "../../../../Common";
import DHFormRow from "../../../../Common/DHFormRow";
import {withStyles} from "@material-ui/core/styles";
import {dateUtil} from "../../../../../util/date";
import { format } from 'date-fns'
import {patientChartUtils} from "../../../../../util/PatientChartUtils";
import DHFormTitle from "../../../../Common/DHFormTitle";
import {IconButton} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import FormComponent from "../Common/FormComponent";
import QuickSelection from "../QuickSelection";


const styles = theme => ({
    title: {
        fontWeight: "600",
        paddingTop: "2em",
        paddingBottom: "1em",
    }
})

const CRITICALITY_OPTIONS = [
    {label: "low", value: "low"},
    {label: "high", value: "high"},
    {label: "unable-to-assess", value: "unable to assess"},
]

const SEVERITY_OPTIONS = [
    {label: "mild", value: "mild"},
    {label: "moderate", value: "moderate"},
    {label: "severe", value: "severe"},
]

const initState = {
    loading: false
}



class MedicationForm extends FormComponent {

    constructor(props) {
        super(props);
        this.state = initState;
    }


    render() {

        let { classes, value } = this.props

        return (
            <React.Fragment>
                <DHFormTitle label={value.code.display}>
                    {(value.recorder || value.recordedDate) && patientChartUtils.renderTitleRecordText(value)}
                    <IconButton onClick={this.__delete}>
                        <DeleteOutline />
                    </IconButton>
                </DHFormTitle>
                {/*{ value.effectiveDateTime && <DHFormRow label={"Start date"}>*/}
                {/*    <DHCalendar defaultValue={dateUtil.parseDate(value.effectiveDateTime)}/>*/}
                {/*</DHFormRow>}*/}
                {/*{ value.endDateTime && <DHFormRow label={"End date"}>*/}
                {/*    <DHCalendar defaultValue={dateUtil.parseDate(value.endDateTime)}/>*/}
                {/*</DHFormRow>}*/}
                <DHFormRow label={"Start"}>
                    <DHFormInput name={"start"} size={"large"} value={value.start?.onset} onChange={this.__updateOnsetText}/>
                </DHFormRow>
                <DHFormRow label={""}>
                    <QuickSelection target={"start"} onClick={this.__populateSuggestion}></QuickSelection>
                </DHFormRow>
                <DHFormRow label={"Sig"}>
                    <DHFormInput name={"sig"} size={"large"} value={value.sig} onChange={this.__updateText}/>
                </DHFormRow>
                <DHFormRow label={"Note"}>
                    <DHFormInput name={"note"} size={"large"} value={value.note} onChange={this.__updateText}/>
                </DHFormRow>

                <DHDivider/>

            </React.Fragment>
        )

    }
}

export default withStyles(styles)(MedicationForm);

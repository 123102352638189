import * as rxjs from 'rxjs';
import {globalEventService} from "../../service/global.event.service";
import {appointmentApi} from "../../../../utils/services/appointments.api";
import {authService} from "../../../../utils/auth";
import Roles from "../../../../utils/auth/roles";


export class WorkflowPatientTrackerBloc {

    globalEventServiceSubscription;

    constructor(organisationId) {

        this.subject = new rxjs.BehaviorSubject({
            organisationId: organisationId,
            items: [],
        });

        this.events = new rxjs.Subject();

        this.globalEventServiceSubscription = globalEventService.registerStateCallback(this.__listenToGlobal);
    }

    start = () => {


        this.pollSubscription = setInterval(() => this.__pollQueue(), 5000);
    }

    close = () => {
        clearInterval(this.pollSubscription);
        this.globalEventServiceSubscription.unsubscribe();
    }

    __listenToGlobal = (event) => {


    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    __pollQueue = () => {

        const { status } = this.__resolveLookup();

        if(!status || status.length === 0) return;

        let data = this.__resolveLookup();
        data.timeWindow = 48;

        appointmentApi.listEncountersInVirtualWaiting(this.subject.value.organisationId, 0, 50, data)
            .then(value => {

                if (value.config.url.indexOf(this.subject.value.organisationId) === -1) return;

                this.__update({
                    items: value.data.items,
                });
            }).catch(reason => {

            console.log("Clinical waiting room polling error.", reason);
        });
    }

    __resolveLookup() {

        let status = [];
        let types = [];

        const roles = authService.getUserRoles();
        if(roles.includes(Roles.TNT_OFFICE) || roles.includes(Roles.PROVIDER)) {

            status.push("TRANSITIONING", "REQUESTED", "CHECKING_IN", "RESERVED", "WAITING");
            types.push("IN_PERSON_WALK_IN", "IN_PERSON", "VIRTUAL");
        }

        return {
            status: status,
            types: types,
        };
    }

    __update = (newValue) => {

        this.subject.next({
            ...this.subject.value,
            ...newValue
        });
    }
}

export class WorkflowBlocEvent {
    static PERMISSIONS_LOADED = "PERMISSIONS_LOADED";
}

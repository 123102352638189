
export const allPossibleColors = [
    "#00FFE0",
    "#0912F0",
    "#A200FF",
    "#0085FF",
    "#FF0097",
    "#9EFF00",
    "#1a9f74de",
    "#9f1a1ade",
    "#9f951ade",
    "#9f1a1ade",
];



export class DifferentialColour {

    constructor(parent) {
        this.parent = parent;
        this.colourAssignment = {};
    }

    getColour = (reasonId, conditionId) => {

        if(!Object.keys(this.colourAssignment).includes(reasonId)) {
            this.colourAssignment[reasonId] = { };
            this.colourAssignment[reasonId][conditionId] = allPossibleColors[0];
        } else if(!Object.keys(this.colourAssignment[reasonId]).includes(conditionId)) {
            this.colourAssignment[reasonId][conditionId] = allPossibleColors[Object.keys(this.colourAssignment[reasonId]).length % allPossibleColors.length];
        }

        return this.colourAssignment[reasonId][conditionId];
    }

    getColoursForAction = (actionId) => {

        let colours = [];

        const { currentComplaint, workspaceGraph } = this.parent.subject.value;
        const conditions = workspaceGraph.orderIdConditions(actionId);
        conditions.forEach(condition => {

            if(Object.keys(this.colourAssignment).includes(currentComplaint.bid) &&
                Object.keys(this.colourAssignment[currentComplaint.bid]).includes(condition.bid)
            ) {
                colours.push(this.colourAssignment[currentComplaint.bid][condition.bid]);
            }
        });

        return colours;
    }
}

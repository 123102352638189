import React, {Component} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Loading from "../../../shared/Loading";
import VirtualClinicContext from "../context";
import {clinicEventService} from "../service/clinic.event.service";
import {notificationService} from "../../../../utils/notification";

const styles = theme => ({
    root: {
        overflow: "auto",
        overscrollBehavior: "contain",
        backgroundColor: "#ffffff",
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
        color: "#fff",
    },
});


class OpenEncounter extends Component {

    clinicBlocStateSubscription;

    constructor(props) {
        super(props);

        this.state = { loading: true }

        this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
    }

    componentDidMount() {

        const { clinicBloc } = this.props.context;

        this.clinicBlocStateSubscription = clinicBloc.subscribeToState(this.__handleStateUpdate)
    }

    componentWillUnmount() {
        this.clinicBlocStateSubscription.unsubscribe();
    }

    __handleStateUpdate = (subject) => {

        const { encounterId, organisationId } = this.props.match.params;

        const { encounters } = subject;

        this.setState({
           loading: encounters.loading
        });

        if(!encounters.loading) {
            if(encounters.encounterIds.includes(encounterId)) {

                const encounter = encounters.encounters.filter((appointment) => appointment.id === encounterId);

                if (encounter.length === 1) {
                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_OPEN',
                        data: encounter[0],
                    });
                } else {

                    notificationService.error("Unable to load encounter.");
                }
            }
        }
    }

    render() {

        let { loading } = this.state;
        let { classes } = this.props;

        return (
            <div className={classes.root}>
                { loading ? <Loading /> :
                    <>Loaded
                    </>
                }
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<OpenEncounter context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));


export function actions(bloc) {

    return [
        {
            id: "/p",
            name: "Physical exam",
            shortcut: ["/", "p"],
            keywords: "physical exam",
            perform: () => { bloc.togglePhysicalExam(); },
        },
        {
            id: "/e",
            name: "Evaluation",
            shortcut: ["/", "e"],
            keywords: "evaluation",
            perform: () => { bloc.evaluation().toggleMenu(); },
        },
        {
            id: "/m",
            name: "Management",
            shortcut: ["/", "m"],
            keywords: "evaluation",
            perform: () => { bloc.management().toggleMenu(); },
        },
    ];
}


export class Actions {

    static portalStyle = {
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: "2000",
    };


    static searchStyle = {
        padding: "12px 16px",
        fontSize: "16px",
        width: "100%",
        boxSizing: "border-box",
        outline: "none",
        border: "none",
        color: "var(--foreground)",
    };

    static animatorStyle = {
        maxWidth: "600px",
        width: "100%",
        background: "var(--background)",
        color: "var(--foreground)",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "var(--shadow)",
    };
}

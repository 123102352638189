import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getFilteredSections,
} from "./CGIUtils";

const initialValue = {
  drawerItems: [],
  dxList: [],
  strickedDXList: [],
  userInfo: {},
  vitals: {},
  isOpen: false,
};

const CGICreateContext = createContext(initialValue);
export const useCGIContext = () => useContext(CGICreateContext);
export const CGIContextWrapper = ({
  children,
  content,
  bloc,
  handleDrawerContextMenu,
  isDrawerContextMenuToggled,
  selectedDrawerContextMenu,
  setSelectedDrawerContextMenu,
}) => {
  const {
    chartSummary: { sections },
    clinicalSummary,
    person,
    open,
    accountPreferences,
  } = content;
  const { workspaceGraph } = bloc.subject.value ?? {
    workspaceGraph: { initialised: false },
  };
  const currentComplaints = workspaceGraph?.initialised ? workspaceGraph.encounterReasonForVisit() : [];
  const drawerItems =
    getFilteredSections({
      sections,
      sectionCode: "vital-signs",
      exceptSectionCode: true,
    }) ?? [];
  const vitalsArray = getFilteredSections({
    sections,
    sectionCode: "vital-signs",
  });
  const vitals = vitalsArray?.length > 0 ? vitalsArray[0] : {};

  const [ambientFeatures, setAmbientFeatures] = useState([]);
  const [currentSelectedComplaint, setCurrentSelectedComplaint] = useState(() => {
      const _item = currentComplaints?.length > 0 ? currentComplaints[0] : {};
      bloc.setCurrentComplaint(_item);
      return _item;
  });
  const [isTouchable, setIsTouchable] = useState(false);

  useEffect(() => {
    setAmbientFeatures(bloc.getSalientFeatures());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.ontouchstart !== undefined) {
      setIsTouchable(true);
    }
  }, []);
  const accntPreferenceUnitMap = accountPreferences?.reduce((acc, el) => {
    acc[el.code] = el.value;
    return acc;
  }, {});

  return (
    <CGICreateContext.Provider
      value={{
        bloc: bloc,
        vitals,
        userInfo: person,
        drawerItems,
        isOpen: open,
        clinicalSummary,
        context: { ...content, bloc },
        ambientFeatures,
        currentComplaints,
        currentSelectedComplaint,
        setCurrentSelectedComplaint: (value) => {
          bloc.clearSelectedDX();
          setCurrentSelectedComplaint(value);
          bloc.setCurrentComplaint(value);
        },
        drawerContextMenu: isDrawerContextMenuToggled,
        setDrawerContextMenu: handleDrawerContextMenu,
        isTouchable,
        accntPreferenceUnitMap,
        selectedDrawerContextMenu,
        setSelectedDrawerContextMenu,
      }}
    >
      {children}
    </CGICreateContext.Provider>
  );
};

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Button, Checkbox, FormControlLabel, TextField, Typography} from "@material-ui/core";
import RpaContext from "../../context";
import {FormattedMessage} from "react-intl";
import {notificationService} from "../../../../../utils/notification";


const styles = theme => ({
    root: {
        height: "inherit",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },

    form: {
        width: "100%",
    },
    formInput: {
        width: "100%",
    },

    formWrapper: {
        padding: "10px 12px 24px 12px",
    },
    formBtn: { },
    cardHeader: {
        padding: "17px 21px 0px 12px",
        display: "flex",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        padding: "14px 29px 0px 19px",
        display: "flex",
        flexDirection: "column",
        height: "230px",
    },
    contentText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        /* or 171% */
        color: "#3D5369",
        textTransform: "capitalize",

        opacity: "0.8",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    button: {
        height: "32px",
        minHeight: "32px",
        width: "100%",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    dialogButton: {
        marginRight: '12px',
    },
    notificationItem: {
        marginTop: "12px",
    }
});

class InteractionAppointmentSchedulePanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busy: false,
            detail: props.detail,
            unlockContinue: false,
        };

        this.__unlock = this.__unlock.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    _submit = () => {

        const { busy } = this.state;

        if(busy) return;

        if(
            document.getElementById('rpaNbr').value.length > 0 &&
            document.getElementById('rpaMnr').value.length > 0
        ) {
            this.setState({ busy: true });
            this.props.bloc.submitResponse( { "NBR": document.getElementById('rpaNbr').value, "MRN": document.getElementById('rpaMnr').value }, this.__unlock);
        } else {

            notificationService.error("You need to enter a FIN and MRN to continue.");
        }
    };

    __unlock = () => {
        this.setState({ busy: false });
    }

    __unlockContinue = () => {
        const { unlockContinue } = this.state;
        this.setState({ unlockContinue: !unlockContinue });
    }

    render() {

        const { busy, unlockContinue } = this.state;
        const { classes } = this.props;

        return (
            <>
                <div className={`${classes.root}`}>
                    <Typography variant="h6" component="h2" className={classes.heading}>
                        <FormattedMessage id={'notification'} defaultMessage={"Notification"} />
                    </Typography>
                    <div className={classes.notificationWrapper}>
                        {this.__renderNotification()}
                    </div>
                    <div className={classes.fill}></div>
                    <div className={classes.formWrapper}>
                        <form className={classes.form} noValidate autoComplete="off">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={unlockContinue}
                                        onChange={this.__unlockContinue}
                                        name="unlockContinue"
                                        color="primary"
                                    />
                                }
                                label={this.__conditions()}
                            />
                            <TextField className={classes.formInput} required={true} id="rpaNbr" label="NBR number" />
                            <TextField className={classes.formInput} required={true} id="rpaMnr" label="MRN number" />
                        </form>
                    </div>
                    <Button disabled={busy || !unlockContinue} className={classes.formBtn} onClick={this._submit} variant={"contained"} color={"primary"}><FormattedMessage id={"submit"} defaultMessage={"Submit"} /></Button>
                </div>
            </>
        );
    }

    __renderNotification = () => {

        const { classes, detail } = this.props;

        if(!detail || !detail.id) return <></>;

        let mnr = undefined;
        let nbr = undefined;
        let type = "";
        let state = "";

        let items = [];

        if(detail.instructions !== "No instructions available. Please contact support if this is an error.") {

            items.push(<div className={classes.notificationItem}>
                { detail.instructions }
            </div>)
        } else {

            for (const [key, value] of Object.entries(detail.request)) {
                if (key.includes("response.payload") && (key.includes("MRN") || key.includes("MN")) && value !== "unknown") {
                    mnr = value;
                } else if (key.includes("response.payload") && (key.includes("NBR")) && value !== "unknown") {
                    nbr = value;
                } else if (key.includes("appointment.type")) {
                    type = value;
                } else if (key.includes("appointment.schedule.state")) {
                    state = value;
                }
            }

            if (mnr || nbr) {
                items.push(<div className={classes.notificationItem}>
                    Job already created an appointment with MRN {mnr || "unknown"} and NBR {nbr || "unknown"}. Please
                    check that the patient is in the correct waiting room.
                </div>)
            } else {
                items.push(<div className={classes.notificationItem}>
                    No appointment could be identified. Please try identify if an appointment has been created. If no
                    appointment is present please create one to capture the MRN and NBR numbers.
                </div>)
            }

            if (type === "virtual") {
                items.push(<div className={classes.notificationItem}>
                    Please ensure the patient is in the virtual waiting room and the link has been added to the
                    appointment.
                </div>)
            } else if (type.includes("person")) {
                if (state === 'RESERVED') {
                    items.push(<div className={classes.notificationItem}>
                        Please ensure the patient is in the expected waiting room.
                    </div>)
                }
            }
        }

        if(items.length > 0) {
            return <>{items}</>
        }

        return <div className={classes.notificationItem}>No notifications</div>
    }

    __conditions = () => {

        const { detail } = this.props;

        if(!detail || !detail.id) return <>Loading</>;

        let type = "";

        for (const [key, value] of Object.entries(detail.request)) {
            if(key.includes("appointment.type")) {
                type = value;
                break;
            }
        }

        if(type === "virtual") {
            return "Patient has been added correctly and the appointment link has been added to the EHR's appointment."
        }

        return "Patient has been added correctly."
    }
}

export default withStyles(styles)(props => (
    <RpaContext.Consumer>
        {value => {
            return (<InteractionAppointmentSchedulePanel context={value} {...props} />);
        }
        }
    </RpaContext.Consumer>
));

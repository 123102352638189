const LINK_ROUTE = '/link';
const LINK_ROUTE_SHORT = '/l';
const ENCOUNTER_LINK_ROUTE = '/link/encounters/:encounterId';
const ENCOUNTER_LINK_SHORT_ROUTE = '/l/ec/:encounterId';
const RPA_LINK_ROUTE = '/link/rpa';
const RPA_LINK_SHORT_ROUTE = '/l/rpa';


export {
    LINK_ROUTE,
    LINK_ROUTE_SHORT,
    ENCOUNTER_LINK_ROUTE,
    ENCOUNTER_LINK_SHORT_ROUTE,
    RPA_LINK_ROUTE,
    RPA_LINK_SHORT_ROUTE,
};

import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Close } from "@material-ui/icons";
import { useCGIContext } from "../CGIContext/CGIContext";

const styles = {
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    minWidth: 540,
    maxHeight: 636,
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 40,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    borderRadius: 13,
    zIndex: 1,
  },
  headerSection: {
    marginBottom: "1em",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
  },
  header: {
    color: "#000",
    fontSize: 20,
  },
  closeIcon: {
    cursor: "pointer",
  },
  subHeader: {
    color: "#323232",
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 8,
    marginTop: 30,
  },
  content: {
    marginTop: 30,
    color: "#636366",
    fontSize: 14,
  },
};
function ClinicalSummaryModal({ classes, open, toggleOpen }) {

  const context = useCGIContext();
  const sections = context?.clinicalSummary?.document?.sections;
  const handleClose = () => toggleOpen();

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div>
          <Paper className={classes.paper} elevation={1}>
            <div className={classes.headerSection}>
              <Typography className={classes.header}>
                Clinical Summary
              </Typography>
              <Close onClick={handleClose} className={classes.closeIcon} />
            </div>
            <div>
              {sections?.map((sec, index) => (
                <React.Fragment key={`${JSON.stringify(sec)}-${index}`}>
                  <Typography className={classes.content}>
                    {sec.content
                      .replace(/[\|]{3}[a-z]{2,10}:/g, "")
                      .replace(/:[a-z]{2,10}[\|]{3}/g, "")
                      .replace(/[#]{2}FIXME/g, "")
                      .replace(/\n*$/, "")
                      .split("\n")
                      .map((value, index) => (
                        <React.Fragment
                          key={`${JSON.stringify(value)}-${index}`}
                        >
                          {value}
                          <br />
                        </React.Fragment>
                      ))}
                  </Typography>
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
          </Paper>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ClinicalSummaryModal);

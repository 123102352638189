import {Subject} from 'rxjs';

export class ClinicEventService {

    subject = new Subject();

    registerStateCallback(callback) {
        return this.subject.subscribe(callback);
    }

    update(event) {
        this.subject.next(event);
    }
}

export const clinicEventService = new ClinicEventService();

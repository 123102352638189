import axios from 'axios';

class ScheduleApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    scheduleSummary(organisationId, serviceId, providers) {

        let request = {
            identifier: {
                system: "decoded/organisation/service",
                code: "serviceId",
                value: serviceId,
            }
        };

        if(providers && providers.length > 0) {
            request.participants = [];
            providers.forEach(_item => request.participants.push({ role: "PROVIDER", identifier: { system: "decoded", code: "id", value: _item } }))
        }

        return axios.post(`${this.baseUrl}/api/organisations/${ organisationId ? organisationId : "_" }/schedules/_summary`, request);
    }

    scheduleSearch(organisationId, serviceId, date, providers) {

        let request = {
            identifier: {
                system: "decoded/organisation/service",
                code: "serviceId",
                value: serviceId,
            },
            at: date
        };

        if(providers) {
            request.participants = [];
            providers.forEach(_item => request.participants.push({ role: "PROVIDER", identifier: { system: "decoded", code: "id", value: _item } }))
        }

        return axios.post(`${this.baseUrl}/api/organisations/${ organisationId ? organisationId : "_" }/schedules/_search`, request);
    }
}

export const scheduleApi = new ScheduleApi();

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';

import RpaContext from "../context";

import JobCard from "./components/JobCard";
import {navigationTitleService} from "../../../../utils/title";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "calc(100vh - 68px)",
        height: "calc(100vh - 68px)",
        backgroundColor: "#fff",
        overflow: "auto",
    },
    fill: {
        flex: "1 1 auto",
    },

    title: {
        marginTop: "50px",
        marginLeft: "70px",
    },

    cardWrapper: {
        display: "flex",
        padding: "14px 35px 50px 50px",
        flexWrap: "wrap",
    },
    card: {
        margin: "16px",
        padding: "17px 21px 20px 12px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },

    cardEncounterPlaceholder: {
        height: "100%",
        display: "flex",
        '& span': {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#98A3AE",
            margin: "auto",
        }
    },

    cardHeader: {
        display: "flex",
        paddingBottom: "20px",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        display: "flex",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "3px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        flex: "1",
    },
    cardFooter: {
        paddingTop: "13px",
        display: "flex",
    },
    footerIndicator: {
        paddingTop: "4px",
        paddingRight: "7px",
        color: "#ff516A",
        height: "20px",
        width: "20px",
    },
    footerText: {
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    footerTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    complaint: {
        minHeight: "115px",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
    },
    datePicker: {
        maxWidth: "14em",
        padding: "1em 2em",
    },
    filterGroup: {
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "2em",
        maxWidth: "100%",
        width: "100%",
    },
    filterButton: {
        paddingRight: "2em"
    },
    filterResult: {
        paddingTop: "1em",
        paddingLeft: "2em",
        color: "#6b6a6a",
    },
    loadButton: {
        paddingTop: "1.5em",
    }
});


class ManualJobs extends Component {

    stateSubscripton;

    constructor(props) {
        super(props);

        this.state = {
            busy: true,
            jobs: [],
        };

        this.__rpaStateUpdate = this.__rpaStateUpdate.bind(this);
    }

    componentDidMount() {

        navigationTitleService.update("RPA Manual Steps");

        const { bloc } = this.props.context;

        this.stateSubscripton = bloc.subscribeToState(this.__rpaStateUpdate);
    }

    componentWillUnmount() {

    }

    __rpaStateUpdate = (data) => {
        this.setState({
            jobs: data.jobs,
        });
    }


    render() {

        const { classes } = this.props;
        const { bloc } = this.props.context;
        const { jobs } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.cardWrapper}>

                    { jobs && jobs.map((job) =>
                        <>
                            <JobCard key={`rpa-job-${job.id}`} rpaBloc={ bloc } item={ job } />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <RpaContext.Consumer>
        {value => {
            return (<ManualJobs context={value} {...props} />);
        }
        }
    </RpaContext.Consumer>
));

import * as rxjs from "rxjs";
import React from "react";
import {cgiApi} from "../../../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../../../utils/notification";
import { jsx } from 'slate-hyperscript'

import escapeHtml from 'escape-html'
import { Text } from 'slate'

const initial = {
    initialised: false,
};

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

export class Bloc {

    autosaveTimer

    constructor(parent, props) {
        this.subject = new rxjs.BehaviorSubject({
            ...initial,
            ...props,
        });

        this.events = new rxjs.Subject();
        this.parentBloc = parent;
    }

    __updateSubject = (value) =>
        this.subject.next({
            ...this.subject.value,
            ...value,
        });

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    initialise = () => {
        this.__generateNote();
    };

    close = () => {
        if(this.autosaveTimer) {
            clearInterval(this.autosaveTimer);
        }
    }

    __generateNote = (force) => {

        const {clinicId, encounter, workspaceGraph} = this.parentBloc.subject.value;

        this.__updateSubject({initialised: false, note: undefined});

        cgiApi.clinicalNote(clinicId, encounter.id, force)
            .then((value) => {

                this.__updateSubject({initialised: true, note: value.data});

                this.autosaveTimer = setInterval(() => { this.__autosave() }, 15000)

            }, reason => notificationService.httpError(reason));
    }


    getInnerText = (el) => {
        let sel, range, innerText = "";
        if (typeof document.selection != "undefined" && typeof document.body.createTextRange != "undefined") {
            range = document.body.createTextRange();
            range.moveToElementText(el);
            innerText = range.text;
        } else if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
            sel = window.getSelection();
            sel.selectAllChildren(el);
            innerText = "" + sel;
            sel.removeAllRanges();
        }
        return innerText;
    }

    serialiseSectionToHtmlFromSlate = (index) => {
        let html = document.getElementById(`section-${index}`).innerHTML
        let _document = new DOMParser().parseFromString(html, 'text/html')
        let textbox = _document.evaluate("//div[@role='textbox']", _document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
        let all = textbox.querySelectorAll("*");

        for( let i = 0; i < all.length; i = i + 1) {
            const keys = Object.keys(all[i].attributes)
            let remove = []
            for( let iK = 0; iK < all[i].length; iK = iK + 1) {
                console.log(all[i].attributes[keys[iK]].localName)
                let attributeName = all[i].attributes[keys[iK]].localName
                if(attributeName.includes("data-slate")) {
                    remove.push(attributeName)
                }
            }
            remove.forEach((toRemove) => { all[i].removeAttribute(toRemove) });
        }
        return textbox.innerHTML;
    }

    deserializeHtmlString = (string) => {
        if(!string.startsWith("<p")) {
            string = `<p>${string}</p>`;
        }
        const document = new DOMParser().parseFromString(string, 'text/html')
        return this.deserializeHtml(document.body);
    }

    deserializeHtml = (el) => {
        if (el.nodeType === 3) {
            return el.textContent
        } else if (el.nodeType !== 1) {
            return null
        } else if (el.nodeName === 'BR') {
            return '\n'
        }

        const { nodeName } = el
        let parent = el

        if (
            nodeName === 'PRE' &&
            el.childNodes[0] &&
            el.childNodes[0].nodeName === 'CODE'
        ) {
            parent = el.childNodes[0]
        }
        let children = Array.from(parent.childNodes)
            .map(this.deserializeHtml)
            .flat()

        if (children.length === 0) {
            children = [{ text: '' }]
        }

        if (el.nodeName === 'BODY') {
            return jsx('fragment', {}, children)
        }

        if (ELEMENT_TAGS[nodeName]) {
            const attrs = ELEMENT_TAGS[nodeName](el)
            return jsx('element', attrs, children)
        }

        if (TEXT_TAGS[nodeName]) {
            const attrs = TEXT_TAGS[nodeName](el)
            return children.map(child => jsx('text', attrs, child))
        }

        return children
    }

    copyNoteToClipboard = () => {

        const {note,} = this.subject.value;

        let text = []
        let plain = []

        if(note.document?.sections) {
            note.document.sections.forEach((section, index) => {

                const title = `<h1>${section.code.display}</h1>`;
                const content = section.content;

                text.push(title);
                text.push(content);

                plain.push(this.getInnerText(document.getElementById(`section-${index}`)));
            });
        }

        const content = `${text.join("")}`;
        const plainContent = `${plain.join("\n")}`;

        if ('clipboard' in navigator) {

            const type = "text/html";
            const blob = new Blob([content], { type });
            const blobText = new Blob([plainContent], { type: 'text/plain' });
            const clipboardItem = new window.ClipboardItem({ [type]: blob, 'text/plain': blobText });
            // const clipboardItem = new window.ClipboardItem({ [type]: blob, });
            const data = [clipboardItem];

            navigator.clipboard.write(data)
                .then(() => notificationService.success("Copied!"),
                    reason => notificationService.httpError(reason));
        } else {
            if(document.execCommand('copy', true, content)) {
                notificationService.success("Copied!")
            } else {
                notificationService.error("Unable to copy!")
            }
        }
    }

    regenerate = () => {
        this.__generateNote(true);
    }

    __autosave = () => {
        const { note, } = this.subject.value;
        if(note.__dirty) {
            this.save(true);
        }
    }

    __cleanSlate = htmlString => {
        let slateJunk = this.deserializeHtmlString(htmlString)
        if(slateJunk.length === 0) {
            return "<p></p>";
        }
        return slateJunk.map(_section => this.__cleanSlateJunk(_section)).join("");
    }
    __cleanSlateJunk = node => {
        if (Text.isText(node)) {
            return node.text.replaceAll("\r\n", "<br />").replaceAll("\n", "<br />").replaceAll("\r", "<br />");
        }

        const children = node.children.map(n => this.__cleanSlateJunk(n)).join('')

        switch (node.type) {
            case 'paragraph':
                return `<p>${children}</p>`
            default:
                return children
        }
    }

    save = (hideSuccess) => {

        const { note, } = this.subject.value;
        const { clinicId, encounter, } = this.parentBloc.subject.value;

        if(note.document?.sections) {
            note.document.sections.forEach((section, index) => {
                section.content = this.__cleanSlate(this.serialiseSectionToHtmlFromSlate(index));
            });
        }

        cgiApi.clinicalNoteSave(clinicId, encounter.id, note).then(value => {
            note.__dirty = false;
            if(hideSuccess) {
                return;
            }
            this.__updateSubject({ note: value.data, });
            notificationService.success("Saved.");
        }, reason => notificationService.httpError(reason))

    }
}



function encloses(entity1, entity2) {

    return entity1.start <= entity2.start &&
        (entity1.end) >= (entity2.end);
}


function compareEntities(a, b) {

    if (b.start < a.start) {

        return 1;
    }

    if (b.start > a.start) {

        return -1;
    }

    if (b.end < a.end) {

        return -1;
    }

    if (b.end > a.end) {

        return 1;
    }

    return 0;
}



export function resolvePlaceholders(item) {

    const { content, metadata } = item;
    const entities = metadata.entities || [];

    let result = [];

    if (entities.length > 0) {

        entities.sort(compareEntities);

        let entityCount = 0;
        let count = 0;
        let chunk = "";

        if (entities.length > 0) {

            while (entityCount < entities.length) {


                let currentEntity = entities[entityCount];
                let highlights = [currentEntity];

                //Add all text up until the entity.
                if (count < currentEntity.start) {

                    result.push(content.slice(count, currentEntity.start));
                }

                entityCount += 1;
                let ids = [];
                ids.push(currentEntity.id);

                while (entities[entityCount] && encloses(currentEntity, entities[entityCount])) {
                    ids.push(entities[entityCount].id);
                    entityCount += 1;
                }

                result.push(`{{${content.slice(currentEntity.start, currentEntity.end)}:${ids.join(",")}}}`);

                count = currentEntity.end;
            }

            if (count < content.length) {

                result.push(content.slice(count));
            }

        } else {

            chunk = content;
        }

        if (chunk.length > 0) {
            result.push(chunk);
        }
    } else {

        result.push(content)
    }

    return result;
}

import axios from 'axios';

class EventApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    publish(event) {

        return axios.post(`${this.baseUrl}/next/users/me/_events`, event)
            .then(function (res) { return res; })
            .catch(function (err) { return err; });
    }
}

export const eventApi = new EventApi();

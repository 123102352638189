import * as rxjs from "rxjs";
import React from "react";

import { v4 as uuidv4 } from "uuid";
import {
  physicalExamStatus,
  physicalExamStatusCode,
  physicalExamValueOrigin,
  physicalExamValueStatus,
} from "./physicalExamUtils";
import { CloudEvent } from "cloudevents";
import {cgiApi} from "../../../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../../../utils/notification";
import { BlocEvent as CgiBlocEvent } from '../../../bloc'

const initial = {
  initialised: false,
  updates: 0,
  conditions: [],
  ddxContext: [],
  ddxLinks: {},
  valueLinks: {},
};

export class Bloc {
  constructor(parent) {
    this.parent = parent;

    const clone = JSON.parse(JSON.stringify(initial));

    this.subject = new rxjs.BehaviorSubject(clone);

    this.events = new rxjs.Subject();
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {

    const { clinicId, encounter, } = this.parent.subject.value;

    const observations = this.__cloneObservations();

    this.cgiStateSubscription = this.parent.subscribeToState(this.__handleCgiState);
    this.cgiEventSubscription = this.parent.subscribeToEvents(this.__handleCgiEvent);

    Promise.all([
      cgiApi.loadPhysicalExamTemplates(clinicId, encounter.id),
      cgiApi.recommendPhysicalExamTemplates(clinicId, encounter.id),
    ]).then(values => {

      const templates = values[0].data.items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      const recommendation = values[1].data.items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      this.__updateSubject({
        templates: templates,
        templateRecommendation: recommendation,
        observations: observations,
      });

      let _current = this.parent.physicalExam();
      if (_current) {
        this.__setPhysicalExam(_current);
      } else if(templates.length === 1) {
        this.setTemplate(templates[0]);
      }  else if(recommendation.length === 1) {
        this.setTemplate(recommendation[0]);
      } else {
        this.__updateSubject({
          initialised: true,
        });
      }
    });

  };

  close = () => {
    if(this.cgiStateSubscription) {
      this.cgiStateSubscription.unsubscribe();
    }
    if(this.cgiEventSubscription) {
      this.cgiEventSubscription.unsubscribe();
    }
  }

  __cloneObservations = () => {
    return this.parent.observations().getAllObservations()
        .map(observation => JSON.parse(JSON.stringify(observation)))
        .map(observation => {
          observation.__transaction = { dirty: 0, links: this.parent.observations().conditions(observation).map(condition => condition.bid) }
          return observation;
        });
  }

  __refreshObservations = () => {

    let { observations } = this.subject.value;
    const filtered = observations.map(observation => observation.bid)

    this.parent.observations().getAllObservations()
        .filter(observation => !filtered.includes(observation.bid))
        .map(observation => JSON.parse(JSON.stringify(observation)))
        .forEach(observation => {
          observation.__transaction = { dirty: 0, links: this.parent.observations().conditions(observation).map(condition => condition.bid) }
          observations.push(observation);
        });

    this.__updateSubject({
      observations: observations,
    });
  }

  __handleCgiState = (state) => {

    const conditions = this.parent.diagnosis().allConditions();
    if(conditions.length !== this.subject.value.conditions.length) {
      this.__updateSubject({ conditions: conditions });
    }
  }

  __handleCgiEvent = (event) => {

    const { type, data } = event;
    switch (type) {
      case CgiBlocEvent.CONDITION_ADDED: {
        this.__refreshObservations();
      }
      break;
    }

  }

  setDDxContext = (item) => {
    const { ddxContext, ddxLinks, } = this.subject.value;

    let newDdxContext = ddxContext;

    if(ddxContext.includes(item.bid)) {
      newDdxContext = ddxContext.filter(_item => _item !== item.bid);
    } else {
      if(!Object.keys(ddxLinks).includes(item.bid)) {
        ddxLinks[item.bid] = [];
      }
      newDdxContext.push(item.bid);
    }

    this.__updateSubject({ddxContext: newDdxContext, ddxLinks: ddxLinks});
  }


  clearDDxContext = () => {
    this.__updateSubject({ddxContext: []});
  }

  setTemplate = (template) => {
    this.parent.addPhysicalExam(template, this.__setPhysicalExam, this.__loadError);
  }

  __setPhysicalExam = (_current) => {
    let clone = JSON.parse(JSON.stringify(_current));
    const { clinicId, encounter, } = this.parent.subject.value;
    const { ddxLinks, valueLinks, } = this.subject.value;

    const conditions = this.parent.diagnosis().allConditions();
    conditions.forEach(condition => {
      ddxLinks[condition.bid] = [];
    });

    clone.sections.forEach(_section => {
      _section.values.forEach(value => {
        valueLinks[value.original.display] = []

        if (value.applicableTo) {
          value.applicableTo.forEach((condition) => {
            valueLinks[value.original.display].push(condition.code);
            if (Object.keys(ddxLinks).includes(condition.code)) {
              ddxLinks[condition.code].push(value.original.display)
            }
          });
        }
      })
    });

    cgiApi.getPhysicalExamTemplate(clinicId, encounter.id, clone.source.code.code)
        .then(_template_result => {
          const template = _template_result.data;
          this.__updateSubject({
            initialised: true,
            physicalExam: clone,
            template: template,
          });
        }, reason => notificationService.httpError(`Error loading templates: ${reason}`));

  }

  __loadError = (_current) => {
    this.__updateSubject({ initialised: true, error: true });
  }

  updateSectionValue = (section, value) => {
    const { updates } = this.subject.value;
    this.__updateSubject({ updates: 1 + updates });
  };

  updateObservationNote = (observation, note) => {
    const { updates } = this.subject.value;
    observation.__transaction.dirty=1;
    observation.note = note || "";
    this.__updateSubject({ updates: 1 + updates });
  };

  addValue = (targetSection, value, links) => {
    let { physicalExam, ddxLinks, valueLinks, } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {
        section.values.push({
          origin: physicalExamValueOrigin.user,
          status: physicalExamValueStatus.modified,
          value: { display: value },
          original: { display: value },
        });
        valueLinks[value] = [...links]
        links.forEach(_link => ddxLinks[_link] = [value])
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam, valueLinks: valueLinks, ddxLinks: ddxLinks });
  };

  toggleFinding = (targetSection, value) => {
    let { physicalExam, ddxContext, ddxLinks, valueLinks } = this.subject.value;
    let display = value.original?.display || value.description;
    let target = valueLinks[display];

    let missing = ddxContext.filter(bid => !target.includes(bid));
    if(missing.length > 0) {
      target = [...target, ...missing];
      missing.forEach(_item => ddxLinks[_item].push(display));
    } else {
      target = target.filter(bid => !ddxContext.includes(bid));
      ddxContext.forEach(bid => ddxLinks[bid] = ddxLinks[bid].filter(_display => _display !== display));
    }

    valueLinks[display] = target;

    value.semanticType = target.length === 0 ? undefined : "T033";
    this.__updateSubject({ physicalExam, ddxLinks, valueLinks, });
  };

  toggleObservation = (observation) => {
    let { ddxContext, observations } = this.subject.value;

    let missing = ddxContext.filter(bid => !observation.__transaction.links.includes(bid));
    if(missing.length > 0) {
      observation.__transaction.links = [...observation.__transaction.links, ...missing];
    } else {
      observation.__transaction.links = observation.__transaction.links.filter(bid => !ddxContext.includes(bid));
    }
    this.__updateSubject({ observations, });
  };

  removeValue = (targetSection, value) => {
    let { physicalExam } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {
        section.values = section.values.filter(_value => _value.original.display !== value.original.display)
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam });
  };

  enableSection = (targetSection) => {
    let { physicalExam } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {
        section.status = physicalExamStatusCode.brief;
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam });
  };

  discardSection = (targetSection) => {
    let { physicalExam } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {
        section.status = physicalExamStatusCode.discarded;
        section.values = [];
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam });
  };

  toggleAbnormalNormal = (targetSection) => {
    let { physicalExam, template } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {

        if(section.status !== physicalExamStatusCode.abnormal) {

          section.status = physicalExamStatusCode.abnormal;
          section.values = section.values.filter((_value) => (_value.origin === 1 || _value.status !== 0));

          for (let tIndex = 0; tIndex < template.sections.length; tIndex++) {
            let tSection = template.sections[tIndex];
            if (tSection.code === section.code) {
              const tSectionValues = tSection["values"]
              for(let tSectionIndex = 0; tSectionIndex < tSectionValues.length; tSectionIndex++) {
                const tSectionValue = tSectionValues[tSectionIndex];
                if (physicalExamStatus.abnormal === tSectionValue["type"] && tSectionValue["values"].length > 0) {
                  let newValues = [];

                  tSectionValue["values"]
                      .filter(_value => {
                        return section.values.filter(_inner => _inner.original.display === _value.display).length === 0 ;
                      })
                      .forEach(_value => {
                        newValues.push({
                          "origin": 0,
                          "status": 0,
                          "value": {..._value},
                          "original": {..._value}
                        });
                      });

                  if(newValues.length > 0) {
                    section.values = [...newValues, ...section.values]
                  }
                }
              }
            }
          }

        } else {

          let chosenSection = {
            "type": physicalExamStatusCode.brief,
            "values": []
          };

          section.values = section.values.filter((_value) => (_value.origin === 1 || _value.status !== 0));

          for (let tIndex = 0; tIndex < template.sections.length; tIndex++) {
            let tSection = template.sections[tIndex];
            if(tSection.code === section.code) {
              const tSectionValues = tSection["values"]
              for(let tSectionIndex = 0; tSectionIndex < tSectionValues.length; tSectionIndex++) {
                const tSectionValue = tSectionValues[tSectionIndex];
                if ([physicalExamStatus.brief, physicalExamStatus.detailed].includes(tSectionValue["type"]) && tSectionValue["values"].length > 0) {

                  let newValues = [];

                  tSectionValue["values"]
                      .filter(_value => {
                        return section.values.filter(_inner => _inner.original.display === _value.display).length === 0 ;
                      })
                      .forEach(_value => {
                          newValues.push({
                            "origin": 0,
                            "status": 0,
                            "value": {..._value},
                            "original": {..._value}
                          });
                      });

                  chosenSection = {
                    "type": physicalExamStatus.brief === tSectionValue["type"] ? physicalExamStatusCode.brief : physicalExamStatusCode.detailed,
                    "values": newValues
                  };
                  break;
                }
              }
            }
          }

          section.status = chosenSection["type"]
          section.values = [...chosenSection["values"], ...section.values]
        }
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam });
  };

  toggleNormalBriefNormal = (targetSection) => {
    let { physicalExam, template } = this.subject.value;
    for (let index = 0; index < physicalExam.sections.length; index++) {
      let section = physicalExam.sections[index];
      if (section.code === targetSection.code) {

        let templateCode = undefined;
        let newValues = [];

        if(section.status !== physicalExamStatusCode.brief) {
          section.status = physicalExamStatusCode.brief;
          templateCode = physicalExamStatus.brief;
        } else {
          section.status = physicalExamStatusCode.detailed;
          templateCode = physicalExamStatus.detailed;
        }

        section.values = section.values.filter((_value) => (_value.origin === 1 || _value.status !== 0));

        for (let tIndex = 0; tIndex < template.sections.length && newValues.length === 0; tIndex++) {
          let tSection = template.sections[tIndex];
          if(tSection?.code === section?.code) {
            const tSectionValues = tSection["values"]
            for(let tSectionIndex = 0; tSectionIndex < tSectionValues.length; tSectionIndex++) {
              const tSectionValue = tSectionValues[tSectionIndex];
              if ([templateCode].includes(tSectionValue["type"]) && tSectionValue["values"].length > 0) {

                tSectionValue["values"]
                    .filter(_value => {
                      return section.values.filter(_inner => _inner.original.display === _value.display).length === 0 ;
                    })
                    .forEach(_value => {
                      newValues.push({
                        "origin": 0,
                        "status": 0,
                        "value": {..._value},
                        "original": {..._value}
                      });
                    });
                break;
              }
            }
          }
        }
        section.values = [...newValues, ...section.values]
        break;
      }
    }
    this.__updateSubject({ physicalExam: physicalExam });
  };

  processDictation = (targetSection, dication) => {
    if (dication.data.message?.length > 0) {
      let items = dication.data.message
        .split("[ space ]")
        .filter((_item) => _item.trim().length > 0)
        .map((_item) => {
          return {
            id: uuidv4(),
            origin: physicalExamValueOrigin.user,
            status: physicalExamValueStatus.modified,
            value: _item,
          };
        });

      if (items.length === 0) {
        return;
      }

      let { physicalExam } = this.subject.value;

      for (let index = 0; index < physicalExam.sections.length; index++) {
        let section = physicalExam.sections[index];
        if (section.code === targetSection.code) {
          items.forEach((item) => section.values.push(item));
          break;
        }
      }

      this.__updateSubject({ physicalExam: physicalExam });
    }
  };


  saveAndClose = async () => {
    await this.save();
    this.close();
  }

  save = async () => {
    let { physicalExam, valueLinks, observations, } = this.subject.value;
    let {clinicId, encounter,} = this.parent.subject.value;

    physicalExam.sections.forEach(section => {
      section.values.forEach(value => {
        if(Object.keys(valueLinks).includes(value.original.display)) {
          value.applicableTo = valueLinks[value.original.display].map(_item => { return { code: _item} });
        }
      });
    });

    const updatedObservations = observations.filter((observation) => observation.__transaction.dirty !== 0)
        .map((observation) => {
          return {
            bid: observation.bid,
            status: observation.status,
            polarity: observation.polarity,
            note: observation.note,
          }
        });

    const payload = {
      command: "update_physical_exam",
      payload: {
        bid: physicalExam.bid,
        sections: physicalExam.sections,
        observations: updatedObservations,
      },
    };

    await this.parent.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "physical_exam");
  };
}

export class Event {
  static INITIALISED = "initialised";
}


export function fromString(string) {
  return fromJson(JSON.parse(string));
}

export function fromJson(json) {
  return new CloudEvent(json);
}

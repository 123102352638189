import {Box, Button, ButtonBase, InputBase, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useMemo, useState} from "react";
import { Bloc } from "../../../bloc";
import { SearchForContextMenu } from ".";
import { createFirstItemOfSearchResult } from "../Drawer/Drawer.utils";
import { isEmpty } from "loadsh";
import  MultiOptionalContextMenu  from "./MultiOptionalContextMenu";
import {withStyles} from "@material-ui/styles";
import debounce from 'lodash/debounce';
import {notificationService} from "../../../../../../../utils/notification";

function WizardContextMenuWrapper({
  options,
  selectedContextMenuOption,
  setSelectedContextMenuOption,
  onSave,
  classes
}) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [pressedItem, setPressedItem] = useState(-1);
  const bloc = new Bloc();

  const queryChangeHandler = (event) => {
    setQuery(event.target.value);
  };

  const isSearch = (target) => {
    const obj = target.component
    return !obj || obj === 'search';
  }

  const isSelection = (target) => {
    const obj = target.component
    return obj && obj === 'select';
  }


  const handleUserKeyPress = (event) => {
      const { key, keyCode } = event;

      let length = results.length;
      let currentIndex = selectedIndex;
      if(selectedContextMenuOption?.options?.length > 0) {
        length = selectedContextMenuOption.options.length;
        if(currentIndex === -1) {
          currentIndex = selectedContextMenuOption.options.length - 1;
        }
      }

    if(currentIndex > -1 && length > 0) {

      let shouldScroll = false;

      if(keyCode === 38) {
        //up
        if(currentIndex !== 0) {
          setSelectedIndex(currentIndex - 1);
          shouldScroll = document.getElementById(`result-${(currentIndex - 1)}`);
        } else if(currentIndex === 0) {

          setSelectedIndex(length - 1);
          shouldScroll = document.getElementById(`result-${(length - 1)}`);
        }
      } else if(keyCode === 40) {
        //down
        if(currentIndex < (length - 1)) {
          setSelectedIndex(currentIndex + 1);
          shouldScroll = document.getElementById(`result-${(currentIndex + 1)}`);
        } else if(currentIndex + 1 >= length) {
          setSelectedIndex(0);
          shouldScroll = document.getElementById(`result-0`);
        }
      } else if(keyCode === 13) {
        if (selectedContextMenuOption?.onItemPress) {
          selectedContextMenuOption.onItemPress(results[currentIndex]);
        }
      }

      if(shouldScroll) {
        shouldScroll.scrollIntoView();
      }
    }

    if(keyCode === 38 || keyCode === 40 || keyCode === 13) {
      event.preventDefault();
    }
  };

  const fetch = React.useMemo(
      () => {
        return debounce((request, callback) => {

          bloc.searchOntology(request.query, request.selectedContextMenuOption?.ontologyConstants,request.selectedContextMenuOption?.vocabularies,request.selectedContextMenuOption?.termTypes)
              .then(value => {

                let _results = request.selectedContextMenuOption?.disableFreeText ? value.data.results : createFirstItemOfSearchResult(
                    request.query,
                    value
                );

                callback(_results);

              }, reason => notificationService.httpError(reason));
        }, 300, { 'maxWait': 1000 });
      },
      [],
  );

  useEffect(() => {
    (async () => {

      if(query.length <= 2) {
        setResults([]);
        setSelectedIndex(-1)
        return;
      }

      fetch( { query, selectedContextMenuOption }, _results => {
        setSelectedIndex(_results.length > 0 ? 0 : -1)
        setResults(_results.splice(0, 15));
      });

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setQuery("");
  }, [selectedContextMenuOption]);


  React.useEffect(() => {
    return () => {
      fetch.cancel();
    };
  }, [fetch]);

  return (
    <div style={{ width: 320, marginLeft: 8, maxHeight: "300px", minHeight: "300px", overflow:"hidden" }} onKeyDown={(e) => {
      handleUserKeyPress(e)
    }} >
      <MultiOptionalContextMenu
        options={options}
        selectedOption={selectedContextMenuOption}
        onClick={(value) => {
          setPressedItem(-1);
          if(selectedContextMenuOption.label !== value.label) {
            setSelectedIndex(value.options ? 0 : -1);
          }
          setSelectedContextMenuOption(value);
        }}
      />
      { pressedItem !== -1 && (
          <div
              style={{
                marginTop: 12,
                paddingTop: 12,
                backgroundColor: "#fff",
                borderRadius: 32,
              }}
          >
            <input id={'ctxMenuHiddenEl'} type={"hidden"} autoFocus={true}/>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "center",
                  maxHeight: "240px",
                  minHeight: "240px",
                  overflowY: "auto",
                }}
            >

              <Box className={classes.description}>
                <Typography>{pressedItem.display}</Typography>
              </Box>
              <Box style={{ width: "90%", }}>
                <InputBase
                    id={`note-new-disposition`}
                    className={classes.textfield}
                    multiline
                    rows={6}
                    defaultValue=""
                    variant="standard"
                    placeholder={"Add a note..."}
                    autoFocus={true}
                />
              </Box>
              <Button className={classes.button} color={"primary"} variant={'contained'} onClick={() => {
                const el = document.getElementById(`note-new-disposition`);
                let note = el.value;
                el.value = "";
                onSave(selectedContextMenuOption.label, pressedItem, {note: note});
              }}>Save</Button>
            </div>
          </div>
      ) }

      {pressedItem === -1 && !isEmpty(selectedContextMenuOption) && isSelection(selectedContextMenuOption) && (
          <div
              style={{
                marginTop: 12,
                paddingTop: 12,
                backgroundColor: "#fff",
                borderRadius: 32,
              }}
          >
            <input id={'ctxMenuHiddenEl'} type={"hidden"} autoFocus={true}/>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "center",
                  maxHeight: "240px",
                  overflowY: "auto",
                }}
            >
              {selectedContextMenuOption.options.map((res, index) => {
                return (
                    <ButtonBase
                        style={{
                          backgroundColor: selectedIndex === index ? "rgb(0, 209, 255)" : "#F9F9F9",
                          borderRadius: 32,
                          width: 290,
                          marginBottom: 16,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          paddingLeft: 21,
                          alignItems: "start",
                        }}
                        onClick={() => {
                          setPressedItem(res);
                        }}
                        id={`result-${index}`}
                        key={`${res.code}`}
                    >
                      <Typography
                          style={{
                            marginVertical: 7,
                            marginHorizontal: 21,
                            maxWidth: 248,
                            lineBreak: "anywhere",
                            textAlign: "left",
                            padding: "7px 0",
                          }}
                          classes={{body1:classes.body1}}
                      >
                        {res.display}
                      </Typography>
                    </ButtonBase>
                );
              })}
            </div>
          </div>
      )}
      {pressedItem === -1 && !isEmpty(selectedContextMenuOption) && isSearch(selectedContextMenuOption) && (
        <div
          style={{
            marginTop: 12,
            backgroundColor: "#fff",
            borderRadius: 32,
          }}
        >
          <div style={{ marginRight: 16, marginLeft: 16 }}>
            <SearchForContextMenu
              value={query}
              onChange={queryChangeHandler}
              placeHolder={selectedContextMenuOption.searchPlaceHolder}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              maxHeight: "240px",
              overflowY: "auto",
            }}
          >
            {results?.map((res, index) => {
              return (
                <ButtonBase
                  style={{
                    backgroundColor: selectedIndex === index ? "rgb(0, 209, 255)" : "#F9F9F9",
                    borderRadius: 32,
                    width: 290,
                    marginBottom: 16,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: 21,
                    alignItems: "start",
                  }}
                  onClick={() => {
                    setPressedItem(res);
                  }}
                  id={`result-${index}`}
                  key={`${res.conceptId}`}
                >
                  <Typography
                    style={{
                      marginVertical: 7,
                      marginHorizontal: 21,
                      maxWidth: 248,
                      lineBreak: "anywhere",
                      textAlign: "left",
                      padding: "7px 0",
                    }}
                    classes={{body1:classes.body1}}
                  >
                    {res?.display || res?.canonicalName}
                  </Typography>
                </ButtonBase>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
const styles = theme => ({
  body1:{
    fontFamily: "inter !important",
    fontSize: 15,
  },
  description: {
    padding: "4px 0 4px 0",
    color: "#858585",
  },
  textfield: {
    marginTop: "18px",
    marginBottom: "18px",
    borderColor: theme?.palette?.CGIInputBorderColor?.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 7,
    flex: 1,
    width: "100%",
    paddingLeft: 12,
    paddingRight: 12,
    fontFamily: "inter !important",
    fontSize: 15,
    color: theme?.palette?.CGITextPrimary?.main,
  },
  button: {
    width: "90%",
  },
});
export default withStyles(styles)(WizardContextMenuWrapper);

import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
    root: {
        height: theme.spacing(4.5),
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "2em",
        borderColor: "#00C3FF",
        borderStyle: "solid",
        borderWidth: "1px",
    },
    iconButton: {
        color: "#00C3FF",
        transform: "scale(1, 1)",
        transition: theme.transitions.create(["transform", "color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    iconButtonHidden: {
        transform: "scale(0, 0)",
        "& > $icon": {
            opacity: 0,
        },
    },
    searchIconButton: {
        color: theme.palette.action.active,
        transform: "scale(1, 1)",
        transition: theme.transitions.create(["transform", "color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
        marginRight: theme.spacing(-6),
    },
    icon: {
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    input: {
        width: "100%",
    },
    searchContainer: {
        margin: "auto 0px",
        width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
    },
});

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleTextChange = (event) => {
        let change = {};
        change.value = event.target.value;
        this.setState(change);
    };

    handleCancel = () => {
        this.setState({value: ""})
        this.props.handleRequestSearch("");
    }

    handleKeyUp = (e) => {

        let { cancelOnEscape, handleRequestSearch } = this.props;

        if (e.charCode === 13 || e.key === "Enter") {
            handleRequestSearch(this.state.value);
        } else if (cancelOnEscape && (e.charCode === 27 || e.key === "Escape")) {
            this.handleCancel();
        }
    }

    render() {

        let { value } = this.state;
        let { classes, disabled, handleBlur, handleFocus, handleRequestSearch} = this.props;

        return (
            <Paper elevation={0} className={classes.root}>
                <IconButton
                    onClick={() => handleRequestSearch(value)}
                    className={classes.iconButton}
                    disabled={disabled}
                >
                    <SearchIcon className={classes.icon}/>
                </IconButton>

                <div className={classes.searchContainer}>
                    <Input
                        onBlur={handleBlur}
                        value={value}
                        onChange={this.handleTextChange}
                        onKeyUp={this.handleKeyUp}
                        onFocus={handleFocus}
                        fullWidth
                        className={classes.input}
                        disableUnderline
                        disabled={disabled}
                    />
                </div>

                <IconButton
                    onClick={this.handleCancel}
                    className={value !== "" ? classes.iconButton : classes.iconButtonHidden}
                    disabled={disabled}
                >
                    <ClearIcon className={classes.icon}/>
                </IconButton>

            </Paper>
        );
    }

}

export default withStyles(styles)(SearchBar);
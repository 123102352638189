import axios from 'axios';

class OrganisationsApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || "";
    }

    addressBook(organisationId) {

        return axios.get(`${this.baseUrl}/api/organisations/${organisationId}/addressbooks/_default`);
    }

    list() {

        return axios.get(`${this.baseUrl}/api/organisations`);
    }

    listServiceProviders(organisationId) {

        return axios.get(`${this.baseUrl}/api/organisations/${organisationId}/service_providers/_summary`);
    }

    listServiceProvidersPeople(organisationId, permissions) {

        return axios.get(`${this.baseUrl}/api/organisations/${organisationId}/service_providers/_/people`, { params: { "permissions": permissions } });
    }
}

export const organisationsApi = new OrganisationsApi();

import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Bloc, RecommendationsBlocEvent,} from "./bloc";
import {DHAccordion, DHChipWithIcon} from "../../../../Common";
import DecodedComponent from "../../../../../../../../shared/DecodedComponent";
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Popover,
    Typography
} from "@material-ui/core";
import {AddCircleOutline, AddCircleSharp, Help, RemoveCircleOutline,} from "@material-ui/icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import InfoIcon from '@material-ui/icons/Info';
import {
    AllStatusForObservation, getIconForObservationFromState,
    ObservationButtonState, polarity
} from "../../../../ClinicalGraph/Observations/Observations.utils";

const styles = theme => ({
    root: {
        height: "inherit",
        marginBottom: 32,
    },
    skeleton: {
        paddingTop: "1em"
    },
    fill: {
        flexGrow: "1",
    },
    iconStyles: {
        marginRight: 24,
        fontSize: 24,
    },
    inline: {
        display: "inline-flex",
    },
    popOver: {
        padding: "12px 16px",
    },
    recommendationContainer: {
        maxHeight: "340px",
        overflow: "hidden",
    },
    recommendationSuggestions: {
        display: "flex",
        flexDirection: "column",
    },
    recommendationSuggestion: {
        margin: "6px",
        maxWidth: "260px",
        backgroundColor: "#fff",
    },
    recommendationContainerPanel: {
      paddingRight: "24px",
    },
    recommendationContainerPanelTitle: {
        color: "#858585",
        marginTop: 12,
        marginBottom: 12,
    },
})

function InformationPopper({ classes, item }) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'chart-recommendation-popover' : undefined

    return (<>
        <IconButton style={{ padding: 0, margin: 0, }} onClick={handleClick}><InfoIcon /></IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.popOver}>
                <Typography>{item.name}</Typography>
            </div>
        </Popover>
    </>);
}

class Recommendations extends DecodedComponent {

    constructor(props) {
        super(props);
        this.state = {
            initialised: false,
            context: false,
            resources: [],
            conditions: [],
            medications: [],
        };
        this.bloc = new Bloc(props.cgiBloc, props.formBloc);
        props.formBloc.registerBloc(this.bloc);
    }

    componentDidMount() {
        super.componentDidMount()
        this.bloc.initialise();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.bloc.close();
    }

    __handleEvent = (event) => {

        switch (event.type) {
            case RecommendationsBlocEvent.RECOMMENDATIONS_UPDATED: {
                this.setState({
                    conditions: this.bloc.conditions(this.props.items,),
                    medications: this.bloc.medications(this.props.items,),
                });
            }
        }
    }

    __add = (selection) => {
        this.bloc.add(selection)
    }

    __toggleContext = () => {
        this.setState({ context: !this.state.context, });
    }

    __button = (state, recommendation,loading, working,classes,) => {

        let button = state === ObservationButtonState.confirm ? <AddCircleOutline  style={{color: "#ededed"}} /> : <RemoveCircleOutline style={{color: "#ededed"}}/>;

        return <ButtonBase disabled={(loading || working)} style={{backgroundColor: "transparent",}} onClick={(e) => {
            if(ObservationButtonState.confirm === state) {
                this.bloc.addRecommendation(recommendation, recommendation.class);
            }
        }}
        >
            <div style={{backgroundColor: "transparent",}}>
                {button}
                {(loading || working) && (
                    <CircularProgress
                        size={24}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                )}
            </div>
        </ButtonBase>
    }



    render() {

        let { initialised, loading, working, conditions, medications, } = this.state;
        let { classes, items } = this.props;

        return (
            <div className={classes.root}>
                <DHAccordion
                    style={{ maxWidth: "98%" }}
                    header={"System recommendations "}
                    id={"recommendations"}
                    headerStyle={{
                        color: "#333333",
                        fontWeight: 600,
                        fontSize: 24,
                        textTransform: "none",
                    }}
                    expandedInitial={true}
                >
                    <Box>
                        <Grid className={classes.recommendationContainer} container>
                            {conditions.length > 0 &&
                                <Grid className={classes.recommendationContainerPanel} xs={4}>
                                    <Typography className={classes.recommendationContainerPanelTitle}
                                                variant={"subtitle1"}>Medical History</Typography>
                                    <Box className={classes.recommendationSuggestions}>
                                        {conditions.map(condition => <><DHChipWithIcon
                                            className={classes.intentionChips}
                                            drawerItem={false}
                                            style={{
                                                maxWidth: '100%',
                                                minWidth: '100%',
                                                width: '100%',
                                            }}
                                            icon={<Box style={{marginTop: 3,}}>
                                                {this.__button(ObservationButtonState.confirm, condition, loading, working, classes)}
                                                {/*{this.__button(ObservationButtonState.deny,condition,loading,classes)}*/}
                                            </Box>}
                                            classes={{icon: classes.outlineChip,}}
                                            label={<>
                                            <span onClick={() => {
                                            }} style={{
                                                textAlign: "left",
                                                justifyContent: "flex-start",
                                                minWidth: "235px",
                                                maxWidth: "100%",
                                                lineHeight: "24px",
                                                cursor: "pointer",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>{condition.name}</span>
                                                <span className={classes.fill}></span>
                                                <span style={{
                                                    paddingLeft: 12,
                                                }}><InformationPopper classes={classes} item={condition}/></span>
                                            </>}
                                        />
                                        </>)}
                                    </Box>
                                </Grid>
                            }
                            {medications.length > 0 &&
                                <Grid className={classes.recommendationContainerPanel} xs={5}>
                                    <Typography className={classes.recommendationContainerPanelTitle}
                                                variant={"subtitle1"}>Medications</Typography>
                                    <Box className={classes.recommendationSuggestions}>
                                        {medications.map(medication => <DHChipWithIcon
                                            className={classes.intentionChips}
                                            drawerItem={false}
                                            style={{
                                                maxWidth: '100%',
                                                minWidth: '100%',
                                                width: '100%',
                                            }}
                                            icon={<Box style={{marginTop: 3,}}>
                                                {this.__button(ObservationButtonState.confirm, medication, loading, working, classes)}
                                                {/*{this.__button(ObservationButtonState.deny,medication,loading,classes)}*/}
                                            </Box>}
                                            classes={{icon: classes.outlineChip,}}
                                            label={<>
                                            <span onClick={() => {
                                            }} style={{
                                                textAlign: "left",
                                                justifyContent: "flex-start",
                                                minWidth: "235px",
                                                maxWidth: "100%",
                                                lineHeight: "24px",
                                                cursor: "pointer",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}>{medication.name}</span>
                                                <span className={classes.fill}></span>
                                                <span style={{
                                                    paddingLeft: 12,
                                                }}><InformationPopper classes={classes} item={medication}/></span>
                                            </>}
                                        />)}
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>

                </DHAccordion>
            </div>
        )
    }
}

export default withStyles(styles)(Recommendations);


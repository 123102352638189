import { Popover, withStyles } from "@material-ui/core";
import React from "react";

const styles = {
  root: {
    backgroundColor: "transparent",
  },
};
function ContextMenu({ display, children, heading, classes, isOpen, onClose }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const open = Boolean(anchorEl) && isOpen;
  const id = open ? "contextMenu" : undefined;

  return (
    <div>
      <div aria-describedby={id} onClick={handleClick}>
        {heading}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          classes: { root: classes.root },
        }}
      >
        {children}
      </Popover>
    </div>
  );
}
export default withStyles(styles)(ContextMenu);

import {ButtonBase, Typography} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";
import { isEmpty } from "loadsh";
import { withStyles } from "@material-ui/styles";
function MultiOptionalContextMenu({
  options,
  selectedOption,
  onClick,
  classes,
}) {
  return (
    <div
      style={{
        width: "332px",
      }}
    >
      <>
        {!isEmpty(selectedOption) ? (
          <div
            style={{
              display: "inline-flex",
              width: "322px",
              color: "#333333",
              borderRadius: 32,
              background: "#fff",
              height: 32,
              marginTop: 4,
              alignItems: "center",
              marginBottom: 4,
            }}
            onClick={() => {
              onClick({});
            }}
          >
            <Clear
              style={{ color: "#00D1FF", marginLeft: 8, marginRight: 8 }}
            />
            <Typography
              classes={{
                body1: classes.body1,
              }}
              component={"span"}
            >
              {selectedOption.label}
            </Typography>
          </div>
        ) : (
          options?.map((option) => (
            <ButtonBase
              style={{
                display: "inline-flex",
                width: "322px",
                color: "#333333",
                borderRadius: 32,
                background: "#fff",
                height: 32,
                marginTop: 4,
                alignItems: "center",
                marginBottom: 4,
                justifyContent: "start",
              }}
              onClick={() => {
                onClick(option);
              }}
            >
              {option.icon}
              <Typography
                component={"span"}
                classes={{
                  body1: classes.body1,
                }}
              >
                {option.label}
              </Typography>
            </ButtonBase>
          ))
        )}
      </>
    </div>
  );
}
const styles = {
  body1: {
    fontFamily: "inter !important",
    fontSize: 15,
  },
};
export default withStyles(styles)(MultiOptionalContextMenu);

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import EncounterContext from "../../context";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ActionBarBloc } from "./bloc";
import ActionBarButton from "./ActionBarButton";

import { ReactComponent as cancelIcon } from "../../../../../../assets/icons/encounter/controls/cancel.svg";
import { ReactComponent as endEncounterIcon } from "../../../../../../assets/icons/encounter/controls/end.encounter.svg";
import { ReactComponent as intakeCompleteIcon } from "../../../../../../assets/icons/encounter/controls/intake.complete.svg";
import { ReactComponent as openChartIcon } from "../../../../../../assets/icons/encounter/controls/open.chart.svg";
import { ReactComponent as resetIcon } from "../../../../../../assets/icons/encounter/controls/reset.svg";
import {
  ReactComponent as sendLinkIcon,
  ReactComponent as clinicalGraphInterfaceIcon,
} from "../../../../../../assets/icons/encounter/controls/send.link.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import Allowed from "../../../../../../utils/auth/Allowed";
import { globalBloc } from "../../../../global.bloc";
import { FormattedMessage } from "react-intl";
import { notificationService } from "../../../../../../utils/notification";
import { phoneUtil } from "../../../../../../utils/phone";

const styles = (theme) => ({
  root: {
    position: "relative",
    top: "-56px",
    width: "100%",
    height: "0",
  },
  container: {
    paddingLeft: "12px",
    paddingTop: "6px",
    height: "56px",
    maxHeight: "56px",
    minHeight: "56px",
    width: "100%",
    backgroundColor: "#FAFAFA",
    display: "flex",
  },
  avatar: {
    width: "42px",
    height: "42px",
  },
  subjectName: {
    paddingLeft: "16px",
  },

  subjectNameText: {
    letterSpacing: "0",
    fontWeight: "400",
    fontSize: "18px",
    color: "#365169",
    lineHeight: "40px",
  },

  controls: {
    display: "flex",
    marginLeft: "32px",
    marginRight: "32px",
    ["& > *"]: {
      margin: "0 6px",
    },
    ["&:last-of-type"]: {
      minWidth: "35px",
    },
  },

  min160: {
    minWidth: "160px",
  },

  fill: {
    flex: "1 1 auto",
  },
  iconButton: {
    marginTop: "8px",
    "&:hover": {
      cursor: "pointer",
      '& path[fill="#7F8993"]': {
        fill: "#07C6FF",
      },
    },
  },
  fillAction: {
    width: "290px",
  },
  dialogButton: {
    marginRight: "12px",
  },
});

const initialState = {
  sendLink: false,
};

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };

    this.bloc = new ActionBarBloc(this.props.context.encounter);

    this.__confirmAction = this.__confirmAction.bind(this);
    this.__handleAction = this.__handleAction.bind(this);
    this.__confirmChartAction = this.__confirmChartAction.bind(this);
    this.__handleChartAction = this.__handleChartAction.bind(this);

    this.__handleStateUpdate = this.__handleStateUpdate.bind(this);
  }

  componentDidMount() {
    this.subscription = this.bloc.subscribeToState(this.__handleStateUpdate);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  __handleStateUpdate = (subject) => {
    this.setState({
      ...subject,
    });
  };

  __confirmAction = (action) => {
    const $this = this;
    return () => {
      if (!$this.state.processing) {
        $this.setState({
          confirmEncounterActionTarget: action,
        });
      } else {
        notificationService.update("Still processing your previous request.");
      }
    };
  };

  __closeAction = () => {
    if (!this.state.processing) {
      this.setState({
        confirmEncounterActionTarget: undefined,
      });
    }
  };

  __handleAction = (action, data) => {
    const $this = this;
    return () => {
      if (!$this.state.processing) {
        const bloc = $this.bloc;
        bloc.handleEncounterAction(action, data);
        $this.setState({
          processing: true,
        });
      } else {
        notificationService.update("Still processing your previous request.");
      }
    };
  };

  __confirmChartAction = (action) => {
    const $this = this;
    return () => {
      let props = {};

      switch (action) {
        case "send.notification": {
          props.sendLinkOpen = true;
        }
      }

      $this.setState({
        ...props,
      });
    };
  };

  __handleChartAction = (action) => {
    return () => {};
  };

  __openChart = () => {
    let { encounterBloc } = this.props.context;
    encounterBloc.openChart();
  };

  __openClinicalGraphInterface = () => {
    let { encounterBloc } = this.props.context;
    encounterBloc.toggleClinicalGraphInterface();
  };

  __sendLink = (channel) => () => {
    let { person, encounter, encounterBloc } = this.props.context;

    encounterBloc.sendPatientLink("_", encounter.id, channel);

    this.setState({
      sendLinkOpen: false,
    });
  };

  __closeLinkConfirmation = () => {
    this.setState({
      sendLinkOpen: false,
    });
  };

  render() {
    let { classes } = this.props;
    let { person } = this.props.context;
    let { confirmEncounterActionTarget } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Avatar className={classes.avatar}>
            {person ? <>{person.name.given.charAt(0)}</> : <>U</>}
          </Avatar>
          <span className={classes.subjectName}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.subjectNameText}
            >
              {person ? (
                <>
                  {person.name.given} {person.name.family}
                </>
              ) : (
                <>Unknown Unknown</>
              )}
            </Typography>
          </span>
          <span className={classes.fill} />
          <span className={classes.controls}>
            {this.__renderEncounterActions()}
            {confirmEncounterActionTarget ? (
              <>{this.__renderEncounterActionConfirmationDialog()}</>
            ) : (
              <></>
            )}
          </span>
          <span className={[classes.controls, classes.min160].join(" ")}>
            {this.__renderChartActions()}
          </span>
          <span className={classes.fillAction} />
          <span className={classes.controls}>
            &nbsp;
            {/*<SvgIcon  className={classes.iconButton} component={ notificationIcon } viewBox="0 0 20 20" />*/}
          </span>
        </div>
      </div>
    );
  }

  __renderEncounterActions = () => {
    let { encounter, roles } = this.props.context;

    let allowCancel = `${process.env.REACT_APP_ENCOUNTER_ALLOW_CANCEL}`;

    return (
      <>
        {encounter.type === "VIRTUAL_WALK_IN" ? (
          <></>
        ) : (
          <>
            {allowCancel === "1" && (
              <Allowed
                roles={roles}
                priviledge="interaction:encounter:cancel"
                yes={() => (
                  <ActionBarButton
                    alt={true}
                    onClick={this.__confirmAction("cancel")}
                    icon={
                      <SvgIcon component={cancelIcon} viewBox="0 0 30 30" />
                    }
                    text={"Cancel Encounter"}
                  />
                )}
                no={() => <></>}
              />
            )}

            <Allowed
              roles={roles}
              priviledge="interaction:encounter:update"
              yes={() => (
                <ActionBarButton
                  onClick={this.__confirmAction("intake")}
                  icon={
                    <SvgIcon
                      component={intakeCompleteIcon}
                      viewBox="0 0 30 30"
                    />
                  }
                  text={"Intake Complete"}
                />
              )}
              no={() => <></>}
            />

            <Allowed
              roles={roles}
              priviledge="interaction:encounter:back"
              yes={() => (
                <ActionBarButton
                  onClick={this.__confirmAction("reset")}
                  icon={<SvgIcon component={resetIcon} viewBox="0 0 30 30" />}
                  text={"Reset Encounter   "}
                />
              )}
              no={() => <></>}
            />
          </>
        )}

        <Allowed
          roles={roles}
          priviledge="interaction:encounter:end"
          yes={() => (
            <ActionBarButton
              onClick={this.__confirmAction("end")}
              icon={
                <SvgIcon component={endEncounterIcon} viewBox="0 0 30 30" />
              }
              text={"End Encounter"}
            />
          )}
          no={() => <></>}
        />
      </>
    );
  };

  __renderEncounterActionConfirmationDialog = () => {
    let { classes } = this.props;
    let { encounter } = this.props.context;
    let { processing, confirmEncounterActionTarget } = this.state;

    let confirmationTargetTitle = "";
    let confirmationTargetText = "";

    let buttons = [
      <Button onClick={this.__closeAction} className={classes.dialogButton}>
        <FormattedMessage id={"cancel.button"} defaultMessage={"Cancel"} />
      </Button>,
    ];

    if (confirmEncounterActionTarget === "cancel") {
      let allowCancel = `${process.env.REACT_APP_ENCOUNTER_ALLOW_CANCEL}`;

      if (allowCancel === "1" /* && encounter.type.includes("VIRTUAL")*/) {
        confirmationTargetTitle = "Cancel encounter";
        confirmationTargetText =
          "This will cancel the encounter and remove the patient from the tracking board. For situations such as unable to reach the patient, or patient request to cancel.";
        buttons.push(
          <Button
            variant={"contained"}
            onClick={this.__handleAction(confirmEncounterActionTarget)}
            color={"primary"}
            autoFocus
          >
            Confirm
          </Button>
        );
      }
    } else if (confirmEncounterActionTarget === "end") {
      let allowTransition = `${process.env.REACT_APP_ENCOUNTER_ALLOW_TRANSITION_TO_INPERSON}`;

      if (allowTransition === "1" && encounter.type.includes("VIRTUAL")) {
        confirmationTargetTitle =
          "Would you like to see the patient in person?";
        confirmationTargetText =
          "If Not, the system will end the encounter virtually and collect billing information and discharge the patient.";
        buttons.push(
          <Button
            variant={"contained"}
            onClick={this.__handleAction("transition", { type: "move" })}
            color={"primary"}
            autoFocus
          >
            See in person
          </Button>
        );
        buttons.push(
          <Button
            variant={"contained"}
            onClick={this.__handleAction(confirmEncounterActionTarget)}
            color={"secondary"}
            autoFocus
          >
            Close encounter
          </Button>
        );
      } else {
        confirmationTargetTitle = "End encounter";
        confirmationTargetText = "Are you sure you want to end the encounter?";
        buttons.push(
          <Button
            variant={"contained"}
            onClick={this.__handleAction(confirmEncounterActionTarget)}
            color={"primary"}
            autoFocus
          >
            Confirm
          </Button>
        );
      }
    } else if (confirmEncounterActionTarget === "intake") {
      confirmationTargetTitle = "Ready for encounter";
      confirmationTargetText = "Are you sure the intake has been completed?";
      buttons.push(
        <Button
          variant={"contained"}
          onClick={this.__handleAction(confirmEncounterActionTarget)}
          color={"primary"}
          autoFocus
        >
          Confirm
        </Button>
      );
    } else if (confirmEncounterActionTarget === "reset") {
      confirmationTargetTitle = "Reset appointment";
      confirmationTargetText =
        "By resetting the appointment you will send the patient back to the waiting room. Are you sure you want to do this?";
      buttons.push(
        <Button
          variant={"contained"}
          onClick={this.__handleAction(confirmEncounterActionTarget)}
          color={"primary"}
          autoFocus
        >
          Confirm
        </Button>
      );
    }

    return (
      <Dialog
        open={confirmEncounterActionTarget}
        onClose={this.__closeAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {processing ? (
          <DialogContent style={{ textAlign: "center" }}>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {confirmationTargetTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {confirmationTargetText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  __renderChartActions = () => {
    const { encounter, person, encounterBloc } = this.props.context;
    const { sendLinkOpen } = this.state;

    let buttons = [];

    if (encounterBloc.hasChartLink()) {
      buttons.push(
        <ActionBarButton
          key={"chartBtn"}
          onClick={this.__openChart}
          icon={<SvgIcon component={openChartIcon} viewBox="0 0 30 30" />}
          text={"Open Chart"}
        />
      );
    }

    let commsOptions = [];

    if (
      globalBloc.checkAllowed("interaction:encounter:send-patient-link") &&
      encounter.type !== "VIRTUAL_WALK_IN" &&
      encounterBloc.videoLink()
    ) {
      if (person?.contactNumber?.line.length > 0) {
        commsOptions.push(
          <ListItem
            key={`${person.contactNumber}`}
            autoFocus
            button
            onClick={this.__sendLink("sms")}
          >
            <ListItemText
              primary={`SMS / Text to ${phoneUtil.formatPhoneNumberFull(
                person.contactNumber
              )}`}
            />
          </ListItem>
        );
      }

      commsOptions.push(
        <ListItem
          key={`${person?.email}`}
          autoFocus
          button
          onClick={this.__sendLink("email")}
        >
          <ListItemText primary={`Email to ${person?.email}`} />
        </ListItem>
      );

      buttons.push(
        <ActionBarButton
          key={"communicationBtn"}
          onClick={this.__confirmChartAction("send.notification")}
          icon={<SvgIcon component={sendLinkIcon} viewBox="0 0 30 30" />}
          text={"Send Link"}
        />
      );
    }

    if (globalBloc.checkAllowed("interaction:encounter:cgi")) {
      buttons.push(
        <ActionBarButton
          key={"cgiBtn"}
          onClick={this.__openClinicalGraphInterface}
          icon={
            <SvgIcon
              component={clinicalGraphInterfaceIcon}
              viewBox="0 0 30 30"
            />
          }
          text={"Clinical Graph"}
        />
      );
    }

    return (
      <>
        {buttons}
        {commsOptions.length > 0 && (
          <Dialog
            onClose={this.__closeLinkConfirmation}
            aria-labelledby="simple-dialog-title"
            open={sendLinkOpen ?? false}
          >
            <DialogTitle id="simple-dialog-title">
              <FormattedMessage
                id={"send.link.confirmation.dialog.title"}
                defaultMessage={"Send via"}
              />
            </DialogTitle>
            <List>{commsOptions}</List>
          </Dialog>
        )}
      </>
    );
  };
}

export default withStyles(styles)((props) => (
  <EncounterContext.Consumer>
    {(value) => {
      return <ActionBar context={value} {...props} />;
    }}
  </EncounterContext.Consumer>
));

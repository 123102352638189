import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {encounterEventService} from "../../service/encounter.event.service";
import {Fab} from "@material-ui/core";

import PhoneIcon from '@material-ui/icons/Phone';

const styles = theme => ({
    root: {
        height: "calc(100vh - 56px)",
        backgroundColor: "#C4C4C430",
        display: "flex",
        flexDirection: "column",
    },
    fill: {
        flex: "1 1 auto",
    },
    wrapper: {
        margin: "auto",
    },
    iconWrapper: {
        display: "flex",
        marginBottom: "20px",
    },
    text: {

        textTransform: "capitalize",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "22px",
        lineHeight: "33px",
        textAlign: "center",

        color: "#FFFFFF",
        cursor: "pointer",
    },
    click: {
        cursor: "pointer",
        flex: "1 1 auto",
    },
    action: {
        height: "70px",
        width: "70px",
        backgroundColor: "rgba(45,176,66,1)",
        border: "8px solid rgba(73,127,93,1)",
        "&:hover": {
            backgroundColor: "rgba(45,176,66,0.6)",
        }
    },
    actionIcon: {
        color: "rgb(255, 255, 255)",
        height: "30px",
        width: "30px"
    },
    controls: {
        paddingTop: "24px",
        paddingLeft: "32px",
        minHeight: "144px",
        display: "flex",
        background: "#FFFFFF",
    },
});


class Call extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    _call = () => {

        encounterEventService.update({
           type: 'ENCOUNTER_CALL_CUSTOMER_START',
           data: {}
        });
    }

    render() {

        let { classes } = this.props;

        return (
            <div className={ classes.root }>
                <div className={classes.fill}/>
                <div className={classes.wrapper}>
                    <div className={classes.iconWrapper}>
                        <div className={classes.fill}></div>
                        <Fab onClick={ this._call } className={classes.action} aria-label="add">
                            <PhoneIcon className={classes.actionIcon} />
                        </Fab>
                        <div className={classes.fill}></div>
                    </div>
                    <div className={classes.text} onClick={ this._call }>Start video call</div>
                </div>
                <div className={classes.fill}/>
            </div>
        );
    }
}

export default withStyles(styles)(Call);

import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import VirtualClinicContext from "../../context";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {clinicEventService} from "../../service/clinic.event.service";
import {appointmentApi} from "../../../../../utils/services/appointments.api";
import {notificationService} from "../../../../../utils/notification";
import {customersApi} from "../../../../../utils/services/customers.api";
import Allowed from "../../../../../utils/auth/Allowed";
import {encounterApi} from "../../../../../utils/services/encounter.api";


const styles = theme => ({
    root: {
        margin: "16px",
        width: "280px",
        height: "326px",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },
    fill: {
        flex: "1 1 auto",
    },
    cardHeader: {
        padding: "17px 21px 0px 12px",
        display: "flex",
    },
    cardAvatar: {
        paddingRight: "13px",
    },
    avatar: {
        width: "49px",
        height: "49px",
    },
    cardTitle: {
        width: "100%",
    },
    cardTitleText: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#20516A",
        paddingBottom: "12px",
    },
    cardTitleSubText: { },
    time: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    visit: {
        paddingLeft: "17px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#636366",
        opacity: "0.8",
    },
    cardBody: {
        padding: "0px 21px 0px 12px",
        display: "flex",
    },
    cardBodyLeft: {
        color: "#808A94",
        paddingTop: "3px",
        paddingLeft: "3px",
        paddingRight: "9px",
    },
    cardBodyRight: {
        flex: "1",
    },
    cardSubHeader: {
        padding: "10px 21px 6px 12px",
        display: "flex",
    },
    cardFooter: {
        padding: "13px 21px 15px 12px",
        display: "flex",
    },
    indicator: {
        paddingRight: "7px",
        height: "24px",
        width: "24px",
    },
    subHeaderText: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },
    subHeaderTime: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "24px",
        color: "#8BA3B0",
    },
    complaint: {
        minHeight: "115px",
        maxHeight: "115px",
        overflow: "auto",
        padding: "7px 18px",
        background: "#F5F5F5",
        opacity: "0.8",
        borderRadius: "12px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#000000",
    },

    cardAlert: {
        minHeight: "33px",
        maxHeight: "33px",
    },

    alert: {
        textAlign:"center",
        margin: "0",
        paddingTop: "4px",
        paddingRight: "7px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#20516A",
    },

    cardBodyClaimed: {
        display: "flex",
        minHeight: "180px",
        maxHeight: "180px",
        background: "#FFFFFF",
        boxShadow: "0px -6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
        flexDirection: "column",
    },
    cardBodyContentClaimed: {
        padding: "17px 21px 0px 12px",
        display: "flex",
        minHeight: "180px",
        maxHeight: "180px",
        flexDirection: "column",
        paddingBottom: "14px",
        '& button': {
            marginTop: "10px",
        }
    },
    button: {
        height: "32px",
        minHeight: "32px",
        width: "100%",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },
    confirmationButton: {
        height: "32px",
        minHeight: "32px",
        paddingLeft: "24px",
        paddingRight: "24px",
        background: "#00C3FF",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
    },

    dialogButton: {
        marginRight: '12px',
    },
});

class EncounterCard extends Component {

    interval;
    endCount = 0;

    constructor(props) {
        super(props);

        this.state = {
            now: new Date().getTime(),
            person: undefined,
            start: Date.parse(props.item.createdOn),
            confirm: false,
        };
    }

    componentDidMount() {
        const { customer } = this.props.item;

        this._loadSubject(customer);

        this.interval = setInterval(() => {

            this.setState({
                now: new Date().getTime(),
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = undefined;
    }

    _loadSubject = (subjectIdentifier) => {
        customersApi.getPersonSummary(subjectIdentifier.value, subjectIdentifier.system)
            .then(value => {
               this.setState({
                  person: value.data,
               });
            });
    };

    _openEncounter = () => {

        let { item } = this.props;

        // Check if the encounter is still available before opening
        appointmentApi.currentEncounters()
            .then(value => {

                const appointments = value.data.items;
                const appointmentIds = appointments.filter((appointment) => appointment.id === item.id);

                if (appointmentIds.length === 1) {
                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_OPEN',
                        data: item,
                    });
                } else {
                    notificationService.update({
                        error: "Unable to open the appointment. The appointment may have been ended.",
                    });
                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_COMPLETED',
                        data: item,
                    });
                }

            })
            .catch(reason => {
                notificationService.update({
                    error: "Unable to open the appointment. " + reason,
                });
            });
    };

    _confirmReadyForIntakeDialog = () => {

        this.setState({
            confirm: true,
            confirmTarget: "intake",
        });
    };
    _confirmResetDialog = () => {

        this.setState({
            confirm: true,
            confirmTarget: "reset",
        });
    };
    _confirmEndSessionDialog = () => {

        this.setState({
            confirm: true,
            confirmTarget: "complete",
        });
    };

    _closeDialog = () => {

        this.setState({
            confirm: false,
            confirmTarget: undefined,
        });
    };

    _processCommand = (target) => () => {

        let { item } = this.props;

        this.setState({
            confirm: false,
            confirmTarget: undefined,
        });

        if("reset" === target) {

            const encounterId = item.id;
            const organisationId = item.organisationId;
            encounterApi.deleteEncounter(organisationId, encounterId)
                .then(value => {
                    notificationService.success("Encounter reset!")
                })
                .catch(reason => {
                    notificationService.update({
                        error: "Unable to reset the encounter. " + reason,
                    });
                });
        } else {

            appointmentApi.updateAppointmentState(item.id, target === "complete" ? "COMPLETE" : "A_REVIEWED")
                .then(value => {

                    clinicEventService.update({
                        type: 'VIRTUAL_CLINIC_ENCOUNTER_COMPLETED',
                        data: item,
                    });
                })
                .catch(reason => {
                    this.endCount += 1;
                    notificationService.update({
                        error: "Unable to end the appointment. " + reason,
                    });
                });
        }
    };

    render() {

        let { person, confirm, confirmTarget, now, start } = this.state;
        let { classes, item } = this.props;
        let { encounters, roles } = this.props.context;

        let seconds = (now - start) / 1000;
        let minutes = seconds / 60;

        let borderColor = classes.okBorder;
        let indicatorColor = classes.okIndicator;

        if(minutes > 5) {

            borderColor = classes.redBorder;
            indicatorColor = classes.redIndicator;
        } else if(minutes > 2) {

            indicatorColor = classes.orangeIndicator;
        }

        let alert = <></>;

        const encounter = item;

        const medium = item.type.startsWith('VIRTUAL') ? "Telehealth" : "In Person";

        if(!encounter || (encounter.code === "appointmentId" && encounter.value === "tbd")) {
            alert = <p className={classes.alert}>*** No appointment created ***</p>
        }

        let buttons = (<>
            <Button variant="contained"
                    color="primary"
                    className={classes.button}
                    fullWidth={true} onClick={this._openEncounter}>Open Encounter</Button>
            <div className={classes.fill} />
            <Allowed roles={roles} priviledge="interaction:encounter:update"
                     yes={() => (
                         <>
                             <Button variant="contained"
                                     color="primary"
                                     className={classes.button} onClick={this._confirmReadyForIntakeDialog}>Intake Complete</Button>
                         </>)}
                     no={() => (<></>)} />
            <Allowed roles={roles} priviledge="interaction:encounter:end"
                     yes={() => (
                         <Button variant="contained"
                                 color="secondary"
                                 className={classes.button} onClick={this._confirmResetDialog}>Reset Encounter</Button>
                     )}
                     no={() => (<></>)} />
            <Allowed roles={roles} priviledge="interaction:encounter:end"
                     yes={() => (
                         <Button variant="contained"
                                 color="secondary"
                                 className={classes.button} onClick={this._confirmEndSessionDialog}>End Encounter</Button>
                     )}
                     no={() => (<></>)} />
        </>);

        let confirmationTargetTitle = "";
        let confirmationTargetText = "";

        if(confirmTarget === "complete") {

            confirmationTargetTitle = "End encounter";
            confirmationTargetText = "Are you sure you want to end the encounter?";
        } else if(confirmTarget === "intake") {

            confirmationTargetTitle = "Ready for encounter";
            confirmationTargetText = "Are you sure the intake has been completed?";
        } else if(confirmTarget === "reset") {

            confirmationTargetTitle = "Reset encounter";
            confirmationTargetText = "Are you would like to reset the counter?";
        }

        return (
            <>
            <div className={`${classes.root} ${ borderColor }`}>
                <div className={classes.cardHeader}>
                    <div className={classes.cardAvatar} onClick={this._seePatient}>
                        <Avatar className={classes.avatar}>{ person ? <>{person.name.given.charAt(0)}</> : <>U</> }</Avatar>
                    </div>
                    <div className={classes.cardTitle}>
                        <div className={classes.cardTitleText}>{ person ? <>{person.name.given}<br />{person.name.family}</> : <>Unknown<br />Unknown</> }</div>
                    </div>
                </div>
                <div className={classes.cardSubHeader}>
                    <div className={classes.fill}/>
                    <FiberManualRecordIcon className={`${classes.indicator} ${indicatorColor}`}/>
                    <span className={classes.subHeaderText}>{ encounter.service ? encounter.service.description : medium }</span>
                    <span
                        className={classes.subHeaderTime}>{`${parseInt(minutes % 60)}`.padStart(2, '0')}:{`${parseInt(seconds % 60)}`.padStart(2, '0')}</span>
                </div>
                <div className={classes.cardAlert}>
                    { alert }
                </div>
                <div className={classes.cardBodyClaimed}>
                    <div className={classes.cardBodyContentClaimed}>
                        { buttons }
                    </div>
                </div>
            </div>
                <Dialog
                    open={confirm}
                    onClose={this._closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{ confirmationTargetTitle }</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { confirmationTargetText }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._closeDialog} className={classes.dialogButton}>
                            Cancel
                        </Button>
                        <Button variant={"contained"} onClick={this._processCommand(confirmTarget)} color={"primary"} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <VirtualClinicContext.Consumer>
        {value => {
            return (<EncounterCard context={value} {...props} />);
        }
        }
    </VirtualClinicContext.Consumer>
));

import {parse, format} from "date-fns";
import enUS from "date-fns/locale/en-US";

class DateUtil {

    fullFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' });
    longFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' });

    encloses(at, from, to) {
        return at.getTime() >= from.getTime() &&
            at.getTime() <= to.getTime();
    }

    parseDate(dateString) {
        const format = "yyyy-MM-dd'T'HH:mm:ssXXX";
        return parse(dateString, format, new Date());
    }

    formatChartRecord(date) {
        const formatString = "MM-dd-yyy hh:mm aaa";
        return format(date, formatString);
    }

    formatIsoDateAdjust(date) {
        return `${date.getFullYear()}-${this.__formatTwoDigits((date.getMonth() + 1))}-${this.__formatTwoDigits(date.getDate())}`;
    }

    __formatTwoDigits(digit) {
        return digit < 10 ? '0' + digit : digit;
    }

}

export const dateUtil = new DateUtil();

import {cgiApi} from "../../../../../utils/services/cgi.api";

export class ManagementUtil {

    constructor(bloc) {
        this.bloc = bloc;
    }

    toggleMenu = () => {
        let { management } = this.bloc.subject.value;
        management.open = !management.open;
        this.bloc.__updateSubject({ management: management });
    }

    addRelatedManagement = (item) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            command: "add_known_actions",
            payload: {
                bid: currentComplaint.bid,
                participants: [
                    {
                        role: "SUBJECT",
                        identifier: {
                            code: {
                                code: workspaceGraph.encounterPatient().reference.code.code,
                                system: workspaceGraph.encounterPatient().reference.code.system,
                            },
                            value: workspaceGraph.encounterPatient().reference.value,
                        }
                    }
                ],
                basedOn: {
                    type: item.class,
                    bid: item.bid,
                },
                type: ["management"]
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "management");
    }

    addManagement = (item) => {

        const {clinicId, encounter, currentComplaint, workspaceGraph} =
            this.bloc.subject.value;

        const payload = {
            command: "add_known_actions",
            payload: {
                bid: currentComplaint.bid,
                participants: [
                    {
                        role: "SUBJECT",
                        identifier: {
                            code: {
                                code: workspaceGraph.encounterPatient().reference.code.code,
                                system: workspaceGraph.encounterPatient().reference.code.system,
                            },
                            value: workspaceGraph.encounterPatient().reference.value,
                        }
                    }
                ],
                code: {
                    system: item.system,
                    code: item.conceptId,
                    display: item.canonicalName,
                },
                type: ["management"],
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "management");
    }
}

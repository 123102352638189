import { authService } from './auth'
import {CloudEvent} from "cloudevents";


export const PING = "user.ping";

async function retrieveToken() {
    let token = await authService.getToken();
    if(!token) window.location.replace("/");
    return token;
}

export async function generatePingEvent() {

    let token = await retrieveToken();

    const ce = new CloudEvent({ source: "/portal/dialog-management", type: PING, data: {} });

    return ce.cloneWith({vendor: "dh", authorization: `Bearer ${token}` }).toJSON();
}

export class AnalyticsEvent {

    static LOGIN_SUCCESS = "login.success";
    static LOGIN_ERROR = "login.error";
    static RESET_PASSWORD = "password.reset";
    static RESET_PASSWORD_ERROR = "password.reset.error";

    static LINK_OPEN = "link.open";
    static LINK_VALID = "link.valid";
    static LINK_ERROR = "link.error";
    static LINK_SUCCESS = "link.success";

    static SCHEDULE_SUCCESS = "schedule.session.success";
    static SCHEDULE_ERROR = "schedule.session.error";
    static SCHEDULE_PATIENT_CONFLICT = "schedule.patient.conflict";
    static SCHEDULE_PATIENT_MATCH_SUCCESS = "schedule.patient.match";
    static SCHEDULE_PATIENT_MATCH_ERROR = "schedule.patient.match.error";
    static SCHEDULE_PATIENT_MATCH_SELECTED = "schedule.patient.match.selected";
    static SCHEDULE_PATIENT_INSURANCE_LOADED = "schedule.insurance.loaded";
    static SCHEDULE_PATIENT_INSURANCE_LOADED_ERROR = "schedule.insurance.error";
    static SCHEDULE_PATIENT_INSURANCE_LOADED_SET = "schedule.insurance.set";
    static SCHEDULE_TRANSITION = "schedule.session.transition";

    static ENCOUNTER_SESSION_EVENT = "encounter.session.event";

    static ENCOUNTER_SESSION_START = "encounter.session.start";
    static ENCOUNTER_SESSION_STARTED = "encounter.session.started";
    static ENCOUNTER_SESSION_END = "encounter.session.end";
    static ENCOUNTER_SESSION_CANCEL = "encounter.session.cancel";
    static ENCOUNTER_SESSION_ERROR = "encounter.session.error";

    static TELEHEALTH_SESSION_START = "telehealth.session.start";
    static TELEHEALTH_SESSION_END = "telehealth.session.end";
    static TELEHEALTH_SESSION_ERROR = "telehealth.session.error";

    static JAVASCRIPT_ERROR = "javascript.error";
}

class AnalyticsEventLogger {

    initialise = (analytics) => {
        this._analytics = analytics;
    }

    setUser = (uid) => {
        if(this._analytics) {
            this._analytics.setUserId(uid);
        }
    }

    log = (event, data) => {
        if(this._analytics) {
            this._analytics.logEvent(event, data);
        }
    }
}

export const analyticsEventLogger = new AnalyticsEventLogger();

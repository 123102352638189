import * as rxjs from "rxjs";
import {chartApi} from "../../../../../../../../../utils/services/chart.api";
import {notificationService} from "../../../../../../../../../utils/notification";
import {CommonBloc} from "../Common/bloc";

export class Bloc extends CommonBloc {

    constructor(cgiBloc) {
        super(cgiBloc, 'problems', 'condition', 'B002', ['medical_history']);
    }


    initialise = () => {
        this.getConditions();
    }


    getConditions = () => {

        let {clinicId, encounterId} = this.subject.value;

        this.events.next({
            type: ConditionBlocEvent.CONDITION_LOADING
        })

        chartApi.getPersonChartConditions(clinicId, encounterId).then(result => {

            result.data.items.sort((a, b) => {

                if(a.clinicalStatus === 'active' && b.clinicalStatus === 'historical') {
                    return -1;
                }

                return a.code.display.localeCompare(b.code.display);
            })

            this.__updateSubject({
                resources : result.data.items
            })

            this.events.next({
                type: ConditionBlocEvent.CONDITION_LOAD_SUCCESS,
                data: {
                    resources: result.data.items,
                }
            })

        }).catch(error => {

            notificationService.error("Unable to retrieve patient's problem list")

            this.events.next({
                type: ConditionBlocEvent.CONDITION_LOAD_ERROR,
                data: {
                    error: error,
                }
            })
        }).finally(() => {
            this.__updateSubject({
                initialised: true,
            });
        });
    }
}

export class ConditionBlocEvent {

    static CONDITION_LOADING = "CONDITION_LOADING";
    static CONDITION_LOAD_SUCCESS = "CONDITION_LOAD_SUCCESS";
    static CONDITION_LOAD_ERROR = "CONDITION_LOAD_ERROR";
}

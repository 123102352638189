import axios from 'axios';

class InsurancesApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    }

    getProviders() {

        return axios.get(`${this.baseUrl}/api/insurances/providers`);
    }


    searchPlan(request) {

        return axios.post(`${this.baseUrl}/api/insurances/_search`, request);
    }
}

export const insurancesApi = new InsurancesApi();

import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const styles = theme => ({
    root: {
        minWidth: "70px",
        height: "0",
        '&:hover': {
            cursor: "pointer",
            '& path[fill="#7F8993"]': {
                fill: "#07C6FF",
            },
        },
    },
    rootAlt: {
        minWidth: "70px",
        height: "0",
        '&:hover': {
            cursor: "pointer",
            '& path[fill="#7F8993"]': {
                fill: "#C92D5C",
            },
        },
    },
    icon: {
        textAlign: "center",
    },
    textHolder: {
        textAlign: "center",
        lineHeight: '1.0',
    },
    text: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "15px",
        alignItems: "center",
        textAlign: "center",

        color: "#4E575E",
    },
});


class ActionBarButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let { classes } = this.props;

        return (
            <span className={this.props.alt ? classes.rootAlt : classes.root} onClick={this.props.onClick}>
                <div className={classes.icon}>
                    { this.props.icon }
                </div>
                <div className={classes.textHolder}>
                    <Typography variant="body1" component="span" className={classes.text}>{ this.props.text }</Typography>
                </div>
            </span>
        );
    }
}

export default withStyles(styles)(ActionBarButton);

import React, {Component} from 'react';

import {Button, CircularProgress, Grid} from "@material-ui/core";
import withStyles from '@material-ui/core/styles/withStyles';
import AccountContext from "../context";
import DecodedContainer from "../../../shared/Container";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {PhoneTextMask} from "../../../shared/InputTextMask";
import {accountEventService} from "../service/account.event.service";


const styles = theme => ({
    root: {
        flex: "1 1 auto",
        width: "100%",
        padding: "60px 100px",
    },
    grid: {
        flex: "1",
        paddingTop: "2em",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    },
    textItem: {
        flex: "1",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    amount: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "5.6em",
        lineHeight: "1.214",
        color: "#0F0F0F",
        marginBottom: "0.44643em",
    },
    confirmation: {
        width: "80%",
        lineHeight: "1.75",
        color: "#575757",
        marginBottom: "1.875em",
        paddingTop: "0.5em",
        padding: "3em",
    },
    button: {
        width: "100%",
        padding: "1em",
        textAlign: "right",
    },

    container: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    validation: {
        width: "100%",
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    }
});

class Profile extends Component {


    constructor(props, context) {
        super(props, context);
    }

    __handleTextChange = (event) => {
        accountEventService.update("PROFILE_TEXT_CHANGE", {
            target: event.target.name,
            value: event.target.value,
        });
    };


    _doSubmit = () => {
        accountEventService.update("PROFILE_SUBMIT", { });
    }

    render() {
        let { classes, handleNext } = this.props;

        const { profile, loading } = this.props.context;

        return (
            <div className={classes.root}>

                <DecodedContainer loading={loading}>
                    <div className={classes.container}>
                        <ValidatorForm
                            onSubmit={this._doSubmit}
                            className={classes.validation}
                        >
                            <div className={classes.form}>
                                <div className={classes.formInput}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                name="firstName"
                                                value={profile.firstName}
                                                onChange={this.__handleTextChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Last Name"
                                                type="text"
                                                name="lastName"
                                                value={profile.lastName}
                                                onChange={this.__handleTextChange}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                value={profile.email}
                                                onChange={this.__handleTextChange}
                                                validators={['isEmail']}
                                                errorMessages={['email is not valid']}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextValidator
                                                required
                                                fullWidth
                                                id="number"
                                                label="Mobile Number"
                                                name="number"
                                                value={profile.number}
                                                onChange={this.__handleTextChange}
                                                inputMode="tel"
                                                autoComplete='off'
                                                inputProps={{
                                                    autoComplete: 'off'
                                                }}
                                                placeholder="e.g. (202) 555-1234"
                                                validators={['matchRegexp:^(\\([0-9]{3}\\)) [0-9]{3}-[0-9]{4}$']}
                                                errorMessages={['Invalid phone number']}
                                                InputProps={{
                                                    inputComponent: PhoneTextMask,
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </div>

                                <div className={classes.fill} />

                                <div className={classes.button}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        {loading && <CircularProgress size="1.5em" />}
                                        {!loading && "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </DecodedContainer>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AccountContext.Consumer>
        {value => {
            return (<Profile context={value} {...props} />);
        }
        }
    </AccountContext.Consumer>
));

import {parse} from "date-fns";

class DateUtil {

    toDate(string) {
        return new Date(string);
    }

    formatIsoDateAdjust(date) {
        return `${date.getFullYear()}-${this.__formatTwoDigits((date.getMonth() + 1))}-${this.__formatTwoDigits(date.getDate())}`;
    }

    formatIsoDate(date) {
        return `${date.getFullYear()}-${this.__formatTwoDigits(date.getMonth())}-${this.__formatTwoDigits(date.getDate())}`;
    }

    formatTime(date) {
        return `${this.__formatTwoDigits(date.getHours())}:${this.__formatTwoDigits(date.getMinutes())}`;
    }


    formatTimeWithSetMinutes(date, minutes) {
        return `${this.__formatTwoDigits(date.getHours())}:${minutes.indexOf(':') > 0 ? minutes.split(':')[1] : minutes}`;
    }

    formatTimeEnd(date) {
        return `${this.__formatTwoDigits(date.getHours())}:59`;
    }

    __formatTwoDigits(digit) {
        return digit < 10 ? '0' + digit : digit;
    }

    formatDate(date) {
        let split = date.split('/')
        return `${split[2]}-${split[0]}-${split[1]}`
    }

    formatDateToAmerica(date) {
        let split = date.split('-')
        return `${split[1]}/${split[2]}/${split[0]}`
    }

    renderTimeFromMinutes = (minutes) => {

        const displayMinutes = minutes % 60;
        const displayHours = parseInt(minutes / 60);

        let display = "";

        if(displayHours > 0) {
            display = `${displayHours}`
            if(displayHours > 1) {
                display += ` hours`
            } else {
                display += ` hour`
            }
        }

        if(displayMinutes > 0) {
            display += (display.length === 0 ? "" : " " ) + `${displayMinutes}`
            if(displayMinutes > 1) {
                display += ` minutes`
            } else {
                display += ` minute`
            }
        }


        return display;
    }

    parseDate(dateString) {
        const format = "yyyy-MM-dd'T'HH:mm:ssX";
        return parse(dateString, format, new Date());
    }
}

export const dateUtil = new DateUtil();

import {globalBloc} from "../global.bloc";

export class AccountUtils {

    constructor(globalBloc) {
        this.globalBloc = globalBloc;
    }

    preferences = () => {
        return this.globalBloc.subject.value.preferences;
    }
}

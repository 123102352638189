import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, InputAdornment, TextField} from "@material-ui/core";
import EnrollmentContext from "../context";
import {accountApi} from "../../../../utils/services/account.api";
import {notificationService} from "../../../../utils/notification";
import {enrollmentEventService} from "../service/enrollment.event.service";

const styles = theme => ({
    root: {
        width: "100%",
        minHeight: "100vh",
        height: "100vh",
        backgroundColor: "#fff",
        overscrollBehavior: "contain",
        touchAction: "none",
    },

    fill: {
        flex: "1 1 auto",
    },

    title: {
        paddingTop: "50px",
        paddingLeft: "68px",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "20px",
        lineHeight: "30px",
        textTransform: "capitalize",
        color: "rgba(0, 0, 0, 0.8)",
    },

    cardWrapper: {
        display: "flex",
        padding: "35px 50px",
        flexWrap: "wrap",
    },

    card: {
        margin: "16px",
        padding: "17px 32px 20px 32px",
        width: "50vw",
        background: "#FFFFFF",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
        borderRadius: "22px",
    },

    cardContent: {

    },

    cardFooter: {
        padding: "24px 0px 10px 0px",
        display: "flex",
    },

    button: {
        paddingLeft: "24px",
        paddingRight: "24px",
        minWidth: "144px",
        height: "39px",
        background: "#00C3FF",
        borderRadius: "22px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
    },
});

class TenantUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            givenName: "",
            familyName: "",
            emrProviderId: "",
            onCallMobile: "",
            busy: true,
            submitDisabled: true,
        };
    }

    componentDidMount() {
        let { user } = this.props.context;

        let names = []

        if(user.displayName) {
            names = user.displayName.split(" ");
            this.setState({
                givenName: names[0],
                familyName: names[1],
            });
        }

        accountApi.preAuthCheck()
            .then(value => {

                const { givenName, familyName } = this.state;

                const suppliedGivenName = givenName ? givenName : value.data.givenName;
                const suppliedFamilyName = familyName ? familyName : value.data.familyName;

                let submitDisabled =
                    !(value.data.contactNumber.match(this.PHONE_NUMBER_REGEX) &&
                        suppliedGivenName?.length > 0 &&
                        suppliedFamilyName?.length > 0 &&
                        value.data.emrIdentifier.value.length > 0);

                this.setState({
                    emrProviderId: value.data.emrIdentifier.value,
                    onCallMobile: value.data.contactNumber,
                    givenName: suppliedGivenName,
                    familyName: suppliedFamilyName,
                    busy: false,
                    submitDisabled: submitDisabled,
                });
            }).catch(reason => {

                this.setState({
                    busy: false,
                })

                if(reason.response.status !== 404) {
                    notificationService.error("Error doing your preauth, please contact support, or if you know your details fill them in to proceed.");
                }
            });
    }

    NUMBER_REGEX = /^([0-9]{0,10})$/;
    PHONE_NUMBER_REGEX = /^([0-9]{10})$/;

    handleTextChange = (event) => {

        let { givenName, familyName, onCallMobile } = this.state;

        let change = {
            givenName: givenName,
            familyName: familyName,
            onCallMobile: onCallMobile,
        };

        if(event.target.name === "onCallMobile") {
            if(event.target.value.match(this.NUMBER_REGEX)) {
                change[event.target.name] = event.target.value;
            }
        } else {
            change[event.target.name] = event.target.value;
        }

        change.submitDisabled =
            !(change.onCallMobile.match(this.PHONE_NUMBER_REGEX) &&
            change.givenName.length > 0 &&
            change.familyName.length > 0);

        this.setState(change);
    };

    registerTenantUser = () => {

        this.setState({
            busy: true,
            submitDisabled: true,
        });

        let { familyName, givenName, emrProviderId, onCallMobile, submitDisabled } = this.state;

        accountApi.completeEnrollment({
            name: {
                family: familyName,
                given: givenName,
            },
            role: "provider",
            contactNumber: {
                type: "MOBILE",
                country: "+1",
                area: onCallMobile.substring(0, 3),
                prefix: onCallMobile.substring(3, 6),
                line: onCallMobile.substring(6, 10),
            }
        }).then(value => {

            setTimeout(() => enrollmentEventService.update({ type: "ENROLLMENT_COMPLETE", data: value.data }), 1000);
        }).catch(reason => {

            notificationService.error("Unable to register. Please try again. If the problem persists please contact support@decodedhealth.com and quote '" + reason + "'.")
            this.setState({
                busy: true,
                submitDisabled: true,
            });
        });
    };

    render() {

        let { classes } = this.props;
        let { user } = this.props.context;
        let { busy, givenName, familyName, emrProviderId, onCallMobile, submitDisabled } = this.state;

        return (
            <div className={classes.root}>

                <div className={classes.title}>
                    { user && user.displayName } Enrollment { busy && <CircularProgress size={20}/> }
                </div>

                <div className={classes.cardWrapper}>
                    <div className={classes.card}>
                        <div className={classes.cardContent}>
                            <TextField
                                id="enrollment-givenname"
                                label="Given name"
                                placeholder="Given name"
                                fullWidth
                                margin="normal"
                                name={"givenName"}
                                value={givenName}
                                onChange={this.handleTextChange}
                            ></TextField>
                            <TextField
                                id="enrollment-familyname"
                                label="Family name"
                                placeholder="Family name"
                                fullWidth
                                margin="normal"
                                name={"familyName"}
                                value={familyName}
                                onChange={this.handleTextChange}
                            ></TextField>
                            <TextField
                                id="enrollment-mobile-number"
                                label="On call mobile number"
                                placeholder="Placeholder"
                                fullWidth
                                margin="normal"
                                name={"onCallMobile"}
                                value={onCallMobile}
                                type={"text"}
                                onChange={this.handleTextChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </div>
                        <div className={classes.cardFooter}>
                            <div className={classes.fill} />
                            <Button variant={"contained"} color={"primary"} className={classes.button} disabled={submitDisabled} onClick={this.registerTenantUser}>Submit</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EnrollmentContext.Consumer>
        {value => {
            return (<TenantUser context={value} {...props} />);
        }
        }
    </EnrollmentContext.Consumer>
));

import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    IconButton,
    Input,
    InputAdornment,
    TextField,
    Typography,
} from "@material-ui/core";
import React, {Component} from "react";
import {SwipeEnabledListItem} from "../../CGICommon";
import {physicalExamValueStatus} from "./physicalExamUtils";
import DictateComponent from "../../CGICommon/Dictation/DictateComponent";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";
import {CancelOutlined, CheckCircle, CheckCircleOutline, DeleteOutlined} from "@material-ui/icons";
import PhysicalLinkWorkspace from "./PhysicalLinkWorkspace";
import PhysicalExamInput from "./PhysicalExamInput";
import {
    AllStatusForObservation,
    getIconForObservationFromState,
    ObservationButtonState, ObservationTypes,
    onIconClickWithState, polarity
} from "../Observations/Observations.utils";
import {DHChipWithIcon} from "../../Common";

const styles = theme => ({
    container: {
        flex: 1,
        textAlign: "right",
        marginBottom: 32,
        paddingRight: "12px",
        display: "flex",
        flexDirection: "row-reverse",
    },
    micIcon: {
        color: "#8BA3B0",
        fontSize: 18,
    },
    input: {
        width: 200,
        backgroundColor: "#fff",
        borderColor: "#E3E3E3",
        borderStyle: "solid",
        textAlign: "right",
        flex: 1,
    },
    textfieldform: {},
    textfield: {
        backgroundColor: "#fff",
        borderColor: "#E3E3E3",
        borderStyle: "solid",
    },
    textfieldUpdate: {
        backgroundColor: "#fff",
        marginRight: 12,
        paddingLeft: 16,
        fontSize: "16px",
        borderColor: theme?.palette?.CGIPrimary?.main,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 32,
        flex: 1,
        height: 39,
    },
    textfieldUpdateBase: {
        marginRight: 12,
        paddingLeft: 12,
        fontSize: "16px",
        borderWidth: 0,
        flex: 1,
        height: 39,
    },
    textfieldUpdateHighlighted: {
        backgroundColor: "#FF48E2",
        marginRight: 12,
        paddingLeft: 16,
        fontSize: "16px",
        color: "#fff",
        borderColor: "#FF48E2",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 32,
        flex: 1,
        height: 39,
    },
    header: {
        display: "flex",
    },
    typography: {
        fontSize: 18,
        color: "#323232",
        fontWeight: 600,
    },
    swipeContainer: {
        marginTop: 8,
        paddingTop: 8,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        minHeight: "56px"
    },
    swipeContainerSelected: {
        marginTop: 8,
        paddingTop: 8,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#fff",
        minHeight: "56px",
        borderRadius: "12px",
    },
    subContent: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 7,
        paddingBottom: 7,
        backgroundColor: "#fff",
        border: "1px solid #E3E3E3",
        borderRadius: 24,
        marginRight: 10,
        marginLeft: 10,
    },
    swipeGesture: {
        maxWidth: "max-content",
        minWidth: "max-content",
        marginBottom: 8,
    },
    column: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        marginRight: 32,
        marginLeft: 32,
        marginTop: 32,
        display: "flex",
        height: "100%",
        paddingBottom: 48,
    },
    rightAlign: {textAlign: "end"},
    cancelBtn: {
        marginRight: 12,
        color: "#858585",
        width: 164,
        height: 32,
    },
    saveBtn: {
        backgroundColor: "#00D1FF",
        color: "#fff",
        width: 164,
        height: 32,
    },
    inline: {
        overflow: "auto",
    },
    workspace: {
        margin: "0 0 16px 0",
    },
    fill: {
        flex: "1 1 auto",
    },
    linkedObservationRoot: {
        maxHeight: 39,
        marginRight: 16,
        border: "1px solid #000000",
    },
    linkedObservationLabel: {
        fontSize: "16px",
        marginRight: 16,
        paddingRight: 0,
    },
});

const FONT_SIZE = 8;

class AutosizeTextField extends Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = { length: props.item.value.display.length, width: this.textWidth(props.item.value.display, 'Roboto', "16px") };
    }

    __mark = () => {
        this.props.bloc.toggleFinding(this.props.parent, this.props.item);
    }

    __delete = () => {
        this.props.item.status = physicalExamValueStatus.discard;
        this.props.bloc.updateSectionValue(this.props.parent, this.props.item);
    }

    __change = (parent, item) => (e) => {

        this.setState({ width: this.textWidth(e.target.value, 'Roboto', "16px"), length: e.target.value.length });
        this.props.onChange(parent, item)(e);
    }

    __onFocus = () => {
        this.props.onFocus(this.props.parent, this.props.item);
    }

    componentDidUpdate(prevProps) {
        if(prevProps?.item && this.props?.item && this.props.item.value.display !== prevProps.item.value.display) {
            this.setState({ width: this.textWidth(this.props.item.value.display, 'Roboto', "16px"), length: this.props.item.value.display });
        }
    }

    textWidth = (text, font, fontSize) => {
        let tag = document.createElement("div");
        tag.style.position = "absolute";
        tag.style.left = "-999em";
        tag.style.whiteSpace = "nowrap";
        tag.style.font = font;
        tag.style.fontSize = fontSize;
        tag.innerHTML = text;

        document.body.appendChild(tag);

        let result = tag.clientWidth;

        document.body.removeChild(tag);

        return result;
    }

    __containsAllDDxContext = (ddxContext, codes) => {

        if(ddxContext.length === 0 || !codes) {
            return false;
        }

        for(let i = 0; i < ddxContext.length; i++) {
            if(codes.includes(ddxContext[i])) {
                return true;
            }
        }

        return false;
    }

    render() {

        const {id, classes, parent, item, bloc, } = this.props;
        const {ddxContext, valueLinks, } = bloc.subject.value;
        const {width} = this.state;

        const selected = this.__containsAllDDxContext(ddxContext, valueLinks[item.original.display]);

        return <><Input
            ref={this.ref}
            value={item.value.display}
            inputProps={{style: {width: `${width + 40}px`, minWidth: "100px", fontSize: "16px",}}}
            disableUnderline={true}
            id={`${id}`}
            onFocus={this.__onFocus}
            onChange={this.__change(parent, item)}
            className={ classes.textfieldUpdate }
            placeholder={"Input"}
            variant="outlined"
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.__delete}>
                        <CancelOutlined />
                    </IconButton>
                </InputAdornment>
            }
        /> </>
    }
}

class AutosizeTextFieldBase extends Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = { length: props.value, width: this.textWidth(props.value, 'Roboto', "16px") };
    }

    __change = (e) => {

        this.setState({ width: this.textWidth(e.target.value, 'Roboto', "16px"), length: e.target.value.length });
        this.props.onChange(e);
    }

    __onFocus = (e) => {
        if(this.props.onFocus) this.props.onFocus(e);
    }

    textWidth = (text, font, fontSize) => {
        let tag = document.createElement("div");
        tag.style.position = "absolute";
        tag.style.left = "-999em";
        tag.style.whiteSpace = "nowrap";
        tag.style.font = font;
        tag.style.fontSize = fontSize;
        tag.innerHTML = text;

        document.body.appendChild(tag);

        let result = tag.clientWidth;

        document.body.removeChild(tag);

        return result;
    }

    render() {

        const {id, classes, value, placeholder} = this.props;
        const {width} = this.state;

        return <><Input
            ref={this.ref}
            value={value}
            inputProps={{style: {width: `${width + 30}px`, minWidth: "50px", maxWidth: "250px", fontSize: "16px",}}}
            disableUnderline={true}
            id={`${id}`}
            onFocus={this.__onFocus}
            onChange={this.__change}
            className={ classes.textfieldUpdateBase }
            placeholder={placeholder || "Input"}
            variant="outlined"
        /> </>
    }
}


class LinkedObservation extends Component {

    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {};
    }

    __mark = () => {
        this.props.bloc.toggleObservation(this.props.observation);
    }

    __button = (state, observation,loading,classes) => {

        return <ButtonBase style={{backgroundColor: "transparent",}} onClick={(e) => {

            observation.__transaction.dirty = 1;

            switch (state) {
                case ObservationButtonState.confirm: {
                    if(AllStatusForObservation.final === observation.status) {
                        if(observation.polarity === polarity.positive) {
                            observation.status = AllStatusForObservation.registered;
                        }
                        observation.polarity = polarity.positive;
                    } else {
                        observation.status = AllStatusForObservation.final;
                        observation.polarity = polarity.positive;
                    }
                }
                    break;
                case ObservationButtonState.deny: {
                    if(AllStatusForObservation.final === observation.status) {
                        if(observation.polarity === polarity.negative) {
                            observation.status = AllStatusForObservation.registered;
                        }
                        observation.polarity = polarity.negative;
                    } else {
                        observation.status = AllStatusForObservation.final;
                        observation.polarity = polarity.negative;
                    }
                }
                    break;
            }
        }}
        >
            <div style={{backgroundColor: "transparent",}}>
                {getIconForObservationFromState(state, observation, { })}
                {loading && (
                    <CircularProgress
                        size={24}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                )}
            </div>
        </ButtonBase>
    }

    __containsAllDDxContext = (ddxContext, observation) => {

        if(ddxContext.length === 0) {
            return false;
        }

        for(let i = 0; i < ddxContext.length; i++) {
            if(observation.__transaction.links.includes(ddxContext[i])) {
                return true;
            }
        }

        return false;
    }

    __updateNote = (e) => {
        let { observation, } = this.props;
        this.bloc.updateObservationNote(observation, e.target.value);
    }

    render() {

        const { observation, classes, } = this.props;
        const { loading, } = this.state;
        const { ddxContext, } = this.bloc.subject.value;

        const selected = this.__containsAllDDxContext(ddxContext, observation);

        if(!selected && ddxContext.length > 0) {
            return (<></>);
        }

        return (

            <SwipeEnabledListItem
                onRemove={() => {

                }}
                onRightSwipe={() => { }}
                onLeftSwipe={() => { }}
                item={observation}
                style={{
                    maxWidth: "max-content",
                    minWidth: "max-content",
                    marginBottom: 8,
                }}
                key={`pe-${observation.bid}`}
            >
            <DHChipWithIcon
            drawerItem={false}
            style={{
                maxHeight: 39,
                minWidth: 0,
                maxWidth: 500,
                marginTop: 0,
                backgroundColor: "#ffffff",
                color: "#000000",
            }}
            icon={
                <Box style={{ marginTop: 3, }}>
                    {this.__button(ObservationButtonState.confirm,observation,loading,classes)}
                    {this.__button(ObservationButtonState.deny,observation,loading,classes)}
                </Box>
            }
            classes={{
                icon: undefined,
                root: classes.linkedObservationRoot,
                label: classes.linkedObservationLabel,
            }}
            label={<Box>{observation?.code?.value ?? observation?.code?.code?.display} <AutosizeTextFieldBase placeholder={"add note"} classes={classes} onChange={this.__updateNote} value={observation?.note}></AutosizeTextFieldBase></Box>}
            /></SwipeEnabledListItem>);
    }
}

class PhysicalExaminationContentSection extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {initialised: false,};
    }

    componentDidMount() {
        super.componentDidMount();
    }

    __updateFocus = (section, item) => {

        const { selectedSection } = this.state;
        if(section.code !== selectedSection) {
            this.props.onSelected(section.code);
        }
    }

    __applicableObservation = (observations, section) => {

        return observations?.filter(observation => {
            if(observation.metadata?.evaluationMethods.length > 0) {
                const evaluationMethods = observation.metadata.evaluationMethods;
                for(let i = 0; i < evaluationMethods.length; i++) {
                    const evaluationMethod = evaluationMethods[i];
                    if(evaluationMethod.physicalExam.section?.length > 0) {
                        const physicalExamSections = evaluationMethod.physicalExam.section;
                        for(let j = 0; j < physicalExamSections.length; j++) {
                            if(physicalExamSections[j].code.toLowerCase() === section.code.toLowerCase()) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }) || [];
    }

    render() {

        const {conditions,observations,} = this.state;
        const {section, selectedSection, classes,} = this.props;

        const applicableObservations = this.__applicableObservation(observations, section);

        return (
            <React.Fragment key={section.code}>
                <div className={classes.header}>
                    <Typography className={classes.typography}
                                component={"span"}>{section.display}</Typography> <span
                    className={classes.fill}></span>
                    {/*{*/}
                    {/*    selectedSection === section.code &&*/}
                    {/*    <DictateComponent targetSection={section} physicalExamBloc={this.bloc}/>*/}
                    {/*}*/}
                </div>
                <div
                    className={section.code === selectedSection ? classes.swipeContainerSelected : classes.swipeContainer}
                    onClick={() => this.props.onSelected(section.code)}>
                    {applicableObservations.map( observation => (<LinkedObservation
                        classes={classes}
                        observation={observation}
                        parent={section}
                        bloc={this.bloc}></LinkedObservation>)) }
                    {section.values?.map((_value, index) =>
                        <SwipeEnabledListItem
                            onRemove={() => {
                                const newValue = _value;
                                newValue.status = physicalExamValueStatus.discard;
                                this.bloc.updateSectionValue(section, newValue);
                            }}
                            onRightSwipe={() => {
                                const newValue = _value;
                                newValue.status = physicalExamValueStatus.valid;
                                this.bloc.updateSectionValue(section, newValue);
                            }}
                            onLeftSwipe={() => {
                                const newValue = _value;
                                newValue.status = physicalExamValueStatus.discard;
                                this.bloc.updateSectionValue(section, newValue);
                            }}
                            item={_value}
                            style={{
                                maxWidth: "max-content",
                                minWidth: "max-content",
                                marginBottom: 8,
                            }}
                            key={`pe-${index}`}
                        >

                            {_value.status !== -1 ?
                                <AutosizeTextField id={`auto-${index}`} onFocus={this.__updateFocus}  onChange={this.props.onItemUpdate} classes={classes}
                                                   parent={section} item={_value} bloc={this.bloc}></AutosizeTextField> :
                                <div className={classes.subContent}>
                                    <Typography
                                        style={{
                                            textDecoration:
                                                _value.status === -1
                                                    ? "line-through"
                                                    : undefined,
                                        }}
                                    >
                                        {_value.value.display}
                                    </Typography>
                                </div>}

                        </SwipeEnabledListItem>
                    )}


                    { selectedSection === section.code && <PhysicalExamInput bloc={this.bloc} conditions={conditions} onSubmit={this.props.addItem}></PhysicalExamInput> }

                </div>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<PhysicalExaminationContentSection context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

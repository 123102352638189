import React, {useRef, useState} from "react";
import {Box, Button, ButtonBase, CircularProgress, Typography, withStyles} from "@material-ui/core";
import {
    AllStatusForObservation,
    getIconForObservationFromState,
    ObservationButtonState,
    onIconClickWithState,
    onLeftSwipe,
    onRightSwipe,
    processObservationDrop,
} from "./Observations.utils";
import {DraggableItem, DropZone, SwipeEnabledListItem} from "../../CGICommon";
import {useCGIContext} from "../CGIContext";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "../../../context";
import {OntologyConstants} from "../../../bloc";
import {
    isContextObservation, isDiagnosisObservation,
    isHPIObservation, isJudgementObservation,
    isPhysicalExamObservation,
    isResultObservation
} from "../../../util/ObservationUtil";

const styles = {

};


const ALLOWED_DROP_TYPES = [...OntologyConstants.CONDITION.semantic, ...OntologyConstants.PROCEDURE_ORDERS_REQUEST.semantic, ...OntologyConstants.OBSERVATION.semantic, ...OntologyConstants.ALLERGIES.semantic]


class Observations extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.context.bloc;
        this.state = {};
    }

    render() {

        const { context } = this.props;
        const { differential } = this.state;

        return (
            <Box>
                <Box>
                    <Button variant={"contained"} color={"primary"} href={"#obs-hpi"}>HPI</Button>
                    <Button variant={"contained"} color={"primary"} href={"#obs-context"}>Context</Button>
                    <Button variant={"contained"} color={"primary"} href={"#obs-physical"}>Physical</Button>
                    <Button variant={"contained"} color={"primary"} href={"#obs-results"}>Results</Button>
                    <Button variant={"contained"} color={"primary"} href={"#obs-diagnosis"}>Diagnosis</Button>
                    <Button variant={"contained"} color={"primary"} href={"#obs-judgement"}>Judgement</Button>
                </Box>
                <DropZone
                    accept={[
                        "drawerChips",
                        "observations"]}
                    allowedSemanticTypes={ALLOWED_DROP_TYPES}
                    processDrop={(item) => {
                        processObservationDrop(context, item, differential.highlightedDx);
                    }}
                    style={{
                        minHeight: "45vh",
                        maxHeight: "45vh",
                        overflow: "auto",
                        marginTop: 16,
                        scrollBehavior: "smooth",
                    }}
                >
                    { ['hpi', 'context', 'physical', 'result', 'diagnosis', 'judgement'].map(section => this.__renderSection(section)) }
                </DropZone>
            </Box>
        );
    }

    __renderSection = (section) => {

        let observations = [];

        switch (section) {
            case 'hpi':

                observations = this.bloc.observations().getFilteredObservations(isHPIObservation);

                return (<>
                    <Box>
                        <Box id={'obs-hpi'}><Typography>HPI ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
            case 'context':

                observations = this.bloc.observations().getFilteredObservations(isContextObservation)

                return (<>
                    <Box>
                        <Box id={'obs-context'}><Typography>Context ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
            case 'physical':

                observations = this.bloc.observations().getFilteredObservations(isPhysicalExamObservation)

                return (<>
                    <Box>
                        <Box id={'obs-physical'}><Typography>Physical ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
            case 'result':

                observations = this.bloc.observations().getFilteredObservations(isResultObservation)

                return (<>
                    <Box>
                        <Box id={'obs-results'}><Typography>Results ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
            case 'diagnosis':

                observations = this.bloc.observations().getFilteredObservations(isDiagnosisObservation)

                return (<>
                    <Box>
                        <Box id={'obs-diagnosis'}><Typography>Diagnosis ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
            case 'judgement':

                observations = this.bloc.observations().getFilteredObservations(isJudgementObservation)

                return (<>
                    <Box>
                        <Box id={'obs-judgement'}><Typography>Judgement ({observations?.length || 0})</Typography></Box>
                        <Box>{
                            observations?.map((observation, index) =>
                                <DraggableItem
                                    type={"observations"}
                                    item={{
                                        ...observation,
                                        source: "observations",
                                        forceDrop: true,
                                    }}
                                    index={index}
                                    key={`${observation.bid}-${index}-${observation.status}`}
                                    accept={["observations"]}
                                    isHoverAllowed={false}
                                >
                                    <ObservationTemplate observation={observation}/>
                                </DraggableItem>
                            )
                        }</Box>
                    </Box>
                </>);
        }

        return <></>;
    }
}


const ObservationTemplate = ({observation}) => {
    const {context} = useCGIContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const clicks = useRef(0);
    const isSelected = observation?.status === AllStatusForObservation.final;
    const getTypoStyle =
        observation?.status === AllStatusForObservation.cancelled
            ? {
                textDecoration: "line-through",
                textDecorationColor: "#858585",
                color: "#858585",
            }
            : {};

    function button(state, ) {

        return <ButtonBase style={{backgroundColor: "transparent",}} onClick={(e) => {
            e.preventDefault();
            if (!loading) {
                setLoading(true);
                onIconClickWithState(state, observation, context, setLoading);
            }
        }}
        >
            <div style={{backgroundColor: "transparent",}}>
                {getIconForObservationFromState(state, observation, {

                })}
                {loading && (
                    <CircularProgress
                        size={24}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                )}
            </div>
        </ButtonBase>
    }


    return (
        <SwipeEnabledListItem
            item={observation}
            onLeftSwipe={() => {
                onLeftSwipe(observation, context);
            }}
            onRightSwipe={() => {
                onRightSwipe(observation, context);
            }}
            disableLeftSwipe={
                observation.status === AllStatusForObservation.cancelled
            }
            disableRightSwipe={
                observation.status !== AllStatusForObservation.cancelled
            }
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    marginBottom: 16,
                    lineHeight: "1rem",
                }}
            >
                <div style={{marginRight: 8}}>
                    { button(ObservationButtonState.confirm)}
                    { button(ObservationButtonState.deny)}
                </div>

                <div
                    style={{
                        WebkitTouchCallout: "none",
                        WebkitUserSelect: "none",
                        KhtmlUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                    }}
                >
                    <Typography
                        style={{fontSize: 15, color: "#323232", ...getTypoStyle}}
                    >
                        {observation?.code?.value ?? observation?.code?.code?.display}
                    </Typography>
                    <Typography
                        style={{fontSize: 12, color: "#636366", ...getTypoStyle}}
                    >
                        {observation.note}
                    </Typography>
                </div>
            </div>
        </SwipeEnabledListItem>
    );
};

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<Observations context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

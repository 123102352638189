import React, {Component} from "react";
import {DHChipWithIcon} from "../../Common";

import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, CircularProgress, TextField} from "@material-ui/core";
import ItemDetail from "../../Common/ItemDetail";
import {CheckCircle, CheckCircleOutline} from "@material-ui/icons";
import {uuidUtil} from "../../../../../../../utils/uuidUtil";

const styles = theme => ({
    inline: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    iconColorAndSize: {
        color: "#C4C4C4",
        fontSize: 32,
        marginLeft: 8,
        marginRight: 8,
    },
    heading: {
        fontWeight: 400,
        fontSize: 20,
    },
    flexLayout: {
        display: "flex",
        flex: 1,
        maxWidth: "100%",
        overflow: "auto",
    },
    selectTemplateLayout: {
        maxWidth: "100%",
        overflow: "auto",
    },
    selectTemplateList: {
        paddingRight: "24px",
    },
    items: {
        marginTop: 16,
    },
    contentInfoSec: {
        backgroundColor: "#F9F9F9",
        marginBottom: 32,
        borderRadius: 16,
    },
    content: {
      maxHeight: "50vh",
      marginBottom: "12px",
      overflow: "auto",
    },
    button: {
        width: "100%",
    },
});


class Detail extends Component {


    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {selected: []};
    }

    __select_item = (item) => {
        let {selected} = this.state;
        selected.push(item.bid);
        this.setState({selected,});
    }

    __deselect_item = (item) => {
        let {selected} = this.state;
        selected = selected.filter((_item) => _item !== item.bid);
        this.setState({selected,});
    }

    __save = () => {

        const {selected,} = this.state;
        this.props.onSave(selected)
        this.setState({selected: []});
    }

    render() {

        const {message, conditions, classes,} = this.props;
        const {selected,} = this.state;

        return (
            <Box className={classes.root}>
                <Box className={classes.content}>
                    {message && <>{message}</>}
                    {!message && conditions.map((_item, index) => {
                        const selectedItem = selected.includes(_item.bid);
                        return <>
                            <DHChipWithIcon
                                clickable
                                key={`physical-exam-link-condition-${_item.bid}`}
                                style={{
                                    minWidth: "98%",
                                    maxWidth: "98%",
                                    backgroundColor: "#f8f8f8",
                                }}
                                drawerItem={false}
                                icon={selectedItem ? <CheckCircle style={{color: "#00D1FF"}}/> : <CheckCircleOutline/>}
                                label={_item.description}
                                onClick={() => {
                                    if (selectedItem) {
                                        this.__deselect_item(_item);
                                    } else {
                                        this.__select_item(_item)
                                    }
                                }}
                            />
                        </>
                    })}
                </Box>
                <Box className={classes.actions}>
                    {!message && conditions.length > 0 &&
                        <Button className={classes.button} color={"primary"} variant={'contained'}
                                onClick={this.__save}>Add</Button>}
                </Box>
            </Box>);
    }
}


class PhysicalExamInput extends DecodedComponent {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.bloc = props.bloc;
        this.state = {initialised: false, open: false, id: uuidUtil.next()};
    }

    __handle = (e) => {
        const {id} = this.state;
        const {conditions} = this.props;
        if (e.keyCode === 13 || e.keyCode === 9 || e.code === "Tab" || e.code === "Enter") {

            const el = document.getElementById(`new-item-${id}-txt`);
            const value = el.value;
            if(value?.length > 0) {
                if (conditions.length === 0) {
                    this.__save([]);
                } else {
                    this.setState({open: true});
                }
                e.preventDefault();
            }
        }
    }
    __close = (e) => {
        this.setState({open: false});
    }

    __save = (links) => {
        const {id} = this.state;
        const el = document.getElementById(`new-item-${id}-txt`);
        const value = el.value;
        this.props.onSubmit(value, links);
        el.value = ""
        this.__close();
    }

    render() {

        const {initialised, id, conditions, open,} = this.state;
        const {classes} = this.props;

        if (!initialised) {
            return (
                <Box style={{
                    height: "100%",
                    padding: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress/>
                </Box>);
        }

        return (<Box>
            <TextField ref={this.ref} inputProps={{id: `new-item-${id}-txt`}} key={`new-item-${id}`}
                       onKeyDown={this.__handle} required={true} className={classes.textfield} id={`new-item-${id}`}
                       placeholder={"Add new finding"} autoComplete={"off"} variant="outlined"/>
            {<ItemDetail onClickAway={() => {
            }} onCloseClick={this.__close} open={open} anchorEl={this.ref.current} title={"Select related dx"}>
                {open &&
                    <Detail key={'physical-exam-item-detail'} classes={classes} bloc={this.bloc} conditions={conditions}
                            onSave={this.__save}/>}
            </ItemDetail>}
        </Box>);
    }
}

export default withStyles(styles)(PhysicalExamInput);

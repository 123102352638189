import * as rxjs from 'rxjs';
import {rpaApi} from "../../../../utils/services/rpa.api";
import {notificationService} from "../../../../utils/notification";


export class RpaJobBloc {

    rpaBloc;
    manualStepPoll;

    constructor(rpaBloc, organisationId, stepId) {

        this.rpaBloc = rpaBloc;
        this.stepId = stepId;

        this.subject = new rxjs.BehaviorSubject({
            busy: true,
            organisationId: organisationId,
            stepId: stepId,
            job: {},
        });

        this.events = new rxjs.Subject();

        this.submitResponse = this.submitResponse.bind(this);
    }

    init = () => {

        rpaApi.get(this.subject.value.stepId)
            .then(value => {

                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                    job: value.data,
                })

                }, reason => notificationService.httpError(reason));
    }

    close = () => {
    }

    subscribeToEvents = (func) => this.events.subscribe(func);
    subscribeToState = (func) => this.subject.subscribe(func);

    submitResponse = (data) => {

        if(this.subject.value.busy) return;

        this.subject.next({
            ...this.subject.value,
            busy: true,
        });

        const request = {
            message: "complete",
            action: "complete",
            inputs: data,
        }

        rpaApi.patch(this.subject.value.stepId, request)
            .then(value => {
                notificationService.success("Manual job done. Thanks you.")
                setTimeout(() => {
                    this.events.next({ event: RpaJobBlocEvent.MANUAL_STEP_COMPLETE, data: {} });
                }, 2000)
            }, reason => {
                notificationService.httpError(reason);
                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                });
            });
    }

    submitResolve = (data, callback) => {

        if(this.subject.value.busy) return;

        this.subject.next({
            ...this.subject.value,
            busy: true,
        });

        const request = {
            message: "resolve",
            action: "resolve",
            inputs: data,
        }

        rpaApi.patch(this.subject.value.stepId, request)
            .then(value => {
                notificationService.success("Manual job resolved. Thanks you.")
                setTimeout(() => {
                    this.events.next({ event: RpaJobBlocEvent.MANUAL_STEP_COMPLETE, data: {} });
                }, 2000);
            }, reason => {
                notificationService.httpError(reason);
                this.subject.next({
                    ...this.subject.value,
                    busy: false,
                });
                callback();
            });
    }
}

export class RpaJobBlocEvent {
    static MANUAL_STEP_COMPLETE = "MANUAL_STEP_COMPLETE";
}

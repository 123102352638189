import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { navigationTitleService } from "../../../../utils/title";
import DecodedComponent from "../../../shared/DecodedComponent";
import ClinicalGraphInterfaceContext from "./context";
import { Box, CircularProgress } from "@material-ui/core";
import ClinicalGraph from "./component/ClinicalGraph/ClinicalGraph";
import ClinicalNote from "./component/ClinicalGraph/ClinicalNote";
import LongitudinalGraph from "./component/LongitudinalGraph";
import { CGITabs, Drawer } from "./component/CGICommon";
import { CGIContextWrapper } from "./component/ClinicalGraph/CGIContext";
import ClinicalSummaryModal from "./component/ClinicalGraph/ClinicalSummary/ClinicalSummaryModal";
import { AddComplaintContextMenu } from "./component/CGICommon/Drawer/AddComplaintContextMenu";
import {BlocEvent, OntologyConstants} from "./bloc";
import {
  addCurrentComplaints,
  DrawerContextMenuTypes,
} from "./component/CGICommon/Drawer/Drawer.utils";
import "./rootStyle.css";
import { DrawerContextPopOver } from "./component/CGICommon/Drawer";
import PhysicalExam from "./component/ClinicalGraph/PhysicalExam/PhysicalExam";
import PhysicalExamTab from "./component/ClinicalGraph/PhysicalExam/PhysicalExamTab";


const __screen = "calc(100vh)";
const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    // top: "-56px",
    minHeight: __screen,
    maxHeight: __screen,
    height: __screen,
    overflow: "auto",
    overscrollBehavior: "contain",
    touchAction: "none",
    width: "100%",
  },
  fill: {
    flex: "1 1 auto",
  },
  tabWrapper: {
    padding: 24,
    maxHeight: "50vh",
  },
  tabWrapperNoPadding: {
    padding: 0,
    maxHeight: "50vh",
  },
  navList: {},
  navListBottom: {
    backgroundColor: colorEnvironment(),
  },
  navListItem: {
    paddingLeft: "20px",
    marginBottom: "50px",
    "&:hover": {
      "& svg": {
        "& path": {
          fill: "#07C6FF",
        },
      },
    },
    "&:active": {
      "& svg": {
        "& path": {
          fill: "#07C6FF",
        },
      },
    },
  },
  navListItemActive: {
    paddingLeft: "20px",
    marginBottom: "50px",
    "& svg": {
      "& path": {
        fill: "#07C6FF",
      },
    },
  },
  navListItemBottom: {
    paddingLeft: "20px",
    marginBottom: "10px",
  },
  navListItemIcon: {},

  icon: {
    color: "#fff",
  },
  navUserInfo: {
    height: "150px",
    color: "#fff",
    backgroundColor: "gray",
    width: "250px",
  },
  cardHeader: {
    padding: "17px 21px 0px 12px",
    display: "flex",
  },
  cardAvatar: {
    paddingRight: "13px",
  },
  avatar: {
    width: "49px",
    height: "49px",
  },
  cardTitle: {
    width: "100%",
  },
  cardTitleText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#20516A",
    paddingBottom: "12px",
  },
  cardTitleSubText: {},
});
function colorEnvironment() {
  if (window.location.href.indexOf("staging") > 0) {
    return "#2121ff";
  } else if (window.location.href.indexOf("test") > 0) {
    return "#21ff21";
  } else if (window.location.href.indexOf("localhost") > 0) {
    return "#ff2121";
  }

  return "transparent";
}
class ClinicalGraphInterface extends DecodedComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialised: false,
      chartSummary: [],
      clinicalSummary: [],
      clinicalSummaryControl: { open: false, initialised: false},
      workspace: [],
      open: true,
      tab: "0",
      drawerContextMenu: false,
      selectedDrawerContextItem: null,
    };

    this.bloc = props.bloc;
  }

  componentDidMount() {
    super.componentDidMount();
    navigationTitleService.update("Virtual Clinic - Clinical Graph Interface");
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __handleEvent = (event) => {
    let newState = {};

    const { type, data } = event;

    switch (type) {
      case BlocEvent.CLINICAL_SUMMARY_LOADED: {
        let { clinicalSummaryControl } = this.state;
        clinicalSummaryControl.open = !clinicalSummaryControl.open;
        newState.clinicalSummaryControl = clinicalSummaryControl;
      }
    }

    this.setState({
      ...newState,
    });
  };

  toggleClinicalSummaryOpen = () => {
    let { clinicalSummaryControl } = this.state;
    clinicalSummaryControl.open = !clinicalSummaryControl.open;
    this.setState({ clinicalSummaryControl: clinicalSummaryControl });
  }

  _tabChange = (event, newValue) => {
    this.setState({
      tab: newValue,
    });
  };

  _handleToggleDrawerContext = (newState) => {
    this.setState((state) => {
      return { ...state, drawerContextMenu: newState };
    });
  };
  handleToggleForDrawerContextMenu = (event, newValue) => {
    this.setState({
      drawerContextMenu: newValue,
    });
  };

  handleDrawerItemTap = (event, newValue) => {
    this.setState({
      ...this.state,
      selectedDrawerContextItem: newValue,
    });
  };
  navigateTo = (url) => {
    return () => {
      this.props.history.push(`${url}`);
    };
  };

   __getSelectedTab = () => {

     const { workspace, tab } = this.state;

     switch (tab) {
      case "0":
        return <ClinicalGraph workspace={workspace} />;
      case "1":
        return <PhysicalExamTab />;
      case "2":
        return <ClinicalNote />;
      case "3":
        return <LongitudinalGraph />;
      default:
        return <ClinicalGraph />;
    }
  };

  __renderMenu = () => {

    const { drawerContextMenu, tab } = this.state;

    if (drawerContextMenu) {

      return <React.Fragment>
        {this.state.selectedDrawerContextItem === DrawerContextMenuTypes.currentComplaint ?
            <AddComplaintContextMenu
                handleClose={() => {
                  this.handleDrawerItemTap(null);
                  this._handleToggleDrawerContext(false);
                }}
                ontologyConstants={OntologyConstants.CONDITION}
                onItemPress={addCurrentComplaints}
            /> :
            <DrawerContextPopOver
                handleClose={() => {
                  this.handleDrawerItemTap(null);
                  this._handleToggleDrawerContext(false);
                }}
            />
        }
      </React.Fragment>
    } else {

      return (
          <React.Fragment>
            <CGITabs selectedTab={tab} onChange={this._tabChange}/>
            {this.__getSelectedTab()}
          </React.Fragment>
      )
    }
  }

  __handleDrawerContextMenu = () => {

  }

  render() {
    let { classes, size } = this.props;
    const { initialised, open, clinicalSummaryControl, chartSummary, person, selectedDrawerContextItem } =
      this.state;

    if (!initialised) return <Box style={{
      display: "flex",
      flex: 1,
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    }}>
      <CircularProgress />
    </Box>;

    let context = {
      bloc: this.bloc,
      drawerItems: chartSummary,
      drawerContextMenu: this.state.drawerContextMenu,
      selectedDrawerContextMenu: selectedDrawerContextItem,
    };

    return (
      <ClinicalGraphInterfaceContext.Provider value={context}>

        <CGIContextWrapper
          content={this.state}
          bloc={this.bloc}
          handleDrawerContextMenu={(newState) => {
            this._handleToggleDrawerContext(newState);
          }}
          setSelectedDrawerContextMenu={(newValue) => {
            this.setState({
              selectedDrawerContextItem: newValue,
            });
          }}
          isDrawerContextMenuToggled={this.state.drawerContextMenu}
          selectedDrawerContextMenu={this.state.selectedDrawerContextItem}>

          <Box
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              position: "fixed",
              top: 0,
              right: 0,
              left: 0
            }}
            className="rootParent"
          >
            <Box style={{ display: "flex", flex: 1, maxWidth: 352 }}>
              <Drawer
                drawerItems={chartSummary}
                isOpen={open}
                userInfo={person}
                toggleClinicalSummary={this.toggleClinicalSummaryOpen}
              />
            </Box>

            <Box className={classes.root}>
              <Box
                hidden={size && size === "small"}
                className={
                  this.state.drawerContextMenu
                    ? classes.tabWrapperNoPadding
                    : classes.tabWrapper
                }
                style={{
                  marginLeft: open ? 355 : 0,
                  backgroundColor: "#F8F8F8",
                  flexDirection: "column",
                  display: "flex",
                  height: "100%",
                  overflow: "hidden",
                  minHeight: "100%",
                }}
              >
                {this.__renderMenu()}
              </Box>
            </Box>

            <ClinicalSummaryModal open={clinicalSummaryControl.open} toggleOpen={this.toggleClinicalSummaryOpen} />
          </Box>

        </CGIContextWrapper>
      </ClinicalGraphInterfaceContext.Provider>
    );
  }
}

export default withStyles(styles)(ClinicalGraphInterface);

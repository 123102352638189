import React, {Component} from 'react';

import EncounterContext from "./context";

import {withStyles} from '@material-ui/core/styles';
import {Button, LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText,} from '@material-ui/core';
import {customersApi} from "../../../../utils/services/customers.api";
import {notificationService} from "../../../../utils/notification";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    root: {
        padding: "30px 25px",
        display: "flex",
        flexDirection: "column",
        maxHeight: "60vh",
    },

    fill: {
        flex: "1 1 auto",
    },
    actionButton: {
        paddingLeft: "24px",
    },
    notificationText: {
        paddingRight: "64px",
    },
    summaryContainer: {
        padding: "0",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#636366",
        opacity: "0.8",
        overflow: "auto",
    },

    title: {
        paddingBottom: "18px",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "20px",
        lineHeight: "30px",
        textTransform: "capitalize",
        color: "#010101",
        opacity: "0.5",
    },
    saveBtnWrapper: {
        paddingBottom: "24px",
    },
    saveBtn: {
        width: "100%",
        background: "#37B9FD 0% 0% no-repeat padding-box",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
        font: "28px",
        fontWeight: "300",
        letterSpacing: "0",
        padding: "0px",
        paddingLeft: "8px",
        color: "#fff",
        borderRadius: "22px",
        maxHeight: "40px",
        height: "40px",
        '&:hover': {
            backgroundColor: '#20516A',
        },
        '&:focus': {
            backgroundColor: '#20516A',
        }
    },
    saveBtnTxt: {
        paddingLeft: "9px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
    },
    saveBtnIcon: {
        paddingTop: "2px",
        height: "26px",
        width: "26px",
        borderRadius: "22px",
        border: "1px solid #fff",
        boxShadow: "-10px 0px 20px #47567917",
        '& svg': {
            height: "20px",
            width: "20px",
            color: "#fff",
        },
    }
});

class NotificationsPanel extends Component {

    state = {
        resolved: [],
        localLoading: false,
    }

    encounterStateSubscription;

    constructor(props, context) {
        super(props, context);
    }


    componentDidMount() {

        this.encounterStateSubscription = this.props.context.encounterBloc.subscribeToState(this.__encounterStateHandler);
    }

    componentWillUnmount() {

        this.encounterStateSubscription.unsubscribe();
    }

    __encounterStateHandler = (state) => {

        this.setState({
            resolved: state.resolvedNotifications,
        });
    }

    __resolveInsuranceNotification = (notification) => () => {

        const { person, encounterBloc } = this.props.context;

        this.setState({
            localLoading: true,
        });

        customersApi.resolveNotifications(person.id, notification.identifier)
            .then((value) => {

                encounterBloc.resolveNotification(notification.identifier.value);

                this.setState({
                    localLoading: false,
                });

                notificationService.success(`Success`);

            }, reason => {

                notificationService.error(`Error resolving notification - ${reason}`);

                this.setState({
                    localLoading: false,
                });
            });
    }

    __renderInsuranceNotification = (notification) => {

        const { classes } = this.props;
        const { resolved, localLoading } = this.state;

        return (<>
            <ListItem>
                <ListItemText className={classes.notificationText} primary={notification.content} />
                <ListItemSecondaryAction className={classes.actionButton}>
                    { resolved.includes(notification.identifier.value) ?
                        <Button
                            disabled={true}
                            color="primary"
                            className={classes.button}
                        >
                            <FormattedMessage id={"resolve"} defaultMessage={"Resolved"} />
                        </Button>
                        :
                        <Button
                            disabled={localLoading}
                            color="primary"
                            onClick={this.__resolveInsuranceNotification(notification)}
                            className={classes.button}
                        >
                            <FormattedMessage id={"resolve"} defaultMessage={"Resolve"} />
                        </Button>
                    }

                </ListItemSecondaryAction>
            </ListItem>
        </>);
    }


    render() {

        let { classes } = this.props;
        let { person } = this.props.context;

        if(!person) return <LinearProgress />;

        return (
            <div className={classes.root}>

                <div className={classes.summaryContainer}>

                    { person && person.notifications ?
                        <>
                            <List>
                                {person.notifications.map(_notification => this.__renderInsuranceNotification(_notification) )}
                            </List>
                        </> :
                        <>
                            No notifications.
                        </>
                    }
                </div>

            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <EncounterContext.Consumer>
        {value => {
            return (<NotificationsPanel context={value} {...props} />);
        }
        }
    </EncounterContext.Consumer>
));

import * as rxjs from "rxjs";
import {chartApi} from "../../../../../../../../../utils/services/chart.api";
import {notificationService} from "../../../../../../../../../utils/notification";
import {BlocEvent} from "../../../../../bloc";
import {CommonBloc} from "../Common/bloc";

export class Bloc extends CommonBloc {

    constructor(cgiBloc) {
        super(cgiBloc, 'immunizations', 'immunization', 'B003');
    }


    initialise = () => {
        this.getImmunizations();
    }


    getImmunizations = () => {

        let {clinicId, encounterId} = this.subject.value;

        this.events.next({
            type: ImmunizationBlocEvent.IMMUNIZATION_LOADING
        })

        chartApi.getPersonChartImmunizations(clinicId, encounterId).then(result => {

            this.__updateSubject({
                resources : result.data.items
            })

            this.events.next({
                type: ImmunizationBlocEvent.IMMUNIZATION_LOAD_SUCCESS,
                data: {
                    resources: result.data.items,
                }
            })

        }).catch(error => {

            notificationService.error("Unable to retrieve patient's surgical history")

            this.events.next({
                type: ImmunizationBlocEvent.IMMUNIZATION_LOAD_ERROR,
                data: {
                    error: error,
                }
            })
        }).finally(() => {
            this.__updateSubject({
                initialised: true,
            });
        });
    }
}

export class ImmunizationBlocEvent {

    static IMMUNIZATION_LOADING = "IMMUNIZATION_LOADING";
    static IMMUNIZATION_LOAD_SUCCESS = "IMMUNIZATION_LOAD_SUCCESS";
    static IMMUNIZATION_LOAD_ERROR = "IMMUNIZATION_LOAD_ERROR";
}

import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel,} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {ValidatorForm} from 'react-material-ui-form-validator';
import AppointmentContext from "./context";

import {appointmentEventService} from "./service/appointment.event.service";
import {appointmentDataChange} from "./util";
import {validateForm} from "./util/form";
import {notificationService} from "../../../../utils/notification";

const styles = theme => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        minHeight: "100%",
    },
    fill: {
        flex: "1 1 auto",
    },
    formGrid: {
    },
    grid: {
        flex: "1 1 auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    },
    form: {
        minHeight: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    validation: {
        width: "100%",
    },
    button: {
        textAlign: "end",
    }
});

const left = 7;
const right = 5;

class Confirmation extends Component {

    __handleTextChange = appointmentDataChange.__handleTextChange;

    __handleCheckboxChange = appointmentDataChange.__handleCheckboxChange;

    __isLoadingData = () => {

        return this.props.context.loading;
    };


    __isFormValid = () => {

        const { form } = this.props.context;

        return form.isInValidLocation;
    }

    __doSubmit = () => {

        if(validateForm(this.props.context.form)) {
            appointmentEventService.update("REGISTRATION_SUBMIT", {})
        } else {
            notificationService.error("Invalid registration form please do it again.");
        }
    };


    render() {
        let {loading, classes, handleNext} = this.props;

        let { form, serviceProviders } = this.props.context;

        return (
            <div className={classes.root}>
                <ValidatorForm onSubmit={this.__doSubmit} className={classes.validation}>
                    <div className={classes.form}>
                        <div className={classes.grid}>
                            <div className={classes.fill}></div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.isInValidLocation}
                                            onChange={this.__handleCheckboxChange}
                                            id="isInValidLocation"
                                            name="isInValidLocation"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            Confirm that the patient will be physically located in the relevant state at the time of the visit.
                                        </React.Fragment>
                                    }
                                />
                            <div className={classes.fill}></div>
                        </div>
                        <div className={classes.button}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!this.__isFormValid() || this.__isLoadingData()}
                            >
                                {this.__isLoadingData() && <CircularProgress size="1.5em"/>}
                                {!this.__isLoadingData() && "Schedule"}
                            </Button>
                        </div>
                    </div>
                </ValidatorForm>
            </div>
        );
    }
}

export default withStyles(styles)(props => (
    <AppointmentContext.Consumer>
        {value => {
            return (<Confirmation context={value} {...props} />);
        }
        }
    </AppointmentContext.Consumer>
));

import {Box, Button,} from "@material-ui/core";
import React from "react";
import {physicalExamValueStatus} from "./physicalExamUtils";
import DecodedComponent from "../../../../../../shared/DecodedComponent";
import {withStyles} from "@material-ui/core/styles";
import ClinicalGraphInterfaceContext from "../../../context";
import PhysicalLinkWorkspace from "./PhysicalLinkWorkspace";
import PhysicalExaminationContentSection from "./PhysicalExaminationContentSection";

const styles = theme => ({
    container: {
        flex: 1,
        textAlign: "right",
        marginBottom: 32,
        paddingRight: "12px",
        display: "flex",
        flexDirection: "row-reverse",
    },
    micIcon: {
        color: "#8BA3B0",
        fontSize: 18,
    },
    input: {
        width: 200,
        backgroundColor: "#fff",
        borderColor: "#E3E3E3",
        borderStyle: "solid",
        textAlign: "right",
        flex: 1,
    },
    textfieldform: {},
    textfield: {
        backgroundColor: "#fff",
        borderColor: "#E3E3E3",
        borderStyle: "solid",
    },
    textfieldUpdate: {
        backgroundColor: "#fff",
        marginRight: 12,
        fontSize: "16px",
        borderColor: theme?.palette?.CGIPrimary?.main,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 32,
        flex: 1,
        height: 39,
    },
    header: {
        display: "flex",
    },
    typography: {
        fontSize: 18,
        color: "#323232",
        fontWeight: 600,
    },
    swipeContainer: {
        marginTop: 8,
        paddingTop: 8,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        minHeight: "56px"
    },
    swipeContainerSelected: {
        marginTop: 8,
        paddingTop: 8,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#fff",
        minHeight: "56px",
        borderRadius: "12px",
    },
    subContent: {
        paddingRight: 24,
        paddingLeft: 24,
        paddingTop: 7,
        paddingBottom: 7,
        backgroundColor: "#fff",
        border: "1px solid #E3E3E3",
        borderRadius: 24,
        marginRight: 10,
        marginLeft: 10,
    },
    swipeGesture: {
        maxWidth: "max-content",
        minWidth: "max-content",
        marginBottom: 8,
    },
    column: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        marginRight: 32,
        marginLeft: 32,
        marginTop: 32,
        display: "flex",
        height: "100%",
        paddingBottom: 48,
    },
    rightAlign: {textAlign: "end"},
    cancelBtn: {
        marginRight: 12,
        color: "#858585",
        width: 164,
        height: 32,
    },
    saveBtn: {
        backgroundColor: "#00D1FF",
        color: "#fff",
        width: 164,
        height: 32,
    },
    inline: {
        overflow: "auto",
        maxHeight: "100%",
    },
    workspace: {
        margin: "0 0 16px 0",
    },
    fill: {
        flex: "1 1 auto",
    },
});

class PhysicalExaminationContent extends DecodedComponent {


    constructor(props) {
        super(props);
        this.bloc = props.physicalExamBloc;
        this.state = {initialised: false, selectedSection: undefined};
    }

    setSelectedSection = (selection) => {
        this.setState({selectedSection: selection});
    }

    __updateFocus = (section, item) => {

        const { selectedSection } = this.state;
        if(section.code !== selectedSection) {
            this.setSelectedSection(section.code);
        }
    }

    __addItem = (section) => (value, links) => {
        this.bloc.addValue(section, value, links);
    }
    __updateItem = (_section, _value) => (e) => {

        let {physicalExam} = this.state;

        _value.value.display = e.target.value;
        _value.status = physicalExamValueStatus.modified;

        this.setState({physicalExam: physicalExam});

        e.preventDefault();
    }

    render() {

        const {physicalExam, selectedSection,} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.column}>
                <Box className={classes.workspace}>
                    <PhysicalLinkWorkspace conditons={this.props.conditions} bloc={this.bloc} />
                </Box>
                <div className={classes.inline}>
                    <div>
                        {physicalExam?.sections?.map((section) => (
                            <PhysicalExaminationContentSection
                                onItemUpdate={this.__updateItem}
                                addItem={this.__addItem(section)}
                                onSelected={this.setSelectedSection}
                                section={section}
                                selectedSection={selectedSection}
                                bloc={this.bloc}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(props => (
    <ClinicalGraphInterfaceContext.Consumer>
        {value => {
            return (<PhysicalExaminationContent context={value} {...props} />);
        }
        }
    </ClinicalGraphInterfaceContext.Consumer>
));

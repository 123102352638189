import {cgiApi} from "../../../../../utils/services/cgi.api";
import {notificationService} from "../../../../../utils/notification";

export class ArrivalDispositionUtil {

    constructor(bloc) {
        this.bloc = bloc;
    }

    toggleMenu = () => {
        let { arrivalDisposition } = this.bloc.subject.value;
        arrivalDisposition.open = !arrivalDisposition.open;
        this.bloc.__updateSubject({ arrivalDisposition: arrivalDisposition });
    }

    addArrival = (item, data) => {

        const {clinicId, encounter,} =
            this.bloc.subject.value;

        const payload = {
            command: "add_arrival",
            payload: {
                code: {
                    system: item.system,
                    code: item.conceptId,
                    display: item.display || item?.canonicalName,
                },
                ...data,
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "arrival");
    }


    addDisposition = (item, data) => {

        const {clinicId, encounter,} =
            this.bloc.subject.value;

        const payload = {
            command: "add_disposition",
            payload: {
                code: {
                    system: item.system,
                    code: item.code,
                    display: item.display,
                },
                ...data,
            }
        }

        this.bloc.__handleCommandResponse(cgiApi.submitCommand(clinicId, encounter.id, payload), "disposition");
    }


    patch = (item, props, callback) => {

        const {clinicId, encounter, workspaceGraph} = this.bloc.subject.value;

        const payload = {
            ...props,
        }

        const promise = item.class !== "disposition" ?
            cgiApi.updateArrival(clinicId, encounter.id, item.bid, payload) :
            cgiApi.updateDisposition(clinicId, encounter.id, item.bid, payload);

        promise.then(value => {
                if(value.data) {
                    workspaceGraph.updateNode(value.data);
                    this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
                }
                callback();
            }, reason => notificationService.httpError(reason));
    }


    delete = (item) => {

        const {clinicId, encounter, workspaceGraph,} =
            this.bloc.subject.value;

        cgiApi.deleteEntity(clinicId, encounter.id, item.class + 's', item.bid)
            .then(value => {
                workspaceGraph.removeNode(item);
                this.bloc.__updateSubject({workspaceGraph: workspaceGraph});
            }, reason => notificationService.httpError(reason));
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App  from './components/App';
import * as serviceWorker from './serviceWorker';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

import { authService } from './utils/auth';
import axios from 'axios';
import {VERSION} from "./utils/version";
import {AnalyticsEvent, analyticsEventLogger} from "./utils/events";
import {eventApi} from "./utils/services/event.api";

let config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(config);

authService.initialise(firebase.auth());

if(process.env.REACT_APP_FIREBASE_APP_ID && process.env.REACT_APP_FIREBASE_APP_ID.length > 0) {
    analyticsEventLogger.initialise(firebase.analytics());
}

ReactDOM.render(
        <Router>
            <App opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"/>
        </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


axios.interceptors.request.use(async (config) => {

    const token = await authService.getToken();
    config.headers.Authorization =  `Bearer ${token}`;
    config.headers['X-DH-source'] =  `Provider PWA`;
    config.headers['X-DH-version'] =  VERSION;
    config.headers['X-API-KEY'] =  process.env.REACT_APP_DH_API_KEY;

    return config;
}, (error) => {

    return Promise.reject(error);
});

window.onerror = function(message, src, lineno, colno, error) {
    eventApi.publish({
        source: "javascript",
        message: JSON.stringify(message),
        error: JSON.stringify(error),
        lineno: JSON.stringify(lineno),
        src: JSON.stringify(src),
    }).then(data => {}, reason => {});
    analyticsEventLogger.log(AnalyticsEvent.JAVASCRIPT_ERROR, message)
}

window.oncontextmenu = function (pointerEvent) {
    return false;
};
